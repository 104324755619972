import React, {useState} from "react";

import * as S from "./styles";
import { IProps } from "./types";

export const AddToCartButton: React.FC<IProps> = ({product}: IProps) => {

  const [isClicked, setisClicked] = useState(false);
  const [qty, setQty] = useState(0);

  const handleClick = () =>{
    setisClicked(true);
    setQty(1);
  }

  const add = () => {
    setQty(qty+ 1);
  }
  
  const subtract = () => {
    setQty(qty-1);
  }

  return (
    <>
    {isClicked && qty >=1 ?
      <S.QuantityInput>
        <S.QuantityWrapper>
        <button onClick={subtract}><i className="fas fa-minus"></i></button>
        <S.Quantity>{qty}</S.Quantity>
        <button onClick={add}><i className="fas fa-plus"></i></button>
        </S.QuantityWrapper>
      </S.QuantityInput>
    : <S.AddToCartButton onClick={handleClick}><i className="fas fa-plus"></i></S.AddToCartButton>
  }
    </>
  );
};
