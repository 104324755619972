import { Radio } from "antd";
import React, { useState } from "react";


const VariantPicker: React.FC<{
  data?: any;
}> = ({ data }) => {

  const [attribute, setAttribute] = useState(data.variants[0].variantName);
  
  const handleOnChange = (e:any) => {
    setAttribute(e.target.value)
  }
  
  return (
    <Radio.Group className="brand-description__attributes" onChange={handleOnChange} value={attribute}>
      <div className="brand-description__attributes__title">
      <h4>{data.title}</h4>
      {data.isRequired &&
      <span className="brand-description__attributes__title__required">REQUIRED</span>
      }
      </div>
      {data.variants.map((variant, i)=> (
        <Radio key={i} value={variant.variantName} className="brand-description__attributes__radio">{variant.variantName}</Radio>
      ))}
    </Radio.Group>
  );
};

export default VariantPicker;
