// import "./scss/index.scss";

import * as React from 'react';

import Media from 'react-responsive';
import {IFilterAttributes, IFilters} from '@types';
import {ProductListHeader} from '../../../@next/components/molecules';
// import { ProductList } from "../../../@next/components/organisms";
import {FilterSidebar} from '../../../@next/components/organisms/FilterSidebar';

import {maybe} from '../../../core/utils';

import {Store_products} from '../gqlTypes/StoreProducts';
import {BrandProductbyTab} from '@temp/@next/components/organisms/BrandProductbyTab';
import {smallScreen} from '@temp/@next/globalStyles/constants';
import {ProductList} from '@temp/@next/components/organisms/';
import NoProduct from './NoProduct';
interface SortItem {
	label: string;
	value?: string;
}

const SMALL_SCREEN = 541;

interface SortOptions extends Array<SortItem> {}

interface PageProps {
	activeFilters: number;
	attributes: IFilterAttributes[];
	activeSortOption: string;
	displayLoader: boolean;
	filters: IFilters;
	hasNextPage: boolean;
	products: Store_products;
	sortOptions: SortOptions;
	clearFilters: () => void;
	onLoadMore: () => void;
	onAttributeFiltersChange: (attributeSlug: string, value: string) => void;
	onOrder: (order: {value?: string; label: string}) => void;
}

const Page: React.FC<PageProps> = ({
	activeFilters,
	activeSortOption,
	attributes,
	displayLoader,
	hasNextPage,
	clearFilters,
	onLoadMore,
	products,
	filters,
	onOrder,
	sortOptions,
	onAttributeFiltersChange,
}) => {
	const canDisplayProducts = maybe(
		() => !!products.edges && products.totalCount !== undefined,
	);
	const [showFilters, setShowFilters] = React.useState(false);

	const getAttribute = (attributeSlug: string, valueSlug: string) => {
		return {
			attributeSlug,
			valueName: attributes
				.find(({slug}) => attributeSlug === slug)
				.values.find(({slug}) => valueSlug === slug).name,
			valueSlug,
		};
	};

	const activeFiltersAttributes =
		filters &&
		filters.attributes &&
		Object.keys(filters.attributes).reduce(
			(acc, key) =>
				acc.concat(
					filters.attributes[key].map((valueSlug) =>
						getAttribute(key, valueSlug),
					),
				),
			[],
		);

	const showEmptyStore = maybe(
		() => products.totalCount < 1 && activeFilters < 1,
	);

	return (
		<div className="category">
			{attributes && attributes.length && attributes.length > 0 ? null : (
				<>
					<FilterSidebar
						show={showFilters}
						hide={() => setShowFilters(false)}
						onAttributeFiltersChange={onAttributeFiltersChange}
						attributes={attributes}
						filters={filters}
					/>
					<ProductListHeader
						activeSortOption={activeSortOption}
						openFiltersMenu={() => setShowFilters(true)}
						numberOfProducts={products ? products.totalCount : 0}
						activeFilters={activeFilters}
						activeFiltersAttributes={activeFiltersAttributes}
						clearFilters={clearFilters}
						sortOptions={sortOptions}
						onChange={onOrder}
						onCloseFilterAttribute={onAttributeFiltersChange}
					/>
				</>
			)}
			{canDisplayProducts && (
				<>
					<Media maxWidth={smallScreen}>
						<BrandProductbyTab
							products={products.edges.map((edge) => edge.node)}
							canLoadMore={hasNextPage}
							loading={displayLoader}
							onLoadMore={onLoadMore}
							isStorePage={true}
							isMerchantProduct={true}
							title={'Best Sellers'}
						/>
						<BrandProductbyTab
							products={products.edges.map((edge) => edge.node)}
							canLoadMore={hasNextPage}
							loading={displayLoader}
							onLoadMore={onLoadMore}
							isStorePage={true}
							isMerchantProduct={true}
							title={'Drinks'}
						/>
					</Media>
					<Media minWidth={SMALL_SCREEN}>
						<ProductList
							products={products.edges.map((edge) => edge.node)}
							canLoadMore={hasNextPage}
							loading={displayLoader}
							onLoadMore={onLoadMore}
							isStorePage={true}
							isMerchantProduct={false}
						/>
					</Media>
				</>
			)}
			{showEmptyStore && <NoProduct />}
		</div>
	);
};

export default Page;
