import gql from 'graphql-tag';

import {TypedMutation} from '../../../core/mutations';
import {
	RegisterAccount,
	RegisterAccountVariables,
} from './gqlTypes/RegisterAccount';

const accountRegisterMutation = gql`
	mutation RegisterAccount(
		$firstName: String!
		$lastName: String!
		$phone: String!
		$email: String!
		$password: String!
		$redirectUrl: String!
	) {
		accountRegister(
			input: {
				firstName: $firstName
				lastName: $lastName
				phone: $phone
				email: $email
				password: $password
				redirectUrl: $redirectUrl
			}
		) {
			errors {
				field
				message
			}
			requiresConfirmation
		}
	}
`;

export const TypedAccountRegisterMutation = TypedMutation<
	RegisterAccount,
	RegisterAccountVariables
>(accountRegisterMutation);
