import gql from "graphql-tag";
import { TypedMutation } from "src/core/mutations";
import { fragementCheckout } from "./fragments";

interface OrderCreateCheckoutCheckout {
  id?: string;
  token: any;
  email?: string;
}

interface OrderCreateCheckout {
  data: {
    orderCreateCheckout: {
      checkout: OrderCreateCheckoutCheckout;
    };
  };
}

interface OrderCreateCheckoutVariables {
  orderId: string;
}

const orderCreateCheckout = gql`
  ${fragementCheckout}
  mutation reCreateOrder($orderId: ID!) {
    orderCreateCheckout(orderId: $orderId) {
      checkout {
        ...Checkout
      }
    }
  }
`;
export const TypedOrderCreateCheckout = TypedMutation<
  OrderCreateCheckout,
  OrderCreateCheckoutVariables
>(orderCreateCheckout);
