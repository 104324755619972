import {media, styled} from '@styles';

export const AddressForm = styled.form`
	width: 100%;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const RowWithTwoCells = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& > div {
		width: calc(50% - ${(props) => props.theme.spacing.fieldSpacer} / 2);
		${media.smallScreen`
      width: 100%;
    `}
	}
`;

export const RowWithOneCell = styled.div`
	width: 100%;
`;

export const GoogleImage = styled.div`
	margin-top: 0.5rem;
	padding-bottom: 1rem;
`;
