import * as React from "react";
// import BrandProduct from "../BrandProduct";
import BranchDetails from './BranchDetails';
// import CheckoutSummary from "../CheckoutSummary";
import { StoreDetailsMerchantBySlug } from "./gqlTypes/StoreDetails"
// import { ProductDetails_product } from "./gqlTypes/ProductDetails"
import { Breadcrumb } from "@temp/components";
import { useLayout } from '@temp/layout/hooks/useLayout';

interface PageProps {
    merchant: StoreDetailsMerchantBySlug;
    hasProductNextPage: boolean;
    productLoadMore: () => void;
    isProductLoading: boolean;
    breadcrumbs?: Breadcrumb[];
}

const Page: React.FC<PageProps> = ({
    merchant,
    isProductLoading,
    hasProductNextPage,
    breadcrumbs,
    productLoadMore,
}) => {
    const { setQueryParams, setShowCategorybar, showCategorybar } = useLayout();
    React.useEffect(()=>{
        setShowCategorybar(false);
    },[showCategorybar])
    return (
        <div className="branch-page">
            <section className="branch-page-section">
                <BranchDetails 
                    breadcrumbs={breadcrumbs}
                    isProductLoading={isProductLoading}
                    hasProductNextPage={hasProductNextPage}
                    productLoadMore={productLoadMore}
                    merchant={merchant}
                    // setProductOverlay={setProductOverlay}
                    setProductOverlay={(status, productId) => {
                        // Add "vwProductId" in querystring to show global product layout
                        setQueryParams({
                            vwProductId: status ? productId : null
                        })
                    }}
                />
            </section>
            {/* <aside className="branch-page-cart">
                <CheckoutSummary/>
            </aside> */}
            {/* {productOverlay && 
            <div className="branch-page-product">
                <div className="branch-page-product__contents">
                    <BrandProduct 
                        setProductOverlay={setProductOverlay} 
                        loading={isProductDetailsLoading}
                        product={product} 
                        merchant={merchant}
                    />
                </div>
                <div onClick={()=> setProductOverlay(false)} className="branch-page-product__overlay"></div>
            </div>} */}
        </div>
    );
}

export default Page;