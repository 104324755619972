import { styled } from "@styles";

export const AddToCartButton = styled.button`
  position: absolute;
  background-color: #4c3eff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  right: 10px;
  bottom: 15px;
  outline: none !important;
  z-index: 2;
`;

export const QuantityInput = styled.div`
  position: absolute;
  width: 90%;
  right: 5%;
  bottom: 15px;
  outline: none !important;
  z-index: 2;
`;

export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  color: #fff;
  padding: 8px 10px;
  font-size: 16px;
  background-color: #4c3eff;
  button {
    outline: none !important;
  }
`;

export const Quantity = styled.div``;
