import React from 'react';
import AddToCartButton from './AddToCartButton';
import './index.scss';
import QuantityInput from './QuantityInput';

interface MoneyProps {
	amount: number;
	currency: string;
}

interface PriceProps {
	gross: MoneyProps;
	net: MoneyProps;
}

interface IProps{
	defaultQty?: number;
	price: PriceProps;
	disable?: boolean;
	addToCartText?: string | null;
	onAddedText?: string | null;
	onAdd: (quantity: number, total: number) => void;
	setProductOverlay?: (bool: boolean) => void;
}

const ProductAddToCart:React.FC<IProps> = ({price, disable = false, addToCartText, onAddedText, defaultQty, onAdd, setProductOverlay})=> {
	const [quantity, setQuantity] = React.useState(defaultQty || 1);
	const amount = (price?.gross?.amount || 0) * quantity;

	const closeModal = () => {
		setTimeout(()=>{
			setProductOverlay(false)
		},1000)
	}

	return (	
		<div className="brand-product-add">
			<div className="brand-product-add__container px-1">
				<QuantityInput 
					disable={disable}
					quantity={quantity}
					onDeduct={() => {
						const newQty = quantity - 1;
						if (!disable) {
							setQuantity(newQty < 1 ? 1 : newQty);
						}
					}}
					onAdd={() => {
						const newQty = quantity + 1;
						if (!disable) {
							setQuantity(newQty);
						}
					}}
				/>
				<AddToCartButton 
					addToCartText={addToCartText}
					disable={disable}
					onAddedText={onAddedText}
					amount={amount} 
					onPress={() => {
						if (disable) {
							return;
						}

						onAdd(quantity, amount);
						setQuantity(1);
						closeModal();
					}}
				/>
			</div>
		</div>
	);

};

export default ProductAddToCart;
