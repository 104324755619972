import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { StoreFeaturedProducts } from "./gqlTypes/StoreFeatureProducts";

export const priceFragment = gql`
  fragment Price on TaxedMoney {
    gross {
      amount
      currency
    }
    net {
      amount
      currency
    }
  }
`;

const basicProductFragment = gql`
  fragment BasicProductFields on MerchantProduct {
    id
    name
    leadTime
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
    }
  }
`;

const productPricingFragment = gql`
  ${priceFragment}
  fragment ProductPricingField on MerchantProduct {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
      priceRange {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
    }
  }
`;

export const storeFeaturedProducts = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  query FeaturedMerchants {
    featuredMerchants {
      merchant {
        id
        storeTitle
        slug
        products (first: 5, filter: {
          isPublished: true
        }) {
          edges {
            node {
              ...BasicProductFields
              ...ProductPricingField
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const TypedStoreFeaturedProducts = TypedQuery<StoreFeaturedProducts, {}>(
    storeFeaturedProducts
);