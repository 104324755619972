import React from "react";

interface MyInfoProps {
  data?: any;
}

const MyInfo: React.FC<Partial<MyInfoProps>> = ({
  data
}) => {

  return (
    <div className="order-details__myInfo">
      <div className="order-details__myInfo__text">
      <h3>My Info</h3>
      </div>
    <span className="order-details__myInfo__myInfoText">Full Name <p>{data ? `${data?.firstName} ${data?.lastName}` : 'Guest'}</p></span>
      <span className="order-details__myInfo__myInfoText">Email Address <p>{data?.email}</p></span>
      <span className="order-details__myInfo__noteText"><i className="fas fa-info-circle mr-2 mt-1"></i>
      All emails will be sent to your registered email
      address, while SMS will be sent to the phone
      number indicated in your delivery address.
      </span>
  </div>
  );
};

export default MyInfo;