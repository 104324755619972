import React, {FC} from 'react';
import {ChevronLeft, 
	// Download
} from 'react-feather';
import {AiOutlineInfo} from 'react-icons/ai';
import { useHistory } from 'react-router';
import Icon from './Icon';
import './index.scss';
import BranchBannerPlaceholder from '../../../../images/merchant-placeholder-img-background.png'
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
interface BannerProps {
	imgUrl: string;
	setShowBranchInfo?: (value: boolean) => void;
}

const Banner: FC<BannerProps> = ({imgUrl, setShowBranchInfo}) => {
	const history = useHistory();
	const isDesktop = useMediaQuery({ minWidth: mediumScreen });
	
	return (
		<section className="banner">
			<div className="main-container">
				<div className="banner-icon">
					<div onClick={()=> history.goBack()} className="banner-icon__back">
						<Icon style={{backgroundColor: '#76aad3'}} IconComponent={ChevronLeft} className="pr-1" />
					</div>
					<div className="banner-icon__other">
						{/* <Icon style={{backgroundColor: '#76aad3'}} className="mr-3" IconComponent={Download} /> */}
						<Icon onClick={()=> setShowBranchInfo(true)} style={{backgroundColor: '#76aad3'}} IconComponent={AiOutlineInfo} />
					</div>
				</div>
			</div>
			<div
				style={{
					height: `${isDesktop ? '450px': '250px'}`,
					width: '100%',
					backgroundImage: `url(${imgUrl ? imgUrl : BranchBannerPlaceholder})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
				}}
			/>
		</section>
	);
};

export default Banner;
