import {styled} from '@styles';

export const Wrapper = styled.div`
	display: grid;
	grid-row-gap: 0px;
	grid-column-gap: 15px;
	grid-template-columns: 60px auto auto;
	grid-template-areas:
		'photo name price'
		'photo variant quantity'
		'photo sku leadTime'
		'photo . .';
`;
export const Variant = styled.div`
	grid-area: variant;
	color: ${(props) => props.theme.colors.baseFont};
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;

export const Photo = styled.div`
	grid-area: photo;
	width: min-content;

	img {
		height: auto;
		max-width: 60px;
	}
`;
export const Sku = styled.div`
	grid-area: sku;
	color: ${(props) => props.theme.colors.lightFont};
	font-size: 12px;
	margin-bottom: 0.5rem;
`;

export const Name = styled.div`
	grid-area: name;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	color: ${(props) => props.theme.colors.baseFont};
`;

export const Price = styled.div`
	grid-area: price;
	text-align: right;
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;

export const LeadTime = styled.div`
	grid-area: leadTime;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	span {
		margin-left: 5px;
		font-size: 14px;
		padding-top: 3px;
	}
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;

export const Quantity = styled.div`
	grid-area: quantity;
	text-align: right;
	color: ${(props) => props.theme.colors.baseFont};
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;
