import React from 'react';
// import MobileRoutes from './Mobile';
// import { useMediaQuery } from 'react-responsive';
// import { smallScreen } from "@temp/@next/globalStyles/constants";
import DesktopRoutes from './Desktop';

const Route = () => {
  // For now we should use the same route for all, instead of 2 routes (mobile & desktop)
  // const isMobileScreen = useMediaQuery({ maxWidth: smallScreen });
  // if(isMobileScreen) {
  //   return <MobileRoutes />
  // }
  return <DesktopRoutes /> 
}

export default Route;
