import { smallScreen } from "../../globalStyles/scss/variables.scss";

import classNames from "classnames";
import React from "react";
import Media from "react-media";

import { Breadcrumbs, ProductDescription } from "../../components";
import { 
  generateProductUrl, 
  generateStoreUrl,
  capitalizeFirstLetter,
  generateStoreCategoryBreadcrumbs
} from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
// import OtherProducts from "./Other";
import RelatedProducts from "./RelatedProducts";

import { ProductDescription as NewProductDescription } from "../../@next/components/molecules";
import { ProductGallery } from "../../@next/components/organisms/";
import { structuredData } from "../../core/SEO/Product/structuredData";
import { IProps } from "./types";
import BrandProductDescription from "@temp/components/BrandProductDescription";
import MobileGalleryCarousel from "./MobileGalleryCarousel";

const SMALL_SCREEN = 541;
const populateBreadcrumbs = product => {
  const { merchant } = product || {};
  const { merchantCategoryAssignment } = merchant || {};
  const storeTitle = product?.merchant?.storeTitle ? product?.merchant?.storeTitle : "";
  let breadcrumbs = [];

  // Add store category hierarchy in breadcrumbs
  if (merchantCategoryAssignment?.edges && merchantCategoryAssignment?.edges.length > 0) {
    const { merchantCategory } = merchantCategoryAssignment.edges[0].node || {};
    breadcrumbs = generateStoreCategoryBreadcrumbs(breadcrumbs, merchantCategory);
  }
  
  // either show category or product 
  breadcrumbs.push({
    link: generateStoreUrl(product?.merchant?.slug),
    value: storeTitle,
  });
  // breadcrumbs.push(
  //   location.pathname && location.pathname.startsWith("/store-product/")
  //   ? {
  //     link: generateStoreUrl(product?.merchant?.slug),
  //     value: storeTitle,
  //   } : {
  //     link: generateCategoryUrl(product.category.id, product.category.name),
  //     value: product.category.name,
  //   }
  // );
  
  // Product breadcrumbs
  breadcrumbs.push({
    link: generateProductUrl(product.id, product.name),
    value: product.name,
  });

  return breadcrumbs;
}

const Page: React.FC<
  IProps & {
    queryAttributes: Record<string, string>;
    onAttributeChangeHandler: (slug: string | null, value: string) => void;
  }
> = ({ add, product, items, queryAttributes, onAttributeChangeHandler }) => {
  const { merchant, relatedProducts } = product || {};
  const { products: merchantProducts } = merchant || {}
  const productGallery: React.RefObject<HTMLDivElement> = React.useRef();
  const [variantId, setVariantId] = React.useState("");

  const getImages = () => {
    if (product.variants && variantId) {
      const variant = product.variants
        .filter(variant => variant.id === variantId)
        .pop();
      if (variant.images.length > 0) {
        return variant.images;
      } else {
        return product.images;
      }
    } else {
      return product.images;
    }
  };

  const newMerchantProducts = merchantProducts.edges?.filter(item => product.name !== item.node.name);
 
  const productDescription = (
    <ProductDescription
      items={items}
      productId={product.id}
      name={product.name}
      merchant={merchant}
      productVariants={product.variants}
      pricing={product.pricing}
      leadTime={product.leadTime}
      queryAttributes={queryAttributes}
      addToCart={add}
      setVariantId={setVariantId}
      onAttributeChangeHandler={onAttributeChangeHandler}
    />
  );

   const mobileProductDescription = (
    <BrandProductDescription
      name={product.name}
      descriptionJson={product?.descriptionJson}
    />
    );
  return (
  <>
  {/* START OF DESKTOP VIEW */}
  <Media query={{ minWidth: SMALL_SCREEN }}>
    <div className="product-page">
      <div className="container">
        <Breadcrumbs breadcrumbs={populateBreadcrumbs(product)} />
      </div>
      <div className="container">
        <div className="product-page__product">
          {/* Add script here */}
          <script className="structured-data-list" type="application/ld+json">
            {structuredData(product)}
          </script>

          {/*  */}
          <Media query={{ maxWidth: smallScreen }}>
            {matches =>
              matches ? (
                <>
                  <GalleryCarousel images={getImages()} />
                  <div className="product-page__product__info">
                    {productDescription}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="product-page__product__gallery"
                    ref={productGallery}
                  >
                    <ProductGallery images={getImages()} />
                  </div>
                  <div className="product-page__product__info">
                    <div
                      className={classNames(
                        "product-page__product__info--fixed"
                      )}
                    >
                      {productDescription}
                    </div>
                  </div>
                </>
              )
            }
          </Media>
        </div>
      </div>
      <div className="container">
        <div className="product-page__product__description">
          <NewProductDescription
            descriptionJson={product.descriptionJson}
            attributes={product.attributes}
            merchant={merchant}
          />
        </div>
      </div>
      <div className="product-page__product__background">
      <div className="container product-page__product__wrapper">
      {merchantProducts?.edges && merchantProducts?.edges.length > 0 && newMerchantProducts?.length > 0 &&
      <RelatedProducts 
        title={`More from ${capitalizeFirstLetter(merchant?.storeTitle || merchant?.name)}`} 
        products={merchantProducts ? newMerchantProducts : []} 
        sideButtonTitle="View More From Store"
        sideButtonRedirectUrl={generateStoreUrl(merchant?.slug)}
        isMerchantProduct={true}
        extraPaddingTop={true}
      />}
      {relatedProducts?.edges && relatedProducts?.edges.length > 0 &&
      <RelatedProducts 
        title={`Related Products`} 
        products={relatedProducts.edges} 
        extraPaddingTop={true}
        extraPaddingBottom={true}
      />
      }
      </div>
    </div>
  </div>
  </Media>
  {/* END OF DESKTOP VIEW */}

  {/* START OF MOBILE VIEW */}
  <Media query={{ maxWidth: smallScreen }}>
  <div className="mobile-product-page">
      <div className="container">
        <div className="mobile-product-page__product">
          <script className="structured-data-list" type="application/ld+json">
            {structuredData(product)}
          </script>
          <Media query={{maxWidth: smallScreen}}>
            <>
             <MobileGalleryCarousel images={getImages()} />
               <div className="mobile-product-page__product__info">
               {mobileProductDescription}
               </div>
            </>
          </Media>
        </div>
      </div>
    </div>
  </Media>
  {/* END OF MOBILE VIEW */}
  </>
  );
};

export default Page;
