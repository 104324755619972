import React from "react";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Address summary
 */
const AddressSummary: React.FC<IProps> = ({ address, email }: IProps) => {
  if (address) {
    return (
      <S.Wrapper data-test="addressTile">
        {`${address.firstName} ${address.lastName}`} | {` `} 
        {address.phone && (
          <>
          <span>
            {/* Phone number:  */}
            {address.phone}
            </span>
          </>
        )}
        <br />
        {address.companyName && (
          <>
        <span style={{display: 'inline-block'}}>{address.companyName}</span>
          </>
        )}
        
        <>
        {/* <span style={{fontFamily:'RockoBold',display: 'block', marginBottom: '0.2rem', marginTop: '0.5rem'}}>PINNED ADDRESS:</span> {" "}  */}
        <span style={{display: 'inline-block'}}>{address.streetAddress1}</span>
        {/* <span style={{fontFamily:'RockoBold', display: 'block', marginBottom: '0.2rem'}}>ADDRESS DETAILS:</span> {" "} */}
        {address.streetAddress2 && 
        <> 
          <span style={{display: 'inline-block'}}>{address.streetAddress2}</span>
        </>},{" "}
        <span style={{display: 'inline-block'}}>
        {address.city}, {address.postalCode}
        {address.countryArea && <> {address.countryArea}</>}{" "}
        {address.country?.country}
        </span>
        <br/>
        </>
        
        {email && (
          <>
            {/* Email:  */}
            {email} <br />
          </>
        )}
      </S.Wrapper>
    );
  } else if (email) {
    return <S.Wrapper data-test="emailTile">{email}</S.Wrapper>;
  }
  return null;
};

export { AddressSummary };
