import React from 'react';
import './index.scss';

interface IProps{
	quantity: number;
	onDeduct: () => void;
	onAdd: () => void;
	disable: boolean;
}

const QuantityInput:React.FC<IProps> = ({disable = false, quantity, onDeduct, onAdd})=> {
	return (
		<div className={`brand-product-add__quantity ${disable && "brand-product-add__disabled"}`}> 
		<div className="brand-product-add__quantity__wrapper">
		<button onClick={onDeduct}>
			<i className="fas fa-minus"></i>
		</button>
		<h4>{quantity}</h4>
		<button onClick={onAdd}>
			<i className="fas fa-plus"></i>
		</button>
		</div>
	  </div>
	);

};

export default QuantityInput;
