import React, {FC} from 'react';
import {Skeleton} from '@material-ui/lab';
import './index.scss';
interface SearchAddressCardProps {
	address1?: string;
	address2?: string;
	className?: string;
	isPreloader?: boolean;
	name?: string;
	notes?: string;
	onSelect?: (data?: any) => void;
	onEdit?: (data?: any) => void;
	onDelete?: (data?: any) => void;
	phone?: string;
	isDefault?: boolean;
	addressName?: string;
	selected?: boolean;
}

const SearchAddressCard: FC<SearchAddressCardProps> = ({
	address1,
	address2,
	className,
	isPreloader,
	name = '[name]',
	notes = null,
	phone = '[phone]',
	onSelect,
	onEdit,
	onDelete,
	isDefault,
	addressName,
	selected = false
}) => {
	const handleOnClick = () => onSelect();

	if (isPreloader) {
		return (
			<div className="search-address--card">
				<div className="d-flex align-items-center">
					<div className="pr-3 flex-grow-1">
						<Skeleton height={12} animation="wave" width="100%" />
					</div>
					<Skeleton
						className="flex-shrink-0 mr-3"
						animation="wave"
						variant="circle"
						width={25}
						height={25}
					/>
					<Skeleton
						className="flex-shrink-0"
						animation="wave"
						variant="circle"
						width={25}
						height={25}
					/>
				</div>
				<Skeleton className="mt-2" height={12} animation="wave" width="87%" />
				<div className="d-flex mt-2">
					<Skeleton className="mr-3" height={12} animation="wave" width="27%" />
					<Skeleton height={12} animation="wave" width="27%" />
				</div>
				<Skeleton className="mt-2" height={12} animation="wave" width="100%" />
			</div>
		);
	}

	return (
		<div className={`${selected ? 'search-address--active' : 'search-address--card'} ${className}`}>
			<header className="search-address--card__header">
				<div onClick={handleOnClick}>
					{selected && <div className="search-address--card__selected"><p>Selected address</p></div>}
				</div>
				<div className="flex-shrink-0 d-flex align-items-center">
					<div
						className="flex-shrink-0 mr-3"
						style={{width: '25px', height: '25px', cursor: 'pointer'}}
						onClick={onEdit}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="#152c59"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
							/>
						</svg>
					</div>
					<div
						className="flex-shrink-0"
						style={{width: '25px', height: '25px', cursor: 'pointer'}}
						onClick={onDelete}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="#152c59"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
							/>
						</svg>
					</div>
				</div>
			</header>
			<div onClick={handleOnClick} className="p-3 flex-grow-1" style={{cursor: 'pointer'}}>
				<div className="d-flex align-items-center">
					<i style={{fontSize: 20, color: '#152c59'}} className="fas fa-map-marker-alt"></i>
					<div className="search-address--card__address px-2">
						{address1}
					</div>
				</div>
				<div className="mt-3 search-address--card__details">{address2}</div>
				<div className="search-address--card__details">
					{name} | {phone}
				</div>
				{notes && 
				<div className="w-100 flex-grow-1 mt-2">
					{notes}
				</div>}
			</div>
		</div>
	);
};

export default SearchAddressCard;
