import React from "react";

import { useCreateUserAddress, useUpdateUserAddress } from "@saleor/sdk";

import { AddressForm } from "../AddressForm";
import { Modal } from "../Modal";

import { CountryCode } from "@saleor/sdk/lib/gqlTypes/globalTypes";
import { IProps } from "./types";
import { IFormError } from "@types";
import { useAlert } from "react-alert";

export const AddressFormModal: React.FC<IProps> = ({
  hideModal,
  submitBtnText,
  target,
  title,
  userId,
  address,
  formId,
  ...props
}: IProps) => {
  const [show, setShow] = React.useState(true);
  let errors: any[] | undefined = [];
  const alert = useAlert();
  const [addressErrors, setAddressErrors] = React.useState<IFormError[]>([]);

  const [
    setCreatUserAddress,
    { data: createData, error: addressCreateErrors },
  ] = useCreateUserAddress();

  const [
    setUpdateUserAddress,
    { data: updateData, error: addressUpdateErrors },
  ] = useUpdateUserAddress();

  if (addressCreateErrors) {
    errors = addressCreateErrors.extraInfo.userInputErrors;
  }

  if (addressUpdateErrors) {
    errors = addressUpdateErrors.extraInfo.userInputErrors;
  }

  React.useEffect(() => {
    if (
      (createData && !addressCreateErrors) ||
      (updateData && !addressUpdateErrors)
    ) {
      hideModal();
    }

    setAddressErrors(errors || []);
  }, [createData, updateData, addressCreateErrors, addressUpdateErrors]);

  return (
    <Modal
      submitButtonTestingContext="submitAddressFormModalButton"
      testingContext="submitAddressFormModal"
      title={title}
      hide={() => {
        hideModal();
        setShow(false);
      }}
      formId={formId}
      disabled={false}
      show={show}
      target={target}
      submitBtnText={submitBtnText}
    >
      <AddressForm
        {...props}
        {...{ errors }}
        formId={formId}
        address={address ? address.address : undefined}
        errors={addressErrors}
        handleSubmit={data => {

          if (!data?.firstName || data.firstName === "") {
            setAddressErrors([{ field: "firstName", message: "Please provide your first name." }]);
            return;
          }

          if (!data?.lastName || data.lastName === "") {
            setAddressErrors([{ field: "lastName", message: "Please provide your last name." }]);
            return;
          }

          if (!data?.phone || data.phone === "") {
            setAddressErrors([{ field: "phone", message: "Please provide phone number." }]);
            return;
          }

          if (!data?.streetAddress1 || data.streetAddress1 === "") {
            setAddressErrors([{ field: "streetAddress1", message: "Please select an address. e.g. Landmark, Bldg, Street" }]);
            return;
          }

          if (!data?.streetAddress2 || data.streetAddress2 === "") {
            setAddressErrors([{ field: "streetAddress2", message: "Please provide address details" }]);
            return;
          }
          
          if (!!userId) {
            setCreatUserAddress({
              input: {
                ...data,
                country: data?.country?.code as CountryCode,
              },
            });
            alert.success({
              content: 'Successfully added new address',
              title: 'Success!',
            }, {
              timeout: 2000,
            });
          } else {
            setUpdateUserAddress({
              id: address!.id,
              input: {
                ...data,
                country: data?.country?.code as CountryCode,
              },
            });
            alert.success({
              content: 'Successfully updated your address',
              title: 'Success!',
            }, {
              timeout: 2000,
            });
          }
        }}
      />
    </Modal>
  );
};
