import './scss/index.scss';

import * as React from 'react';
import {Link} from 'react-router-dom';
import ReactSVG from 'react-svg';

import {baseUrl} from '../../app/routes';
import NavItem, {INavItem} from './NavItem';
import SideNavFooter from './SideNavFooter';

import logoutSVG from '@images/arrow-back.svg'; 
import backImg from '@images/chevron-left.svg';
// import logoImg from "@images/JRMall-logo-mobile.svg";
import shoppingCart from '@images/shopping-cart-2.svg';
import close from '@images/close.svg';
import {signIn, signUp} from '@temp/routes/paths';
import { MENU_ACCOUNTS_STRING } from "src/constants";

interface NavListProps {
	items: INavItem[];
	user?: any | null;
	hideOverlay(): void;
	signOut?(): void;
}

interface NavListState {
	parent: INavItem | null;
	displayedItems: INavItem[];
	hasSubCategory: boolean;
}

class NavList extends React.PureComponent<NavListProps, NavListState> {
	state: NavListState = {
		displayedItems: this.props.items,
		hasSubCategory: false,
		parent: null,
	};

	handleShowSubItems = (item: INavItem) => {
		this.setState({
			parent: item,
			displayedItems: item.children,
			hasSubCategory: true,
		});
	};

	handleGoBack = () => {
		const grandparent = this.state.parent.parent;

		if (!grandparent) {
			this.setState({
				parent: null,
				displayedItems: this.props.items,
				hasSubCategory: false,
			});
		} else {
			const newParent = this.findItemById(grandparent.id);
			this.setState({
				displayedItems: newParent.children,
				parent: newParent,
			});
		}
	};

	findItemById(id: string): INavItem {
		let match = null;
		function find(item) {
			if (item.id === id) {
				match = item;
				return true;
			}
			return item.children && item.children.some(find);
		}
		this.props.items.some(find);
		return match;
	}

	render() {
		const {hideOverlay, user} = this.props;
		const {displayedItems, parent, hasSubCategory} = this.state;

		const accountMenu: INavItem = {
			__typename: "MenuItem",
			id: "",
			name: MENU_ACCOUNTS_STRING,
			url: "/account/",
			iconName: "fas fa-hamburger mr-3",
			children: [
				{ name: "Address Book", url: "/address-book/", __typename: "MenuItem", id: "1" },
				{ name: "Order History", url: "/order-history/", __typename: "MenuItem", id: "2" },
			]
		};

		return (
			<>
				<ul>
					{parent ? (
						<li className="side-nav__menu-item side-nav__menu-item-back">
							<span onClick={this.handleGoBack}>
								<ReactSVG path={backImg} /> {parent.name}
							</span>
						</li>
					) : (
						<>
							<li className="side-nav__menu-item border-0 side-nav__menu-item--parent">
								<Link
									to={baseUrl}
									className="side-nav__menu-item-logo"
									onClick={hideOverlay}
								>
									{/* <ReactSVG path={logoImg} /> */}
								</Link>
								<span
									className="side-nav__menu-item-close"
									onClick={hideOverlay}
								>
									<ReactSVG path={close} />
								</span>
							</li>
							{!user && 
							<li className="side-nav__menu-item side-nav__menu-item__sign">
								<div className="side-nav__menu-item-link border-0">
									<Link to={`${signUp}`}>Sign Up</Link>
									<span className="px-1" style={{textTransform: 'lowercase'}}>
										or
									</span>
									<Link to={`${signIn}`}>Login</Link>
								</div>
								<i className="fas fa-user-circle mr-3"></i>
							</li>}
							<li className="side-nav__menu-item">
								<Link
									to={baseUrl}
									className="side-nav__menu-item-link"
									onClick={hideOverlay}
								>
									<i className="fas fa-home mr-3"></i>Home
								</Link>
							</li>
							{user &&
							<li className="side-nav__menu-item">
								<NavItem
									hideOverlay={hideOverlay}
									showSubItems={this.handleShowSubItems}
									{...accountMenu}
								/>	
							</li>}
						</>
					)}

					{displayedItems.map((item) => (
						<NavItem
							key={item.id}
							hideOverlay={hideOverlay}
							showSubItems={this.handleShowSubItems}
							{...item}
						/>
					))}

					{!hasSubCategory && (
						<div className="side-nav__menu-item">
							<Link className="side-nav__menu-item-link" to={'/checkout'}>
								<ReactSVG path={shoppingCart} className="mr-3" />
								Checkout
							</Link>
						</div>
					)}

					{!!user && (
						<div 
							className="side-nav__menu-item" 
							onClick={() => {
								this.props.signOut();
							}}
						>
							<div className="side-nav__menu-item-link">
								<ReactSVG path={logoutSVG} className="mr-3" />
								Logout
							</div>
						</div>
					)}
				</ul>
				{!hasSubCategory && <SideNavFooter />}
			</>
		);
	}
}

export default NavList;
