import React from "react";

import { TaxedMoney } from "@components/containers";
import { CachedImage } from "../CachedImage";

import * as S from "./styles";
import { IProps } from "./types";
import { convertMinutesToHoursDaysString } from "@temp/helpers";
import ReactSVG from "react-svg";
import preparationIcon from '../../../../images/preparation-icon.svg';
/**
 * Row with product to display in cart summary.
 */
const CartSummaryRow: React.FC<IProps> = ({
  index,
  sku,
  variantName,
  productName,
  price,
  quantity,
  leadTime,
  thumbnail,
}: IProps) => {
  const productLeadTime = convertMinutesToHoursDaysString(leadTime) || "0 mins";
  return (
    <S.Wrapper data-test="cartSummary" data-test-id={sku}>
      <S.Name data-test="name">{productName || "Name not found"}</S.Name>
      <S.Variant data-test="variant">{variantName}</S.Variant>
      {/* <S.Sku>
        SKU: <span data-test="sku">{sku}</span>
      </S.Sku> */}
      <S.Quantity>
        Qty: <span data-test="quantity">{quantity}</span>
      </S.Quantity>
      <S.Price data-test="price">
        <TaxedMoney taxedMoney={price} />
      </S.Price>
      <S.LeadTime data-test="leadTime">
          <ReactSVG path={preparationIcon} />
          <span>{productLeadTime}</span>
      </S.LeadTime>
      <S.Photo>
        <CachedImage data-test="image" {...thumbnail} />
      </S.Photo>
    </S.Wrapper>
  );
};

export { CartSummaryRow };
