import React, {useState} from 'react';
import { numberWithCommas } from "src/helpers";
import './index.scss';

interface IProps{
	amount: number;
	onPress: () => void;
	disable?: boolean;
	addToCartText?: string | null;
	onAddedText?: string | null;
}

const AddToCartButton:React.FC<IProps> = ({amount, disable = false, onPress, addToCartText, onAddedText})=> {
	const [isAdded, setIsAdded ] = useState(false);
	const handleClick = () => {
		if (disable || isAdded) {
			return;
		}

		if (onPress) {
			onPress();
		}

		setIsAdded(true);
		setTimeout(()=>{
			setIsAdded(false);
		},1000)
	}
	
	return (
		<>
		{!disable ?
		<div style={{cursor: 'pointer'}} className={!isAdded ? 'brand-product-add__addButton' : 'brand-product-add__addedButton'} onClick={handleClick}>
		{ !isAdded ?
		<>
			<p>{addToCartText || `Add To Cart`}</p>
			<p>{`₱ ${numberWithCommas(amount)}`}</p>
		</>
			:
			<p>{onAddedText}</p>
		}
		</div>
		: <div className="brand-product-add__outOfStock"><p>Out of Stock</p></div>
		}
		</>
	);

};

export default AddToCartButton;
