import "./scss/index.scss";
import React from "react";

import VariantPicker from "./VariantPicker";
import { RichTextContent } from "@temp/@next/components/atoms";
import { MobileQuantityTextField } from "./MobileQuantityTextField";
import MobileAddToCart from "./MobileAddToCart";
import SpecialInstruction from "./SpecialInstruction";

// import { Link } from "@temp/@next/components/atoms/DemoBanner/styles";

const dummydata = [
  {
    isRequired : true,
    title : 'Select Flavor',
    variants: [
      { price: 100.00, variantName: 'Plain' }, 
      { price: 110.00, variantName: 'Barbeque' },
      { price: 120.00,variantName: 'Sour Cream' },
      { price: 100.00, variantName: 'Cheeze' },
      { price: 150.00, variantName: 'KKK Original' },
    ],
  },
  {
    isRequired : true,
    title : 'Flavor Level',
    variants: [
      { price: 100.00, variantName: 'Heavy (150%)' }, 
      { price: 110.00, variantName: 'Regular (100%)' },
      { price: 120.00,variantName: 'Light (50%)' },
    ],
  },
]

interface BrandProductDescriptionProps {
  name?: string;
  descriptionJson?: any;
}

const BrandProductDescription:React.FC<BrandProductDescriptionProps> = ({name, descriptionJson}) => 
  {
    return (
      <div className="brand-description">
        <h3>{name}</h3>
        <RichTextContent descriptionJson={descriptionJson} />
        
        {dummydata.map(data => (
          <VariantPicker data={data}/>
        ))}
        <SpecialInstruction title={'Special Instructions'}/>
        
        <div className="product-description__wrapper">
          <MobileQuantityTextField/>
          <MobileAddToCart price={600.00} />
        </div>
      </div>
    );
}

export default BrandProductDescription;
