import {styled, media} from '@styles';

export const Wrapper = styled.div``;

export const DropdownIndicator = styled.div<{rotate: string}>`
	position: absolute;
	right: 1rem;
	bottom: 0.9rem;
	transition-duration: 0.3s;
	transform: ${(props) =>
		props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const ClearIndicator = styled.div`
	position: absolute;
	right: 1rem;
`;

export const InputWrapper = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily} !important;
	display: flex;
	${media.smallScreen`
  display: block;
  `};
`;
export const GoogleImage = styled.div`
	margin-bottom: -0.35rem;
	align-self: center;
	margin-left: 0.8rem;
	${media.smallScreen`
  margin-left: 0;
  `};
`;
