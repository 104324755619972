import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "@components/atoms";
import { TaxedMoney, SubDetails } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";
import { useLayout } from '@temp/layout/hooks/useLayout';
import * as S from "./styles";
import { ICostLine, ICosts, IProps } from "./types";
import { ITaxedMoney } from "@types";
import { accumulateAllTaxMoney, generateBranchUrl } from "src/core/utils";
import { Tooltip } from 'antd'; 
import { Info } from 'react-feather';

const CostLine = ({
  name,
  cost,
  showTooltip = false,
  last = false,
  negative = false,
  lineThrough = false,
  marginBottom = 20
}: ICostLine) => (
  <S.CostLine last={last} marginBottom={marginBottom}>
    <S.LabelWrapper>
      <span>{name}</span>
      {showTooltip ? 
      <Tooltip title={name}>
        <Info size={16} color={'#191919'}/>
      </Tooltip>
      : ''}
    </S.LabelWrapper>
    <span data-test={`cartSummaryCost${name.replace(/\s/g, "")}`}>
      {negative && "- "}
      <TaxedMoney 
        style={lineThrough ? { textDecoration: 'line-through' } : {}} 
        taxedMoney={cost} 
      />
    </span>
  </S.CostLine>
);

const Costs = ({ subtotal, promoCode, shipping, isShippingFree, total, distance, vehicleType }: ICosts) => (
  <S.Costs>
    {subtotal && <CostLine name="Subtotal" cost={subtotal} />}
      {shipping &&
        <CostLine 
          name={isShippingFree 
            ? `Delivery Fee (FREE)`
            :`Delivery Fee ${distance ? "(" + distance.toFixed(2) + "km) ": ""}`
          } 
          cost={shipping} 
          lineThrough={isShippingFree}
          last={true}
          marginBottom={vehicleType ? 0 : 20}
        />
      }
      {shipping && vehicleType &&
        <S.VehicleType>
          {`(${vehicleType})`}
        </S.VehicleType>
      }
      {!shipping && 
      <S.Delivery>
        <span>Delivery Fee</span>
        <span>Calculated during checkout</span>
      </S.Delivery>
      }
    
    {promoCode && promoCode.gross.amount > 0 && (
      <CostLine name="Promo Code" cost={promoCode} negative={true} />
    )}
    {total && <CostLine name="Total" cost={total} marginBottom={0} />}
  </S.Costs>
);

/**
 * Cart summary displayed in checkout page
 */
const CartSummaryByMerchant: React.FC<IProps> = ({
  productsByMerchant
}: IProps) => {
  const [mobileCartOpened, setMobileCartOpened] = useState(false);
  const grandTotal: ITaxedMoney = accumulateAllTaxMoney();
  const { setQueryParams } = useLayout();
  return (  
    <S.Wrapper id="toggleMobileCartSummary" mobileCartOpened={mobileCartOpened}>
      <S.Container>
      <S.Title
        mobileCartOpened={mobileCartOpened}
        data-test="cartSummaryTitle"
        onClick={() => setMobileCartOpened(!mobileCartOpened)}
      >
        Cart Summary
        <S.ArrowUp mobileCartOpened={mobileCartOpened}>
          <Icon name="arrow_up" size={16} viewBoxValue={"0 0 16 16"} color='#fff' />
        </S.ArrowUp>
      </S.Title>
      </S.Container>
      {productsByMerchant?.map(({ merchant, products, deliveryQuotations }, index) => {
        const merchantName = merchant?.storeTitle || merchant?.name || "unknown store";
        const address = merchant 
                        && merchant.warehouses 
                        && merchant.warehouses.edges
                        && merchant.warehouses.edges.length > 0
                        && merchant.warehouses.edges[0].node
                        && merchant.warehouses.edges[0].node.address
                        ? merchant.warehouses.edges[0].node.address : null;

        // Compute subtotla
        let subtotal: ITaxedMoney = accumulateAllTaxMoney();

        if (products) {
          products.map(product => {
            subtotal = accumulateAllTaxMoney(subtotal, product.price);
          });
        }
        
        const { freeShipping, freeTotalFee, totalKm, totalFeeAmount, serviceType } = deliveryQuotations || {};

        // Compute shipping
        const distance = totalKm || "";
        const shipping: ITaxedMoney = totalFeeAmount ? {
          gross: totalFeeAmount,
          net: totalFeeAmount,
        } : accumulateAllTaxMoney();
        const shippingFreeAmount: ITaxedMoney = {
          gross: { currency: totalFeeAmount?.currency || "", amount: freeTotalFee || 0 },
          net: { currency: totalFeeAmount?.currency || "", amount: freeTotalFee || 0 },
        };

        // Shipping fee to display in cart summary
        const shippingFeeComputed: ITaxedMoney | null = 
                                  deliveryQuotations 
                                  ? freeShipping ? shippingFreeAmount : shipping
                                  : null;

        // Compute total
        const total: ITaxedMoney = accumulateAllTaxMoney(subtotal, shipping);

        // Compute subtotal
        grandTotal.gross.amount += total.gross.amount;
        grandTotal.gross.currency = total.gross.currency;
        grandTotal.net.amount += total.net.amount;
        grandTotal.net.currency = total.net.currency;
        
        return (
          <div key={index}>
            { productsByMerchant.length > 1 
              && index === 0 
              &&
              <>
                <S.HR />
                <S.PaddingContainer>
                  <S.GrandTotal>
                  <CostLine showTooltip name={!shippingFeeComputed ? "EST. TOTAL":"GRAND TOTAL"} cost={grandTotal} marginBottom={0} />                
                  </S.GrandTotal>
                </S.PaddingContainer>
              </>
            }
            <S.HR />
            <S.PaddingContainer>
              <S.MerchantName>
                <Link to={generateBranchUrl(merchant?.slug || "")}>{merchantName}</Link>
              </S.MerchantName>
              <SubDetails flex={true} items={[
                { fontSize: 16,iconName: "fas fa-map-pin", iconSize: 16, text: address?.city, toolTip: "Store Location"},
              ]} />
            </S.PaddingContainer>
            <S.HR />
            <S.Content>
              <S.CartSummaryProductList>
                {products?.map((product, index) => {
                  return (
                  <div key={product.sku}>
                    <S.ProductLine onClick={() => {
                      if (setQueryParams) {
                        setQueryParams({ vwProductCartId: product?.id })
                      }
                    }}>
                      <CartSummaryRow
                        productName={product?.productName}
                        index={index}
                        sku={product.sku}
                        quantity={product.quantity}
                        variantName={product.name}
                        price={product.price}
                        thumbnail={product.thumbnail}
                        leadTime={product?.leadTime}
                      />
                    </S.ProductLine>
                    <S.HR />
                  </div>
                )
                  })}
              </S.CartSummaryProductList>
              <Costs
                subtotal={subtotal}
                total={total}
                shipping={shippingFeeComputed}
                isShippingFree={freeShipping}
                distance={distance || null}
                vehicleType={serviceType}
                // promoCode={promoCode}
              />
              <S.Notes>
                <span>
                *Incidental fees (eg. applicable toll, parking, and other fees) are not included in the 
                total computed delivery fee above. The assigned rider or driver will collect these 
                fees from you separately upon delivery.
                </span>
              </S.Notes>
            </S.Content>
            <br/>
          </div>
        )
      }
      )}
    </S.Wrapper>
  );
};

export { CartSummaryByMerchant };
