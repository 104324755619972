import {
	baseUrl, 
	// cartUrl
} from '@temp/app/routes';
import React from 'react';
import {Link} from 'react-router-dom';
import logoImg from '../../../images/jrmall-white.svg';
import {
	// ShoppingCart, 
	Menu, 
	Search,
	ShoppingCart
} from 'react-feather';
import {OverlayTheme, OverlayType} from '@temp/components';
import { 
	useCart, 
} from "@saleor/sdk";
// import { AutoComplete } from 'antd';
import { getMenuItems } from "src/helpers";
import './index.scss';


const MobileNav = ({overlayContext, setSearchOverlay}) => {
	const { items } = useCart();
	const cartItemsQuantity =
	  (items &&
		items.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0)) ||
	  0;

	return (
		<div className="d-flex align-items-center justify-content-between ">
			<div className="d-flex align-items-center">
			<button
				onClick={() => {
						const items = getMenuItems();
						overlayContext.show(OverlayType.sideNav, OverlayTheme.left, { data: items })
					}
				}
				className="bg-transparent border-none mr-3"
			>
				<Menu size={30} color={'#fff'} />
			</button>
			<Link to={baseUrl}>
				<img src={logoImg} style={{width: '7em', height: 'auto', marginRight: '3rem'}} />
			</Link>
			</div>
				{/* <div id="nav-search" className="flex-grow-1 d-flex mx-3 align-items-center position-relative">
					<Search style={{zIndex: 10}} className="position-absolute ml-2" />
					<AutoComplete options={[{label: "Brand X", value: "Brand X"}]} className="flex-grow-1"/>
				</div> */}
			<ul className="d-flex">
				<button onClick={()=> setSearchOverlay(true)} className="bg-transparent border-none mr-3">
					<Search color={'#fff'} />
				</button>
				<div className="main-menu__icon main-menu__cart">
					{/* <ShoppingCart 
						onClick={() => overlayContext.show(OverlayType.cart, OverlayTheme.right)}
						color={'#fff'} 
					/> */}
					<ShoppingCart
						color={'#fff'}
						onClick={() => overlayContext.show(OverlayType.cart, OverlayTheme.right)}
					/>
					{cartItemsQuantity > 0 ?
					<span className="main-menu__cart__quantity">
						{cartItemsQuantity}
					</span>
					: null}
				</div>
				{/* <Link to={cartUrl} className="bg-transparent border-none">
					<ShoppingCart color={'#fff'} />
				</Link> */}
			</ul>
		</div>
	);
};

export default MobileNav;
