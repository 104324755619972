import React, { FC, useState, useEffect } from 'react';
import Nav from '../components/Nav/Nav';
import {
	Context,
	UserLocationProps,
	QueryParamProps
} from '../Context';
import CategoryBar from '../components/CategoryBar';
import Addressbar from '../components/AddressBar';
import ScreenTitle from '@temp/components/ScreenTitle';
import '../index.scss';
import { useMediaQuery } from 'react-responsive';

import { useQueryParams, StringParam } from 'use-query-params';
import { Footer, OverlayManager } from '@temp/components';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
import * as helpers from "src/helpers";
import NearbyStoresQuery from "../graphQL/NearbyStoresQuery";
import { useCart, useUserDetails } from "@saleor/sdk";
import BrandProductView from "src/screens/mobile/BrandProduct/View";
import AddressModal from '../components/AddressModal/AddressModal';
import NoNearbyStores from '@temp/views/NoNearbyStores/Page';
import { useLocation } from 'react-router';
import { searchAddress, signIn, signUp } from '@temp/routes/paths';
import { DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT } from '@temp/constants';
import { TypedReverseGeolocation } from '@temp/components/AddressAutocomplete/queries';

const ScreenLayout: FC = ({ children }) => {
	const tag = document.getElementsByTagName('body')[0];
	const location = useLocation();
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const { data: user } = useUserDetails();
	const { addresses } = user || {};
	const { addItem, removeItem, updateItem } = useCart();
	const [showNavbar, setShowNavbar] = useState<boolean>(true);
	const [showAddressbar, setShowAddressbar] = useState<boolean>(true);
	const [showCategorybar, setShowCategorybar] = useState<boolean>(true);
	const [showScreenTitle, setShowScreenTitle] = useState<boolean>(false);
	const [screenTitle, setScreenTitle] = useState<string>('');
	const [showSearchOverlay, setSearchOverlay] = useState<boolean>(false);
	const [showAddressOverlay, setAddressOverlay] = useState<boolean>(false);
	const [myUserLocation, setMyUserLocation] = useState<UserLocationProps>(helpers.getUserLocation());
	const [showAddressModal, setShowAddressModal] = useState<boolean>(true);
	// const [scrollPosition, setScrollPosition] = useState(0);
	const [appQueryString, setAppQueryString] = useQueryParams({
		vwProductId: StringParam,
		vwProductCartId: StringParam,
	});
	const setQueryParams = (params: QueryParamProps) => {
		// Make sure that ID schema is initialized in "Product"
		params.vwProductCartId = params?.vwProductCartId ? helpers.convertGraphIDSchema(params?.vwProductCartId, 'ProductVariant') : null;
		params.vwProductId = params?.vwProductId ? helpers.convertGraphIDSchema(params?.vwProductId, 'Product') : null;
		setAppQueryString(params);
	}

	const addCartItem = (variantId, qty, merchantId, name, notes) => {
		addItem(variantId, qty);
		helpers.addCartItemNotesCache(variantId, merchantId, name, notes);
	}
	const updateCartItem = (variantId, qty, notes) => {
		updateItem(variantId, qty);
		const myNotes = helpers.getCartItemNotesCache().map(item => {
			if (item?.variantId === variantId) {
				item.notes = notes || item.notes;
			}
			return item;
		});
		helpers.setCartItemNotesCache(myNotes);
	}
	const removeCartItem = (variantId) => {
		removeItem(variantId)
		helpers.removeCartItemNotesCache(variantId);
		return variantId
	}
	const getCartItemNotes = () => helpers.getCartItemNotesCache() || [];
	const setCartItemNotes = (data) => helpers.setCartItemNotesCache(data) || [];

	const getCheckoutSchedules = () => helpers.getCheckoutSchedulesCache() || [];
	const setCheckoutSchedules = (data) => helpers.setCheckoutSchedulesCache(data) || [];

	const removeUserLocation = () => {
		setMyUserLocation(null);
		helpers.removeUserLocation();
	};
	const setUserLocation = (location: UserLocationProps) => {
		setMyUserLocation(location);
		helpers.setUserLocation(location);
	};
	const useUserLocation = (): UserLocationProps => {
		return myUserLocation || helpers.getUserLocation();
	}

	useEffect(() => {
		if (showAddressModal || showAddressOverlay) {
			tag.classList.add('hidden');
			tag.classList.remove('show');
		}
		else {
			tag.classList.add('show')
			tag.classList.remove('hidden');
		}
	}, [showAddressOverlay, showAddressModal]);

	useEffect(() => {
		if (!useUserLocation()?.address1) {
			navigator.geolocation.getCurrentPosition((position) => {
				navigator.permissions.query({ name: 'geolocation' })
					.then(status => {
						if (status.state === 'denied') {
							setShowAddressModal(true);
						};
						if (status.state === 'granted' || status.state === 'prompt') {
							setMyUserLocation({
								address1: DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT,
								address2: '',
								latitude: position.coords.latitude,
								longitude: position.coords.longitude,
								placeId: '',
							});
							setShowAddressModal(false);
						}
					});
			});
			if (!!user) {
				setMyUserLocation({
					address1: addresses[0]?.streetAddress1 || "",
					address2: addresses[0]?.streetAddress2 || "",
					latitude: addresses[0]?.latitude || 0,
					longitude: addresses[0]?.longitude || 0,
					placeId: addresses[0]?.placeId || ''
				});
				setShowAddressModal(false);
			}
		}
		else {
			setShowAddressModal(false);
		}
	}, [useUserLocation()?.latitude || useUserLocation()?.longitude]);

	// set states to initial value
	const cleanUp = () => {
		setShowNavbar(true);
		setShowAddressbar(true);
		setShowCategorybar(true);
		setShowScreenTitle(false);
		setScreenTitle('');
		setUserLocation(null);
	};

	React.useEffect(() => {
		// setShowNavbar(false);
		// setShowAddressbar(false);
		// setShowCategorybar(false);

		// This is used to disable the scroll on body when the modal is open
		const scrollY = document.body.style.top;
		const windowY = `-${window.scrollY}px`;
		document.body.style.position = (appQueryString?.vwProductId || appQueryString?.vwProductCartId) ? 'fixed' : '';
		document.body.style.top = (appQueryString?.vwProductId || appQueryString?.vwProductCartId) ? windowY : '';
		document.body.style.overflowY = (appQueryString?.vwProductId || appQueryString?.vwProductCartId) ? 'hidden' : '';
		window.scrollTo(0, parseInt((scrollY || '0'), 10) * -1);

		// Hide the cart overlay after clicking the product name
		const cart = document.getElementById('toggleMobileCartSummary');
		if (cart && isTablet) {
			cart.style.display = (appQueryString?.vwProductId || appQueryString?.vwProductCartId) ? 'none' : '';
		}

	}, [appQueryString?.vwProductId, appQueryString?.vwProductCartId]);

	return (
		<NearbyStoresQuery
			placeId={useUserLocation()?.placeId}
			latitude={useUserLocation()?.latitude}
			longitude={useUserLocation()?.longitude}
		// region={region}
		>
			{({ nearByStores, loading: isNearbyStoreLoading, loadMore: nearByStoresLoadMore, hasNextPage: nearbyStoresHasNextPage }) => {
				const useNearbyStores = () => ({
					nearByStores,
					loadMore: nearByStoresLoadMore,
					loading: isNearbyStoreLoading,
					hasNextPage: nearbyStoresHasNextPage
				})

				return (
					<TypedReverseGeolocation variables={{ lat: useUserLocation()?.latitude || 0, lng: useUserLocation()?.longitude || 0 }}
						onCompleted={data => {
							setUserLocation({
								...useUserLocation(),
								latitude: useUserLocation()?.latitude || 0,
								longitude: useUserLocation()?.longitude || 0,
								region: data?.reverseGeolocation?.region || ''
							});
						}}>
						{({ data: reverseLocationData, error, loading: isLoading }) => {


							return (
								<Context.Provider
									value={{
										cleanUp,
										setScreenTitle,
										setShowAddressbar,
										setShowCategorybar,
										setShowNavbar,
										setShowScreenTitle,
										removeUserLocation,
										setUserLocation,
										useUserLocation,
										useNearbyStores,
										setAddressOverlay,
										addCartItem,
										updateCartItem,
										removeCartItem,
										getCartItemNotes,
										setCartItemNotes,
										getCheckoutSchedules,
										setCheckoutSchedules,
										setQueryParams,
										showAddressbar,
										showCategorybar,
										showNavbar,
									}}
								>
									<section className="layout flex-column d-flex position-relative">
										<div id="header" className={`flex-shrink-0 layout-desktop layout-desktop-sticky`}>
											{showNavbar &&
												<Nav
													showSearchOverlay={showSearchOverlay}
													setSearchOverlay={setSearchOverlay}
													showAddressOverlay={showAddressOverlay}
													setAddressOverlay={setAddressOverlay}
													setUserLocation={setUserLocation}
													removeUserLocation={removeUserLocation}
													isLoading={isLoading}
												/>}
											{showScreenTitle && <ScreenTitle>{screenTitle}</ScreenTitle>}
											{showAddressbar && isTablet && <Addressbar setAddressOverlay={setAddressOverlay} />}
											{showCategorybar && <CategoryBar />}
											{(appQueryString?.vwProductId || appQueryString?.vwProductCartId) &&
												<BrandProductView
													productId={appQueryString?.vwProductId}
													productCartId={appQueryString?.vwProductCartId}
													setProductOverlay={(status, productId) => {
														if (!status) {
															setAppQueryString({
																vwProductId: null,
																vwProductCartId: null
															});
														}
													}}
												/>
											}
										</div>
										{showAddressModal && !useUserLocation()?.address1 && !user && !location.pathname.includes(signIn) && !location.pathname.includes(signUp) &&
											<AddressModal
												showAddressModal={showAddressModal}
												setUserLocation={setUserLocation}
												setShowAddressModal={setShowAddressModal}
												removeUserLocation={removeUserLocation}
											/>
										}
										{!!nearByStores?.length || isNearbyStoreLoading || showAddressModal || (location.pathname.includes(signIn) || location.pathname.includes(signUp) || location.pathname.includes(searchAddress)) ?
											<div className="flex-grow-1 h-100" style={{ backgroundColor: '#fff' }}> {children}</div>
											: <NoNearbyStores />
										}
										<div className="flex-grow-0"><Footer /></div>
									</section>
									<OverlayManager />
								</Context.Provider>
							);
						}}
					</TypedReverseGeolocation>

				)
			}}
		</NearbyStoresQuery>

	)
};

export default ScreenLayout;
