import * as React from "react";
import { Link } from "react-router-dom";

import { Carousel, ProductListItem } from "..";
import { generateMerchantProductUrl } from "../../core/utils";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";
import "./scss/index.scss";

const SMALL_SCREEN = 541;

interface ProductsFeaturedProps {
  merchant?: any;
  title?: string;
  products: any[];
  slug: string;
}

const ProductsFeatured: React.FC<ProductsFeaturedProps> = ({ merchant, title, products, slug }) => {
    if (products.length) {
        return (
            <>
                <div className="home-page__categories__productsContainer">
                    <h4 className="home-page__categories__text">{title}</h4>
                    <Link to={`/store/${slug}/`} className="home-page__categories__link">View All</Link>
                </div>
                <hr></hr>
                <div className="products-featured">
                    <Media maxWidth={smallScreen}>
                        <Carousel slidesToScroll='auto' slidesToShow={2.1} transitionMode='scroll'>
                            {products.map((product) => (
                                <Link
                                    to={generateMerchantProductUrl(product.id, product.name)}
                                    key={product.id}
                                >
                                <ProductListItem storeTitle={merchant.storeTitle} product={product} />
                                </Link>
                            ))}
                        </Carousel>
                    </Media>
                    <Media minWidth={SMALL_SCREEN}>
                        <Carousel isHomepage maxItemPerSlide={5} dragging>
                            {products.map((product) => (
                                <Link
                                    to={generateMerchantProductUrl(product.id, product.name)}
                                    key={product.id}
                                >
                                <ProductListItem storeTitle={merchant.storeTitle} product={product} />
                                </Link>
                            ))}
                        </Carousel>
                    </Media>
                </div>
            </>
        );
    } else {
        return null;
    }
};

ProductsFeatured.defaultProps = {
  title: "Best of the Best",
};

export default ProductsFeatured;
