import {media, styled} from '@styles';

export const Wrapper = styled.div`
	margin-bottom: 1.4rem;
`;

export const Bar = styled.div`
	height: 4rem;
	background-color: ${(props) => props.theme.colors.secondary};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	margin-top: 1rem;
	margin-bottom: 1.4rem;
	border-radius: 5px;
`;

export const LeftSide = styled.div`
	display: flex;
	align-items: center;
`;

export const RightSide = styled.div`
	height: 1.2rem;
	${media.smallScreen`
  height: 2.5rem !important;
  text-align: right;
    `}
`;

export const FiltersButton = styled.button`
	outline: none !important;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	display: flex;
	align-items: center;
	cursor: pointer;
	> svg {
		> path {
			fill: white;
		}
	}
`;

export const Clear = styled.button`
	margin-left: 2rem;
	outline: none !important;
	cursor: pointer;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	color: ${(props) => props.theme.colors.white};
`;
export const Element = styled.span`
	padding-left: 1rem;
	display: inline-block;
`;

export const Filters = styled.span`
	color: white;
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	padding: 0 0.6rem;
	font-size: ${(props) => props.theme.typography.baseFontSize};
	${media.smallScreen`
    font-size: ${(props: any) => props.theme.typography.smallFontSize};
`}
`;

export const Label = styled.span`
	color: ${(props) => props.theme.colors.lightFont};
`;

export const Sort = styled.div`
	// width: 12rem;
	display: inline-block;
`;

export const FiltersChipsWrapper = styled.div`
	> div {
		margin: 0.4rem;
	}
`;
