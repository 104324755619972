import React, {FC} from 'react';
import './index.scss';
import { useHistory } from 'react-router';
import { setHasAdultContent } from '@temp/helpers';
interface BranchInfoProps {
	setShowWarning?: (value: boolean) => void;
	hasAdultContent?: boolean;
}

const WarningModal: FC<BranchInfoProps> = ({
	setShowWarning,
	hasAdultContent,
}) => {
	const history = useHistory();
	return (
		<div className="branch-warning-modal">
			<div className="branch-warning-modal-container">
			<p className="branch-warning-modal__title"><i className="mr-2 fas fa-exclamation-triangle"></i>Confirm your age</p>
			<p className="branch-warning-modal__description">This store contains age-restricted products. Please verify that you are at least <strong>18 years</strong> of age to continue.</p>
			<div className="d-flex justify-content-center align-items-center px-2">
			<button onClick={()=> {setHasAdultContent(hasAdultContent); setShowWarning(false)}} className="branch-warning-modal__confirm" style={{flex: 1, whiteSpace: 'nowrap'}}>
				18 or Older
			</button>
			<button onClick={()=> history.goBack()} className="branch-warning-modal__decline" style={{flex: 1}}>
				Exit
			</button>
			</div>
			</div>
		<div onClick={()=> history.goBack()} className="branch-warning-modal-overlay"></div>
		</div>
	);
};

export default WarningModal;
