import React, {FC} from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './index.scss';
import { Link } from 'react-scroll'
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
import { sorter } from 'src/helpers';
interface CollectionProps {
	id: string;
	name: string;
	sortOrder?: string;
	products?: any[];
}

interface StoreCollectionsProps {
	collections?: CollectionProps[];
	selectedCollectionId?: string;
	onSelectChange?: (collectionId: string) => void;
}

const StoreCollections: FC<StoreCollectionsProps> = ({
	collections = [],
	selectedCollectionId,
	onSelectChange
}) => {

    const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const collectionMenu = sorter(collections).map((collection, index) => (
		<Link
			offset={isTablet ? -200 : -150}
			key={collection?.id}
			className={`d-block p-3 store-categories-item`}
			to={`collection-${index}`} spy={true} smooth={true}
		>
			{collection?.name}
		</Link>
	));

	return (
		<ul className="p-0 store-categories">
			<div className="main-container">
			<ScrollMenu
				data={collectionMenu}
				alignCenter={false}
				arrowClass="store-categories__arrows"
				wrapperStyle={{padding: '0 1rem'}}
				hideSingleArrow={!isTablet && true}
				arrowLeft={!isTablet && <i className="fas fa-chevron-left ml-n3"></i>}
				arrowRight={!isTablet && <i className="fas fa-chevron-right mr-n3"></i>}
				onSelect={(value: string) => {
					if (onSelectChange) {
						onSelectChange(value);
					}
				}}
			/>
			</div>
		</ul>
	);
};

export default StoreCollections;
