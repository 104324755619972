import {styled, media} from '@styles';

export const Footer = styled.div<{divider: boolean}>`
	position: relative;
	text-align: right;
	padding: ${(props) => `1.1rem ${props.theme.spacing.gutter}`};
	${({divider, theme}) =>
		divider && `border-top: 1px solid ${theme.colors.light};`}
	button {
		padding: 0.5rem 2rem;
		border-radius: 30px;
		&:last-child {
			margin-left: 2rem;
			margin-right: 0.7rem;
		}
	}
	${media.smallScreen`
  text-align: center;
  button {
    margin-bottom: 7rem;
    padding: 1rem 2rem;
    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
  `};
`;
