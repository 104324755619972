import * as React from "react";

import { IFilters } from "@types";
import { StringParam, useQueryParam } from "use-query-params";
import { PRODUCTS_PER_PAGE } from "../../../core/config";
import {
  convertSortByFromString,
  convertToAttributeScalar,
  maybe,
} from "../../../core/utils";
import Page from "./Page";
import { TypedStoreProductsQuery } from "../queries";

interface IProps {
    merchantId: string;
};

export const FilterQuerySet = {
  encode(valueObj) {
    const str = [];
    Object.keys(valueObj).forEach(value => {
      str.push(value + "_" + valueObj[value].join("_"));
    });
    return str.join(".");
  },

  decode(strValue) {
    const obj = {};
    const propsWithValues = strValue.split(".").filter(n => n);
    propsWithValues.map(value => {
      const propWithValues = value.split("_").filter(n => n);
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  },
};

export const View: React.FC<IProps> = ({ merchantId }) => {
  const [sort, setSort] = useQueryParam("sortBy", StringParam);
  const [attributeFilters, setAttributeFilters] = useQueryParam(
    "filters",
    FilterQuerySet
  );

  const clearFilters = () => {
    setAttributeFilters({});
  };

  const onFiltersChange = (name, value) => {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes[`${name}`].length === 1) {
          const att = { ...attributeFilters };
          delete att[`${name}`];
          setAttributeFilters({
            ...att,
          });
        } else {
          setAttributeFilters({
            ...attributeFilters,
            [`${name}`]: attributeFilters[`${name}`].filter(
              item => item !== value
            ),
          });
        }
      } else {
        setAttributeFilters({
          ...attributeFilters,
          [`${name}`]: [...attributeFilters[`${name}`], value],
        });
      }
    } else {
      setAttributeFilters({ ...attributeFilters, [`${name}`]: [value] });
    }
  };

  const filters: IFilters = {
    attributes: attributeFilters,
    merchantId,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null,
  };
  
  const variables = {
    ...filters,
    attributes: filters.attributes
      ? convertToAttributeScalar(filters.attributes)
      : {},
    // id: getGraphqlIdFromDBId(match.params.id, "Category"),
    sortBy: convertSortByFromString(filters.sortBy),
  };

  const sortOptions = [
    {
      label: "Clear...",
      value: null,
    },
    {
      label: "Price Low-High",
      value: "price",
    },
    {
      label: "Price High-Low",
      value: "-price",
    },
    {
      label: "Name Increasing",
      value: "name",
    },
    {
      label: "Name Decreasing",
      value: "-name",
    },
    {
      label: "Last updated Ascending",
      value: "updated_at",
    },
    {
      label: "Last updated Descending",
      value: "-updated_at",
    },
  ];

  return (
    <TypedStoreProductsQuery
        variables={variables}
        errorPolicy="all"
        loaderFull
    >
        {({ loading, data, loadMore }) => {

            if (data && data.products) {    
                const handleLoadMore = () =>
                loadMore(
                    (prev, next) => ({
                    ...prev,
                    products: {
                        ...prev.products,
                        edges: [...prev.products.edges, ...next.products.edges],
                        pageInfo: next.products.pageInfo,
                    },
                    }),
                    { after: data.products.pageInfo.endCursor }
                );
                return (
                  <Page
                      clearFilters={clearFilters}
                      attributes={data.attributes.edges.map(edge => edge.node)}
                      displayLoader={loading}
                      hasNextPage={maybe(
                          () => data.products.pageInfo.hasNextPage,
                          false
                      )}
                      sortOptions={sortOptions}
                      activeSortOption={filters.sortBy}
                      filters={filters}
                      products={data.products}
                      onAttributeFiltersChange={onFiltersChange}
                      onLoadMore={handleLoadMore}
                      activeFilters={
                          filters!.attributes
                          ? Object.keys(filters!.attributes).length
                          : 0
                      }
                      onOrder={value => {
                          setSort(value.value);
                      }}
                  /> 
                );
            }
           
            return <></>
        }}
    </TypedStoreProductsQuery>
  );
};

export default View;
