import {styled} from '@styles';

export const Dot = styled.div<{done?: boolean}>`
	position: relative;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	text-align: center;
	border: 6px solid ${(props) => (props.done ? '#76aad3' : '#c2c2c2')};
	background-color: ${(props) => (props.done ? '#76aad3' : '#c2c2c2')};
	color: ${(props) => props.theme.colors.white};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	line-height: 1.7rem;
`;

export const ActiveDot = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	// box-shadow: 0 0 0 4px #c2c2c2;
`;

export const Label = styled.span`
	white-space: pre;
	display: block;
	position: absolute;
	top: 45px;
	transform: translateX(-18%);
	font-size: ${[(props) => props.theme.typography.smallFontSize]};
	letter-spacing: 2%;
	color: ${(props) => props.theme.colors.baseFontColorSemiTransparent};
`;

export const LeftLabel = styled(Label)`
	transform: translateX(-10%);
	top: 45px;
`;
export const RightLabel = styled(Label)`
	transform: none;
	top: 45px;
	right: 0;
`;

export const ProgressBar = styled.div<{done?: boolean}>`
	// z-index: -1;
	width: 100%;
	height: 4px;
	background-color: ${(props) => (props.done ? '#76aad3' : '#c2c2c2')};
`;

export const Step = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	&:not(:last-child) {
		width: 100%;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	position: relative;
`;
