import {styled, media} from '@styles';

export const Button = styled.div`
	button {
		border-radius: 30px;
	}
	span {
		font-weight: 0;
		text-transform: capitalize;
	}
	${media.smallScreen`
  text-align: center;
  `}
`;

export const MerchantName = styled.div`
	font-size: 1.125em;
	a {
		color: ${(props) => props.theme.colors.primary} !important;
	}
`;
