import {media, styled} from '@styles';

export const Wrapper = styled.div`
	margin: 40px 0 80px;

	${media.smallScreen`
    margin: 20px 0 60px;
  `}
`;

export const Paragraph = styled.p`
	font-size: ${(props) => props.theme.typography.h4FontSize};
	margin: 0;
	line-height: 170%;

	span {
		font-weight: ${(props) => props.theme.typography.boldFontWeight};
	}
	${media.smallScreen`
    text-align: center;
  `}
`;

export const Buttons = styled.div`
	width: 50%;
	margin-top: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	button {
		padding-left: 0;
		padding-right: 0;
		border-radius: 30px !important;
		background-color: ${(props) => props.theme.colors.primary} !important;
		color: ${(props) => props.theme.colors.white} !important;
	}
	span {
		text-transform: capitalize !important;
	}

	${media.smallScreen`
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  `}
`;
