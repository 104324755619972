import React from 'react';
import './index.scss';

interface IProps{
	title?: string;
	placeHolder?: string;
	onChange?: (value: any) => void;
	defaultText?: string | null;
	disabled?: boolean;
}

const BrandProductNotes:React.FC<IProps> = ({title, placeHolder, defaultText, onChange, disabled = false})=> {
	return (	
		<div className="brand-product-notes px-3">
			<h4>{title || 'Special Instructions'}</h4>
			<textarea 
				disabled={disabled}
				className="brand-product-notes__textArea" 
				defaultValue={defaultText || ""}
				maxLength={200}
				rows={4} 
				placeholder={placeHolder || 'e.g. No ketsup'} 
				onChange={onChange} 
			/>
		</div>
	);
};

export default BrandProductNotes;
