import {media, styled} from '@styles';

export const CategoryWrapper = styled.div`
  padding: 1rem 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  > h3 {
    font-size: 1.5rem;
  }
  grid-column-gap: 1.5rem;
  grid-row-gap: 2.5rem;
  ${media.largeScreen`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
  `}
  ${media.mediumScreen`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}

  ${media.smallScreen`
    padding: 1rem 0 3rem 0;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1.5rem;
  `}
`;

export const Wrapper = styled.div`
	a {
		color: inherit;
		text-decoration: none;
	}
`;
export const Title = styled.div`
	position: absolute;
	top: 65%;
	min-height: 2.9rem;
	max-height: 2.9rem;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 0.5rem;
	> h3 {
		line-height: 22px;
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		font-size: 22px;
		color: ${(props) => props.theme.colors.white};
	}
	${media.smallScreen`
  top: 60%;
  padding: 0 0.5rem;
  min-height: 3rem;
  max-height: 3rem;
  > h3{
    font-size: 18px;
    line-height: 16px;
  }  
  `}
`;
export const Header = styled.div`
	margin-top: 1rem;
	> h3 {
		line-height: 22px;
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		font-size: 18px;
		color: ${(props) => props.theme.colors.primary} !important;
	}
	${media.smallScreen`
    margin-top: 0.5rem;
	> h3 {
		font-size: 16px;
	}
  `};
`;
export const TitleShort = styled.div`
	position: absolute;
	top: 75%;
	min-height: 2.9rem;
	max-height: 2.9rem;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 0.7rem;
	> h3 {
		line-height: 22px;
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		font-size: 22px;
		color: ${(props) => props.theme.colors.white};
	}
	${media.smallScreen`
  top: 65%;
  padding: 0 0.5rem;
  min-height: 3rem;
  max-height: 3rem;
  > h3{
    font-size: 18px;
    line-height: 16px;
  }  
  `}
`;

export const Place = styled.div`
	padding: 0rem 0.5rem;
	color: ${(props) => props.theme.colors.lightFont};
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;

export const List = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 2rem;

	${media.largeScreen`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}

	${media.smallScreen`
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}
`;

export const Loader = styled.div`
	text-align: center;
	margin: 2.5rem 0;
	button {
		span {
			text-transform: capitalize !important;
			font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		}
	}
`;

export const Image = styled.div`
	position: relative;
	width: auto;
	height: 170px;
	max-width: 100%;
	background-color: #191919;
	border-radius: 20px;
	${media.smallScreen`
  height: 100px;
  `}
	> img {
		opacity: 0.5;
		border-radius: 20px;
		width: 100%;
		height: 170px;
		max-width: 100%;
	}
	${media.smallScreen`
  > img {
    border-radius: 20px;
    width: 100%;
    height: 100px;
    max-width: 100%;
  }
  `}
`;

export const Photo = styled.div`
	width: 100%;
	height: 180px;
	overflow: hidden;
	> img {
		transition: 0.2s ease-in-out;
		border-radius: 3px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	${media.smallScreen`
	width: 100%;
	height: 100px;
	> img {
		border-radius: 3px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	`}
`;
