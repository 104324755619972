import React from "react";

import { AccountTile } from "./AccountTile";
import { PasswordTile } from "./PasswordTile";
import { JRPayTile } from "./JRPayTile";

export const AccountTabTiles: React.FC = () => (
  <div>
    <AccountTile />
    <JRPayTile />
    <PasswordTile />
  </div>
);
