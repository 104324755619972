import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {MerchantBrands, MerchantBrandsVariables} from './types/Brand';

const merchantBrandQuery = gql`
	query GetMerchantBrands($search: String!) {
		merchantBrands(
			first: 5
			filter: {search: $search, status: ACTIVE}
			sortBy: {direction: ASC, field: NAME}
		) {
			edges {
				node {
					id
					name
					slug
				}
			}
		}
	}
`;
export const TypedMerchantBrandQuery = TypedQuery<
	MerchantBrands,
	MerchantBrandsVariables
>(merchantBrandQuery);
