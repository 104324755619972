import {DefaultTheme, styled} from '@styles';

type WrapperProps = {
	active: boolean;
	error: boolean;
	disabled: boolean;
	theme: DefaultTheme;
	label: string;
	borderRadius: number;
};

const getEdgeColor = (
	{active, error, disabled, theme, label}: WrapperProps,
	hovered = false,
) => {
	if (disabled) {
		return theme.colors.disabled;
	}

	if (error) {
		return theme.colors.error;
	}

	if (hovered) {
		return theme.colors.disabled;
	}

	return active
		? theme.colors.disabled
		: `${
				label === 'Quantity' || label === 'Size' || label === 'Bottle Size'
					? theme.colors.inputBorder
					: theme.colors.baseFont
		  }`;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  border: 1px solid #cccccc;
  color: ${(props) => getEdgeColor(props)};
  outline: 
  // ${(props) =>
		props.active ? `1px solid ${getEdgeColor(props)};` : 'none'};
  transition: all 0.3s ease;
  border-radius: ${(props) =>
		`${
			props?.borderRadius ? `${props?.borderRadius}` + 'px !important' : '0px'
		}`};

  &:hover {
    color: ${(props) => getEdgeColor(props, true)};
    // outline-width: ${(props) => (props.disabled ? 0 : 1)}px;
    // outline-style: solid;
    // border-color: ${(props) => getEdgeColor(props, true)};
    border: 1px solid #767676;
    // outline-color: ${(props) => getEdgeColor(props, true)};
    
  }
  &:active{
    border-color: none;
  }
`;

export const Content = styled.span`
	display: flex;
	align-items: center;
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const Input = styled.input<{
	contentLeft: boolean;
}>`
	padding: 0.8rem 1rem;
	margin: 0;
	border: none;
	width: 100%;
	font-size: ${(props) => props.theme.typography.baseFontSize};
	outline: none;
	background-color: transparent;
	font-family: ${(props) =>
		`${
			props?.contentLeft
				? props.theme.typography.baseFontFamily
				: props.theme.typography.baseFontFamily
		}`};
	text-align: ${(props) => `${props?.contentLeft ? 'left' : 'center'}`};
	color: ${(props) => props.theme.colors.baseFont} !important;
`;
