import { Radio } from "antd";
import React, { useState } from "react";
import './index.scss';

interface BrandVariantPickerProps {
  title: string;
  isRequired?: boolean;
  variants: any[];
  onClick: (data: any) => void;
}

const BrandVariantPicker: React.FC<BrandVariantPickerProps> = ({ 
  title,
  isRequired = true,
  variants,
  onClick
}) => {
  const [attribute, setAttribute] = useState(variants[0]?.id);
  const handleOnChange = (e: any) => {
    const variantId = e.target.value;
    setAttribute(variantId);
    if (onClick) {
      onClick(variants.find(item => item?.id === variantId))
    }
  }

  return (
    <Radio.Group key={title} className="brand-product-variant__container px-3" onChange={handleOnChange} value={attribute}>
      <div className="brand-product-variant__title">
        <h4>{title}</h4>
        {isRequired && <span className="brand-product-variant__required">REQUIRED</span>}
      </div>
      {(variants || []).map((variant, i) => 
        <Radio key={i} value={variant?.id} className="brand-product-variant__option">{`${variant?.name} ${variant?.isAvailable ? "" : "(Out of stock)"}`}</Radio>
      )}
    </Radio.Group>
  );
};

export default BrandVariantPicker;
