import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { StoreDetails, StoreDetailsVariables } from "./gqlTypes/StoreDetails";
import {
  basicProductFragment,
  productPricingFragment,
} from "../Product/queries";
import {
  StoreProducts,
  StoreProductsVariables,
} from "./gqlTypes/StoreProducts";

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.
export const storeDetailsQuery = gql`
  query VariantList($slug: String!) {
    merchantBySlug(slug: $slug) {
      id
      name
      description
      storeTitle
      storeDescription
      storeUrl
      storeTrademark
      leadTime
      storeHourStart
      storeHourEnd
      operatingDays
      slug
      logo {
        url
      }
      banner {
        url
      }
      warehouses(first: 5) {
        edges {
          node {
            name
            companyName
            address {
              phone
              city
              streetAddress1
              streetAddress2
            }
          }
        }
        totalCount
      }
      merchantCategoryAssignment (first: 1) {
        edges {
          node {
            id
            merchantCategory {
              id
              name
              slug
              parent {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;
export const TypedStoreDetailsBySlugQuery = TypedQuery<
  StoreDetails,
  StoreDetailsVariables
>(storeDetailsQuery);

export const storeProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  query StoreProducts(
    $attributes: [AttributeInput]
    $after: String
    $pageSize: Int
    $sortBy: ProductOrder
    $priceLte: Float
    $priceGte: Float
    $merchantId: UUID
  ) {
    products(
      after: $after
      first: $pageSize
      sortBy: $sortBy
      filter: {
        isPublished: true
        attributes: $attributes
        minimalPrice: { gte: $priceGte, lte: $priceLte }
      }
      merchantId: $merchantId
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    attributes(
      first: 100
      merchantId: $merchantId
    ) {
      edges {
        node {
          id
          name
          slug
          values {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
export const TypedStoreProductsQuery = TypedQuery<
  StoreProducts,
  StoreProductsVariables
>(storeProductsQuery);
