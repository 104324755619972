import React from "react";

import { Attribute, IconButton, Tile } from "@components/atoms";

import { usePasswordChange, useUserDetails } from "@saleor/sdk";
import { PasswordChangeForm } from "./PasswordChangeForm";
import * as S from "./styles";

export const PasswordTile: React.FC = () => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [setPasswordChange, { data, error }] = usePasswordChange();
  const { data: user } = useUserDetails();

  React.useEffect(() => {
    if (data && !error) {
      setIsEditing(false);
    }
  }, [data, error]);
  return (
    <S.TileWrapper>
      <Tile tileType="account">
        <S.Wrapper>
          <S.Header>
            My Password
            {!user?.facebookUser && !isEditing && (
              <IconButton
                testingContext="editPasswordButton"
                name="edit"
                size={22}
                onClick={() => setIsEditing(isEditing => !isEditing)}
              />
            )}
          </S.Header>
          <S.Content>
            {isEditing ? (
              <S.ContentEdit>
                <PasswordChangeForm
                  handleSubmit={data => {
                    setPasswordChange(data);
                  }}
                  hide={() => {
                    setIsEditing(false);
                  }}
                  error={error ? error!.extraInfo!.userInputErrors : []}
                />
              </S.ContentEdit>
            ) : (
              <Attribute
                description="Password"
                attributeValue="**************"
              />
            )}
          </S.Content>
          <S.Reset>
          <a onClick={() => setIsEditing(isEditing => !isEditing)} href="#">{isEditing ? '' : 'Click to reset password'}</a>
          </S.Reset>
        </S.Wrapper>
      </Tile>
    </S.TileWrapper>
  );
};
