import React from "react";

import { IAddress } from "@types";

import * as S from "./styles";

export const Address: React.FC<IAddress> = ({
  firstName,
  lastName,
  companyName,
  streetAddress1,
  streetAddress2,
  city,
  postalCode,
  countryArea,
  country,
  phone,
}: IAddress) => (
  <div>
    <S.Name>{`${firstName} ${lastName}`}</S.Name>
      {companyName && (
        <span>{companyName}</span>
        )}
        <S.Title>Pinned Address</S.Title>
        <span>{streetAddress1}</span>
        {streetAddress2 && 
        <>
          <S.Title>Address Details</S.Title>
          <span>{streetAddress2}{" "}</span>
          {city}, {postalCode}
          {countryArea && <>{countryArea},</>}{" "}
          {country?.country}
        </>
        }
        {phone && (
        <>
          <S.Title>Phone number</S.Title>
          <span>{phone}</span>      
        </>
        )}
  </div>
);
