import {styled} from '@styles';

export const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	min-height: 15rem;
	max-height: 18rem;
	/* height: auto; */
	padding-left: 3rem;
	padding-top: 2.5rem;
	border-radius: 15px;
`;

export const MenuHeader = styled.div`
	color: ${(props) => props.theme.colors.white};
	font-size: ${(props) => props.theme.typography.h3FontSize};
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	text-transform: 'uppercase';
	padding-bottom: 2rem;
`;
export const Menu = styled.div`
	a:hover {
		text-decoration: none !important;
		color: ${(props) => props.theme.colors.white};
	}
`;

export const MenuItem = styled.div<{
	active: boolean;
}>`
	cursor: pointer;
	padding-bottom: 1.5rem;
	color: ${(props) =>
		props.active ? props.theme.colors.white : props.theme.colors.white};
`;
