import { styled } from "@styles";

export const Name = styled.span`
  display: block;
  font-family: ${props => props.theme.typography.secondaryFontFamily};
  text-transform: uppercase;
`;

export const Text = styled.span`
  font-family: ${props => props.theme.typography.baseFontFamily};
`;

export const Title = styled.p`
margin-top: 1rem;
font-family: ${props => props.theme.typography.secondaryFontFamily} !important;
`;


