import * as React from "react";
import Media from 'react-responsive';
// import bannerOneMobile from "@images/bannerOne-mobile.jpg";
import bannerTwoMobile from "@images/bannerTwo-mobile.jpg";
import bannerThreeMobile from "@images/bannerThree-mobile.jpg";
import bannerTwoDesktop from "@images/bannerTwo.jpg";
// import bannerOneDesktop from "@images/bannerOne.jpg";
import bannerFiveDesktop from "@images/bannerFive.jpg";
import bannerFiveMobile from "@images/bannerFive-mobile.jpg";
import bannerThreeDesktop from "@images/bannerThree.jpg";
import bannerFourDesktop from "@images/bannerFour.jpg";
import bannerFourMobile from "@images/bannerFour-mobile.jpg";

import NearbyBrands from '@temp/components/NearbyBrands';
import Brands from './Brands';
import { smallScreen } from '@temp/@next/globalStyles/constants';
// import { useLayout } from '@temp/layout/mobile/hooks/useLayout';
import { useLayout } from '@temp/layout/hooks/useLayout';

import {
  BrandCollectionsResultEdgesNode
} from "./gqlTypes/BrandCollections";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import './index.scss';
import { useHistory } from "react-router";
import { Loader } from "@temp/@next/components/atoms";
import { useAlert } from "react-alert";
import { useUserDetails } from "@saleor/sdk";

interface HomeProps {
  brandCollections: BrandCollectionsResultEdgesNode[] | null;
  createOrGetProfileJoyride?: any;
  match?: any;
}

const Home: React.FC<HomeProps> = ({
  brandCollections,
  createOrGetProfileJoyride,
  match
}) => {
  const { setShowCategorybar, setShowAddressbar } = useLayout();
  setShowCategorybar(true);
  setShowAddressbar(true);

  const [isLoading, setIsLoading] = React.useState(false);

  const alert = useAlert();
  const user = useUserDetails()
  const history = useHistory();
  const joyrideToken = match?.params?.id;

  const handleAccountMutation = async (createOrGetProfileJoyride) => {
    const savedEmail = localStorage.getItem("loggedInEmail") || null;
    const data = await createOrGetProfileJoyride({
      variables: {
        joyrideToken
      }
    })
    if (data.data?.createOrGetProfileJoyride?.token) {
      if (savedEmail !== data?.data?.createOrGetProfileJoyride?.user?.email) {
        localStorage.removeItem("apollo-cache-persist");
        localStorage.setItem("token", data?.data?.createOrGetProfileJoyride?.token);
        localStorage.setItem("loggedInEmail", data?.data?.createOrGetProfileJoyride?.user?.email);
        window.location.href = '/';
        localStorage.setItem("isLoggedInViaJR", "true")
        setIsLoading(false);
      }
      else {
        history.push('/');
        setIsLoading(false);
      }
    }
    else {
      localStorage.removeItem("loggedInEmail");
      history.push('/');
      setIsLoading(false);
    }
  }

  SwiperCore.use([Pagination, Autoplay]);

  const params = {
    observeParents: true,
    observer: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 3000
    }
  }

  React.useEffect(() => {
    if (joyrideToken) {
      setIsLoading(true);
      handleAccountMutation(createOrGetProfileJoyride);
    }
  }, [joyrideToken])

  React.useEffect(() => {
    if (localStorage.getItem("isLoggedInViaJR")) {
      alert.show(
        {
          title: "You are now logged in",
        },
        { type: "success" }
      );
    }
    if (localStorage.getItem("isLoggedInViaJR") === "true") {
      localStorage.removeItem("isLoggedInViaJR")
    }
  }, [!!user])


  return (
    <>
      {!isLoading ?
        <div className="home-page">
          <div className="home-page__categories">
            <div className="home-page__categories__carousel">
              <Media maxWidth={smallScreen}>
                <Swiper {...params}>
                  <SwiperSlide><div><img src={bannerFiveMobile} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerFourMobile} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerTwoMobile} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerThreeMobile} /></div></SwiperSlide>
                </Swiper>
                <div className="swiper-pagination"></div>
              </Media>
              <Media minWidth={541}>
                <Swiper {...params}>
                  <SwiperSlide><div><img src={bannerFiveDesktop} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerFourDesktop} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerTwoDesktop} /></div></SwiperSlide>
                  <SwiperSlide><div><img src={bannerThreeDesktop} /></div></SwiperSlide>
                </Swiper>
                <div className="swiper-pagination"></div>
              </Media>
            </div>
          </div>
          <div className="home-page__featured">
            <div className="home-page__featured__brands">
              {/* Collections of brands */}
              {brandCollections?.map(
                (brandCollection) => (
                  brandCollection.merchantBrands.edges.length > 0 ?
                  <Brands key={brandCollection?.id} brandCollection={brandCollection} /> : ""
                )
              )
              }
            </div>
            <NearbyBrands />
            <div style={{ padding: '4rem 0' }}></div>
          </div>
        </div>
        : <div className="w-100 h-100 d-flex justify-content-center align-items-center position-fixed" style={{ backgroundColor: '#fff', zIndex: 999 }}>
          <Loader />
        </div>}
    </>
  )
}

export default Home;