import * as React from "react";

// import { useLayout } from '@temp/layout/mobile/hooks/useLayout';
import { useLayout } from '@temp/layout/hooks/useLayout';

import { ChevronRight } from 'react-feather';
import { Button, Loader } from "@components/atoms";
import { BrandCollectionsData } from "./gqlTypes/BrandCollection";
import * as S from "./styles";
import "./index.scss";
import BrandCard from "@temp/components/BrandCard";
import { Link } from "react-router-dom";
import JRMallPlaceholder from '../../../images/merchant-placeholder-img.jpg';
interface PageProps {
  brandCollection: BrandCollectionsData | null;
  canLoadMore?: boolean;
  loading?: boolean;
  onLoadMore: () => void;
}

const Page: React.FC<PageProps> = ({
  brandCollection,
  canLoadMore,
  loading,
  onLoadMore
}) => {
  const {setShowCategorybar} = useLayout();
  setShowCategorybar(true);
  
  const merchantBrands = brandCollection?.merchantBrands?.edges?.map(item => item.node) || [];

  return (
    <div className="bg-white py-4 collection-brands">
      <div className="main-container">
      <div className="collection-brands__breadcrumbs">
        <Link to='/'>Home</Link>
        <ChevronRight />
        <span>{brandCollection?.name}</span>
      </div>
      <h4>{brandCollection?.name}</h4>
      
      {/* <div className="my-3 delete this it is just sample how to display its preloader state">
        <NearbyBrandCard isPreloading />
      </div> */}
      <div className="collection-brands-container">
        {/* {data.map((item, ind) => <NearbyBrandCard key={ind} {...item} />)} */}
        {merchantBrands.map((brand, ix) => {
          const categories = [];
          brand?.merchantBrandMerchantCategoryAssignment?.edges.map(item => {
            const name =  item?.node?.merchantCategory?.name;
            if (name) {
              categories.push(name);
            }
          });

          const bgUrl = brand?.banner?.url || brand?.logo?.url || JRMallPlaceholder;
          return (
            <BrandCard 
              key={ix}  
              brandName={brand?.name}
              categories={categories}
              backgroundUrl={bgUrl}
              slug={brand?.slug}
            />
          );
        })}
        </div>
        <S.Loader>
          {loading ? (
            <Loader />
          ) : (
            canLoadMore && (
              <Button
                testingContext="loadMoreProductsButton"
                color="secondary"
                onClick={onLoadMore}
              >
                <span>More +</span>
              </Button>
            )
          )}
        </S.Loader>
      </div>
    </div>
  )
}

export default Page;