import React from "react";
import Product, { ProductInterface } from "./Product";
import MerchantInfo, { MerchantInfoProps } from "./MerchantInfo";
import Footer, { FooterProps } from "./Footer";

export interface ItemProps {
  merchantInfo?: MerchantInfoProps;
  items?: ProductInterface[];
  orderDetails?: FooterProps;
  remove?: (variantId: string) => void;
}

const Item: React.FC<ItemProps> = ({
  merchantInfo,
  items,
  orderDetails,
  remove,
}) => {
  return (
    <div className="mb-4">
      <MerchantInfo {...merchantInfo} />
      {items.map((product) => {
        return <Product remove={remove} {...product} />;
      })}
      <Footer {...orderDetails} />
    </div>
  );
};

export default Item;
