import React from "react";
import { Money } from "@temp/@next/components/containers";

export interface FooterProps {
  total?: string;
  totalPrice: {
    amount: number;
    currency: string;
  };
  deliveryFee: {
    amount: any;
    currency: string;
  };
}

const Footer: React.FC<FooterProps> = ({
  totalPrice = {
    amount: 0,
    currency: "Php",
  },
  deliveryFee,
}) => {
  deliveryFee = deliveryFee || {
    amount: 'Calculated during checkout',
    currency: "Php",
  };

  return (
    <section className="border-top mt-3 border-bottom py-3">
      <section className="d-flex justify-content-between">
        <span className="__fw-bold">Subtotal</span>
        <span className="__fw-bold">
          <Money
            defaultValue={`${totalPrice.currency} 0.00`}
            money={totalPrice}
          />
        </span>
      </section>
      <section className="mt-2 d-flex justify-content-between">
        <span className="__fw-bold">Delivery Fee</span>
        <span className="__deliveryText">
          <Money
            defaultValue={`${totalPrice.currency} 0.00`}
            money={deliveryFee}
          />
        </span>
      </section>
    </section>
  );
};

export default Footer;
