import gql from 'graphql-tag';
import {TypedMutation} from 'src/core/mutations';

interface CreateOrGetProfileJoyrideVariables {
	joyrideToken: string;
}

interface CreateOrGetProfileJoyride {
	data: {
		createOrGetProfileJoyride: {
			token: string;
			message: string;
			user: {
				lastName: string;
				firstName: string;
				email: string;
				isStaff: boolean;
				isActive: boolean;
			};
		};
	};
}

const createOrGetProfileJoyride = gql`
	mutation createOrGetProfileJoyride($joyrideToken: String!) {
		createOrGetProfileJoyride(joyrideToken: $joyrideToken) {
			token
			message
			user {
				lastName
				firstName
				email
				isStaff
				isActive
			}
		}
	}
`;
export const TypedCreateOrGetProfileJoyride = TypedMutation<
	CreateOrGetProfileJoyride,
	CreateOrGetProfileJoyrideVariables
>(createOrGetProfileJoyride);
