import React from 'react';
import { RichTextContent } from "@temp/@next/components/atoms";
import './index.scss';
import preparationIcon from '../../../../images/preparation-icon.svg';
import ReactSVG from 'react-svg';

interface IProps{
	name?: string;
	descriptionJson?: string;
	preparationTime?: string;
}

const BrandProductDescription:React.FC<IProps> = ({name, descriptionJson, preparationTime})=> {
	
	return (	
		<div className="brand-product-description px-3 py-2">
			<h4>{name}</h4>
			<RichTextContent descriptionJson={descriptionJson} />
			{/* <p>{description}</p> */}
			<div className="brand-product-description__preparationTime">
				<ReactSVG path={preparationIcon}/>
				<p>{preparationTime}</p>
			</div>
		</div>
	);

};

export default BrandProductDescription;
