import React from "react";
import { RouteComponentProps } from "react-router";

import { useQueryParam } from "use-query-params";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import Page from "./Page";
import { TypedStoreCategoryQuery } from "./queries";
import { getGraphqlIdFromDBId, getDBIdFromGraphqlIdString, capitalizeFirstLetter } from "../../core/utils";
import { PAGE_SIZE } from "./constants"
import { useLayout } from "@temp/layout/hooks/useLayout";

const FilterQuerySet = {
  encode(valueObj) {
    const str = [];
    Object.keys(valueObj).forEach(value => {
      str.push(value + "_" + valueObj[value].join("_"));
    });
    return str.join(".");
  },

  decode(strValue) {
    const obj = {};
    const propsWithValues = strValue.split(".").filter(n => n);
    propsWithValues.map(value => {
      const propWithValues = value.split("_").filter(n => n);
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  },
};

const View: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const storeCategDBId = getGraphqlIdFromDBId(match?.params?.id, "MerchantCategory");
  const [attributeFilters, setAttributeFilters] = useQueryParam(
    "filters",
    FilterQuerySet
  );
  const filters: any = {
    attributes: attributeFilters,
  };
  const { useUserLocation } = useLayout();

  return (
    <TypedStoreCategoryQuery
      loaderFull
      variables={{
        afterFirstLevel: "",
        afterSecondLevel: "",
        id: storeCategDBId,
        pageSize: PAGE_SIZE,
        selectedCities: filters?.attributes?.city,
        latitude: useUserLocation()?.latitude,
        longitude: useUserLocation()?.longitude
      }}
      errorPolicy="all"
      key={match?.params?.id}
    >
      {({ loading, data, loadMore }) => (
        <NetworkStatus>
          {isOnline => {
            const { merchantCategory } = data || {};
            const { id, slug, name, parent } = merchantCategory || {};
            const handleFirstLevelLoadMore = () => {
              loadMore(
                (prev, next) => ({
                  ...prev,
                  merchantCategory: {
                    ...next.merchantCategory,
                    children: {
                      ...prev.merchantCategory.children,
                      edges: [...prev.merchantCategory.children.edges, ...next.merchantCategory.children.edges],
                      pageInfo: next.merchantCategory.children.pageInfo,
                    }
                  }
                }),
                { afterFirstLevel: data?.merchantCategory?.children?.pageInfo.endCursor || "", selectedCities: filters?.attributes?.city }
              );
            };
            const handleSecondLevelLoadMore = () => {
              loadMore(
                (prev, next) => ({
                  ...prev,
                  merchantCategory: {
                    ...next.merchantCategory,
                    merchantBrandCategoryAssignment: {
                      ...prev.merchantCategory.merchantBrandCategoryAssignment,
                      edges: [...prev.merchantCategory.merchantBrandCategoryAssignment.edges, ...next.merchantCategory.merchantBrandCategoryAssignment.edges],
                      pageInfo: next.merchantCategory.merchantBrandCategoryAssignment.pageInfo,
                    }
                  }
                }),
                { afterSecondLevel: data?.merchantCategory?.merchantBrandCategoryAssignment?.pageInfo.endCursor || "", selectedCities: filters?.attributes?.city }
              );
            };

            if (merchantCategory) {
              const breadcrumbs = [];
              if (parent) {
                breadcrumbs.push({
                  link: `/store-category/${parent.slug}/${getDBIdFromGraphqlIdString(parent.id)}`,
                  value: capitalizeFirstLetter(parent.name),
                });
              }
              breadcrumbs.push({
                link: `/store-category/${slug}/${getDBIdFromGraphqlIdString(id)}`,
                value: capitalizeFirstLetter(name),
              });

              return (
                <MetaWrapper meta={merchantCategory}>
                  <Page
                    merchantCities={[]}
                    merchantCategory={merchantCategory}
                    breadcrumbs={breadcrumbs}
                    displayLoader={loading}
                    loadMoreFirstLevelCategory={handleFirstLevelLoadMore}
                    loadMoreSecondLevelCategory={handleSecondLevelLoadMore}
                    attributeFilters={attributeFilters}
                    filters={filters}
                    onFilterAttributeChange={(newAttributeFilter) => setAttributeFilters(newAttributeFilter)}
                  />
                </MetaWrapper>
              );
            }

            if (merchantCategory === null) {
              return <NotFound />;
            }

            if (!isOnline) {
              return <OfflinePlaceholder />;
            }
          }}
        </NetworkStatus>
      )}
    </TypedStoreCategoryQuery>
  );
};

export default View;
