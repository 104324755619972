import React from "react";

interface PaymentMethodProps {
  paymentTransactionType: any;
}

const PaymentMethod: React.FC<Partial<PaymentMethodProps>> = ({
  paymentTransactionType
}) => {
  const hasCard = false;
  return (
    <div className="order-details__paymentMethod">
      <div className="order-details__paymentMethod__text">
        <h3>Payment Method</h3>
      </div>
      <p className="order-details__paymentMethod__paymentText">{paymentTransactionType?.display}</p>
      {hasCard && <p className="order-details__paymentMethod__paymentText">Card ending in {5409}</p>}
      
  </div>
  );
};

export default PaymentMethod;