import {styled} from '@styles';

export const CustomerDetails = styled.div`
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: ${(props) => props.theme.colors.cardBg};
	border: 1px solid ${(props) => props.theme.colors.secondary};
	min-width: 280px;
	border-radius: 10px;
	* {
		color: ${(props) => props.theme.colors.baseFont};
		font-size: 14px;
		line-height: 1.2rem;
	}
`;

export const Customer = styled.p`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
`;

export const CustomerAddress = styled.p`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
`;

export const Address = styled.p`
	text-transform: uppercase;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
`;

export const Dropdown = styled.button<{showMore?: boolean}>`
	padding: 0 1rem;
	outline: none !important;
	transform: ${(props) => (props.showMore ? 'rotate(180deg)' : 'rotate(0deg)')};
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;

	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	transition-property: transform;
`;

export const CustomerWrapper = styled.div<{showMore?: boolean}>`
	display: ${(props) => (props.showMore ? 'none' : 'flex')};
	justify-content: space-between;
	align-items: center;
`;
