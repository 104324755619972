import {media, styled} from '@styles';

export const Wrapper = styled.div``;

export const Divider = styled.div`
	width: 100%;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightFont};
	margin: 30px 0;
`;

export const Title = styled.h3`
	margin-top: 1rem;
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	font-size: 28px;
	color: ${(props) => props.theme.colors.primary};
`;
export const FieldContainer = styled.div`
	margin: 1.5rem 0;
	font-size: 16px;
`;

export const DiscountField = styled.div`
	background-color: ${(props) => props.theme.colors.light};
	padding: 30px;

	${media.smallScreen`
    padding: 30px 20px;
  `}
`;

export const Tile = styled.label<{checked: boolean}>`
  display: block;
  background-color: ${(props) => props.theme.colors.light};
  padding: 20px;
  ${(props) => props.checked && `border: 2px solid #21125E;`}
  font-size: ${(props) => props.theme.typography.smallFontSize};
  cursor: pointer;
`;

export const AdditionalText = styled.div`
  padding: 2px;
  color: grey
  font-size: 14px;
  flex-grow: 1;
  flex-direction: row;
  display: flex;
`;
