import {styled, media} from '@styles';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 1rem;
	button {
		border-radius: 30px;
	}
	${media.smallScreen`
    margin: 1rem 0;
  `};
`;

export const ButtonWrapper = styled.div`
	text-align: center;
	width: 100%;
	padding-top: 1rem;
	button {
		padding: 1rem 5rem;
	}
	span {
		font-weight: 0;
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		text-transform: unset;
	}
`;

export const Dispatch = styled.p`
	margin-top: 1rem;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.primary};
	padding: 15px 15px;
	border-radius: 10px;
`;

export const Container = styled.div`
	background-color: #fff;
	border: 2px solid ${(props) => props.theme.colors.secondary};
	border-radius: 10px;
	padding-bottom: 1rem;
`;
