import {media, styled} from '@styles';

export const Wrapper = styled.div`
	display: grid;
	min-height: 140px;
	max-height: min-content;
	width: 100%;
	grid-template-areas: 'photo description unitPrice quantity totalPrice trash';
	grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 0.5fr;
	align-items: center;
	border-bottom: 1px solid rgba(50, 50, 50, 0.1);
	padding: 0.8rem 0.5rem;
	${media.mediumScreen`
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 20px;
    grid-template-areas: 
    "photo description description  "
    "trash description description "
    "trash . quantity"
    "unitPrice . ."
    "totalPrice . .";
    padding: 1rem 0rem;
  `};
`;

export const QuantityButtons = styled.div`
	display: flex;
	justify-content: center;
	padding: 0;
	margin: 0 15px 0 15px;
	width: 10px;

	> div {
		display: flex;
	}

	svg {
		cursor: pointer;
		justify-self: center;
	}
	${media.mediumScreen`

  `}
`;

export const Photo = styled.div`
	grid-area: photo;
	display: flex;
	align-items: flex-start;
	justify-self: center;
	align-self: top;
	width: 85px;
	height: 85px;
	cursor: pointer;
	background-color: #fff;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Description = styled.div`
	cursor: pointer;
	grid-area: description;
	#height: 100%;
	height: 65%;
	margin-top: 20px;
	margin-left: 20px;
	${media.mediumScreen`
    margin-left: 0px;
  `}
`;

export const Sku = styled.p`
	margin: 6px 0;
	text-align: left;
	margin-bottom: 10px;
`;

export const Attributes = styled.div`
	display: grid;
	grid-auto-columns: max-content;
	grid-template-columns: repeat(auto-fit, minmax(166px, 500px));
	margin-left: -15px;
	${media.mediumScreen`
    flex-flow: column;
  `};
`;

export const SingleAttribute = styled.p`
	margin: 0;
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
	background-color: white;
	padding: 0px 15px;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	color: ${(props) => props.theme.colors.lightFont};
`;

export const Name = styled.p`
	a {
		font-family: ${(props) => props.theme.typography.baseFontFamily};
		font-size: ${(props) => props.theme.typography.h4FontSize};
		text-align: left;
		margin-bottom: 6px;
		color: ${(props) => props.theme.colors.baseFont} !important;
	}
	a:hover {
		color: ${(props) => props.theme.colors.lightFont} !important;
	}
`;

export const LightFont = styled.span`
	font-size: ${(props) => props.theme.typography.smallFontSize};
	color: ${(props) => props.theme.colors.lightFont};
`;

export const Price = styled.div`
	font-size: ${(props) => props.theme.typography.h4FontSize};
	display: flex;
	justify-content: center;
	${media.mediumScreen`
    flex-direction: column;
  `}

	p {
		font-family: ${(props) => props.theme.typography.baseFontFamily};
		margin: 0;
	}
`;

export const PriceLabel = styled.p`
	display: none;
	${media.mediumScreen`
    display: block;
  `}
`;

export const TotalPrice = styled(Price)`
	grid-area: totalPrice;
	${media.mediumScreen`
    p {
      text-align: left;
    }
  `}
`;

export const Trash = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: trash;
`;

export const UnitPrice = styled(Price)`
	grid-area: unitPrice;
`;

export const Quantity = styled.div`
	grid-area: quantity;
	min-width: 120px;
	margin: 0 15px;
	height: 50px;
	${media.mediumScreen`
    margin: 0px;
    min-width: 160px;
  `}
`;
