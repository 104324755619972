import gql from "graphql-tag";

import { TypedMutation } from "src/core/mutations";
import { ValidateFacebookUser, ValidateFacebookUserVariables } from "./types";

const validateFacebookUserMutation = gql`
    mutation validateFacebookUser($facebookId: String!, $facebookAccessToken: String!, $facebookName: String) {
        validateFacebookUser(facebookId: $facebookId, facebookAccessToken: $facebookAccessToken, facebookName: $facebookName) {
            errors {
                field
                message
            }
            facebookUserKey
            facebookUser {
                id
                facebookId
                email
                name
            }
        }
    }
`;

export const TypedValidateFacebookUserMutation = TypedMutation<
    ValidateFacebookUser,
    ValidateFacebookUserVariables
>(validateFacebookUserMutation);
