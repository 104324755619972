import classNames from "classnames";
import * as React from "react";
import ReactSVG from "react-svg";
import { MENU_ACCOUNTS_STRING } from "src/constants";
import { NavLink } from "..";
import { MainMenuSubItem } from "../MainMenu/gqlTypes/MainMenuSubItem";

import subcategoriesImg from "@images/chevron-right.svg";

export interface INavItem extends MainMenuSubItem {
  iconName?: string | null;
  children?: INavItem[];
}

interface NavItemProps extends INavItem {
  hideOverlay(): void;
  showSubItems(item: INavItem): void;
}

const NavItem: React.FC<NavItemProps> = ({
  hideOverlay,
  showSubItems,
  ...item
}) => {

  const handleNextStepClick = () => {
    switch (item.name) {
      case MENU_ACCOUNTS_STRING:
        return (
          "fas fa-user mr-3"
        );
      case "Food":
        return (
          "fas fa-hamburger mr-3"
        );
      case "Drinks":
        return (
          "fas fa-glass-cheers mr-3"
        );
      case "Groceries":
        return (
          "fas fa-shopping-basket mr-3"
        );
      case "Lifestyle":
        return (
          "fas fa-tshirt mr-3"
        );
      case "Essentials":
        return (
          "fas fa-tools mr-3"
        );
      case "Others":
        return (
          "fas fa-gifts mr-3"
        );
      default:
    }
  };
  const hasSubNavigation = item.children && !!item.children.length;

  return (
    <li
      className={classNames({
        "side-nav__menu-item": true,
        "side-nav__menu-item--has-subnavigation": hasSubNavigation,
      })}
    >
      <NavLink
        iconName={handleNextStepClick()}
        item={item}
        className={"side-nav__menu-item-link"}
        onClick={hideOverlay}
      />
      {hasSubNavigation && (
        <ReactSVG
          path={subcategoriesImg}
          className="side-nav__menu-item-more"
          onClick={() => showSubItems(item)}
        />
      )}
    </li>
  );
};

export default NavItem;
