import { styled, media } from "@styles";

export const Container = styled.div<{ flex?: boolean; paddingTop?: number }>`
  display: flex;
  flex-direction: ${(props) => `${props?.flex ? "column" : "row"}`};
  padding-top: ${(props) =>
    `${props?.paddingTop ? `${props.paddingTop}px` : "5px"}`};
`;
interface StylesProps {
  alignRight?: boolean;
  fontSize?: number;
  mobileView?: boolean;
  flex?: boolean;
}
export const Content = styled.div<{ styles?: StylesProps }>`
  color: ${(props) => props.theme.colors.baseFont};
  margin: ${(props) => `${props?.styles?.flex ? "1rem" : "0"}`};
  font-size: ${(props) =>
    `${
      props?.styles?.fontSize
        ? `${props.styles?.fontSize}px !important`
        : "16px"
    }`};
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.typography.baseFontFamily};
  ${(props) =>
    `${props?.styles?.alignRight ? "justify-content: flex-end;" : ""}`}
  ${(props) =>
    `${props?.styles?.alignRight ? "text-transform: lowercase;" : ""}`}
  ${media.smallScreen`
    ${(props: any) =>
      `${props?.styles?.mobileView ? "justify-content: flex-start;" : ""}`}
    `};
`;
export const IconContainer = styled.div<{
  iconSize?: number;
  paddingRight?: number;
  paddingLeft?: number;
}>`
  font-size: ${(props) =>
    `${props?.iconSize ? `${props.iconSize}px` : "17px"}`};
  padding-right: ${(props) =>
    `${props?.paddingRight ? `${props.paddingRight}px` : "6px"}`};
  padding-left: ${(props) =>
    `${props?.paddingLeft ? `${props.paddingLeft}px` : "0px"}`};
`;
