import {DefaultTheme, media, styled} from '@styles';

export const Wrapper = styled.div<{mobileCartOpened: boolean}>`
	background-color: #f5f5f5;
	${media.mediumScreen`
    width: 100%;
    height: 100%;
    position: fixed;
    top: calc(100% - 60px);
    left: 0%;
    transition: all 0.5s ease;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  `}
	${(props) =>
		props.mobileCartOpened &&
		media.mediumScreen`
    // padding-top: 5rem;
    top: 0;
    overflow-y: scroll;
    z-index: 2;
  `}
`;
export const Content = styled.div`
	padding: 0 20px 30px 20px;
`;

export const ProductLine = styled.div`
	padding: 30px 0;
	cursor: pointer;
`;

export const CartSummaryProductList = styled.div`
	margin-bottom: 30px;
`;

export const HR = styled.hr`
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid
		${(props) => props.theme.colors.baseFontColorTransparent};
	margin: 0;
	padding: 0;
`;
export const Container = styled.div`
	border-top: 1px solid #fff;
	padding: 20px 20px 20px 20px;
	background-color: ${(props) => props.theme.colors.secondary};
`;
export const Title = styled.div<{mobileCartOpened: boolean}>`
	color: ${(props) => props.theme.colors.white};
	${(props) =>
		props.mobileCartOpened &&
		media.mediumScreen`
  padding-bottom: 0rem;
`}
	display: flex;
	justify-content: space-between;
	margin: 0;
	align-items: flex-start;
	font-size: ${(props) => props.theme.typography.h3FontSize};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	${media.mediumScreen`
    font-size: ${(props: {theme: DefaultTheme}) =>
			props.theme.typography.h4FontSize};
    cursor: pointer;
  `}
`;

export const SubTitle = styled.div`
	padding: 10px 20px;
`;
export const PaddingContainer = styled.div`
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	div {
		color: ${(props) => props.theme.colors.lightFont};
		font-family: ${(props) => props.theme.typography.baseFontFamily};
	}
`;

export const GrandTotal = styled.div`
	span {
		color: ${(props) => props.theme.colors.baseFont};
		font-size: ${(props) => props.theme.typography.baseFontSize};
		font-family: ${(props) =>
			props.theme.typography.secondaryFontFamily} !important;
	}
`;
export const MerchantName = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0;
	font-size: ${(props) => props.theme.typography.baseLineHeight};
	a {
		text-transform: uppercase;
		font-size: 16px;
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		color: ${(props) => props.theme.colors.primary} !important;
	}
	${media.mediumScreen`
    font-size: ${(props: {theme: DefaultTheme}) =>
			props.theme.typography.h4FontSize};
    cursor: pointer;
  `}
`;
export const ArrowUp = styled.div<{mobileCartOpened: boolean}>`
	display: none;
	${media.mediumScreen`
    display: flex;
    align-self: center;
    justify-content: center;
  `}
	${(props) =>
		props.mobileCartOpened &&
		media.mediumScreen`
    transform: rotate(180deg);
  `}
`;
export const CostLine = styled.div<{last: boolean; marginBottom?: number}>`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${(props) =>
		props.marginBottom ? `${props.marginBottom || 0}px` : `0px`};
	span {
		display: inline-block;
		&:nth-child(2) {
			font-size: 14px;
		}
	}
`;

export const Costs = styled.div`
	display: flex;
	flex-direction: column;
`;
export const Delivery = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	span {
		font-size: 16px;
		&:nth-child(2) {
			font-size: 14px;
			text-align: right;
		}
	}
`;
export const VehicleType = styled.span`
	display: flex;
	justify-content: flex-end;
	color: #767676;
	font-size: 10px;
	margin-bottom: 20px;
`;

export const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	line-height: 1.2rem;

	> svg {
		margin-left: 5px;
	}
`;

export const Notes = styled.div`
	padding-top: 10px;
	span {
		color: ${(props) => props.theme.colors.baseFont};
		font-size: ${(props) => props.theme.typography.smallFontSize};
		font-family: ${(props) => props.theme.typography.baseFontFamily};
	}
`;
