import React, {FC, useState, 
	// useState, 
	// useRef
} from 'react';
// import {Plus, Minus} from 'react-feather';
import * as helpers from "src/helpers";
import './index.scss';
import preparationIcon from '../../images/preparation-icon.svg'
import SkeletonProductCard from './SkeletonProductCard';
import ReactSVG from 'react-svg';

export interface ProductCardProps {
	className?: string;
	imageUrl?: string;
	name?: string;
	price?: string;
	id?: string;
	setProductOverlay?: (state: boolean, id?: string)=> void;
	categoryName?: string;
	prepareTime?: string;
}

const ProductCard: FC<ProductCardProps> = ({
	id,
	imageUrl,
	name = '[Unknown name]',
	price = '[Unknown price]',
	categoryName = "",
	setProductOverlay,
	prepareTime,
}) => {

	const [isLoading, setIsLoading] = useState(true);
	const [isHovered, setIsHovered] = useState(false);
	// const [quanity, setQuantity] = useState<number>(0);
	// const productElementRef = useRef<HTMLDivElement>(null);

	// const handleAddQuantity = (event) => {
	// 	event.stopPropagation();
	// 	setQuantity((quanity) => ++quanity);
	// };

	// const handleDeductQuantity = (event) => {
	// 	event.stopPropagation();
	// 	setQuantity((quanity) => --quanity);
	// };

	// let Action = (
	// 	<button
	// 		onClick={() => setProductOverlay(true)}
	// 		className="p-1 bg-warning border-0 product-card-action"
	// 	>
	// 		<Plus />
	// 	</button>
	// );

	// if (quanity) {
	// 	Action = (
	// 		<div className="w-100 font-weight-bold d-flex justify-content-between align-items-center py-1 px-2 bg-warning product-card-action-container">
	// 			<button onClick={handleAddQuantity} className="bg-transparent border-0">
	// 				<Plus />
	// 			</button>
	// 			<span>{quanity}</span>
	// 			<button
	// 				onClick={handleDeductQuantity}
	// 				className="bg-transparent border-0"
	// 			>
	// 				<Minus />
	// 			</button>
	// 		</div>
	// 	);
	// }

	// const actionAlignment = quanity ? 'center' : 'end';

	// handle dynamic height
	// useEffect(() => {
	// 	const properties = window.getComputedStyle(productElementRef?.current);
	// 	if (productElementRef) {
	// 		if (productElementRef.current) {
	// 			productElementRef.current.style.height = properties.getPropertyValue(
	// 				'width',
	// 			);
	// 		}
	// 	}
	// }, []);

	return (
		<>
		{isLoading ? <SkeletonProductCard category={categoryName}/> : null}
		<div onMouseEnter={()=> setIsHovered(true)} onMouseLeave={()=> setIsHovered(false)} style={isLoading ? {display: 'none'}: {}} onClick={()=> setProductOverlay(true, id)} className="product-card w-100">
			{/* <div className="product-card">
				<div
					ref={productElementRef}
					className={`p-2 rounded-lg d-flex justify-content-${actionAlignment} align-items-end`}
					style={{
						width: '100%',
						background: `#eccb27 url(${imageUrl})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						height: '100%'
					}}
				>
					{Action}
				</div>
			</div> */}
			<div className="product-card__image">
			<img style={{transform: `${isHovered ? 'scale(1.05)' : 'scale(1)'}`}} src={imageUrl ? imageUrl : helpers.getProductPlaceholder(categoryName)} onLoad={()=> setIsLoading(false)} />
			</div>
			<div className="product-card__name">{name}</div>
			<div className="product-card__price">{price}</div>
			<div className="product-card__preparation">
				<ReactSVG path={preparationIcon} />
				<span>{prepareTime}</span>
			</div>
		</div>
		</>
	);
};

export default ProductCard;
