import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Loader } from '@components/atoms';
// import { ProductTile } from "@components/molecules";

import {
	// generateStoreUrl,
	generateStoreCategoryUrl,
} from '../../../../core/utils';

import * as S from './styles';
import { IProps } from './types';
import SkeletonBrandCard from '@temp/components/BrandCard/SkeletonBrandCard';
// import { MerchantTile } from "../../molecules/MerchantTile";
// import { ITEM_PREVIEW_SIZE } from "src/views/StoreCategory/constants";

export const CategoryList: React.FC<IProps> = ({
	merchantCategories,
	canLoadMore = false,
	loading = false,
	testingContextId,
	onLoadMore = () => null,
	isStorePage = false,
}: IProps) => {

	const [isLoading, setIsLoading] = useState(true);
	const [hoveredEl, setHoveredEl] = useState('');

	const onMouseOver = (e: any) => {
		setHoveredEl(e);
	}
	const onMouseLeave = () => {
		setHoveredEl('');
	}
	return (
		<>
			<S.CategoryWrapper>
				{merchantCategories?.map((merchantCategory) => {
					const { id, slug, name, backgroundImage } =
						merchantCategory || {};

					return (
						<>
							<S.Wrapper key={id}  >
								{isLoading ? <SkeletonBrandCard /> : null}
								<Link onMouseEnter={() => onMouseOver(id)} onMouseLeave={() => onMouseLeave()} style={isLoading ? { display: 'none' } : {}} to={generateStoreCategoryUrl(id, slug)}>
									<S.Photo>
										<img
											onLoad={() => setIsLoading(false)}
											src={backgroundImage?.url}
											style={{ transform: `${hoveredEl === id ? 'scale(1.05)' : 'scale(1)'}` }}
										/>
									</S.Photo>
									<S.Header>
										<h3>{name}</h3>
									</S.Header>

								</Link>
							</S.Wrapper>
						</>
					);
				})}
			</S.CategoryWrapper>
			<S.Loader>
				{loading ? (
					<Loader />
				) : (
					canLoadMore && (
						<Button
							testingContext="loadMoreProductsButton"
							color="secondary"
							onClick={onLoadMore}
						>
							<span>More +</span>
						</Button>
					)
				)}
			</S.Loader>
		</>
	);
};
