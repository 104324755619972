import { Button } from "@temp/@next/components/atoms";
import React from "react";
import { useHistory } from "react-router";
import "../scss/index.scss";
import { useLayout } from "@temp/layout/hooks/useLayout";

const CheckoutAddressModal = ({
  invalidMerchantData,
  setShowInvalidAddressModal
}: any) => {
  const { removeCartItem } = useLayout();
  const history = useHistory();
  return (
    <div>
      <div className="checkoutAddressModal-overlay" />
      <nav className="border-bottom checkoutAddressModal">
        <div className="main-container">
          <div className="checkoutAddressModal__autocomplete">
            <div className="checkoutAddressModal__autocomplete-wrapper">
              <div className="checkoutAddressModal__autocomplete-label">
                <h5>Your address is too far from {invalidMerchantData[0]?.variant?.product?.merchant?.storeTitle}</h5>
                <Button
                  onClick={() => {
                    if (removeCartItem) {
                      removeCartItem(invalidMerchantData[0]?.variant?.id)
                      setShowInvalidAddressModal(false);
                    }
                  }
                  }
                  className="checkoutAddressModal__autocomplete-button__primary mt-5 mb-2"
                  testingContext="addressAutocompleteButton">Remove items from Cart</Button>
                <Button
                  onClick={() => setShowInvalidAddressModal(false)}
                  className="checkoutAddressModal__autocomplete-button__primary my-2"
                  testingContext="addressAutocompleteButton">Update Address</Button>
                <Button
                  onClick={() => history.push('/')}
                  className="checkoutAddressModal__autocomplete-button my-2"
                  testingContext="addressAutocompleteButton">See Other Stores</Button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export { CheckoutAddressModal };
