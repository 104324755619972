import gql from "graphql-tag";
import { TypedQuery } from "src/core/queries";
import {
    MerchantBrand,
    MerchantBrandVariables
} from "./gqlTypes/MerchantBrand";

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

const basicMerchantBrandFragment = gql`
    fragment BasicMerchantBrandViewFields on MerchantBrand {
        id
        name
        description 
        status 
        slug 
        logo {
          url
        }
        banner {
          url
        }
        merchantBrandMerchantCategoryAssignment (first: 5) {
            edges {
                node {
                    merchantCategory {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const basicMerchantFragment = gql`
    fragment BasicMerchantViewFragment on Merchant {
        id
        name
        description
        storeTitle
        storeHourStart
        storeHourEnd
        leadTime
        operatingDays
        slug
        primaryAddress {
          id
          streetAddress1
          latitude
          longitude
        }
    }
`;

const singleBrandCollectionQuery = gql`
    ${basicMerchantBrandFragment}
    ${basicMerchantFragment}
    query GetBrandMerchantBrandView (
        $slug: String
        $after: String
        $pageSize: Int
    ) {
        merchantBrand(slug: $slug) {
            ...BasicMerchantBrandViewFields
            merchants(first: $pageSize, after: $after) {
                edges {
                    node {
                        ...BasicMerchantViewFragment
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                }
                totalCount
            }
        }
    }
`;
export const TypedMerchantBrandQuery = TypedQuery<
    MerchantBrand,
    MerchantBrandVariables
>(singleBrandCollectionQuery);


