import React from "react";

import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";
import moment from "moment";

/**
 * Review order view showed in checkout.
 */
const CheckoutReview: React.FC<IProps> = ({
  shippingAddress,
  billingAddress,
  // shippingMethodName,
  // paymentMethodName,
  deliverQuotations,
  email,
  errors,
  paymentTransactionType,
  note
}: IProps) => {
  return (
    <S.Wrapper data-test="sectionTitle">
      <S.Title data-test="checkoutPageSubtitle">Review Order</S.Title>
      <S.Grid>
        <section data-test="shippingAddressSection">
          <S.SubTitle>Delivery Address</S.SubTitle>
          <S.Divider />
          <AddressSummary address={shippingAddress} email={email} />
        {/* <section data-test="billingAddressSection">
          <S.SubTitle>Billing Address</S.SubTitle>
          <S.Divider />
          <AddressSummary address={billingAddress} email={email} />
        </section> */}
          <S.SubTitle>Delivery Schedule</S.SubTitle>
          <S.Divider />
          <S.TextSummary data-test="shippingMethodName">
            {deliverQuotations && deliverQuotations.map((quotation, id) => {
              const selectedSchedule = moment(quotation.deliverySchedule ? quotation.deliverySchedule : new Date());
              const scheduleText = `${selectedSchedule.format('MMM DD (ddd) h:mma')} - ${selectedSchedule.add(1, 'hour').format('h:mma')}`;
              return (
                <S.ShippingTextContainer>
                  {`${id + 1}. ${quotation?.merchant ? quotation?.merchant.name : ""}: `}
                  <S.ShippingTextValue>
                    {scheduleText}
                  </S.ShippingTextValue>
                </S.ShippingTextContainer>
              );
            })}
          </S.TextSummary>
        </section>
        <section>
          <S.SubTitle>Payment Method</S.SubTitle>
          <S.Divider />
          <S.TextSummary data-test="paymentMethodName">
            {paymentTransactionType?.display}
          </S.TextSummary>

        {note && note.trim() !== ''
        && 
        <section>
          <S.SubTitle>Additional Notes</S.SubTitle>
          <S.Divider />
          <S.TextSummary data-test="deliveryRemarks">
            {note}
          </S.TextSummary>
        </section>}
        </section>
      </S.Grid>
      <S.ErrorMessages>
        <ErrorMessage errors={errors} />
      </S.ErrorMessages>
    </S.Wrapper>
  );
};

export { CheckoutReview };
