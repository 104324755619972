import React from "react";

import * as S from "./styles";

/**
 * Cart header to use with conjunction of cart rows.
 */
const CartHeader: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Products>Product</S.Products>
      <S.Price>Unit Price</S.Price>
      <S.Quantity>Quantity</S.Quantity>
      <S.Total>Total Price</S.Total>
    </S.Wrapper>
  );
};

export { CartHeader };
