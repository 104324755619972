import React from "react";
import { Link } from "react-router-dom";

import { Button, Loader } from "@components/atoms";
// import { ProductTile } from "@components/molecules";
// import { Thumbnail } from "@components/molecules";
import BrandCard from "@temp/components/BrandCard";
import merchantImgPlaceholder from 'src/images/merchant-placeholder-img.jpg';
import {
  // generateMerchantProductUrl, 
  // generateStoreUrl 
  generateBrandUrl
} from "../../../../core/utils";
// import { ITEM_PREVIEW_SIZE } from "src/views/StoreCategory/constants";

import * as S from "./styles";
import { IProps } from "./types";
// import { MerchantProductTile } from "../../molecules/MerchantProductTile";

export const BrandList: React.FC<IProps> = ({
  merchantBrandCategoryAssignments,
  canLoadMore = false,
  loading = false,
  testingContextId,
  onLoadMore = () => null,
  isStorePage = false,
}: IProps) => {
  return (
    <>
      <S.CategoryWrapper>
        {merchantBrandCategoryAssignments && merchantBrandCategoryAssignments?.map(merchantBrandCategory => {
          const { merchantBrand } = merchantBrandCategory || {};

          // const hasBanner = merchant?.banner !== null;

          // const products = merchant?.products.edges.map(edge => edge.node) || [];
          return <>
            {/* {hasBanner && */}
            <S.Wrapper>
              {/* const products = merchant?.products.edges.map(edge => edge.node) || [];
          const showViewAll = products.length > ITEM_PREVIEW_SIZE;

          return <>
            <S.CategoryWrapper>
              <h3 >{merchant?.storeTitle}</h3>
              {showViewAll &&  */}
              <Link
                key={merchantBrand?.id}
                to={generateBrandUrl(merchantBrand?.slug || "")}
              >
                <BrandCard
                  key={merchantBrand?.id}
                  brandName={merchantBrand?.name}
                  categories={[]}
                  backgroundUrl={merchantBrand?.banner?.url || merchantBrand?.logo?.url || merchantImgPlaceholder}
                  slug={merchantBrand?.slug}
                />
                {/* 
                <MerchantProductTile merchant={merchant}/>
                <S.Image>
                  <img src={merchant?.banner?.url}></img>
                </S.Image>
                <S.Title>
                  <h3>{merchant?.storeTitle || merchant?.name}</h3>
                </S.Title>
                <S.Place>
                <SubDetails
                  items={[
                    {
                      iconName: 'fa fa-map-marker',
                      text: city,
                      toolTip: "Store Location",
                    },
                  ]}
                />
                </S.Place> */}
              </Link>
            </S.Wrapper>
            {/* } */}
            {/* <hr></hr>
                <p style={{cursor: 'pointer'}}>View All</p>
              </Link>}
            </S.CategoryWrapper>
            <hr></hr>
            <S.List data-test="productList" data-test-id={testingContextId}>
              <Link
                key={merchant?.id}
                to={generateStoreUrl(merchant?.slug || "")}
              >
                <S.ImageWrapper>
                  <img src={merchant?.logo?.url || ""}></img>   
                </S.ImageWrapper>
              </Link>
              {products.slice(0,3).map(product => (
                <>
                  <Link style={{textDecoration: 'none'}}
                    to={generateMerchantProductUrl(product?.id, product?.slug)}
                    key={product?.id}
                  >
                    <MerchantProductTile product={product}/>
                  </Link>
                </>
              ))}
            </S.List> */}
          </>
        })
        }
      </S.CategoryWrapper>

      {/* {products.map(product => (
          <Link style={{textDecoration: 'none'}}
            to={generateUrl(product.id, product.name)}
            key={product.id}
          >
          <ProductTile product={product} hideStoreTitle={isStorePage} />
            <MerchantTile product={product} hideStoreTitle={isStorePage} />
          </Link>
        ))} */}
      <S.Loader>
        {loading ? (
          <Loader />
        ) : (
          canLoadMore && (
            <Button
              testingContext="loadMoreProductsButton"
              color="secondary"
              onClick={onLoadMore}
            >
              <span>More +</span>
            </Button>
          )
        )}
      </S.Loader>
    </>
  );
};
