import * as React from "react";

import { CachedImage } from "@components/molecules";

import { Carousel } from "../../components";
import { ProductDetails_product_images } from "./gqlTypes/ProductDetails";
import noPhotoImg from "../../images/no-photo.svg";
import { useHistory } from "react-router";

const MobileGalleryCarousel: React.FC<{
  images: ProductDetails_product_images[];
}> = ({ images }) => {

  const generateCloseButton = () => {
  const close = useHistory();
  
    return (
    <div onClick={close.goBack} className="mobile-product-page__product__gallery__close">
      <i className="fas fa-times"></i>  
    </div>
  )
}

  return (
  <div className="mobile-product-page__product__gallery">
    {generateCloseButton()}
    <Carousel
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      renderBottomCenterControls={props => {
        const indexes = [];

        for (let i = 0; i < props.slideCount; i++) {
          indexes.push(i);
        }

        return (
          <ul className="mobile-product-page__product__gallery__nav">
            {indexes.map(index => (
              <li
                key={index}
                onClick={props.goToSlide.bind(null, index)}
                className={props.currentSlide === index ? "active" : ""}
              >
                <span />
              </li>
            ))}
          </ul>
        );
      }}
    >
      {images.map(image => (
        <CachedImage url={image.url || noPhotoImg} key={image.id}>
          <img src={noPhotoImg} />
        </CachedImage>
      ))}
    </Carousel>
  </div>
);
}

export default MobileGalleryCarousel;
