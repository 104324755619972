import { Skeleton } from '@material-ui/lab';
import React, {FC} from 'react';
import * as helpers from "src/helpers";
import './index.scss';
// import ProductPlaceholder from '../../images/merchant-placeholder-avatar.jpg';
interface BrandItem {
	brandName: string;
	category: string;
	backgroundUrl: string;
	slug: string;
}

const SkeletonProductCard: FC<Partial<BrandItem>> = ({
	brandName = '[brandName]',
	category = '[category]',
	backgroundUrl = '[backgroundUrl]',
	slug,
}) => {
	// const [redirect, setRedirect] = useState(false);
	// if (redirect) {
	// 	return <Redirect push to={`${selectBranch}/${id}`} />;
	// }
	return (
		<div className="product-card-skeleton">
		<img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={helpers.getProductPlaceholder(category)} />
		{/* <Skeleton animation='wave' style={{borderRadius: 5}} variant="rect" width={'100%'} height={'80%'} /> */}
		<Skeleton animation='wave' width={'50%'} height={'10%'} variant="text" />
		<Skeleton animation='wave' width={'40%'} height={'10%'} variant="text" />
		<div style={{margin: '2rem 0'}}/>
		</div>
	);
};

export default SkeletonProductCard;
