import React from "react";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { Link } from "react-router-dom";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";
import * as S from "./styles";
import { IProps, IOrders } from "./types";
import { generateGuestOrderDetailsUrl } from "@utils/core";
import bannerSrc from "images/shoppingCart_light.jpg";
import bannerSrcMobile from "images/shoppingCart_light_mobile.png";

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  // orderNumber,

  continueShopping,
  orders
}: IProps) => {
  const generateOrderReferences = (orders: IOrders[] | null) => {


    return (
      <S.ListContainer>
      {orders && orders.length && orders.map(order => (
        <S.ListItem key={order.id}>●{' '}
          <Link to={generateGuestOrderDetailsUrl(order.token)}>{order.number}</Link>
        </S.ListItem>
      ))}
      </S.ListContainer>
    )
  }
  
  const title = 
  <div className="container">
  <div className="article-page__thankYouPage" data-test="cartPageTitle">THANK YOU<br/> FOR YOUR ORDER!</div>
  </div>
  const banner = 
  <div className="article-page">
        <div
          className="article-page__header"
          style={ bannerSrc && {
                  backgroundImage: `url(${bannerSrc})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  height: "300px",
                }            
          }
        >
          {title}
          {/* {!bannerSrc && (
            <span className="article-page__header__title">
              {!headerImage && <h1>{"No Image Uploaded"}</h1>}
            </span>
          )} */}
        </div>
    </div>

const bannerMobile = 
<div className="article-page">
      <div
        className="article-page__header"
        style={ bannerSrcMobile && {
                backgroundImage: `url(${bannerSrcMobile})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: "300px",
              }            
        }
      >
        {title}
        {/* {!bannerSrc && (
          <span className="article-page__header__title">
            {!headerImage && <h1>{"No Image Uploaded"}</h1>}
          </span>
        )} */}
      </div>
  </div>
  return (
    <>
    <Media maxWidth={smallScreen}>
    {bannerMobile}
    </Media>
    <Media minWidth={541}>
    {banner}
    </Media>
    <Container data-test="thankYouView">
      <S.Wrapper>
        {/* <S.ThankYouHeader>
        THANK YOU
        <br />
        FOR YOUR ORDER!
        </S.ThankYouHeader> */}

        {/* <S.Paragraph>
          Your order reference is <span>{orderNumber}</span>
        </S.Paragraph> */}
        {/* <S.Paragraph>
          We’ve emailed you an order confirmation, and we’ll notify you the when
          order has been shipped.
        </S.Paragraph> */}

        <S.Paragraph>
          Below are your generated order reference number(s):
        </S.Paragraph>
        {generateOrderReferences(orders)}
        <S.Notes>
        You may click the order reference number(s) above to view more details 
        </S.Notes>

        <S.Buttons>
          <Button
            testingContext="continueShoppingButton"
            onClick={continueShopping}
            color="primary"
            fullWidth={true}
          >
            Continue Shopping
          </Button>
          {/* <Button
            testingContext="gotoOrderDetailsButton"
            onClick={orderDetails}
            fullWidth={true}
          >
            ORDER DETAILS
          </Button> */}
        </S.Buttons>
      </S.Wrapper>
    </Container>
    </>
  );
};

export { ThankYou };
