
import "./scss/index.scss";
import * as React from "react";

const NoNearbyStores: React.FC<{}> = () => {


  return (
    <div className="no-nearby-stores">
      <h1>No stores nearby</h1>
      <div className="no-nearby-stores__container">
        <h4>Sorry, we're not in your area yet.</h4>
        <h4>Try a different location!</h4>
      </div>
      <div className="no-nearby-stores__container">
        <span>Get delivery in:</span>
        <h5 style={{ fontFamily: 'RockoBold' }}>Manila | Bulacan | Cavite | Baguio | Laguna | Cebu</h5>
      </div>
    </div>
  );
};

export default NoNearbyStores;
