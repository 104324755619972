import * as React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

// import { TaxedMoney } from "@components/containers";
import {
  OrderDetail,
  // OrderDetail_lines,
} from "@saleor/sdk/lib/fragments/gqlTypes/OrderDetail";

import {
  AddressSummary,
  // CartTable,
  NotFound,
} from "../../../components";
// import { ILine } from "../../../components/CartTable/ProductRow";

import { orderHistoryUrl } from "../../../app/routes";
import * as S from "./styles";

import { Button } from "@components/atoms";
import { PaymentChargeStatusEnum, OrderStatus, useUserDetails } from "@saleor/sdk";
import { formatStoreOperatingDays } from "src/core/checkoutScheduleUtils";

import Header from './Header';
import OrderTimeline from './OrderTimeline';
import OrderDeliveryDetails from './OrderDeliveryDetails';
import OrderPurchaseDetails from './OrderPurchaseDetails';
import OrderSummary from './OrderSummary';
import PaymentMethod from './PaymentMethod';
import MyInfo from './MyInfo';

// const extractOrderLines = (lines: OrderDetail_lines[]): ILine[] => {
//   return lines
//     .map((line) => ({
//       quantity: line.quantity,
//       totalPrice: {
//         ...line.unitPrice,
//         currency: line.unitPrice.currency,
//         gross: {
//           amount: line.quantity * line.unitPrice.gross.amount,
//           ...line.unitPrice.gross,
//         },
//         net: {
//           amount: line.quantity * line.unitPrice.net.amount,
//           ...line.unitPrice.net,
//         },
//       },
//       ...line.variant,
//       name: line.productName,
//     }))
//     .sort((a, b) => b.id.toLowerCase().localeCompare(a.id.toLowerCase()));
// };

const formatOrderDeliverySchedule = (dateString: string, hasHourRange = false, hasYear = true) => {
  const momentDateTime = moment(dateString);
  const defaultFormat = `MMM DD${hasYear ? ', YYYY' : ''} (ddd) hh:mm`;
  if (hasHourRange) {
    const dateTime = momentDateTime.format(defaultFormat);
    const toTime = momentDateTime.add(1, 'hours').format('hh:mm a'); 
    return `${dateTime} - ${toTime}`
  } else {
    return `${momentDateTime.format(`${defaultFormat} a`)}`
  }
}

const Page: React.FC<{
  guest: boolean;
  order: Partial<OrderDetail> & any;
  orderCreateCheckout: () => void;
  loading?: boolean;
}> = ({ guest, order, orderCreateCheckout, loading }) => {
  const { merchant, paymentStatus: paymentStatusDisplay, status, payment, deliveryQuotation, deliveryOrder } = order || {};
  const { paymentTransactionType } = payment || {};
  const { warehouses, operatingDays, storeHourEnd, storeHourStart } = merchant || {};
  let { deliverySchedule: requestedDeliverySchedule } = deliveryQuotation || {};
  let { deliverySchedule: finalDeliverySchedule } = deliveryOrder || {};
  const storeInfo: any = merchant?.warehouses?.edges?.[0]?.node?.address || {};
  const storeInfoEmail: string = merchant?.warehouses?.edges?.[0]?.node?.email || "";
  const merchantImage = "../../../images/favicon.png";
  const merchantName = merchant?.storeTitle || merchant?.name;
  const merchantLogo = merchant?.logo ? merchant?.logo?.url : merchantImage;

  const address =
      warehouses &&
      warehouses.edges &&
      warehouses.edges.length > 0 &&
      warehouses.edges[0].node &&
      warehouses.edges[0].node.address
        ? warehouses.edges[0].node.address
        : null;

  const merchantAddress = address?.streetAddress1;
  const merchantCity = address?.city;
  const storeOpen = storeHourStart && moment(storeHourStart, "HH:mm:ss").format("h:mma");
  const storeClose = storeHourEnd && moment(storeHourEnd, "HH:mm:ss").format("h:mma");
  const merchantSchedule = `${formatStoreOperatingDays(operatingDays)} ${' | '} ${storeOpen} ${storeClose} ` ;

  let paymentStatus = paymentStatusDisplay || "";
  if (paymentStatus === PaymentChargeStatusEnum.NOT_CHARGED) {
    paymentStatus = "Not Paid";
  } else if (paymentStatus === PaymentChargeStatusEnum.FULLY_CHARGED) {
    paymentStatus = "Paid";
  }
  else if (paymentStatus === PaymentChargeStatusEnum.FULLY_REFUNDED) {
    paymentStatus = "Fully Refunded"
  }
  else if (paymentStatus === 'FOR_REFUND') {
    paymentStatus = "For Refund"
}

  let statusDisplay = "";
  if (status === OrderStatus.UNFULFILLED) {
    statusDisplay = "Pending Delivery Confirmation";
  } else if (status === OrderStatus.FULFILLED) {
    statusDisplay = "Confirmed Delivery"
  } else if (status === OrderStatus.CANCELED) {
    statusDisplay = "Order Cancelled";
  }
  
  const user = useUserDetails();
  const { data } = user;

  requestedDeliverySchedule = requestedDeliverySchedule ? formatOrderDeliverySchedule(requestedDeliverySchedule, true, false) : "";
  finalDeliverySchedule = (finalDeliverySchedule && status === OrderStatus.FULFILLED) ? formatOrderDeliverySchedule(finalDeliverySchedule, false, false) : "Pending";

  return (
    <div className="order">
      {order ? (
        <>
          {!guest && (
            <Link className="order-details__link" to={orderHistoryUrl}>
              <i className="fas fa-chevron-left mr-2"></i>BACK
            </Link>
          )}
          {/* Header
            Prop(s) to pass:
            1. Date and time when the order has been placed.
          */}
          <Header paymentStatus={paymentStatusDisplay === PaymentChargeStatusEnum.FULLY_REFUNDED || paymentStatusDisplay === PaymentChargeStatusEnum.PARTIALLY_REFUNDED || paymentStatusDisplay === 'FOR_REFUND' ? paymentStatus : null} statusDisplay={statusDisplay} customerStatusHistory={order?.customerStatusHistory}/>
         
          <div className="order-details__grid">
            <OrderTimeline 
              merchantName={merchantName} 
              orderReference={order?.orderReference} 
              customerStatusHistory={order?.customerStatusHistory || []}
            />
            
            <OrderDeliveryDetails 
              requestedDeliverySchedule={requestedDeliverySchedule} 
              finalDeliverySchedule={finalDeliverySchedule}
              deliveryReference={order?.orderReference} 
              vehicleType={order?.deliveryQuotation?.serviceType}
              driverContactNo={deliveryOrder?.mobileNumber}
              driverName={deliveryOrder?.driverName}
            />

            <OrderPurchaseDetails
              lines={order.lines}
              subTotal={order.subtotal}
              total={order.total}
              deliveryQuotation={order?.deliveryQuotation}
              note={order?.customerNote}
            />

            <div className="order-details__wrapper">
              <OrderSummary 
                lines={order.lines}
                subTotal={order.subtotal}
                total={order.total}
                deliveryQuotation={order?.deliveryQuotation}
              />
              <PaymentMethod paymentTransactionType={paymentTransactionType} />
              {data && <MyInfo data={data}/>}
            </div>

          </div>
          <div className="order-details__summary">
            <div className="order-details__summary__storeInfo">
            <div className="order-details__summary__storeInfo__text">
              <h3>Delivery Address</h3>
              </div>
              <AddressSummary
                address={order.shippingAddress}
                email={order.userEmail}
                paragraphRef={this.shippingAddressRef}
              />
            </div>
            <div className="order-details__summary__storeInfo">
              <div className="order-details__summary__storeInfo__text">
                <h3>Store Info</h3>
              </div>
                <AddressSummary
                  isStore={true}
                  merchantName={merchantName}
                  merchantImage={merchantLogo}
                  merchantAddress={merchantAddress}
                  merchantCity={merchantCity}
                  merchantSchedule={merchantSchedule}
                  address={storeInfo}
                  email={storeInfoEmail}
                  paragraphRef={this.shippingAddressRef}
                />
            </div>
            <div className="order-details__summary__storeInfo">
            <div className="order-details__summary__storeInfo__text">
              <h3>Reorder</h3>
              </div>
              <div className="order-details__summary__storeInfo__reorder">
              <p>Want to order these items again? <br/>Just click the <span>REORDER</span> button below.</p>
              <S.ButtonWrapper>
                <Button 
                  testingContext="reorderButton"
                  onClick={orderCreateCheckout}
                  disabled={loading}
                >
                  Reorder
                </Button>
              </S.ButtonWrapper>
              </div>
            </div>
            
            {/* <div>
              <h4>Billing Address</h4>
              <AddressSummary
                address={order.shippingAddress}
                email={order.userEmail}
                paragraphRef={this.shippingAddressRef}
              />
            </div> */}
            {/* <div>
              <h4>Delivery Pickup Date(s)</h4>
              <p>{`${merchantName} | ${deliverySchedule}`}</p>
              <S.DeliveryButton isFinal={isFinal}>
                <button>{orderStatus}</button>
              </S.DeliveryButton>
            </div>
            <div>
              <h4>Payment Method</h4>
                <p>{paymentTransactionType?.display}</p>
            </div> */}
          </div>
          {/* <S.Note>
          <p>Note: Delivery Pickup Date and Time is subject to change at the discretion of the store. <br/>You will be
              informed of the Final Delivery Pickup Date and Time via Text and Email. </p>
          </S.Note>
          <hr></hr> */}
          
        </>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default Page;
