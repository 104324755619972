import {styled} from '@styles';
import {TitleSize} from './types';

export const Header = styled.div<{divider: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => `1.235rem ${props.theme.spacing.gutter}`};
  ${({divider, theme}) =>
		divider && `border-bottom: 1px solid ${theme.colors.light};`}
  background-color: ${(props) => props.theme.colors.primary};
`;

export const Title = styled.h4<{size: TitleSize}>`
	font-size: ${({size, theme: {typography}}) =>
		size === 'lg' ? typography.h4FontSize : typography.baseFontSize};
	color: ${(props) => props.theme.colors.white};
	padding-right: 0.6rem;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	margin: 0;
	display: inline-block;
	white-space: nowrap;
`;

export const Paragraph = styled.p`
	font-size: ${(props) => props.theme.typography.smallFontSize};
	color: ${(props) => props.theme.colors.lightFont};
	padding-right: 0.6rem;
	margin: 0;
`;
