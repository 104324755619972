
import * as React from "react";

import { Button } from "../..";
import empty_cart_art from "images/empty_cart.jpg";

const Empty: React.FC<{ overlayHide(): void }> = ({ overlayHide }) => (
  <div className="cart__empty">
    <img className="cart__empty__logo"src={empty_cart_art} alt="empty"/>
    <h4>Your cart is empty</h4>
    <p>
      Start shopping and adding items to your cart! 
    </p>
    <div className="cart__empty__action">
      <Button
        testingContext="emptyCartHideOverlayButton"
        onClick={overlayHide}
      >
        Start Shopping
      </Button>
    </div>
  </div>
);

export default Empty;

