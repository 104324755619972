import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import * as React from "react";
import Media from "react-media";

import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, ILine } from "./ProductRow";

interface TableProps extends EditableProductRowProps {
  lines: ILine[];
  subtotal: React.ReactNode;
  deliveryCost?: React.ReactNode;
  totalCost?: React.ReactNode;
  discount?: React.ReactNode;
  discountName?: string;
  deliveryDistance?: number | null;
  isForViewing?: boolean;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  totalCost,
  discount,
  discountName,
  lines,
  deliveryDistance,
  isForViewing,
  ...rowProps
}) => (
  <Media query={{ minWidth: smallScreen }}>
    {(mediumScreen) => (
      <table className="cart-table py-0 my-0">
        <thead>
          <tr>
            <th>Items</th>
            {mediumScreen && <th>Price</th>}
            <th>Variant</th>
            <th className="cart-table__quantity-header">Quantity</th>
            <th colSpan={2}>{mediumScreen ? "Total Price" : "Price"}</th>
          </tr>
        </thead>
        <tbody>
          {lines.map((line) => (
            <ProductRow
              key={line.id}
              line={line}
              mediumScreen={mediumScreen}
              isForViewing={isForViewing}
              {...rowProps}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              className="pt-0 pb-3"
              colSpan={mediumScreen ? 6 : 5}
            ></td>
          </tr>
          <CostRow
            mediumScreen={mediumScreen}
            heading="Subtotal"
            cost={subtotal}
          />
          {discount && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={`Discount: ${discountName}`}
              cost={discount}
            />
          )}
          {deliveryCost && (
            <CostRow
              className="pb-3"
              mediumScreen={mediumScreen}
              heading={`Delivery Fee ${deliveryDistance ? `(${deliveryDistance.toFixed(2)} km)` : ""}`}
              cost={deliveryCost}
            />
          )}
          {totalCost && (
            <CostRow
              className="pt-3 border-top cart-table__total"
              mediumScreen={mediumScreen}
              heading="Total Payment"
              cost={totalCost}
            />
          )}
        </tfoot>
      </table>
    )}
  </Media>
);

export default Table;
