import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";
import { IProps, IStep } from "./types";

const activeDot = (stepIndex: number) => (
  <S.ActiveDot>
    <S.Dot done={true}>{stepIndex + 1}</S.Dot>
  </S.ActiveDot>
);

const doneDot = (stepIndex: number) => <><S.Dot done={true}>{stepIndex + 1}</S.Dot></>;

const inactiveDot = (stepIndex: number) => <S.Dot>{stepIndex + 1}</S.Dot>;

const generateDot = (stepIndex: number, currentActiveStep: number) => {
  if (stepIndex < currentActiveStep) {
    return doneDot(stepIndex);
  }
  if (stepIndex === currentActiveStep) {
    return activeDot(stepIndex);
  }
  if (stepIndex > currentActiveStep) {
    return inactiveDot(stepIndex);
  }
};

const generateLabel = (
  stepIndex: number,
  name: string,
  numberOfSteps: number
) => {
  if (stepIndex === 0) {
    return <S.LeftLabel>{name}</S.LeftLabel>;
  }
  if (stepIndex === numberOfSteps - 1) {
    return <S.RightLabel>{name}</S.RightLabel>;
  }
  return <S.Label>{name}</S.Label>;
};

const generateProgressBar = (
  index: number,
  currentActive: number,
  numberOfSteps: number
) => {
  if (index !== numberOfSteps - 1) {
    return <S.ProgressBar done={currentActive > index} />;
  }
};

const generateSteps = (steps: IStep[], currentActive: number, isDisabled?: boolean) => {
  return steps?.map(step => {
    return (
      <S.Step key={step.index}>
        <Link to={isDisabled ? '/checkout/address' : step.link}>
          {generateDot(step.index, currentActive)}
          {generateLabel(step.index, step.name, steps.length)}
        </Link>
        {generateProgressBar(step.index, currentActive, steps.length)}
      </S.Step>
    );
  });
};

/**
 * Progress bar showing current step of checkout process.
 */
const CheckoutProgressBar: React.FC<IProps> = ({
  steps,
  activeStep,
  isDisabled,
}: IProps) => {
  return <S.Wrapper>{generateSteps(steps, activeStep, isDisabled)}</S.Wrapper>;
};

export { CheckoutProgressBar };
