import React from 'react';
import {ShoppingBag} from 'react-feather';

const EmptyScreen = () => {
	return (
		<div
			style={{opacity: 0.75}}
			className="d-flex flex-column align-items-center py-3"
		>
			<ShoppingBag size={72} strokeWidth={1} />
			<div className="mt-3">Cannot find store product</div>
		</div>
	);
};

export default EmptyScreen;
