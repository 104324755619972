import React, { FC } from 'react';
import './index.scss';
import BranchAvatarPlaceholder from '../../../../images/merchant-placeholder-avatar.png';
import { Breadcrumb, Breadcrumbs } from '@temp/components';
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
import ReactSVG from 'react-svg';
import preparationIcon from '../../../../images/preparation-icon.svg';
import { formatStoreOperatingDays } from '@temp/core/checkoutScheduleUtils';
import { getBrowserType } from '@temp/helpers';
interface BranchInfoProps {
	name?: string;
	imageUrl?: string;
	categories?: string[];
	distance?: number | null;
	time?: string;
	breadcrumbs?: Breadcrumb[];
	storeHourStart?: string;
	storeHourEnd?: string;
	operatingDays?: string;
	storeOpen?: string;
	storeClose?: string;
	streetAddress1?: string;
}

const BranchInfo: FC<BranchInfoProps> = ({
	name = '[Unknown store name]',
	imageUrl = '',
	categories = [],
	distance,
	time = '',
	breadcrumbs,
	storeHourStart,
	storeHourEnd,
	operatingDays,
	storeOpen,
	storeClose,
	streetAddress1
}) => {
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });

	return (
		<div className="main-container py-3">
			<div
				className="flex-shrink-0 branch-info"
				style={{
					position: 'absolute',
					marginTop: isTablet ? '-5.5rem' : '-6.5rem',
					width: isTablet ? '120px' : '150px',
					height: isTablet ? '120px' : '150px',
					transformOrigin: 'bottom left',
					borderRadius: 1000,
					background: `url(${imageUrl ? imageUrl : BranchAvatarPlaceholder}) #fff`,
					backgroundSize: 'cover',
					backgroundPositionY: 'center',
				}}
			/>
			<div style={{ marginTop: `${getBrowserType() === 'Firefox' ? '4.5rem' : '2.5rem'}` }} className="flex-grow-1">
				{!isTablet && <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>}
				<h4 className="branch-info__branchName">{name}</h4>
				<p className="branch-info__categories mt-2">{categories.join(', ')}</p>
				<div className="mt-2 d-flex align-items-center">
					{!!distance &&
						<div className="branch-info__details">
							<i className="fa fa-map-marker-alt mr-1"></i>
							<p>{distance ? `${distance}km` : ''}</p>
						</div>
					}
					<div className={`branch-info__details ${distance ? 'ml-3' : ''}`} >
						<ReactSVG className="mr-1 mt-n1" path={preparationIcon} />
						<p>{time || '0 mins'}</p>
					</div>
				</div>
				{storeHourStart && storeHourEnd && operatingDays ?
					<div className="branch-info__details">
						<p><i style={{ fontSize: '13px', marginLeft: '-1.5px' }} className="far fa-clock mr-1"></i>{storeHourStart && storeHourEnd && `${storeOpen} - ${storeClose}`} {operatingDays && `(${formatStoreOperatingDays(operatingDays)})`}</p>
					</div>
					: null}
				<div className="branch-info__details">
					<p><i className="fas fa-map-pin mr-1"></i>{streetAddress1}</p>
				</div>
				{distance > 100 && <div className="branch-info__far">
					<i className="fas fa-exclamation-triangle" style={{ color: '#fffc59' }}></i>
					<p>This store cannot deliver to your current location. Please try to select a different branch or adjust your pin location.</p>
				</div>}
				{/* <div className="branch-info__details">
						<p><i style={{fontSize: '13.5px', marginLeft: '-1px'}} className="fas fa-map-marker-alt mr-1"></i>{address?.streetAddress2}</p>
					</div> */}
			</div>
		</div>
	);
};

export default BranchInfo;
