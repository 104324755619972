import { media, styled } from "@styles";

export const FacebookLoginBtn = styled.button<{
  disabled?: boolean;
  isGuest?: boolean;
}>`
  width: ${(props) => (props.isGuest ? "80%" : "100%")};
  border-radius: 10px;
  color: white;
  background-color: ${(props) => (props.disabled ? "grey" : "#3b5998")};
  padding: 15px;
  font-size: 18px;
  font-family: ${(props) => props.theme.typography.baseFontFamily};
  ${media.smallScreen`
    width: ${(props) => (props.isGuest ? "90%" : "100%")};
  `}
`;

export const FacebookLoginIcon = styled.i`
  margin-right: 6px;
`;
