import {DefaultTheme, media, styled} from '@styles';

export const Wrapper = styled.div`
	margin: 80px 0;

	${media.smallScreen`
    margin: 40px 0;
  `}
`;

export const ThankYouHeader = styled.p`
	font-size: 70px;
	font-family: ${(props) => props.theme.typography.h3FontFamily};
	margin: 0;
	line-height: 110%;
	padding-bottom: 40px;
	border-bottom: 1px solid
		${(props) => props.theme.colors.baseFontColorTransparent};
	margin-bottom: 40px;

	${media.smallScreen`
    font-size: ${(props: {theme: DefaultTheme}) =>
			props.theme.typography.h1FontSize};
  `}
`;

export const Paragraph = styled.p`
	font-size: ${(props) => props.theme.typography.h4FontSize};
	margin: 0;
	line-height: 170%;

	span {
		font-weight: ${(props) => props.theme.typography.boldFontWeight};
	}
`;

export const Buttons = styled.div`
	width: 50%;
	margin-top: 40px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	button {
		padding-left: 0;
		padding-right: 0;
		border-radius: 30px !important;
		background-color: ${(props) => props.theme.colors.primary} !important;
		color: ${(props) => props.theme.colors.white} !important;
	}
	span {
		text-transform: capitalize !important;
	}

	${media.smallScreen`
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  `}
`;

export const ListContainer = styled.ul`
	margin-top: 20px;
	margin-bottom: 20px;
`;

export const ListItem = styled.li`
	margin-left: 15px;
	margin-bottom: 10px;
	a {
		color: ${(props) => props.theme.colors.baseFont};
	}
`;

export const Notes = styled.p`
	margin-top: 10px;
	font-size: ${(props) => props.theme.typography.baseFontSize};
	margin: 0;
	color: grey;
	line-height: 170%;
`;
