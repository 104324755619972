import * as React from "react";

import { MetaWrapper } from "src/components";
import HomePageView from '@screens/mobile/Home/Home';
import { TypedBrandCollectionsQuery } from "./queries";
import { RouteComponentProps } from "react-router";
import { TypedCreateOrGetProfileJoyride } from "./mutations";
import { useLayout } from "@temp/layout/hooks/useLayout";

const View: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
    const { useUserLocation } = useLayout();

    return (
        <TypedCreateOrGetProfileJoyride>
            {(createOrGetProfileJoyride) => {
                return (
                    <TypedBrandCollectionsQuery
                        variables={{
                            longitude: useUserLocation()?.longitude || 0,
                            latitude: useUserLocation()?.latitude || 0,
                            region: useUserLocation()?.region || ""
                        }}
                        loaderFull
                        errorPolicy="all">
                        {({ data, loading }) => {
                            const { brandCollections } = data || {};
                            const collections = brandCollections?.edges.map(item => item?.node);
                            return (
                                <MetaWrapper meta={{}}>
                                    <HomePageView
                                        match={match}
                                        createOrGetProfileJoyride={createOrGetProfileJoyride}
                                        brandCollections={collections} />
                                </MetaWrapper>
                            );
                        }}
                    </TypedBrandCollectionsQuery>
                );
            }}
        </TypedCreateOrGetProfileJoyride>
    )
};

export default View;