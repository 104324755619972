import { useEffect, useState } from "react";

import { IItems } from "@saleor/sdk/lib/api/Cart/types";
import { ICheckout, IPayment } from "@saleor/sdk/lib/api/Checkout/types";
import { CheckoutStep } from "@temp/core/config";

export const useCheckoutStepState = (
  items?: IItems,
  checkout?: ICheckout,
  payment?: IPayment,
  pathname?: string
): CheckoutStep => {
  const isShippingRequiredForProducts =
    items &&
    items.some(
      ({ variant }) => variant.product?.productType.isShippingRequired
    );

  const getStep = () => {
    // Temporarily added. We reset the steps if consumer redirect to checkout
    if (pathname && (pathname === "/checkout" || pathname === "/checkout/")) {
      return CheckoutStep.Address;
    }

    if (!checkout?.id && items && isShippingRequiredForProducts) {
      return CheckoutStep.Address;
    } else if (!checkout?.id && items) {
      return CheckoutStep.Payment;
    }

    const isShippingStep =
      !!checkout?.shippingAddress || !isShippingRequiredForProducts;
    // const isPaymentStep =
    //   (isShippingStep && !!checkout?.shippingMethod) ||
    //   !isShippingRequiredForProducts;
      const isPaymentStep =
      (isShippingStep && !!checkout?.deliveryQuotations && checkout?.deliveryQuotations.length > 0);

    // const isReviewStep =
    //   isPaymentStep && !!checkout?.billingAddress && !!payment?.id;
    const isReviewStep =
      isPaymentStep && !!checkout?.billingAddress;

    if (isReviewStep) {
      return CheckoutStep.Review;
    } else if (isPaymentStep) {
      return CheckoutStep.Payment;
    } else if (isShippingStep) {
      return CheckoutStep.Shipping;
    } else {
      return CheckoutStep.Address;
    }
  };

  const [step, setStep] = useState(getStep());

  useEffect(() => {
    const newStep = getStep();
    if (step !== newStep) {
      setStep(newStep);
    }
  }, [checkout, items, payment]);

  return step;
};
