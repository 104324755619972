import React from 'react';
// import {Link} from 'react-router-dom';

const dummyData = [
	// {
	// 	subtitles: [
	// 		{iconName: '', name: 'About Us'},
	// 		{iconName: '', name: 'Our Blog'},
	// 	],
	// 	title: 'Get to Know Us',
	// },
	{
		subtitles: [
			{iconName: '', name: 'Home', path: '/'},
			{iconName: '', name: 'My Account', path: '/account'},
			{iconName: '', name: 'Checkout', path: '/cart'},
		],
		title: 'Quick Links',
	},
	{
		subtitles: [
			{iconName: '', name: 'Help Center', path: '/support/faq'},
			{iconName: '', name: 'Become a Merchant', path:"https://become-merchant.jrmall.ph/"},
			{iconName: '', name: 'Bulk Orders', path: '/support/faq#bulkOrders'},
		],
		title: 'Support',
	},
	{
		subtitles: [
			{iconName: 'fab fa-facebook', name: 'Facebook', path: 'https://www.facebook.com/JRMallPH/'},
			{iconName: 'fab fa-instagram', name: 'Instagram', path: 'https://www.instagram.com/joyridephilippines/'},
		],
		title: 'Follow Us',
	},
];
const NavItem: React.FC<{}> = ({}) => {
	return (
		<div className="side-nav__menu-item--footer mt-1">
			{dummyData.map((data) => (
				<ul className="side-nav__menu-item--footer__list">
					<h4>{data.title}</h4>
					<div className="side-nav__menu-item--footer__children">
						{data.subtitles.map((subtitle) => (
							<a href={subtitle?.path}>
								{subtitle?.iconName ? (
									<i
										className={`${subtitle?.iconName} pr-3 my-1`}
										style={{fontSize: 25}}
									></i>
								) : (
									<p>{subtitle?.name}</p>
								)}
							</a>
						))}
					</div>
				</ul>
			))}
		</div>
	);
};

export default NavItem;
