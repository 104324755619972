import React from "react";
import moment from "moment";

interface HeaderProps {
  customerStatusHistory: any[];
  paymentStatus?: string;
  statusDisplay?: string;
}

const Header: React.FC<Partial<HeaderProps>> = ({
  customerStatusHistory,
  paymentStatus,
  statusDisplay
}) => {

  const getStepDetails = (date) => {
    if (date) {
      const _date = moment(date).format("MMM DD YYYY");
      const day = moment(date).format("ddd");
      // const time = moment(date).format("LT");
  
      return (
        <>
          <span>{day}, {_date}</span>
        </>
      );
    }

    return null
  }
  
  return (
    <>
      <div className="order-details__title">
        <h3>Order Details</h3>
        <p>Order placed on {getStepDetails(customerStatusHistory[0]?.date)}</p>
      </div>
      <p className="order-details__status mb-1">
        {/* {order.paymentStatusDisplay} / {order.statusDisplay} */}
        {paymentStatus &&
        <div>{paymentStatus} / {statusDisplay}</div>
        }
      </p>
    </>
  );
};

export default Header;