import {styled, media} from '@styles';

export const Wrapper = styled.div`
	overflow: auto;
	width: 410px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);
	${media.smallScreen`
  width: 310px;
  `}
`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
	padding: 1.235rem 2.5rem;
	color: #fff;
	background-color: ${(props) => props.theme.colors.secondary};
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	font-size: ${(props) => props.theme.typography.h3FontSize};
`;
