// import {branch} from '@temp/routes/paths';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ReactSVG from 'react-svg';
import './index.scss';
import preparationIcon from '../../images/preparation-icon.svg';

interface SelectBranchCard {
	distance?: number;
	onClick?: () => void;
	operatingDays?: string;
	operatingHours?: string;
	timeToPrepare?: string;
	storeName?: string;
	slug?: string;
}

const SelectBranchCard: FC<SelectBranchCard> = ({
	distance = 0,
	onClick,
	operatingDays = '[not define]',
	operatingHours = '[not define]',
	timeToPrepare = 0,
	storeName = '[storeName]',
	slug = null
}) => {
	// const [redirect, setRedirect] = useState(false);

	// const handleOnSelect = () => {
	// 	if (onSelect) {
	// 		return onSelect();
	// 	}
	// 	setRedirect(true);
	// };

	// if (redirect) {
	// 	// return <Redirect push to={`${store}/${storeName}`} />;
	// 	// Todo: Uncomment this when API endoint is already availabl
	// 	return <Redirect push to={`${branch}/1`} />;
	// }
	const history = useHistory();

	return (
		<div className="select-branch-card">
			<button
				onClick={() => history.push(`/branch/${slug}`)}
				className="select-branch-card__button"
			>
				<div className="select-branch-card__container">
					<p className="select-branch-card__branchName">{storeName}</p>
					<p className="select-branch-card__distance">{distance && distance > 0 ? `${distance}km` : ""}</p>
				</div>
				<div className="select-branch-card__container">
					<div className="mt-3 mt-md-4">
						<p className="select-branch-card__schedule">{operatingDays}</p>
						<p className="select-branch-card__schedule">{operatingHours}</p>
					</div>
					<div className="select-branch-card__preparationTime">
						<ReactSVG className="mr-1" path={preparationIcon} />
						<p>{timeToPrepare}</p>
					</div>
				</div>
			</button>
		</div>
	);
};

export default SelectBranchCard;
