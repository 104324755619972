import {media, styled} from '@styles';

export const Label = styled.label<{checked: boolean}>`
  height: 100%;
  min-height: 190px;
  display: block;
  background-color: ${(props) => (props.checked ? `#bddef4` : `#fff`)};
  padding: 30px;
  // padding: ${(props) => (props.checked ? `28px` : `30px`)};
  border-radius: ${(props) => (props.checked ? `10px` : `10px`)};
  ${(props) =>
		props.checked ? `border: 2px solid #76aad3;` : `border: 2px solid #ccc;`}
  font-size: ${(props) => props.theme.typography.smallFontSize};
  cursor: pointer;
  // * {
  //   font-family: ${(props) => props.theme.typography.inputFont} !important;
  // }
  ${media.smallScreen`
    padding: 30px 20px;
  `}
`;

export const Input = styled.input`
	display: none;
`;

export const Address = styled.div`
	* {
		color: ${(props) => props.theme.colors.baseFont} !important;
	}
`;
