import {Plus} from 'react-feather';
import SearchAddressCard from '@jrmall/SearchAddressCard';
import React, { useEffect } from 'react';
import './index.scss';
// import { editAddress } from '@temp/routes/paths';
import { useUserDetails, useDeleteUserAddresss } from "@saleor/sdk";
import { useHistory } from "react-router-dom";
import { useLayout } from '@temp/layout/hooks/useLayout';
import { AddressFormModal } from "@components/organisms";
// import { CostLine } from '@temp/@next/components/organisms/CartSummary/styles';

const SearchAddress = () => {
	const { data: user, loading } = useUserDetails();
	const { addresses } = user || {};
	const history = useHistory();
	const { setUserLocation, useUserLocation, setShowAddressbar, setShowCategorybar } = useLayout();
	const userLocation = useUserLocation();

	const handleOnClickClose = () => history.goBack();

	const [displayNewModal, setDisplayNewModal] = React.useState(false);
	const [displayEditModal, setDisplayEditModal] = React.useState(false);
	const [addressData, setAddressData] = React.useState(null);
	const [setDeleteUserAddress] = useDeleteUserAddresss();

	useEffect(()=>{
		setShowAddressbar(false);
		setShowCategorybar(false);
	},[])
	

	return (
		<div className="main-container">
			<div className="d-flex flex-column pt-3 pb-5 search-address">
				<div>
				<button onClick={handleOnClickClose}>
					<svg
						style={{width: '30px', height: '30px'}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
			</div>
			<h4 className="search-address__title">Deliver to</h4>
			<button
				// onClick={() => history.push(editAddress)}
				onClick={() => setDisplayNewModal(true)}
				className="search-address__addNewCard"
			>
				Add new address
				<Plus />
			</button>
			<h4 className="search-address__title">Saved Address</h4>
			{
				loading ? <SearchAddressCard isPreloader />
				: 
				<div className="search-address__grid my-3">
					{(addresses || []).map(address => (
						<SearchAddressCard
							selected={address?.latitude === userLocation?.latitude && address?.longitude === userLocation?.longitude}
							address1={address?.streetAddress1}
							address2={address?.streetAddress2}
							name={`${address?.firstName} ${address?.lastName}`}
							phone={address?.phone || address?.email}
							notes=" "
							onSelect={(e) => {
								setUserLocation({
									address1: address?.streetAddress1,
									address2: address?.streetAddress2,
									latitude: address?.latitude,
									longitude: address?.longitude
								});
								history.goBack();
							}}
							onEdit={(e) => {
								setAddressData({
									address,
									id: address.id,
								});
								setDisplayEditModal(true);
							}}
							onDelete={(e) => {
								setDeleteUserAddress({
									addressId: address.id,
								});
							}}
						/>	
					))}
				</div>
			}
			</div>
			{displayNewModal && (
				<AddressFormModal
					hideModal={() => {
						setDisplayNewModal(false);
					}}
					userId={user.id}
					submitBtnText={"Add"}
					title={"Add new address"}
					formId="address-form"
				/>
			)}
			{displayEditModal && (
				<AddressFormModal
					hideModal={() => {
						setDisplayEditModal(false);
					}}
					address={addressData}
					submitBtnText={"Save"}
					title={"Edit address"}
					formId="address-form"
				/>
			)}
		</div>
	);
};

export default SearchAddress;
