import React from "react";
import Media from "react-media";
import moment from "moment";
import { ThemeContext } from "styled-components";
import { TaxedMoney } from "@components/containers";

// import { Thumbnail } from "..";
// import { generateProductUrl } from "../../../../core/utils";

import * as S from "./styles";
import { IProps } from "./types";
import { DeliveryStatus, OrderStatus, StatusDisplay } from "@temp/helpers";

const header = (matches: boolean) => (
  <S.HeaderRow>
    <S.IndexNumber>Order Ref No.</S.IndexNumber>
    {matches && (
      <>
        <S.MerchantName>Store Name</S.MerchantName>
        <S.DateOfOrder>Date of Order</S.DateOfOrder>
        <S.ProductsOrdered>No of Items</S.ProductsOrdered>
        <S.Value>Amount Paid</S.Value>
      </>
    )}
    <S.Status>Status</S.Status>
  </S.HeaderRow>
);

interface IOrderLine {
  variant: {
    product: {
      merchant: {
        id: string;
        name: string;
      };
    };
  };
}
const extractMerchant = (order: IOrderLine) => {
  if (!order) {
    return "No Merchant Name";
  }
  return order?.variant?.product?.merchant?.name;
};

export const OrderTabel: React.FC<IProps> = ({ orders, history }: IProps) => {
  const theme = React.useContext(ThemeContext);

  return (
    <S.Wrapper>
      <Media
        query={{
          minWidth: theme.breakpoints.largeScreen,
        }}
      >
        {(matches: boolean) => {
          return (
            <>
              <S.Row>{header(matches)}</S.Row>
              {orders &&
                orders.map((order) => {
                  const { statusDisplay, deliveryStatus } = order?.node;

                  let statusText = '';
                  if ((statusDisplay === OrderStatus.UNFULFILLED || statusDisplay === OrderStatus.FULFILLED) && deliveryStatus !== DeliveryStatus.DELIVERED) {
                    statusText = StatusDisplay.PENDING;
                  } else if (deliveryStatus === DeliveryStatus.DELIVERED) {
                    statusText = StatusDisplay.DELIVERED
                  } else if (statusDisplay === OrderStatus.CANCELLED) {
                    statusText = StatusDisplay.CANCELLED;
                  }
                  const date = new Date(order.node.created);
                  return (
                    <>
                      <S.Row
                        data-test="orderEntry"
                        data-test-id={order?.node?.number}
                        key={order?.node?.number}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          history.push(`/order-history/${order?.node?.token}`);
                        }}
                      >
                        <S.IndexNumber>{order?.node?.orderReference}</S.IndexNumber>
                        {matches ? (
                          <>
                            <S.MerchantName>
                              {extractMerchant(order?.node?.lines[0])}
                            </S.MerchantName>
                            <S.DateOfOrder>
                              {moment(date).format("MMM D, YYYY")}
                            </S.DateOfOrder>
                            <S.ProductsOrdered>
                              {/* {order.node.lines
                              .slice(0, 5)
                              .map((product: any) => (
                                <span>{count}</span>
                                // <span
                                //   key={product.variant.product.id}
                                //   onClick={(evt) => {
                                //     evt.stopPropagation();
                                //     history.push(
                                //       generateProductUrl(
                                //         product.variant.product.id,
                                //         product.variant.product.name
                                //       )
                                //     );
                                //   }}
                                // >
                                //   <Thumbnail source={product} />
                                // </span>
                              ))} */}
                              {order?.node?.lines?.length || 0}
                            </S.ProductsOrdered>
                            <S.Value>
                              <TaxedMoney taxedMoney={order?.node?.total} />
                            </S.Value>
                          </>
                        ) : (
                          ""
                        )}
                        <S.Status>{statusText || ""}</S.Status>
                      </S.Row>
                      {/*                     
                    <S.Row
                      data-test="orderEntry"
                      data-test-id={order.node.number}
                      key={order.node.number}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        history.push(`/order-history/${order.node.token}`);
                      }}
                    >
                      <S.IndexNumber>{order.node.orderReference}</S.IndexNumber>
                      {matches ? (
                        <>
                          <S.MerchantName>
                            {extractMerchant(order.node.lines[0])}
                          </S.MerchantName>
                          <S.DateOfOrder>
                            {moment(date).format("MMM D, YYYY")}
                          </S.DateOfOrder>
                          <S.ProductsOrdered>
                           
                              {order?.node?.lines?.length || 0}
                          </S.ProductsOrdered>
                          <S.Value>
                            <TaxedMoney taxedMoney={order.node.total} />
                          </S.Value>
                        </>
                      ) : (
                        ""
                      )}
                      <S.Status>{order.node.statusDisplay}</S.Status>
                    </S.Row> */}
                    </>
                  );
                })}
            </>
          );
        }}
      </Media>
    </S.Wrapper>
  );
};
