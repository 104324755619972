import React from 'react';
// import { AutoComplete } from 'antd';
import './index.scss';
import logoImg from '../../../images/jrmall-white.svg';
import { OverlayContext } from '@temp/components';
// import { Autocomplete } from '@material-ui/lab';
// import { TextField } from '@material-ui/core';
// import LocationAutocomplete from '@temp/components/LocationAutocomplete';
import { UserLocationProps } from "src/layout/Context"
// import { useLayout } from '@temp/layout/hooks/useLayout';
import AddressAutoComplete from '@temp/components/AddressAutocomplete';
import { DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT } from "src/constants";
import { Button } from '@temp/@next/components/atoms';
import { useHistory } from 'react-router';
import { signIn } from '@temp/routes/paths';

interface AddressModalProps {
	showAddressModal?: boolean;
	setShowAddressModal?: (status: boolean) => void;
	setUserLocation: (data: UserLocationProps) => void;
	removeUserLocation: () => void;
}

const AddressModal: React.FC<AddressModalProps> = ({
	setShowAddressModal,
	setUserLocation,
	showAddressModal,
	removeUserLocation,
}) => {
	const history = useHistory();
	const [userAddressValue, setUserAddressValue] = React.useState(
		{ placeId: "", address1: "", address2: "", latitude: 0, longitude: 0 }
	)
	const [addressError, setAddressError] = React.useState(false)

	const handleSetUserLocation = () => {
		if (!!userAddressValue.address1) {
			setUserLocation(userAddressValue);
			setShowAddressModal(false);
		}
		else {
			setAddressError(true);
			// alert.error({
			// 	content: "Please enter address from suggestions.",
			// 	title: "Error",
			// }, { timeout: 2000 });
		}
	}


	return (
		<OverlayContext.Consumer>
			{(overlayContext) => (
				<div>
					<div className="addressNavigation-overlay"></div>
					<nav className="border-bottom addressNavigation">
						<div className="main-container">
							<div className="addressNavigation__autocomplete">
								<div className="addressNavigation__autocomplete-wrapper">
									<div className="addressNavigation__autocomplete-label">
										<img src={logoImg} />
										<h3 className="text-white">Food, groceries, and shops delivered to your doorstep</h3>
									</div>
									<div className="addressNavigation__autocomplete-container">
										<h4>Pin your location to find local stores nearby</h4>
										<AddressAutoComplete
											label="Search Address"
											defaultLabel="Enter Delivery Address"
											name="deliveryAddressPin"
											errors={null}
											defaultValue={userAddressValue?.address1}
											onChange={(value) => {
												setUserAddressValue({
													address1: value?.name,
													address2: null,
													latitude: value?.lat,
													longitude: value?.lon,
													placeId: value?.placeId
												});
											}}
											onLocationClicked={(latitude, longitude) => {
												setUserAddressValue({
													address1: DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT,
													address2: null,
													latitude,
													longitude,
													placeId: null
												});
											}}
											onRemoveText={() => {
												removeUserLocation();
												setUserAddressValue({ placeId: "", address1: "", address2: "", latitude: 0, longitude: 0 })
											}}
											addressError={addressError}
											setAddressError={setAddressError}
										/>
										{addressError && <p className="addressNavigation__autocomplete-container__error">Please enter address from suggestions.</p>}
										<span className="addressNavigation__autocomplete-container__login">Select from your recent addresses.
											<a onClick={() => history.push(signIn)} className="ml-1"><u>Login now.</u></a>
										</span>
										<Button onClick={handleSetUserLocation} testingContext="addressAutocompleteButton">Let's go!</Button>
									</div>
								</div>
								<div className="addressNavigation__autocomplete-region">
									<h4>Get delivery in: Manila | Bulacan | Cavite | Baguio | Laguna | Cebu</h4>
									<p>© 2021 JR Mall by JoyRide</p>
								</div>
								{/* <Autocomplete 
								size="small"
								placeholder={'Search Address'}
								options={['bruh', 'bruh']}
								renderInput={(params) => <TextField {...params} label="Enter your Address" variant="outlined"/>}
							/> */}
							</div>
						</div>
					</nav>
				</div>
			)}
		</OverlayContext.Consumer>
	);
};

export default AddressModal;
