import * as React from "react";
import { RouteComponentProps } from "react-router";
// import { useMediaQuery } from "react-responsive";
// import { mediumScreen } from "@temp/@next/globalStyles/constants";
// import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
// import { useLayout } from "@temp/layout/hooks/useLayout";
// import { AiOutlineConsoleSql } from "react-icons/ai";
import NetworkStatus from "src/components/NetworkStatus";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "src/components";
import { capitalizeFirstLetter } from "src/helpers"
import Page from "./Page";
import QueryAdapter from "./QueryAdapter";

const View: React.FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {    
    return (
        <QueryAdapter
            merchantSlug={match.params.slug}
            merchantProductPageSize={12}
            merchantProductAfter=""
            merchantCollectionsPageSize={90}
            merchantCollectionsProductPageSize={50}
        >
        {({ merchant, merchantProductLoading, merchantProductHasNextPage, merchantProductLoadMore }) => (
            <NetworkStatus>
            {isOnline => {
                const {slug, merchantBrand, storeTitle} = merchant || {};
                const breadcrumbs = [];
                if (slug) {
                    // Add store branch select hierarchy in breadcrumbs
                    if (merchantBrand) {
                        breadcrumbs.push({
                            link: `/brand/${merchantBrand?.slug}`,
                            value: 'Select Branch',
                          });
                    }
      
                    // Branch url
                    breadcrumbs.push({
                      link: `/branch/${slug}`,
                      value: capitalizeFirstLetter(storeTitle),
                    });
                }
                
                if (merchant && merchant?.status) {
                    return (
                        <MetaWrapper meta={merchant}>
                            <Page 
                                // isProductDetailsLoading={productDetailsLoading}
                                isProductLoading={merchantProductLoading}
                                productLoadMore={merchantProductLoadMore}
                                merchant={merchant}
                                // productOverlay={productOverlayState}
                                // setProductOverlay={setProductOverlay}
                                hasProductNextPage={merchantProductHasNextPage}
                                breadcrumbs={breadcrumbs}
                            />
                        </MetaWrapper>
                    );
                }

                if (merchant === null || !merchant?.status) {
                    return <NotFound />;
                }

                if (!isOnline) {
                    return <OfflinePlaceholder />;
                }
            }}
            </NetworkStatus>
        )}
        </QueryAdapter>
    );
} 

export default View;