import gql from 'graphql-tag';
import {TypedQuery} from '../../core/queries';
import {
	StoreCategory,
	StoreCategoryVariables,
	MerchantCities,
} from './gqlTypes/StoreCategory';

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

const fragementMerchantCategoryAssignment = gql`
	fragment MerchantFragment on Merchant {
		id
		name
		slug
		storeTitle
		leadTime
		logo {
			alt
			url
		}
		banner {
			alt
			url
		}
		primaryAddress {
			id
			city
			phone
		}
	}
	fragment MerchantCategoryAssignmentFragment on MerchantCategoryAssignment {
		id
		ordering
		merchant {
			...MerchantFragment
		}
	}
`;

const fragementMerchantBrandCategoryAssignment = gql`
	fragment MerchantBrandFragment on MerchantBrand {
		id
		name
		slug
		logo(size: 300) {
			alt
			url
		}
		banner(size: 350) {
			alt
			url
		}
	}
	fragment MerchantBrandCategoryAssignmentFragment on MerchantBrandCategoryAssignment {
		id
		ordering
		merchantBrand {
			...MerchantBrandFragment
		}
	}
`;

export const storeCategoryQuery = gql`
	${fragementMerchantCategoryAssignment}
	${fragementMerchantBrandCategoryAssignment}
	query getMerchantCategory(
		$id: ID
		$afterFirstLevel: String
		$afterSecondLevel: String
		$pageSize: Int
		$selectedCities: [String]
		$longitude: Float
		$latitude: Float
	) {
		merchantCategory(id: $id) {
			id
			name
			slug
			backgroundImage {
				alt
				url
			}
			parent {
				id
				name
				slug
			}
			# merchantCategoryAssignments(
			# 	first: $pageSize
			# 	after: $afterFirstLevel
			# 	cities: $selectedCities
			# ) {
			# 	edges {
			# 		node {
			# 			...MerchantCategoryAssignmentFragment
			# 		}
			# 	}
			# 	totalCount
			# 	pageInfo {
			# 		endCursor
			# 		hasNextPage
			# 		hasPreviousPage
			# 		startCursor
			# 	}
			# }
			merchantBrandCategoryAssignment(
				first: $pageSize
				after: $afterSecondLevel
				latitude: $latitude
				longitude: $longitude
			) {
				edges {
					node {
						...MerchantBrandCategoryAssignmentFragment
					}
				}
				totalCount
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
			children(first: $pageSize, after: $afterFirstLevel) {
				edges {
					node {
						id
						name
						slug
						backgroundImage(size: 350) {
							alt
							url
						}
						merchantCategoryAssignments(first: 5, cities: $selectedCities) {
							edges {
								node {
									...MerchantCategoryAssignmentFragment
								}
							}
							totalCount
						}
					}
				}
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
		}
	}
`;
export const TypedStoreCategoryQuery = TypedQuery<
	StoreCategory,
	StoreCategoryVariables
>(storeCategoryQuery);

const fragementMerchantCities = gql`
	query getMerchantCities {
		merchantCities
	}
`;
export const TypedMerchantCitiesQuery = TypedQuery<MerchantCities, null>(
	fragementMerchantCities,
);
