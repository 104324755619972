import React from "react";

import { Button, Tile } from "@components/atoms";

import { useUserDetails } from "@saleor/sdk";

import * as S from "./styles";

import { TypedGetJRPayUserBalance } from "@app/pages/CheckoutPage/queries"
import {
  TypedUserJRPayLinkingMutation,
  TypedUserJRPayUnLinkMutation
} from "@app/pages/CheckoutPage/mutations"

import { useAlert } from "react-alert";

const ALERT_SETTINGS = {
  timeout: 2000
}

export const JRPayTile: React.FC = () => {
  const { data: user } = useUserDetails();
  const alert = useAlert();

  return (
    <TypedUserJRPayUnLinkMutation>
    {(unLinkJRPayUserAccount, { loading: unLinkingLoading, data }) => (
      <TypedUserJRPayLinkingMutation>
      {(linkJRPayUserAccount, { loading: linkingLoading, data }) => (
        <TypedGetJRPayUserBalance variables={{ userId: user?.id || "" }}>
        {({ data, error, loading: balanceLoading, refetch }) => {
          const isLinked = !!data?.jrpayBalance?.accountNumber;
          const loading = balanceLoading || linkingLoading || unLinkingLoading;
          
          return (
            <S.TileWrapper>
              <Tile tileType="account">
                <S.Wrapper>
                  <S.Header>JR Pay</S.Header>
                  <S.Content>
                    <S.LinkButton>
                    <Button
                      testingContext="link"
                      size="md"
                      disabled={loading}
                      onClick={async() => {
                        if (isLinked) {
                          // @ts-ignore
                          const { data } = await unLinkJRPayUserAccount({
                            variables: {
                              userId: user?.id || "",
                            } 
                          });

                          if (data?.accountUserUnlinkJrpay?.user?.id) {
                            alert.success({
                              content: "JR Pay account successfully unlinked.",
                              title: "Success",
                            }, ALERT_SETTINGS);
                          } else {
                            alert.error({
                              content: "Problem occurs while unlinking JR Pay account.",
                              title: "Error",
                            }, ALERT_SETTINGS);
                          }
                          refetch()
                        } else {
                          // @ts-ignore
                          const { data } = await linkJRPayUserAccount({
                            variables: {
                              userId: user?.id || "",
                            } 
                          });
                          const redirectUrl = data?.accountUserGenerateJrpayLinkId?.jrpayLinks?.redirectUrl;
                          if (redirectUrl) {
                            window.location.href = `${redirectUrl}?redirect_url=${window.location.origin}/account`;
                            return;
                          }
                        }
                      }}
                    >
                      {loading ? "Loading..." : (isLinked ? 'Unlink my JR Pay': ' Link my JR Pay')}
                    </Button>
                    </S.LinkButton>
                  </S.Content>
                  
                </S.Wrapper>
              </Tile>
            </S.TileWrapper>
          );

        }}
        </TypedGetJRPayUserBalance>
      )}
      </TypedUserJRPayLinkingMutation>
    )}
    </TypedUserJRPayUnLinkMutation>
  )
};
