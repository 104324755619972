import React, {useState} from "react";


const MobileAddToCart: React.FC<{
  price?: number;
}> = ({ price }) => {

  const [addedToCart, setAddedToCart] = useState(false);

  const handleClick = () => {
    setAddedToCart(true);
    timer();
  }

  const timer = () =>
  setTimeout(() => { 
      setAddedToCart(false);
    }, 1000);

  return (
    <button onClick={handleClick} className={addedToCart ? "brand-description__disabled" : "brand-description__action"}>
      <div className="brand-description__action__wrapper">
      {addedToCart ? 
      <span>Added</span> 
      :
      <>
      <span>Add to Cart</span>
      <span>₱ {price}</span>
      </>
      }
      </div>
    </button>
  );
};

export default MobileAddToCart;
