import * as React from 'react';

import { Breadcrumb, Breadcrumbs } from '../../components';
import { MerchantCategory } from './gqlTypes/StoreCategory';

import { CategoryList } from '@temp/@next/components/organisms/CategoryList';
import { BrandList } from '@components/organisms/BrandList';
// import {StoreCategoryListHeader} from './StoreCategoryListHeader';
import { FilterSidebar } from '@components/organisms/FilterSidebar';
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@styles/constants';
// import { EmptyMerchantList } from "@temp/@next/components/organisms/EmptyMerchantList";

// import bannerOneMobile from '../../images/bannerOne-mobile.jpg';
// import bannerTwoMobile from '../../images/bannerTwo-mobile.jpg';
// import bannerThreeMobile from '../../images/bannerThree-mobile.jpg';
// import bannerThree from '../../images/bannerThree.jpg';
// import bannerTwo from '../../images/bannerTwo.jpg';
// import bannerOne from '../../images/bannerOne.jpg';
// import NearbyBrands from '@temp/components/NearbyBrands';
// import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { useHistory } from 'react-router';
import Icon from './Icon';
import { ChevronLeft } from 'react-feather';

enum MerchantCategoryLevelEnum {
	FIRST_LEVEL = 1,
	SECOND_LEVEL = 2,
}

// const BRANDS_TO_SHOW = 8;

interface PageProps {
	merchantCities?: string[] | null;
	merchantCategory: MerchantCategory | null;
	breadcrumbs: Breadcrumb[];
	displayLoader?: boolean;
	loadMoreFirstLevelCategory?: () => void;
	loadMoreSecondLevelCategory?: () => void;
	filters: any;
	attributeFilters: any;
	onFilterAttributeChange: (attributeFilters: any) => void;
}
export const Page: React.FC<PageProps> = ({
	breadcrumbs,
	merchantCategory,
	displayLoader = false,
	loadMoreFirstLevelCategory,
	loadMoreSecondLevelCategory,
	merchantCities = [],
	filters,
	attributeFilters,
	onFilterAttributeChange,
}) => {
	SwiperCore.use([Pagination, Autoplay]);

	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const history = useHistory();

	const {
		backgroundImage,
		parent,
		children,
		name,
		// merchantCategoryAssignments,
		merchantBrandCategoryAssignment
	} = merchantCategory || {};
	const bannerSource = backgroundImage?.url;
	const merchantCategoryLevel = !!parent
		? MerchantCategoryLevelEnum.SECOND_LEVEL
		: MerchantCategoryLevelEnum.FIRST_LEVEL;



	// const renderBackgroundImages = () => {
	// 	if (children){
	// 		let images = [];
	// 		let count = 0;
	// 		for (let key in children.edges){
	// 			const {backgroundImage} = children.edges[key].node;
	// 			if (backgroundImage?.url && count < 4){
	// 				images.push(<img style={{height: '450px', objectFit: 'cover'}} src={backgroundImage?.url}/>);
	// 			}
	// 			count++;
	// 		}
	// 		return images;
	// 	}
	// }
	// const storeCount =
	// 	merchantCategoryLevel === MerchantCategoryLevelEnum.SECOND_LEVEL
	// 		? merchantCategoryAssignments?.totalCount
	// 		: null;

	// const storeCount = merchantCategoryAssignments.edges.map(item => {
	//   let count = 0;
	//   if (item?.node?.merchant?.banner !== null || item?.node?.merchant?.logo !== null ){
	//     count += 1;
	//   }
	//   return count;
	// }
	// );

	// const newStoreCount = storeCount.reduce((a,b) =>a+b,0);

	const cityAttributes = [
		{
			id: 'city',
			name: 'City',
			slug: 'city',
			values:
				merchantCities?.map((city) => ({
					id: city,
					name: city,
					slug: city,
					selected: false,
				})) || [],
		},
	];

	const [showFilters, setShowFilters] = React.useState(false);

	// const getAttribute = (attributeSlug: string, valueSlug: string) => {
	// 	return {
	// 		attributeSlug,
	// 		valueName: cityAttributes
	// 			.find(({slug}) => attributeSlug === slug)
	// 			.values.find(({slug}) => valueSlug === slug).name,
	// 		valueSlug,
	// 	};
	// };

	// const activeFiltersAttributes =
	// 	filters &&
	// 	filters.attributes &&
	// 	Object.keys(filters.attributes).reduce(
	// 		(acc, key) =>
	// 			acc.concat(
	// 				filters.attributes[key].map((valueSlug) =>
	// 					getAttribute(key, valueSlug),
	// 				),
	// 			),
	// 		[],
	// 	);

	const onFiltersChange = (name, value) => {
		if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
			if (attributeFilters[name].includes(value)) {
				if (filters.attributes[`${name}`].length === 1) {
					const att = { ...attributeFilters };
					delete att[`${name}`];
					onFilterAttributeChange({
						...att,
					});
				} else {
					onFilterAttributeChange({
						...attributeFilters,
						[`${name}`]: attributeFilters[`${name}`].filter(
							(item) => item !== value,
						),
					});
				}
			} else {
				onFilterAttributeChange({
					...attributeFilters,
					[`${name}`]: [...attributeFilters[`${name}`], value],
				});
			}
		} else {
			onFilterAttributeChange({ ...attributeFilters, [`${name}`]: [value] });
		}
	};

	// const renderCategoryCarousel = () => {

	// 	const params = {
	// 		observeParents : true,
	// 		observer: true,
	// 		pagination: {
	// 		   el: '.swiper-pagination',
	// 		   clickable: true,
	// 		},
	// 		autoplay: {
	// 		  delay: 3000
	// 		}
	// 	}

	// 	return (
	// 	<>
	// 		<Media maxWidth={smallScreen}>
	// 			<div className="home-page__categories__carousel">
	// 				<Swiper {...params}>
	// 					<SwiperSlide><img src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/FILIPINO.jpg'} /></SwiperSlide>
	// 					<SwiperSlide><img src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/PARTY-TRAY.jpg'} /></SwiperSlide>
	// 					<SwiperSlide><img src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/DESSERT.jpg'} /></SwiperSlide>
	// 					<SwiperSlide><img src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/BREAD-AND-PASTRY.jpg'} /></SwiperSlide>
	// 				</Swiper>
	// 			</div>
	// 		</Media>
	// 		<Media minWidth={541}>
	// 			<div className="home-page__categories__carousel">
	// 				<Swiper {...params}>
	// 					<SwiperSlide>
	// 						<img style={{height: '450px', objectFit: 'cover'}} src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/FILIPINO.jpg'} />
	// 					</SwiperSlide>
	// 					<SwiperSlide>
	// 						<img style={{height: '450px', objectFit: 'cover'}} src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/DESSERT.jpg'} />
	// 					</SwiperSlide>
	// 					<SwiperSlide>
	// 						<img style={{height: '450px', objectFit: 'cover'}} src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/PARTY-TRAY.jpg'} />
	// 					</SwiperSlide>
	// 					<SwiperSlide>
	// 						<img style={{height: '450px', objectFit: 'cover'}} src={'https://staging-ecom-media.wemove.com.ph/merchant-category-backgrounds/BREAD-AND-PASTRY.jpg'} />
	// 					</SwiperSlide>
	// 					{/* {renderBackgroundImages()} */}
	// 				</Swiper>
	// 				<div className="swiper-pagination"></div>
	// 			</div>
	// 		</Media>
	// 		</>
	// 	);
	// }

	const renderSubCategoryBackground = () => {
		return (
			<div className="article-page">
				<div
					className="article-page__header"
					style={
						bannerSource
							? {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundImage: `url(${bannerSource})`,
								backgroundPosition: "center center",
								backgroundSize: "cover",
								height: `${isTablet ? '250px' : '450px'}`,
								textAlign: 'center',
								position: 'relative',
							}
							: null
					}
				>
					{/* {bannerSource ? (<h1 className="article-page__header__title" >{name || ""}</h1>) : null} */}
					{isTablet && <div onClick={() => history.goBack()} className="branch-select__back">
						<Icon style={{ backgroundColor: '#76aad3' }} IconComponent={ChevronLeft} className="pr-1" />
					</div>}
					{!bannerSource && (
						<span className="article-page__header__title">
							{<h1>{"No Image Uploaded"}</h1>}
						</span>
					)}
				</div>
			</div>
		);
	}

	return (
		<div style={{ minHeight: '75vh' }}>
			{merchantCategoryLevel === MerchantCategoryLevelEnum.FIRST_LEVEL ?
				// renderCategoryCarousel() 
				''
				: renderSubCategoryBackground()}
			<div className="main-container py-3">
				{!isTablet ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : <h4 style={{ fontSize: '1.25rem', color: '#152c59', lineHeight: '2rem' }}>{name || ''}</h4>}
				{/* {bannerSource ? (
					<h1 className="article-page__header__title">{name || ''}</h1>
				) : null}
				<hr></hr> */}
				<FilterSidebar
					show={showFilters}
					hide={() => setShowFilters(false)}
					onAttributeFiltersChange={onFiltersChange}
					attributes={cityAttributes}
					filters={filters}
				/>
				{/* <StoreCategoryListHeader
					activeFilters={activeFiltersAttributes?.length || 0}
					numberOfStores={storeCount}
					openFiltersMenu={() => setShowFilters(true)}
					activeFiltersAttributes={activeFiltersAttributes}
					onCloseFilterAttribute={onFiltersChange}
				/> */}
				{/* First Level component */}
				{merchantCategoryLevel === MerchantCategoryLevelEnum.FIRST_LEVEL && (
					<CategoryList
						merchantCategories={children.edges.map((edge) => edge.node)}
						canLoadMore={children?.pageInfo?.hasNextPage}
						loading={displayLoader}
						onLoadMore={loadMoreFirstLevelCategory}
					/>
				)}
				{/* NO MERCHANT FOUND IN THE SECOND LEVEL CATEGORY */}
				{/* {
				merchantCategoryLevel === MerchantCategoryLevelEnum.SECOND_LEVEL && newStoreCount === 0 && 
				<EmptyMerchantList/>
				} */}
				{/* Second Level component */}
				{merchantCategoryLevel === MerchantCategoryLevelEnum.SECOND_LEVEL && (
					<>
						<BrandList
							merchantBrandCategoryAssignments={
								merchantBrandCategoryAssignment &&
								merchantBrandCategoryAssignment.edges.map((edge) => edge.node)
							}
							canLoadMore={merchantBrandCategoryAssignment?.pageInfo?.hasNextPage}
							loading={displayLoader}
							onLoadMore={loadMoreSecondLevelCategory}
						/>
					</>
				)}
			</div>
			{/* Uncomment this to include nearby brands in the Brand Selection Page */}
			{/* {merchantCategoryLevel === MerchantCategoryLevelEnum.SECOND_LEVEL && <NearbyBrands/>} */}
		</div>
	);
};
export default Page;
