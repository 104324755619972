import React, {lazy, Suspense} from "react";
import { Preloader } from '@jrmall/Preloader'

const ScreenLayout = lazy(() => 
  import('@layout/screenLayout')
    .then(({ ScreenLayout }) => ({ default: ScreenLayout }))
)
const Layout: React.FC = ({ children }) => {
  return (
    <Suspense fallback={Preloader}>
      <ScreenLayout>
        { children }
      </ScreenLayout>
    </Suspense>
  )
};

export default Layout;
