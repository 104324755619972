
// import { CartSummary } from "@components/organisms";
// import { ITaxedMoney } from "@types";

import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Loader } from "@components/atoms";
import { CheckoutProgressBar } from "@components/molecules";
import { CartSummaryByMerchant } from "@components/organisms";
import { Checkout } from "@components/templates";
import { useCart, useCheckout } from "@saleor/sdk";
import { IItems } from "@saleor/sdk/lib/api/Cart/types";
import { IDeliveryQuotations } from "@saleor/sdk/lib/api/Checkout/types";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { CheckoutRouter } from "./CheckoutRouter";
import {
  CheckoutAddressSubpage,
  CheckoutPaymentSubpage,
  CheckoutReviewSubpage,
  CheckoutShippingSubpage,
  ICheckoutAddressSubpageHandles,
  ICheckoutPaymentSubpageHandles,
  ICheckoutReviewSubpageHandles,
  ICheckoutShippingSubpageHandles,
} from "./subpages";
import { IProps } from "./types";
import { mapItemsByMerchant } from "src/core/utils";
import { TypedGetPaymentTransactionTypes } from "./queries"
import { TypedCheckoutPaymentUpdateMutation, TypedUserJRPayLinkingMutation } from "./mutations";
import "./scss/index.scss";
// import { useLayout } from "@temp/layout/hooks/useLayout";


// const prepareCartSummary = (
//   totalPrice?: ITaxedMoney | null,
//   subtotalPrice?: ITaxedMoney | null,
//   shippingTaxedPrice?: ITaxedMoney | null,
//   promoTaxedPrice?: ITaxedMoney | null,
//   items?: IItems
// ) => {
//   const products = items?.map(({ id, variant, totalPrice, quantity }) => ({
//     id: id || "",
//     name: variant.name || "",
//     price: {
//       gross: {
//         amount: totalPrice?.gross.amount || 0,
//         currency: totalPrice?.gross.currency || "",
//       },
//       net: {
//         amount: totalPrice?.net.amount || 0,
//         currency: totalPrice?.net.currency || "",
//       },
//     },
//     quantity,
//     sku: variant.sku || "",
//     thumbnail: {
//       alt: variant.product?.thumbnail?.alt || undefined,
//       url: variant.product?.thumbnail?.url,
//       url2x: variant.product?.thumbnail2x?.url,
//     },
//     merchant: variant 
//               && variant.product
//               && variant.product.merchant
//               ? variant.product.merchant
//               : null
//   }));

//   return (
//     <CartSummary
//       shipping={shippingTaxedPrice}
//       subtotal={subtotalPrice}
//       promoCode={promoTaxedPrice}
//       total={totalPrice}
//       products={products}
//     />
//   );
// };

const prepareCartSummaryByMerchant = (
  items?: IItems,
  deliveryQuotations?: IDeliveryQuotations[]
) => {
  return (
    <CartSummaryByMerchant
      // shipping={shippingTaxedPrice}
      // subtotal={subtotalPrice}
      // total={totalPrice}
      // promoCode={promoTaxedPrice}

      // @ts-ignore
      productsByMerchant={mapItemsByMerchant(items, deliveryQuotations)}
    />
  );
};

const getCheckoutProgress = (
  loaded: boolean,
  activeStepIndex: number,
  isShippingRequired: boolean,
  isDisabled?: boolean
) => {
  const steps = isShippingRequired
    ? CHECKOUT_STEPS
    : CHECKOUT_STEPS.filter(
      ({ onlyIfShippingRequired }) => !onlyIfShippingRequired
    );

  return loaded ? (
    <CheckoutProgressBar isDisabled={isDisabled} steps={steps} activeStep={activeStepIndex} />
  ) : null;
};

const getButton = (text: string, onClick: () => void) => {
  if (text) {
    return (
      <div className="checkout__action">
        <Button
          className="checkout__action__button"
          testingContext="checkoutPageNextStepButton"
          onClick={onClick}
          type="submit"
        >
          {text}
        </Button>
      </div>

    );
  } else {
    return null;
  }
};

const CheckoutPage: React.FC<IProps> = ({ }: IProps) => {
  // const {setShowCategorybar} = useLayout();
  const { pathname } = useLocation();
  const {
    loaded: cartLoaded,
    // shippingPrice,
    // discount,
    // subtotalPrice,
    // totalPrice,
    items,
  } = useCart();
  const { loaded: checkoutLoaded, checkout, payment } = useCheckout();

  if (cartLoaded && (!items || !items?.length)) {
    return <Redirect to="/cart/" />;
  }
  const [isDisabled, setIsDisabled] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<
    string | undefined
  >(payment?.gateway);
  const [
    selectedPaymentGatewayToken,
    setSelectedPaymentGatewayToken,
  ] = useState<string | undefined>(payment?.token);

  useEffect(() => {
    setSelectedPaymentGateway(payment?.gateway);
  }, [payment?.gateway]);
  useEffect(() => {
    setSelectedPaymentGatewayToken(payment?.token);
  }, [payment?.token]);

  const matchingStepIndex = CHECKOUT_STEPS.findIndex(
    ({ link }) => link === pathname
  );
  const activeStepIndex = matchingStepIndex !== -1 ? matchingStepIndex : 3;
  const activeStep = CHECKOUT_STEPS[activeStepIndex];

  const checkoutAddressSubpageRef = useRef<ICheckoutAddressSubpageHandles>(
    null
  );
  const checkoutShippingSubpageRef = useRef<ICheckoutShippingSubpageHandles>(
    null
  );
  const checkoutPaymentSubpageRef = useRef<ICheckoutPaymentSubpageHandles>(
    null
  );
  const checkoutReviewSubpageRef = useRef<ICheckoutReviewSubpageHandles>(null);

  const handleNextStepClick = () => {
    switch (activeStepIndex) {
      case 0:
        if (checkoutAddressSubpageRef.current?.submitAddress) {
          checkoutAddressSubpageRef.current?.submitAddress();
        }
        break;
      case 1:
        if (checkoutShippingSubpageRef.current?.submitShipping) {
          checkoutShippingSubpageRef.current?.submitShipping();
        }
        break;
      case 2:
        if (checkoutPaymentSubpageRef.current?.submitPayment) {
          checkoutPaymentSubpageRef.current?.submitPayment();
        }
        break;
      case 3:
        if (checkoutReviewSubpageRef.current?.complete) {
          checkoutReviewSubpageRef.current?.complete();
        }
        break;
    }
  };

  // useEffect(()=>{
  //   if(window.screen.height <= 650){
  //     setShowCategorybar(false);
  //   }
  // },[window.screen.height]);
  // const shippingTaxedPrice =
  //   checkout?.shippingMethod?.id && shippingPrice
  //     ? {
  //         gross: shippingPrice,
  //         net: shippingPrice,
  //       }
  //     : null;
  // const promoTaxedPrice = discount && {
  //   gross: discount,
  //   net: discount,
  // };

  const [paymentTransactionType, setPaymentTransactionType] = useState(null);
  const checkoutView =
    cartLoaded && checkoutLoaded ? (
      <TypedUserJRPayLinkingMutation>
        {(linkJRPayUserAccount) => (
          <TypedCheckoutPaymentUpdateMutation>
            {(checkoutPaymentUpdate, { loading, data }) => (
              <TypedGetPaymentTransactionTypes variables={{ first: 100 }}>
                {({ data, error, loading }) => {
                  const payOptions = data?.paymentTransactionTypes?.edges || [];
                  const paymentTransactionTypeOptions = payOptions?.map(item => item.node);

                  return (
                    <CheckoutRouter
                      items={items}
                      checkout={checkout}
                      payment={payment}
                      renderAddress={props => (
                        <CheckoutAddressSubpage
                          ref={checkoutAddressSubpageRef}
                          changeSubmitProgress={setSubmitInProgress}
                          setIsDisabled={setIsDisabled}
                          {...props}
                        />
                      )}
                      renderShipping={props => (
                        <CheckoutShippingSubpage
                          ref={checkoutShippingSubpageRef}
                          changeSubmitProgress={setSubmitInProgress}
                          {...props}
                        />
                      )}
                      renderPayment={props => (
                        <CheckoutPaymentSubpage
                          ref={checkoutPaymentSubpageRef}
                          selectedPaymentGateway={selectedPaymentGateway}
                          selectedPaymentGatewayToken={selectedPaymentGatewayToken}
                          changeSubmitProgress={setSubmitInProgress}
                          selectPaymentGateway={setSelectedPaymentGateway}
                          paymentTransactionType={paymentTransactionType || checkout?.paymentTransactionType}
                          // @ts-ignore
                          selectedPaymentTransactionType={setPaymentTransactionType}
                          paymentTransactionTypeOptions={paymentTransactionTypeOptions}
                          checkoutPaymentUpdate={checkoutPaymentUpdate}
                          linkJRPayUserAccount={linkJRPayUserAccount}
                          {...props}
                        />
                      )}
                      renderReview={props => (
                        <CheckoutReviewSubpage
                          ref={checkoutReviewSubpageRef}
                          selectedPaymentGatewayToken={selectedPaymentGatewayToken}
                          changeSubmitProgress={setSubmitInProgress}
                          paymentTransactionType={paymentTransactionType || checkout?.paymentTransactionType}
                          {...props}
                        />
                      )}
                    />
                  )
                }}
              </TypedGetPaymentTransactionTypes>
            )}
          </TypedCheckoutPaymentUpdateMutation>
        )}
      </TypedUserJRPayLinkingMutation>
    ) : (
      <Loader />
    );


  const isShippingRequiredForProducts =
    items &&
    items.some(
      ({ variant }) => variant.product?.productType.isShippingRequired
    );
  const deliveryQuotations = checkout?.deliveryQuotations ? checkout?.deliveryQuotations : [];
  return (
    <Checkout
      loading={submitInProgress}
      navigation={getCheckoutProgress(
        cartLoaded && checkoutLoaded,
        activeStepIndex,
        !!isShippingRequiredForProducts,
        isDisabled
      )}
      cartSummary={prepareCartSummaryByMerchant(
        items,
        deliveryQuotations
      )}
      checkout={checkoutView}
      button={getButton(
        activeStep.nextActionName,
        handleNextStepClick
      )}
      showNotes={activeStepIndex === 1 ? true : false}
    />
  );
};

export { CheckoutPage };
