import * as React from "react";

const CostRow: React.FC<{
  mediumScreen: boolean;
  heading: string;
  cost: React.ReactNode;
  className?: string;
}> = ({ mediumScreen, heading, cost, className }) => (
  <tr>
    <td
      colSpan={mediumScreen ? 4 : 3}
      className={`cart-table__cost py-1 ${className}`}
    >
      {heading}
    </td>
    <td className={`py-1 ${className}`} colSpan={2}>
      {cost}
    </td>
  </tr>
);

export default CostRow;
