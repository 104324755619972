import {styled} from '@styles';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	max-width: 600px;

	h3 {
		font-size: 28px;
		font-weight: ${(props) => props.theme.typography.boldFontWeight};
	}

	p {
		margin-top: 5px;
		color: ${(props) => props.theme.colors.lightFont};
	}
`;

export const GeneralError = styled.p`
	color: ${(props) => props.theme.colors.error} !important;
`;

export const InputFields = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 10.5rem;
	margin: 2rem auto;

	div {
		border-radius: 5px;
	}
`;
