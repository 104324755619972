import { Skeleton } from "@material-ui/lab";
import * as React from "react";
import { Link } from "react-router-dom";
import { generateBranchUrl } from "src/core/utils";
import JRMallPlaceholder from '../../../images/merchant-placeholder-img.jpg';
import "./scss/index.scss";
import ReactSVG from "react-svg";
import preparationIcon from '../../../images/preparation-icon.svg';

interface BranchCard {
  name: string;
  slug: string;
  categories?: string;
  distance?: string;
  id?: string;
  isPreloading?: boolean;
  preparationTime?: string;
  backgroundUrl ?: string;
}


const BranchCard: React.FC<BranchCard> = ({
  name,
  categories,
  distance,
  id,
  isPreloading,
  preparationTime,
  backgroundUrl,
  slug
}) => {

  const [isHovered, setIsHovered] = React.useState(false);

  // const category = categories?.map((item) => {return item});

    if(isPreloading) {
      return <div>
        <Skeleton width="100%" style={{borderRadius: "10px", height: "200px"}} variant="rect" />
        <div className="mt-2 d-flex justify-content-between">
          <div className="d-flex">
            <Skeleton width="93px" height={16} />
            <Skeleton className="ml-2" width="120px" height={16} />
          </div>
            <Skeleton width="50px" height={12} />
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <div className="d-flex">
            <Skeleton width="50px" height={12} />
            <Skeleton className="ml-2" width="37px" height={12} />
            <Skeleton className="ml-2" width="55px" height={12} />
          </div>
          <Skeleton width="87px" height={12} />
        </div>
      </div>
    }
  
    return (
    <div className="branch-card">
      <Link onMouseEnter={()=> setIsHovered(true)} onMouseLeave={()=> setIsHovered(false)}  className="branch-card__merchant" to={generateBranchUrl(slug)}>
            <div className="branch-card__image"><img style={{transform: `${isHovered ? 'scale(1.05)': 'scale(1)'}`}} src={backgroundUrl ? backgroundUrl : JRMallPlaceholder}/></div>
            <div className="branch-card__merchant__textWrapper">
              <p className="branch-card__merchant__name">{name}</p>
              <p className="branch-card__merchant__distance">{distance}</p>
            </div>
            <div className="branch-card__merchant__wrapper">
              <p className="branch-card__merchant__category">{categories}</p>
              <div className="branch-card__merchant__subDetails">
                <ReactSVG className="mr-1" path={preparationIcon}/>
                <p>{preparationTime}</p>
              </div>
          </div>
      </Link>
    </div>
    );
  }

export default BranchCard;
