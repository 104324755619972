import "./scss/index.scss";

import isEqual from "lodash/isEqual";
import * as React from "react";

import { Thumbnail } from "@components/molecules";

import { TaxedMoney, SubDetails } from "../../@next/components/containers";
import { BasicProductFields } from "../../views/Product/gqlTypes/BasicProductFields";
import { ProductDetails_merchant } from "@temp/views/Product/gqlTypes/ProductDetails";

export interface Product extends BasicProductFields {
  category?: {
    id: string;
    name: string;
  };
  pricing: {
    priceRange: {
      start: {
        gross: {
          amount: number;
          currency: string;
        };
        net: {
          amount: number;
          currency: string;
        };
      };
    };
    priceRangeUndiscounted: {
      start: {
        gross: {
          amount: number;
          currency: string;
        };
        net: {
          amount: number;
          currency: string;
        };
      };
    };
  };
  merchant?: ProductDetails_merchant | null;
}

// interface ProductMerchant {
//   id: string;
//   name: string | null;
//   slug: string;
//   storeTitle: string | null;
//   operatingDays: string | null;
//   storeHourStart: string | null;
//   storeHourEnd: string | null;
// }

interface ProductListItemProps {
  product: Product;
  storeTitle?: string;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product, storeTitle }) => {
  const { 
    // category, 
    merchant } = product || {};
  const price = product.pricing.priceRange.start;
  const priceUndiscounted = product.pricing.priceRangeUndiscounted.start;

  const address = merchant 
  && merchant.warehouses 
  && merchant.warehouses.edges
  && merchant.warehouses.edges.length > 0
  && merchant.warehouses.edges[0].node
  && merchant.warehouses.edges[0].node.address
  ? merchant.warehouses.edges[0].node.address : null;

  const getProductPrice = () => {
    if (isEqual(price, priceUndiscounted)) {
      return <TaxedMoney taxedMoney={price} />;
    } else {
      return (
        <>  
          <span className="product-list-item__unit_price">      
          <TaxedMoney taxedMoney={price} />
          </span>  
          <span className="product-list-item__undiscounted_price">
            <TaxedMoney taxedMoney={priceUndiscounted} />
          </span>
        </>
      );
    }
  };
  return (
    <div className="product-list-item">
      <div className="product-list-item__image">
        <Thumbnail source={product} />
      </div>
      <div className="product-list-item__wrapper">
      {storeTitle && <p className="product-list-item__merchant">{storeTitle}</p>}
      <h4 className="product-list-item__title">{product?.name}</h4>
      {/* <p className="product-list-item__category">{category?.name}</p> */}
      <p className="product-list-item__price">{getProductPrice()}</p>
      {merchant && 
      <div className="product-list-item__place">
        <SubDetails
          items={[
            {
              fontSize: 13,
              // iconName: 'fas fa-map-marker-alt',
              // iconSize: 13,
              text: address?.city,
              toolTip: "Store Location",
            },
          ]}
        />
      </div>
      }
      </div>
    </div>
  );
};

export default ProductListItem;
