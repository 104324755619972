import React, {
  forwardRef,
  RefForwardingComponent,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import moment from "moment";

import { CheckoutShipping } from "@components/organisms";
import { useCheckout } from "@saleor/sdk";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IFormError } from "@types";
import { useAlert } from "react-alert";
import { DELIVERY_TYPES } from "src/constants";
import { CheckoutShippingScheduleProps } from "src/core/checkoutScheduleUtils";
import { catchInActiveCheckoutError } from "src/helpers";

export interface ICheckoutShippingSubpageHandles {
  submitShipping: () => void;
}

interface IProps extends RouteComponentProps<any> {
  changeSubmitProgress: (submitInProgress: boolean) => void;
}

const formatDate = (date: Date | null) => {
  if (!date) {
    return "";
  }
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

const CheckoutShippingSubpageWithRef: RefForwardingComponent<
  ICheckoutShippingSubpageHandles,
  IProps
> = ({ changeSubmitProgress, ...props }: IProps, ref) => {
  const checkoutShippingFormId = "shipping-form";
  const checkoutShippingFormRef = useRef<HTMLFormElement>(null);
  const [errors, setErrors] = useState<IFormError[]>([]);
  const history = useHistory();
  const {
    checkout,
    availableShippingMethods,
    setShippingMethod,
  } = useCheckout();

  const shippingMethods = availableShippingMethods
    ? availableShippingMethods
    : [];

  const alert = useAlert();
  const showErrorAlert = (title: string, message: string) => {
    alert.error({
      content: message,
      title,
    }, {
      timeout: 2000, // custom timeout just for this one alert
      // onOpen: () => {}, // callback that will be executed after this alert open
      // onClose: () => {} // callback that will be executed after this alert is removed
    })
  };

  useImperativeHandle(ref, () => ({
    submitShipping: () => {
      checkoutShippingFormRef.current?.dispatchEvent(
        new Event("submit", { cancelable: true })
      );
    },
  }));

  const REQUIRED = "Required!";
  // const handleSetShippingMethod = async (shippingMethodId: string, deliveryType: string, deliverySchedule: string) => {
  const handleSetShippingMethod = async (shippingMethodId: string, deliverySchedules: CheckoutShippingScheduleProps[], note: string) => {
    // if (!deliveryType) {
    //   showErrorAlert(REQUIRED, "Please select a delivery type.");
    //   return;
    // } 

    // if (deliveryType === DELIVERY_TYPES.SCHEDULED) {
    //   // @ts-ignore
    //   const diffs = (new Date(deliverySchedule) - new Date());
    //   const diffsMins = Math.floor((diffs / 1000)/60);
    //   if (deliverySchedule && diffsMins < 0) {
    //     showErrorAlert("Error", "Input date and time is incorrect, please check.");
    //     return;
    //   }
    // }

    const hasError = deliverySchedules.find(item => (!!item.errorMessage && item.errorMessage !== ""));
    if (!!hasError) {
        showErrorAlert("Error", "Invalid selected date(s), please check.");
        return; 
    }

    const pickupDates = deliverySchedules.map(({ merchant, pickupDate }) => ({ merchantId: merchant?.id, pickupDate: formatDate(pickupDate) }));
    
    changeSubmitProgress(true);
    const { dataError } = await setShippingMethod(shippingMethodId, DELIVERY_TYPES.SCHEDULED, pickupDates, note);

    const errors = dataError?.error;
    changeSubmitProgress(false);

    if (errors) {
      setErrors(errors);
      showErrorAlert(REQUIRED, (errors.length && errors[0].message) || "An error occurs upon delivery pickup schedule entry.");

      // Check if current checkout is IN-ACTIVE
      catchInActiveCheckoutError(errors)
    } else {
      setErrors([]);
      history.push(CHECKOUT_STEPS[1].nextStepLink);
    }
  };

  return (
    <CheckoutShipping
      {...props}
      shippingMethods={shippingMethods}
      selectedShippingMethodId={checkout?.shippingMethod?.id}
      errors={errors}
      selectShippingMethod={handleSetShippingMethod}
      formId={checkoutShippingFormId}
      formRef={checkoutShippingFormRef}
    />
  );
};

const CheckoutShippingSubpage = forwardRef(CheckoutShippingSubpageWithRef);

export { CheckoutShippingSubpage };
