import React from 'react';

import { Link } from "react-router-dom";
import { Button } from "..";
import { OverlayContextInterface } from "../Overlay";

const CheckoutAsGuest: React.FC<{
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ overlay, checkoutUrl }) => (
  <div className="checkout-login__guest">
    <h3 className="checkout__header">Guest Checkout</h3>
    <div className="checkout-login__text">
    <p>No account yet? You can create an account later.</p>
    <p style={{marginTop: '0.5rem'}}>We recommend signing up for faster checkout, order
    tracking, and access to exclusive deals and promotions!</p>
    </div>
    <Link to={checkoutUrl}>
      <Button className="checkout-login__guest__action" testingContext="continueAsGuestButton">
        Continue as Guest
      </Button>
    </Link>
  </div>
);

export default CheckoutAsGuest;
