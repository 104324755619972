import React from "react";
import { Route, Switch } from "react-router-dom";
import NewBrands from "@temp/screens/mobile/BrandCollectionsPage";
// import TopPicks from '@screens/mobile/TopPicks';
// import TopShops from "@screens/mobile/TopShops";

export const baseUrl = "/view/";
export const newBrandsUrl = `${baseUrl}new-brands/`;
export const topShopsUrl = `${baseUrl}top-shops/`;
export const topPicksUrl = `${baseUrl}top-picks/`;

const BrandRoutes: React.FC = () => (
  <Switch>
    <Route path={newBrandsUrl} component={NewBrands} />
    {/* <Route path={topShopsUrl} component={TopShops} />
    <Route path={topPicksUrl} component={TopPicks} /> */}
  </Switch>
);

export default BrandRoutes;
