import { Input } from 'antd';
import React from "react";


const SpecialInstruction: React.FC<{
  title?: string;
}> = ({ title }) => {
  const { TextArea } = Input;
  
  return (
  <div className="brand-description__attributes">
    <div className="brand-description__attributes__title">
    <h4>{title}</h4>
    </div>
    <TextArea className="brand-description__attributes__textArea" placeholder={'Please make it...'} maxLength={100} rows={5} />
  </div>
  );
};

export default SpecialInstruction;
