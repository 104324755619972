import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {fragmentOrderDetail} from './fragments';
import {OrderByToken, OrderByTokenVariables} from './types';

const orderByTokenQuery = gql`
	${fragmentOrderDetail}
	query OrderByToken($token: UUID!) {
		orderByToken(token: $token) {
			...OrderDetail
		}
	}
`;

export const TypedOrderByTokenQuery = TypedQuery<
	OrderByToken,
	OrderByTokenVariables
>(orderByTokenQuery);
