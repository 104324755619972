import {styled} from '@styles';

export const ShippingMethodForm = styled.form`
	display: grid;
	grid-gap: 20px;
`;

export const Title = styled.h3`
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	color: ${(props) => props.theme.colors.primary};
	font-size: 28px;
`;

export const Tile = styled.label<{checked: boolean}>`
  display: block;
  background-color: ${(props) => props.theme.colors.light};
  padding: 20px;
  ${(props) => props.checked && `border: 2px solid #21125E;`}
  font-size: ${(props) => props.theme.typography.smallFontSize};
  cursor: pointer;
`;

export const Price = styled.span`
	color: #21125e;
`;

export const DeliveryDateContainer = styled.div`
	margin-top: 12px;
`;

export const ItemContainer = styled.div<{error: boolean}>`
	display: grid;
	margin-top: 1rem;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: ${(props) => props.theme.colors.cardBg};
	min-width: 280px;
	border-radius: 10px;
	border-style: solid;
	border-color: ${(props) => `${props.error ? 'red' : '#76aad3'};`};
	border-width: ${(props) => `${props.error ? '1px' : '1px'};`};
`;

export const ItemTitle = styled.div`
	font-size: 18px;
	> a {
		text-transform: uppercase;
		color: ${(props) => props.theme.colors.primary};
	}
	> a:hover {
		color: ${(props) => props.theme.colors.primary};
	}
`;
export const ItemSubTitleContent = styled.div`
	color: ${(props) => props.theme.colors.primary};
	font-size: 18px;
	margin: 0.5rem 0 1rem 0;
	font-family: ${(props) => props.theme.typography.baseFontFamily};
`;
export const ItemErrorText = styled.div`
  padding-top: 5px;
  color: red
  font-size: 12px;
  font-style: italic;
`;

export const Divider = styled.div`
	width: 100%;
	border-bottom: 1px solid
		${(props) => props.theme.colors.baseFontColorTransparent};
	margin: 30px 0;
`;

export const InputRemarks = styled.textarea`
	font-size: 14px;
	width: 100%;
	padding: 10px;
	border-color: grey;
	margin-top: 0.5rem;
	border-radius: 10px;
	border: 1px solid #cccccc;
	outline: none !important;
	:hover,
	:active,
	:focus {
		border: 2px solid ${(props) => props.theme.colors.secondary};
	}
`;
export const SubDetailsContainer = styled.div`
	* {
		color: ${(props) => props.theme.colors.baseFont} !important;
	}
`;

export const Photo = styled.div`
	margin-right: 1rem;
	img {
		max-width: 70px;
		max-height: 70px;
		border-radius: 10px;
	}
`;

export const Flex = styled.div`
	display: flex;
	align-items: stretch;
	justify-items: flex-start;
`;

export const MerchantDetailsWrapper = styled.div`
	align-self: center;
`;

export const Date = styled.div`
	div:first-child {
		width: 60%;
	}
`;
export const Time = styled.div`
	div:first-child {
		width: 60%;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	height: 40px;
	padding: 0px 2px 0px 2px;
	background-color: #fff;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	border-left: 1px solid #d9d9d9;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;

	* {
		margin-left: 3px;
		padding-right: 0px;
		padding-top: 1px;
		padding-bottom: 0.6px;
	}
`;

export const Wrapper = styled.div``;

export const NotesTitle = styled.h3`
	margin-top: 1rem;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	font-size: 20px;
	color: ${(props) => props.theme.colors.baseFont};
	outline: none;
`;
