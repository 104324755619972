import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ThankYou } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
// import { generateGuestOrderDetailsUrl } from "@utils/core";
import { Loader } from "@components/atoms";
import { IProps } from "./types";

const ORDERS_KEY = 'jrmall-order-finalized-key-201';

const ThankYouPage: React.FC<IProps> = ({}: IProps) => {
  const location = useLocation();
  const history = useHistory();
  // const { token, orderNumber } = location.state;

  const { orders, paymentRedirectUrl } = location?.state || { orders: null, paymentRedirectUrl: null };

  if (paymentRedirectUrl) {
    localStorage.setItem(ORDERS_KEY, JSON.stringify(orders));
    window.location.href = paymentRedirectUrl || window.location.href;
  }

  let tempOrders = null;
  if (!orders) {
    tempOrders = JSON.parse(localStorage.getItem(ORDERS_KEY) || "[]");

    // Remove cache
    if (tempOrders) {
      setTimeout(() => localStorage.removeItem(ORDERS_KEY), 180000);
    }
  }

  return !paymentRedirectUrl ? (
    <ThankYou
      continueShopping={() => history.push(BASE_URL)}
      orders={orders || tempOrders}
    />
  ) : <Loader fullScreen={true} />;
};

export { ThankYouPage };
