import {media, styled} from '@styles';

export const Wrapper = styled.div``;

export const StoreInfoTitle = styled.div`
	font-size: ${(props) => props.theme.typography.h3FontSize};
	display: flex;
	align-items: center;
	> a {
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		:hover {
			color: ${(props) => props.theme.colors.dividerDark};
		}
	}
`;
export const Description = styled.div`
	p {
		font-family: ${(props) => props.theme.typography.baseFontFamily} !important;
	}
`;
export const StoreDescription = styled.div`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	p {
		font-family: ${(props) => props.theme.typography.baseFontFamily} !important;
		font-size: ${(props) => props.theme.typography.h4FontSize};
		color: ${(props) => props.theme.colors.lightFont};
	}
`;

export const TitleWrapper = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
 700;
`;

export const StoreInfoList = styled.ul`
	column-width: 50%;
	${media.largeScreen`
    column-width: 100%;
    columns: 1;
  `};
	width: 100%;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 15px;
		font-size: ${(props) => props.theme.typography.baseFontSize};
		color: ${(props) => props.theme.colors.baseFont};
	}

	li::before {
		content: '•';
		margin-right: 10px;
		color: ${(props) => props.theme.colors.baseFont};
	}
`;

export const AttributeList = styled.ul`
	columns: 2;
	column-width: 50%;

	${media.largeScreen`
    column-width: 100%;
    columns: 1;
  `};
	width: 100%;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 30px;
		font-size: ${(props) => props.theme.typography.h4FontSize};
	}

	li::before {
		content: '•';
		margin-right: 20px;
		color: ${(props) => props.theme.colors.secondary};
	}
`;

export const Tabs = styled.div`
	display: flex;
	flex-wrap: none;
	width: 100%;
	border-bottom: 1px solid ${(props) => props.theme.colors.tabsBorder};
	margin-bottom: 30px;
`;

export const TabTitle = styled.div<{active?: boolean}>`
	cursor: pointer;
	min-width: 180px;
	font-size: ${(props) => props.theme.typography.h3FontSize};
	letter-spacing: 0.02em;
	font-family: ${(props) =>
		props.active && props.theme.typography.secondaryFontFamily};
	color: ${(props) => props.active && props.theme.colors.baseFont};
	border-bottom-width: 3px;
	border-bottom-style: solid;
	border-bottom-color: ${(props) =>
		props.active ? props.theme.colors.primary : 'transparent'};
	padding-bottom: 25px;
	margin-right: 40px;

	${media.smallScreen`
    font-size: ${(props: any) => props.theme.typography.h4FontSize};
    min-width: 120px;
    margin-right: 20px;
  `};
`;

export const AttributeName = styled.span`
	color: ${(props) => props.theme.colors.baseFont};
`;
