import React, { FC, useState } from 'react';
import { Link } from "react-router-dom";
import { generateBrandUrl } from "src/core/utils";
import './index.scss';
import SkeletonBrandCard from './SkeletonBrandCard';
import JRMallPlaceholder from '../../images/merchant-placeholder-img.jpg';

interface BrandItem {
	brandName: string;
	categories: any[];
	backgroundUrl: string;
	slug: string;
}

const Card: FC<Partial<BrandItem>> = ({
	brandName = '[brandName]',
	categories,
	backgroundUrl = '[backgroundUrl]',
	slug,
}) => {
	// const [redirect, setRedirect] = useState(false);
	// if (redirect) {
	// 	return <Redirect push to={`${selectBranch}/${id}`} />;
	// }
	const [isLoading, setIsLoading] = useState(true);
	const [isHovered, setIsHovered] = useState(false);

	// useEffect(()=>{
	// 	if(image && image.current){
	// 		if(!image.current.complete){
	// 			setIsLoading(true);
	// 		}
	// 		else{
	// 			setIsLoading(false);
	// 		}
	// 	}
	// 	return ()=> setIsLoading(false);
	// },[]);

	return (
		<>
			{isLoading ? <SkeletonBrandCard /> : null}
			<Link onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={isLoading ? { display: 'none' } : {}} className="brand-card" to={generateBrandUrl(slug)}>
				<div
					draggable={false}
					className="d-flex flex-column flex-shrink-0"
				// onClick={() => setRedirect(true)}
				>
					<div className="brand-card__image">
						<img style={{ transform: `${isHovered ? 'scale(1.05)' : 'scale(1)'}` }} src={backgroundUrl ? backgroundUrl : JRMallPlaceholder} onLoad={() => setIsLoading(false)} draggable={false} />
					</div>
					<div draggable={false} className="mt-1">
						<p className="brand-card__name">{brandName}</p>
						<p className="brand-card__categories">{categories.join(', ')}</p>
					</div>
				</div>
			</Link>
		</>
	);
};

export default Card;
