
import React from "react";
import * as S from "./styles";

export interface MerchantItemProps {
    text?: string | null;
    iconName?: string | null;
    toolTip?: string;
    flex?:boolean;
    fontSize?: number;
    alignRight?: boolean;
    iconSize?: number;
    component?: any;
    mobileView?: boolean;
    paddingRight?: number;
    paddingLeft?: number;
};

export interface IProps {
    items: MerchantItemProps[]
    flex?:boolean;
    mobileView?: boolean;
    paddingTop?: number;
};

export const SubDetails: React.FC<IProps> = ({
    items, flex, mobileView, paddingTop
}: IProps) => {
    return (
        <S.Container flex={flex} paddingTop={paddingTop}>
            {items && items.map(({ flex, text, iconName, toolTip, fontSize, alignRight, iconSize, paddingRight, paddingLeft, component, mobileView }, ix) => (
                component 
                ? <div>{component}</div>
                : <S.Content styles={{ flex, fontSize, alignRight, mobileView }} key={ix}>
                    {iconName && text && 
                    <S.IconContainer iconSize={iconSize} paddingRight={paddingRight} paddingLeft={paddingLeft}>
                        <i className={iconName || ""} title={toolTip || ""}></i>
                    </S.IconContainer>}
                    {text || ""}
                </S.Content>
            ))}
      </S.Container>
    );
};

SubDetails.displayName = "SubDetails";
export default SubDetails;