import * as React from "react";
import './index.scss';
import { Link } from 'react-router-dom';
import BrandCard from '@jrmall/BrandCard';
import { generateBrandCollectionsUrl } from '@temp/core/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	BrandCollectionsResultEdgesNode,
	BrandCollectionsResultEdgesNodeMerchantBrandNodeData
} from "../gqlTypes/BrandCollections";
import SwiperCore, { Navigation } from 'swiper/core';

const selected = 'item1';
SwiperCore.use([Navigation]);

interface BrandProps {
	brandCollection: BrandCollectionsResultEdgesNode | null;
}

const MenuItem = ({ data, selected }) => {
	const url = data?.banner?.url || data?.logo?.url;
	const categories = [];
	data?.merchantBrandMerchantCategoryAssignment?.edges.map(item => {
		const name = item?.node?.merchantCategory?.name;
		if (name) {
			categories.push(name);
		}
	});

	return (
		<BrandCard
			backgroundUrl={url || ""}
			brandName={data?.name}
			categories={categories}
			slug={data?.slug}
		/>
	);
};

const Menu = (merchantbrands: BrandCollectionsResultEdgesNodeMerchantBrandNodeData[], selected) =>
	merchantbrands.map((merchantBrand) =>
		<SwiperSlide>
			<MenuItem key={merchantBrand?.id} selected={selected} data={merchantBrand} />
		</SwiperSlide>);

const Brands: React.FC<BrandProps> = ({
	brandCollection
}) => {
	const merchantBrands = brandCollection?.merchantBrands?.edges?.sort((a: any, b: any) => a?.node?.orderByKm - b?.node?.orderByKm)?.map(item => item.node) || [];
	const merchantBrandsLength = brandCollection?.merchantBrands?.edges?.length;
	const menu = Menu(merchantBrands, selected);

	const prevRef = React.useRef<HTMLDivElement>(null)
	const nextRef = React.useRef<HTMLDivElement>(null)

	const params = {
		observeParents: true,
		observer: true,
		slidesPerView: 1.2,
		freeMode: true,
		spaceBetween: 15,
		breakpoints: {
			1265: {
				slidesPerView: 5,
			},
			992: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 3,
			},
			540: {
				slidesPerView: 2.2,
			},
			470: {
				slidesPerView: 1.6,
			},
			410: {
				slidesPerView: 1.5,
			},
			374: {
				slidesPerView: 1.3,
			}
		},
		navigation: {
			nextEl: nextRef.current,
			prevEl: prevRef.current,
		},
	}

	return (
		<section className="brands">
			<div className="main-container">
				<header className="d-flex justify-content-between align-items-center">
					<h4>{brandCollection?.name}</h4>
					{merchantBrandsLength > 5 &&
						<Link
							to={generateBrandCollectionsUrl(brandCollection?.slug)}
							className="brands-viewAll"
						>
							View All
						</Link>
					}
				</header>
				{/* <ScrollMenu
				wrapperClass="mt-3 pb-1 px-3"
				data={menu}
				wheel={false}
				alignCenter={false}
			/> */}
				<Swiper {...params} className="py-2">
					{menu}
				</Swiper>
				<div ref={prevRef} style={{ position: 'absolute', top: '50%', left: '1rem' }} className="swiper-button-prev"><i style={{ fontSize: 25 }} className="fas fa-chevron-left"></i></div>
				<div ref={nextRef} style={{ position: 'absolute', top: '50%', right: '1rem' }} className="swiper-button-next"><i style={{ fontSize: 25 }} className="fas fa-chevron-right"></i></div>
			</div>
		</section>
	);
};

export default Brands;