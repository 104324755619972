import React from "react";
import moment from "moment";
import { Select, Input } from 'antd';
import * as S from "./styles";
import { SubDetails } from "@components/containers";
const { Option } = Select;

interface IExceptionDatesProps {
    id: string;
    date: string;
    isStoreClosed: boolean;
}

interface IProps {
    startDateTime: any;
    operatingDays: string;
    onChange: (e: any) => void;
    value: string;
    options: any;
    exceptionDates?: IExceptionDatesProps[] | null;
}

interface OptionType {
    value: any,
    display: string
}

export const DATE_FORMAT = "YYYY-MM-DD";
// const DATE_DISPLAY_FORMAT = "MMM DD (ddd)";

const DateOptions: React.FC<IProps> = ({
    startDateTime,
    operatingDays,
    onChange,
    options,
    value,
    exceptionDates
}: IProps) => {
    // const options = initOptions(startDateTime, operatingDays, exceptionDates);
    const defaultValue = moment(startDateTime).format(DATE_FORMAT);

    return (
        <Input.Group style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 40, width: '100%', marginBottom: '0.5rem', marginTop: '1rem' }} compact>
            <S.IconContainer>
                <SubDetails
                    items={[
                        {
                            iconName: 'far fa-calendar',
                            iconSize: 20,
                            paddingLeft: 2.5,
                            text: ' '
                        },
                    ]}
                />
            </S.IconContainer>
            <Select
                defaultValue={defaultValue}
                size={'large'}
                showArrow
                allowClear
                placeholder={'Select your delivery date'}
                onChange={onChange}
                value={value || defaultValue}
            >
                {
                    options.map((option: OptionType) => (
                        <Option value={option.value}>{option.display}</Option>
                    ))
                }
            </Select>
        </Input.Group>
    );
};

export default DateOptions;