import { styled, media } from "@styles";

export const Wrapper = styled.div`
  display: grid;
  min-height: 60px;
  max-height: min-content;
  width: 100%;
  grid-template-areas: ". products price quantity total .";
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 0.5fr;
  align-items: center;
  justify-items: center;
  font-size: ${(props) => props.theme.typography.smallFontSize};
  color: rgba(40, 35, 74, 0.6);
  padding: 0 0.5rem;
  ${media.mediumScreen`
    display: none;
  `}
`;

export const Column = styled.div`
  padding: 0.5rem;
`;

export const Products = styled.div`
  grid-area: products;
`;

export const Price = styled.div`
  grid-area: price;
`;

export const Quantity = styled.div`
  grid-area: quantity;
`;

export const Total = styled.div`
  grid-area: total;
`;
