import ProductCard, {ProductCardProps} from '@temp/components/ProductCard';
import React, {FC} from 'react';
import { Button, Loader } from "@components/atoms";
import './index.scss';
import * as S from "./styles";
import { Element } from 'react-scroll'

interface ProductProps {
	products?: ProductCardProps[];
	setProductOverlay?: (state: boolean, id?: string)=> void
	loading?: boolean;
	canLoadMore?: boolean;
	onLoadMore?: () => void;
	title?: string;
}

const Products: FC<ProductProps> = ({
	products = [], 
	loading,
	canLoadMore,
	onLoadMore,
	setProductOverlay,
	title
}) => {
	const productList = products.map((product, index) => {
		// const dynamicPadding =
		// 	index % 2 === 0
		// 		? {paddingLeft: 0, paddingRight: 8}
		// 		: {paddingLeft: 8, paddingRight: 0};

		return (
			<div>
				<ProductCard setProductOverlay={setProductOverlay} key={product?.id} {...product} />
			</div>
		);
	});

	return (
		<Element name="collection-0" className="product pb-5">
			<h4 className="product-title">{title || ""}</h4>
			<div className="product-grid py-2 mt-2 mb-4">
				{productList}
			</div>

			<S.Loader>
				{loading ? (
					<Loader />
				) : (
					canLoadMore && (
					<Button
						testingContext="loadMoreProductsButton"
						color="secondary"
						onClick={onLoadMore}
					>
						<span>More +</span>
					</Button>
					)
				)}
			</S.Loader>
		</Element>
	);
};

export default Products;
