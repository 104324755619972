import React from "react";

import { IProps } from "./types";

// import NoPhoto from "images/no-photo.svg";
import DefaultImagePlaceholder from "src/images/merchant-placeholder-avatar.jpg";

export const PlaceholderImage: React.FC<IProps> = ({
  alt = "placeholder",
}: IProps) => {
  return <img src={DefaultImagePlaceholder} alt={alt} />;
};
