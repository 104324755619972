import { smallScreen } from "@temp/@next/globalStyles/constants";
import BannerCarousel from "@temp/components/BannerCarousel";
import React from "react";
import Media from "react-responsive";
import _ from "lodash";

const bannerSrc = [
    "../../images/bannerOne.jpg",
    "../../images/bannerTwo.jpg",
    "../../images/bannerThree.jpg",
  ]

const bannerSrcMobile = [
  "../../images/bannerOne-mobile.jpg",
  "../../images/bannerTwo-mobile.jpg",
  "../../images/bannerThree-mobile.jpg",
]

const Slides: React.FC<{}> = ({
}) => {


  return (
    <>
        <Media maxWidth={smallScreen}>
        <div className="home-page__categories__carousel">
            <BannerCarousel dragging autoplay={true} wrapAround={true}>
            {bannerSrcMobile.map(bannerPath => {
                return (
                  <img src={bannerPath} />
                );
              })}
            </BannerCarousel>
          </div>
        </Media>
        <Media minWidth={541}>
          <div className="home-page__categories__carousel">
            <BannerCarousel dragging autoplay={true} wrapAround={true}>
              {bannerSrc.map(bannerPath => {
                return (
                  <img src={bannerPath} />
                );
              })}
            </BannerCarousel>
          </div>
        </Media>
    </>
  );
};

export default Slides;