import "./scss/index.scss";

import * as React from "react";
import { generatePath, Link } from "react-router-dom";
// import ReactSVG from "react-svg";

import { TaxedMoney, Money } from "@components/containers";
import { useCart, useUserDetails, useCheckout } from "@saleor/sdk";
import { useLayout } from '@temp/layout/hooks/useLayout';
import {
  Button,
  Offline,
  OfflinePlaceholder,
  Online,
  Overlay,
  OverlayContextInterface,
} from "../..";
import { cartUrl, checkoutLoginUrl, checkoutUrl } from "../../../app/routes";
import Loader from "../../../components/Loader";
import Empty from "./Empty";

// import cartImg from "../../../images/shopping-cart.svg";
// import closeImg from "../../../images/x.svg";
import ProductList from "./ProductList/index";
import { mapItemsByMerchant } from "@temp/core/utils";
import ReactSVG from "react-svg";

// import cartImg from '@images/shopping-cart.svg'
import closeImg from "../../../images/x.svg";
import { ShoppingCart } from "react-feather";
import { setCheckoutSchedulesCache } from "@temp/helpers";


const Cart: React.FC<{ overlay: OverlayContextInterface }> = ({ overlay }) => {
  const { data: user } = useUserDetails();
  const {
    items,
    // removeItem,
    // subtotalPrice,
    // shippingPrice,
    discount,
    totalPrice,
  } = useCart();

  const { checkout } = useCheckout();

  const { removeCartItem } = useLayout();

  const itemsPerMerchant = mapItemsByMerchant(
    items,
    checkout?.deliveryQuotations || []
  );

  const totalDeliveryFee = itemsPerMerchant.reduce((previous, current) => {
    return previous + (current?.deliveryQuotations?.totalFee || 0);
  }, 0);

  const grossAmount: number = totalPrice?.gross?.amount || 0;
  const grandTotal = {
    amount: grossAmount + totalDeliveryFee,
    currency: totalPrice?.gross?.currency || "USD",
  };

  const promoTaxedPrice = discount && {
    gross: discount,
    net: discount,
  };

  const missingVariants = () => {
    return items.find((item) => !item.variant || !item.totalPrice);
  };

  return (
    <Overlay testingContext="cartOverlay" context={overlay}>
      <Online>
        <div className="cart d-flex flex-column h-100">
          <div className="overlay__header flex-grow-0 flex-shrink-0">
          <ShoppingCart color={'#fff'} className="overlay__header__cart-icon" />
          
          <div className="overlay__header-text">
            Cart Summary{" "}
            <span className="overlay__header-text-items">
              {items?.reduce(
                (prevVal, currVal) => prevVal + currVal.quantity,
                0
              ) || 0}{" "}
              item(s)
            </span>
          </div>
          
          <ReactSVG
                path={closeImg}
                onClick={overlay.hide}
                className="overlay__header__close-icon"
              />

          </div>
          {items?.length ? (
            <>
              {missingVariants() ? (
                <Loader full={true} />
              ) : (
                <>
                  {/* <ProductList lines={items} remove={removeItem} /> */}
                  <div className="cart__list flex-grow-1">
                    <ProductList remove={removeCartItem} data={itemsPerMerchant} />
                  </div>

                  <div className="cart__footer flex-shrink-0">
                    {/* <div className="cart__footer__price">
                      <span>Subtotal</span>
                      <span>
                        <TaxedMoney
                          data-test="subtotalPrice"
                          taxedMoney={subtotalPrice}
                        />
                      </span>
                    </div> */}

                    {/* {shippingTaxedPrice &&
                      shippingTaxedPrice.gross.amount !== 0 && (
                        <div className="cart__footer__price">
                          <span>Shipping</span>
                          <span>
                            <TaxedMoney
                              data-test="shippingPrice"
                              taxedMoney={shippingTaxedPrice}
                            />
                          </span>
                        </div>
                      )} */}

                    {promoTaxedPrice && promoTaxedPrice.gross.amount !== 0 && (
                      <div className="cart__footer__price">
                        <span>Promo code</span>
                        <span>
                          <TaxedMoney
                            data-test="promoCodePrice"
                            taxedMoney={promoTaxedPrice}
                          />
                        </span>
                      </div>
                    )}

                    <div className="cart__footer__price">
                      <span>Total</span>
                      <span>
                        {/* <TaxedMoney
                          data-test="totalPrice"
                          taxedMoney={totalPrice}
                        /> */}
                        <Money money={grandTotal} />
                      </span>
                    </div>

                    <div className="cart__footer__buttonOne">
                      <Link
                        to={generatePath(cartUrl, {
                          token: null,
                        })}
                      >
                        <Button testingContext="gotoBagViewButton">
                          Review Cart
                        </Button>
                      </Link>
                    </div>
                    <div className="cart__footer__button">
                      <Link to={user ? checkoutUrl : checkoutLoginUrl} onClick={()=> setCheckoutSchedulesCache([])}>
                        <Button testingContext="gotoCheckoutButton">
                          Proceed to Checkout
                        </Button>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <Empty overlayHide={overlay.hide} />
          )}
        </div>
      </Online>
      <Offline>
        <div className="cart">
          <OfflinePlaceholder />
        </div>
      </Offline>
    </Overlay>
  );
};

export default Cart;
