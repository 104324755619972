import {History, LocationState} from 'history';
import {Base64} from 'js-base64';
import {each} from 'lodash';
import {parse as parseQs, stringify as stringifyQs} from 'query-string';
import {FetchResult} from 'react-apollo';
import moment from 'moment';

import {OrderDirection, ProductOrderField} from '../../gqlTypes/globalTypes';
import {IFilterAttributes} from '../@next/types';
import {FormError} from './types';
import {IItems} from '@saleor/sdk/lib/api/Cart/types';
import {Checkout_lines_variant_product_merchant} from '@saleor/sdk/lib/fragments/gqlTypes/Checkout';
import {IDeliveryQuotations} from '@saleor/sdk/lib/api/Checkout/types';
import {ITaxedMoney} from 'src/@next/types/ITaxedMoney';
import {formatStoreOperatingDays} from './checkoutScheduleUtils';

export interface IExceptionDatesProps {
	id: string;
	date: string;
	isStoreClosed: boolean;
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'MMM DD (ddd)';

export const slugify = (text: string | number): string =>
	text
		.toString()
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars
		.replace(/\-\-+/g, '-'); // Replace multiple - with single -

export const getDBIdFromGraphqlId = (
	graphqlId: string,
	schema?: string,
): number => {
	// This is temporary solution, we will use slugs in the future
	const rawId = Base64.decode(graphqlId);
	const regexp = /(\w+):(\d+)/;
	const arr = regexp.exec(rawId);
	if (schema && schema !== arr![1]) {
		throw new Error('Schema is not correct');
	}
	return parseInt(arr![2], 10);
};

export const capitalizeFirstLetter = (text: string) => {
	if (!text) {
		return null;
	}

	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getDBIdFromGraphqlIdString = (
	graphqlId: string,
	schema?: string,
): string => {
	// This is temporary solution, we will use slugs in the future
	const rawId = Base64.decode(graphqlId);
	const arr = rawId.split(':');

	if (schema && schema !== arr![0]) {
		throw new Error('Schema is not correct');
	}

	return arr![1];
};

export const getGraphqlIdFromDBId = (id: string, schema: string): string =>
	// This is temporary solution, we will use slugs in the future
	Base64.encode(`${schema}:${id}`);

export const priceToString = (
	price: {amount: number; currency: string},
	locale?: string,
): string => {
	const {amount} = price;
	if (locale) {
		return amount.toLocaleString(locale, {
			currency: price.currency,
			style: 'currency',
		});
	} else {
		return `${price.currency} ${amount.toFixed(2)}`;
	}
};

export const generateMerchantProductUrl = (id: string, name: string) =>
	`/product/${slugify(name)}/${getDBIdFromGraphqlId(id, 'MerchantProduct')}/`;

export const generateRelatedProductUrl = (id: string, name: string) =>
	`/product/${slugify(name)}/${getDBIdFromGraphqlId(id, 'RelatedProduct')}/`;

export const generateProductUrl = (id: string, name: string) =>
	`/product/${slugify(name)}/${getDBIdFromGraphqlId(id, 'Product')}/`;

export const generateStoreProductUrl = (id: string, name: string) =>
	`/store-product/${slugify(name)}/${getDBIdFromGraphqlId(id, 'Product')}/`;

export const generateStoreUrl = (slug: string) => `/store/${slugify(slug)}/`;

export const generateCategoryUrl = (id: string, name: string) =>
	`/category/${slugify(name)}/${getDBIdFromGraphqlId(id, 'Category')}/`;

export const generateStoreCategoryUrl = (id: string, name: string) =>
	`/store-category/${slugify(name)}/${getDBIdFromGraphqlId(
		id,
		'MerchantCategory',
	)}/`;

export const generateCollectionUrl = (id: string, name: string) =>
	`/collection/${slugify(name)}/${getDBIdFromGraphqlId(id, 'Collection')}/`;

export const generatePageUrl = (slug: string) => `/page/${slug}/`;

export const generatePromotionUrl = (name: string) => `/view/${name}`;

export const generateBrandCollectionsUrl = (slug: string) =>
	`/brand-collections/${slug}`;

export const generateBrandUrl = (slug: string) => `/brand/${slug}`;

export const generateBranchUrl = (slug: string) => `/branch/${slug}`;

interface AttributeDict {
	[attributeSlug: string]: string[];
}
export const convertToAttributeScalar = (
	attributes: AttributeDict | IFilterAttributes,
) =>
	Object.entries(attributes)
		.map(([key, value]) =>
			value.map((attribute: any) => ({slug: key, value: attribute})),
		)
		.reduce((prev, curr) => [...prev, ...curr], []);

interface QueryString {
	[key: string]: string[] | string | null | undefined;
}
export const getAttributesFromQs = (qs: QueryString) =>
	Object.keys(qs)
		.filter(
			(key) =>
				!['pageSize', 'priceGte', 'priceLte', 'sortBy', 'q'].includes(key),
		)
		.reduce((prev: any, curr: any) => {
			prev[curr] = typeof qs[curr] === 'string' ? [qs[curr]] : qs[curr];
			return prev;
		}, {});

export const getValueOrEmpty = <T>(value: T): T | string =>
	value === undefined || value === null ? '' : value;

export const convertSortByFromString = (sortBy: string) => {
	if (!sortBy) {
		return null;
	}
	const direction = sortBy.startsWith('-')
		? OrderDirection.DESC
		: OrderDirection.ASC;

	let field;
	switch (sortBy.replace(/^-/, '')) {
		case 'name':
			field = ProductOrderField.NAME;
			break;

		case 'price':
			field = ProductOrderField.MINIMAL_PRICE;
			break;

		case 'updated_at':
			field = ProductOrderField.DATE;
			break;

		default:
			return null;
	}
	return {field, direction};
};

export const maybe = <T>(exp: () => T, d?: T) => {
	try {
		const result = exp();
		return result === undefined ? d : result;
	} catch {
		return d;
	}
};

export const parseQueryString = (
	location: LocationState,
): {[key: string]: string} => {
	const query = {
		...parseQs((location as any).search.substr(1)),
	};
	each(query, (value, key) => {
		if (Array.isArray(value)) {
			query[key] = value[0];
		}
	});
	return query as {[key: string]: string};
};

export const updateQueryString = (
	location: LocationState,
	history: History,
) => {
	const querystring = parseQueryString(location);

	return (key: string, value?: any) => {
		if (value === '') {
			delete querystring[key];
		} else {
			querystring[key] = value || key;
		}
		history.replace('?' + stringifyQs(querystring));
	};
};

export const findFormErrors = (result: void | FetchResult): FormError[] => {
	if (result) {
		const data = Object.values(maybe(() => result.data) as object);

		return data.reduce((prevVal: any, currVal: any) => {
			const errors = currVal.errors || [];

			return [...prevVal, ...errors];
		}, []);
	}
	return [];
};

export const removeEmptySpaces = (text: string) => text.replace(/\s+/g, '');

interface IProduct {
	id: string;
	leadTime: number | null;
	name: string;
	productName: string;
	price: {
		gross: {
			amount: number;
			currency: string;
		};
		net: {
			amount: number;
			currency: string;
		};
	};
	quantity: number;
	thumbnail: {
		alt: string;
		utl: string;
		url2x: string;
	};
}

export interface DeliveryTimeSlot {
	id: string;
	isSelected: boolean;
	name: string;
	text: string;
	createdAt: string;
}
export interface ImapMerchant {
	id: string;
	name: string;
	deliveryTimeSlots: DeliveryTimeSlot[];
	storeHourStart: string;
	storeHourEnd: string;
	operatingDays: string;
	slug: string | null;
	storeTitle: string | null;
	storeDescription: string | null;
	logo: {
		url: string;
	} | null;
	warehouses: {
		edges: [
			{
				node: {
					name: string;
					companyName: string;
					address: {
						city: string;
						latitude: number;
						longitude: number;
						phone: string;
						streetAddress1: string;
						streetAddress2: string;
					};
				};
			},
		];
	};
	exceptionDates:
		| Array<{
				id: string;
				date: string;
				isStoreClosed: boolean;
		  }>
		| [];
	__typename: string;
}
export interface ImapItemsByMerchant {
	deliveryQuotations: IDeliveryQuotations;
	merchant: ImapMerchant;
	products: IProduct[];
}

export const mapItemsByMerchant = (
	items: IItems,
	deliveryQuotations?: IDeliveryQuotations[],
): ImapItemsByMerchant[] => {
	const productMerchants: Checkout_lines_variant_product_merchant[] = [];
	const products = items?.map(({id, variant, totalPrice, quantity}) => {
		const merchant =
			variant && variant.product && variant.product.merchant
				? variant.product.merchant
				: null;

		if (merchant && !productMerchants.find((mer) => mer.id === merchant?.id)) {
			productMerchants.push(merchant);
		}

		// @ts-ignore
		return {
			id: variant?.id || '',
			productId: variant?.product?.id || '',
			leadTime: variant?.product?.leadTime,
			merchant,
			name: variant ? variant.name || '' : '',
			price: {
				gross: {
					amount: totalPrice?.gross.amount || 0,
					currency: totalPrice?.gross.currency || '',
				},
				net: {
					amount: totalPrice?.net.amount || 0,
					currency: totalPrice?.net.currency || '',
				},
			},
			productName: variant.product?.name || 'No Product Name',
			quantity,
			sku: variant.sku || '',
			thumbnail: {
				alt: variant.product?.thumbnail?.alt || undefined,
				url: variant.product?.thumbnail?.url,
				url2x: variant.product?.thumbnail2x?.url,
			},
		};
	});

	// @ts-ignore
	return productMerchants.map((merchant) => ({
		deliveryQuotations: deliveryQuotations
			? deliveryQuotations?.find(
					(quote) => quote.merchant && quote.merchant.id === merchant.id,
			  )
			: [],
		merchant,
		products: products?.filter(
			(product) => product.merchant && product.merchant.id === merchant.id,
		),
	}));
};
export const accumulateAllTaxMoney = (
	value1: ITaxedMoney | null = null,
	value2: ITaxedMoney | null = null,
) => {
	const defaultValue = {
		gross: {amount: 0, currency: ''},
		net: {amount: 0, currency: ''},
	};

	value1 = value1 || defaultValue;
	value2 = value2 || defaultValue;

	const newValue: ITaxedMoney = {
		gross: {
			amount: value1.gross.amount + value2.gross.amount,
			currency: value1.gross.currency || value2.gross.currency,
		},
		net: {
			amount: value1.net.amount + value2.net.amount,
			currency: value1.net.currency || value2.net.currency,
		},
	};

	return newValue;
};

export const generateStoreCategoryBreadcrumbs = (
	breadcrumbs = [],
	merchantCategory,
) => {
	const {parent} = merchantCategory || {};

	// Main store category

	if (parent) {
		breadcrumbs.push({
			link: generateStoreCategoryUrl(parent.id, parent.name),
			value: capitalizeFirstLetter(parent.name),
		});
	}

	// Sub store category
	if (merchantCategory) {
		breadcrumbs.push({
			link: generateStoreCategoryUrl(
				merchantCategory.id,
				merchantCategory.name,
			),
			value: capitalizeFirstLetter(merchantCategory.name),
		});
	}

	return breadcrumbs;
};

export const generateBranchBreadcrumbs = (
	breadcrumbs = [],
	merchantCategory,
) => {
	const {parent} = merchantCategory || {};

	// Main store category

	if (parent) {
		breadcrumbs.push({
			link: generateStoreCategoryUrl(parent.id, parent.name),
			value: capitalizeFirstLetter(parent.name),
		});
	}

	// Sub store category
	if (merchantCategory) {
		breadcrumbs.push({
			link: generateStoreCategoryUrl(
				merchantCategory.id,
				merchantCategory.name,
			),
			value: capitalizeFirstLetter(merchantCategory.name),
		});
	}

	return breadcrumbs;
};

export function isSameDay(d1: Date, d2: Date) {
	return (
		d1?.getFullYear() === d2?.getFullYear() &&
		d1?.getMonth() === d2?.getMonth() &&
		d1?.getDate() === d2?.getDate()
	);
}

export const initOptions = (
	startDateTime: any,
	operatingDays: string,
	exceptionDates?: IExceptionDatesProps[] | null,
) => {
	const availableDayWeekStr = formatStoreOperatingDays(
		operatingDays,
	).toLowerCase();
	let daysAdded = 14;
	const options = [];
	let i = 1;
	// let counter = 0;
	startDateTime = moment(startDateTime).subtract(i, 'days');
	while (i < daysAdded) {
		let dateException: any = {};
		const date = moment(startDateTime).add(i, 'days');
		// console.log(specialDays);
		// console.log(specialDays);
		// if (specialDays?.length && date > moment(specialDays, 'YYYY-MM-DD')) {
		// 	options.push({
		// 		display: `${moment(specialDays)?.format(DATE_DISPLAY_FORMAT)} - Special Operating Day`,
		// 		value: moment(specialDays)?.format(DATE_FORMAT)
		// 	})
		// 	counter++;
		// 	i++;
		// }
		dateException = (exceptionDates || []).find(
			(excDate) => excDate?.date === date.format(DATE_FORMAT),
		);

		// console.log(dateException);
		const day = date.format('ddd').toLowerCase();
		if (dateException && dateException?.isStoreClosed) {
			daysAdded++;
		} else if (
			(dateException && !dateException.isStoreClosed) ||
			availableDayWeekStr.indexOf(day) !== -1
		) {
			options.push({
				display:
					availableDayWeekStr.indexOf(day) === -1
						? `${date.format(DATE_DISPLAY_FORMAT)} - Special Operating Day`
						: date.format(DATE_DISPLAY_FORMAT),
				value: date.format(DATE_FORMAT),
			});
		} else {
			daysAdded++;
		}
		i++;
	}

	// const specialDays = exceptionDates?.filter(item => !item.isStoreClosed && moment(item?.date, DATE_FORMAT) < moment(options[0]?.value, DATE_FORMAT));

	// if (specialDays?.length) {
	// 	while (counter < specialDays?.length) {
	// 		options?.push({
	// 			display: `${moment(specialDays[counter]?.date)?.format(DATE_DISPLAY_FORMAT)} - Special Operating Day`,
	// 			value: moment(specialDays[counter]?.date)?.format(DATE_FORMAT)
	// 		});
	// 		counter++
	// 	}
	// }

	return options;
	// ?.sort((oldPos, newPos) => moment(oldPos?.value, DATE_FORMAT) < moment(newPos?.value, DATE_FORMAT) ? -1 : 0);
};
