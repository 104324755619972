import React from "react";

import { OrderDetail_deliveryQuotation } from "@saleor/sdk/lib/fragments/gqlTypes/OrderDetail";
import { ITaxedMoney } from "@temp/@next/types";
import CartGrouping from "./CartGrouping";

interface OrderPurchaseDetailsProps {
  lines: any;
  total: ITaxedMoney;
  subTotal: ITaxedMoney;
  deliveryQuotation?: OrderDetail_deliveryQuotation | null;
  note?: string | null | undefined;
}

const OrderPurchaseDetails: React.FC<Partial<OrderPurchaseDetailsProps>> = ({
  lines,
  total,
  subTotal,
  deliveryQuotation,
  note,
}) => {
  
  return (
    
    <div className="order-details__purchaseDetails">
    <div className="order-details__purchaseDetails__text">
    <h3>Purchased Items</h3>
    </div>
    <CartGrouping
      lines={lines}
      subTotal={subTotal}
      total={total}
      deliveryQuotation={deliveryQuotation}
      isForViewing={true}
    />
    <div className="order-details__purchaseDetails__totalNote">
      <p className="order-details__purchaseDetails__totalNote__text">
        *Incidental fees (eg. applicable toll, parking, and other fees) are not included in the total 
        computed delivery fee above. The assigned rider or driver will collect these fees from you 
        separately upon delivery.</p>
    </div>
    <div className="order-details__purchaseDetails__notes">
      <p><i className="fas fa-sticky-note mr-2"></i>Notes to Store</p>
      <p className="order-details__purchaseDetails__notes__text">{note ? note : '-'}</p>
    </div>
    </div>
  );
};

export default OrderPurchaseDetails;