import React from "react";
import { Route, Switch } from "react-router-dom";
import { BecomeAMerchant } from "./BecomeAMerchant";
import { BulkOrders } from "./BulkOrders";
import { TermsAndConditions } from "./TermsAndConditions";
import { FAQ } from "./FAQ";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const baseUrl = "/support/";
export const faqUrl = `${baseUrl}FAQ/`;
export const becomeAMerchantUrl = `${baseUrl}become-a-merchant/`;
export const bulkOrdersUrl = `${baseUrl}bulk-orders/`;
export const privacyPolicyUrl = `${baseUrl}privacy-policy/`;
export const termsAndConditionsUrl = `${baseUrl}terms-and-conditions/`;


const Routes: React.FC = () => (
  <Switch>
    <Route exact path={faqUrl} component={FAQ} />
    <Route path={becomeAMerchantUrl} component={BecomeAMerchant} />
    <Route path={bulkOrdersUrl} component={BulkOrders} />
    <Route exact path={termsAndConditionsUrl} component={TermsAndConditions} />
    <Route exact path={privacyPolicyUrl} component={PrivacyPolicy} />
    <Route component={FAQ} />
  </Switch>
);

export default Routes;
