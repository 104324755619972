import React from "react";

import { Button } from "@components/atoms";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";
import * as S from "./styles";
import { IProps } from "./types";
import bannerSrc from "images/shoppingCart_light.jpg";
import bannerSrcMobile from "images/shoppingCart_light_mobile.png";

const CancelledPage: React.FC<IProps> = ({orderCreateCheckout}: IProps) => {

  const title = 
  <div className="main-container">
  <div className="article-page__thankYouPage" data-test="cartPageTitle">Your Order<br/> has been cancelled!</div>
  </div>
  const banner = 
  <div className="article-page">
        <div
          className="article-page__header"
          style={ bannerSrc && {
                  backgroundImage: `url(${bannerSrc})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  height: "300px",
                }            
          }
        >
          {title}
        </div>
    </div>

const bannerMobile = 
<div className="article-page">
      <div
        className="article-page__header"
        style={ bannerSrcMobile && {
                backgroundImage: `url(${bannerSrcMobile})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: "300px",
              }            
        }
      >
        {title}
      </div>
  </div>
  return (
    <>
    <Media maxWidth={smallScreen}>
    {bannerMobile}
    </Media>
    <Media minWidth={541}>
    {banner}
    </Media>
    <div className="main-container">
      <S.Wrapper>
        <S.Paragraph>
          We regret to inform you that your payment was unsuccessful, kindly click the button below to try again.
        </S.Paragraph>
        <S.Buttons>
          <Button
            testingContext="retryPayment"
            onClick={orderCreateCheckout}
            color="primary"
            fullWidth={true}
          >
            Retry order
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </div>
    </>
  );
};

export { CancelledPage };
