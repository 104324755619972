import {
	baseUrl,
	// cartUrl
} from '@temp/app/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from "react-svg";
import logoImg from '../../../images/jrmall-white.svg';
// import {
// 	ShoppingCart, 
// 	User
// } from 'react-feather';
// import { AutoComplete } from 'antd';
import './index.scss';
import Addressbar from '../AddressBar';
import { signIn, signUp } from '@temp/routes/paths';
import {
	useCart,
	useSignOut,
	useUserDetails
} from "@saleor/sdk";

import {
	MenuDropdown,
} from "src/components";
import * as appPaths from "src/app/routes";
import {
	OverlayTheme,
	OverlayType,
} from '@temp/components';

import userImg from "src/images/user.svg";
import { ShoppingCart } from 'react-feather';

const DesktopNav = ({ overlayContext, setAddressOverlay, isLoading }) => {
	const { data: user } = useUserDetails();
	const [signOut] = useSignOut();
	const handleSignOut = () => {
		signOut();
	};
	const { items } = useCart();
	const cartItemsQuantity =
		(items &&
			items.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0)) ||
		0;

	return (
		<div className="d-flex align-items-center justify-content-between ">
			<div className="d-flex align-items-center">
				<Link to={baseUrl}>
					<img src={logoImg} style={{ width: '7em', height: 'auto', marginRight: '2rem' }} />
				</Link>
				<Addressbar isLoading={isLoading} setAddressOverlay={setAddressOverlay} />
			</div>
			{/* <div id="nav-search" className="flex-grow-1 d-flex mx-3 align-items-center position-relative">
				<Search style={{zIndex: 10}} className="position-absolute ml-2" />
				<AutoComplete options={[{label: "Brand X", value: "Brand X"}]} className="flex-grow-1"/>
			</div> */}
			<ul className="d-flex align-items-center">
				{user ?
					<MenuDropdown
						// suffixClass={"__rightdown"}
						head={
							<li className="main-menu__icon main-menu__user--active">
								{/* <User color={'#fff'} /> */}
								<ReactSVG className="main-menu__icon--user" path={userImg} />
							</li>
						}
						content={
							<ul className="main-menu__dropdown">
								<li data-test="desktopMenuMyAccountLink">
									<Link to={appPaths.accountUrl}>My Account</Link>
								</li>
								<li data-test="desktopMenuOrderHistoryLink">
									<Link to={appPaths.orderHistoryUrl}>
										Order history
									</Link>
								</li>
								<li data-test="desktopMenuAddressBookLink">
									<Link to={appPaths.addressBookUrl}>
										Address book
									</Link>
								</li>
								<li
									onClick={handleSignOut}
									data-test="desktopMenuLogoutLink"
								>
									<a href="/">Log Out</a>
								</li>
							</ul>
						}
					/>
					: <div className="main-menu__sign" style={{ marginRight: '1rem' }}>
						<Link className="text-white text-right" to={signUp}>Sign up </Link>
						<strong className="text-white">or</strong>
						<Link className="text-white text-right" to={signIn}> Login</Link>
					</div>
				}
				<div className="main-menu__icon main-menu__cart">
					{/* <ShoppingCart 
						onClick={() => overlayContext.show(OverlayType.cart, OverlayTheme.right)}
						className="mr-3" 
						color={'#fff'} 
					/> */}
					<ShoppingCart
						color={'#fff'}
						onClick={() => overlayContext.show(OverlayType.cart, OverlayTheme.right)}
					/>
					{cartItemsQuantity > 0 ?
						<span className="main-menu__cart__quantity">
							{cartItemsQuantity}
						</span>
						: null}
				</div>
				<div
					// to={cartUrl} 
					onClick={() => overlayContext.show(OverlayType.cart, OverlayTheme.right)} className="d-flex align-items-center bg-transparent border-none main-menu__checkout">
					<h4 className="text-white">Checkout</h4>
				</div>
			</ul>
		</div>
	);
};

export default DesktopNav;
