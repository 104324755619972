import React from "react";
import { SubDetails } from "@components/containers";
import { Link } from "react-router-dom";
import { generateBranchUrl } from "@temp/core/utils";

export interface MerchantInfoProps {
  merchantName?: string;
  phoneNumber?: string;
  city?: string;
  url?: string;
}

const MerchantInfo: React.FC<MerchantInfoProps> = ({
  merchantName = "[merchantName]",
  phoneNumber = "[phoneNumber]",
  city = "[city]",
  url = "[url]"
}) => {
  return (
    <div>
      <section className="border-bottom pb-3 mb-3">
        <header className="__fh-bold">
        <Link to={generateBranchUrl(url)}>
        {merchantName}
        </Link>
          </header>
        {/* <p className="text-secondary d-block small">
          Contact Number: {phoneNumber}
        </p>
        <p className="text-secondary d-block small mt-n2">City: {city}</p> */}
        <div className="__address">
        <SubDetails flex={true} items={[
            { text: city, iconName: "fas fa-map-marker-alt", toolTip: "Store Location" },
          ]} />
        </div>
      </section>
    </div>
  );
};

export default MerchantInfo;
