import React from "react";
import { components } from "react-select";
import { ControlProps } from "react-select/lib/components/Control";
import { InputProps } from "react-select/lib/components/Input";
import { OptionProps } from "react-select/lib/components/Option";
import { ThemeContext } from "styled-components";

import { Icon, InputLabel, Select } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

export const InputSelect: React.FC<IProps> = ({
  label,
  inputProps,
  ...props
}: IProps) => {
  // const customTheme = React.useContext(ThemeContext);
  // const secondaryColor = customTheme.colors.lightFont;
  // const borderColor = customTheme.input.border;

  const customStyles = {
    control: (provided: any, state: { menuIsOpen: any }) => ({
      ...provided,
      ":hover": {
        // border: `1px solid ${borderColor}`,
        border: `1px solid #767676`,
        // outlineColor: borderColor,
        // outlineStyle: "solid",
        // outlineWidth: "1px",
      },
      background: "none",
      border: state.menuIsOpen
        ? 
        // `1px solid ${secondaryColor}`
        `1px solid #cccccc`
        :
        `1px solid #cccccc`,
        //  `1px solid ${borderColor}`,
      borderRadius: 5,
      boxShadow: 0,
      boxSizing: "border-box",
      margin: 0,
      // outline: state.menuIsOpen ? `1px solid ${secondaryColor}` : "",
      padding: "0.55rem 2rem 0.55rem 1rem",
    }),
    valueContainer: (provided: any) => {
      return {
        ...provided,
        padding: 0,
      };
    },
    menu: (provided: any)=> {
      return {
        ...provided,
        zIndex: 2,
      }
    },
  };

  const customComponents = {
    Control: (props: ControlProps<any>) => {
      const customTheme = React.useContext(ThemeContext);
      return (
        <>
          <components.Control
            data-test="inputSelect"
            {...{ customTheme, ...props }}
          />
          {
            <InputLabel
              labelBackground={customTheme.colors.light}
              active={props.selectProps.menuIsOpen || props.hasValue}
            >
              <S.InputWrapper>
              {label} 
              {/* {googleImg &&
              <S.GoogleImage>
              <img src={googleImg} alt="googleImg"/>
              </S.GoogleImage>
              } */}
              </S.InputWrapper>
            </InputLabel>
          }
        </>
      );
    },
    IndicatorSeparator: () => null,
    IndicatorsContainer: ({ selectProps, hasValue, clearValue }: any) => {
      const showClearIndicator =
        selectProps.isClearable ||
        (selectProps.isMulti && selectProps.isClearable === undefined);

      if (showClearIndicator && hasValue) {
        return (
          <S.ClearIndicator id={'#clear'} onClick={clearValue}>
            <Icon name="select_x" size={14} />
          </S.ClearIndicator>
        );
      } else {
        // Boolean to string conversion done due to
        // https://github.com/styled-components/styled-components/issues/1198
        return (
          <S.DropdownIndicator rotate={String(selectProps.menuIsOpen)}>
            <Icon name={label === "Address Pin (Click and Type to Search)" ? 'location':'select_arrow'} viewBoxValue={"0 0 36 36"} size={16} />
          </S.DropdownIndicator>
        );
      }
    },
    Input: (props: InputProps) => {
      return <components.Input {...{ ...props, ...inputProps }} />;
    },
    Option: (props: OptionProps<any>) => {
      const customTheme = React.useContext(ThemeContext);
      return <components.Option {...{ customTheme, ...props }} />;
    },
  };

  return (
    <Select
      customComponents={customComponents}
      {...props}
      customStyles={customStyles}
    />
  );
};
