import {media, styled} from '@styles';

export const Wrapper = styled.div<{
	showShipping: boolean;
	showDiscount: boolean;
}>`
	display: grid;
	font-size: ${(props) => props.theme.typography.h4FontSize};
	grid-template-areas:
		'. subtotalText subtotalPrice'
		${(props) =>
			props.showShipping
				? `". shippingText shippingPrice"`
				: `". shippingText shippingPrice"`}
		${(props) => props.showDiscount && `". discountText discountPrice"`}
		'. totalText totalPrice';
	grid-template-columns: 3fr 1fr 1.26fr;
	grid-gap: 1rem;
	padding: 2rem 0;
	${(props) => media.mediumScreen`
    grid-template-areas:
      "subtotalText . subtotalPrice"
      ${(props) =>
				props.showShipping
					? `"shippingText . shippingPrice"`
					: `"shippingText . shippingPrice"`}
      ${props.showDiscount && `"discountText . discountPrice"`}
      "totalText . totalPrice";
    grid-template-columns: 1fr 1fr 3fr;
  `}
	border-bottom: 1px solid rgba(50, 50, 50, 0.1);
`;

export const SubtotalText = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	grid-area: subtotalText;
`;
export const SubtotalPrice = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	grid-area: subtotalPrice;

	${media.mediumScreen`
    text-align: right;
  `}
`;

export const ShippingText = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	grid-area: shippingText;
`;
export const ShippingPrice = styled.div`
	grid-area: shippingPrice;
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	${media.mediumScreen`
    text-align: right;
  `}
`;

export const DiscountText = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	grid-area: discountText;
`;
export const DiscountPrice = styled.div`
	font-family: ${(props) => props.theme.typography.baseFontFamily};
	grid-area: discountPrice;
	${media.mediumScreen`
    text-align: right;
  `}
`;

export const TotalText = styled.div`
	grid-area: totalText;
`;
export const TotalPrice = styled.div`
	grid-area: totalPrice;
	${media.mediumScreen`
    text-align: right;
  `}
`;
