import {styled} from '@styles';

export const Checkbox = styled.div`
	width: 100%;
	margin-bottom: 1.25rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	position: relative;
	margin-left: -2px;
	font-size: 18px;
`;

export const Label = styled.label<{
	isChecklist: boolean;
}>`
	display: flex;
	cursor: pointer;
	justify-content: flex-start;
	align-items: center;
	padding-right: 1.25rem;
	input[type='checkbox'] {
		display: none;
		position: relative;
		right: -999em;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 22px;
		width: 22px;

		span {
			border: 1px solid ${(props) => props.theme.colors.secondary};
			width: 20px;
			height: 20px;
			display: inline-block;
		}

		${Checkbox}:hover & {
			border: 1px solid;
			border-color: ${(props) => props.theme.colors.secondaryOverlay};
			background-color: ${(props) => props.theme.colors.secondaryOverlay};
		}

		:focus {
			border: 1px solid;
			outline: none;
			border-color: ${(props) => props.theme.colors.secondaryOverlayDark};
			background-color: ${(props) => props.theme.colors.secondaryOverlayDark};
		}
	}

	input:checked + div {
		span {
			border-radius: 3px;
			background-clip: content-box;
			background: url(https://staging.jrmall.ph/images/check-icon.svg);
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
`;
