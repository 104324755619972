import * as React from "react";

import { MetaWrapper, NotFound } from "src/components";
import Page from './Page';
import { TypedMerchantBrandQuery } from "./queries";
import { RouteComponentProps } from "react-router";

const View: React.FC<RouteComponentProps<{ slug: string }>> = ({ match }) => (
    <TypedMerchantBrandQuery 
        errorPolicy="all"
        loaderFull
        variables={{
            slug: match.params.slug,
            pageSize: 100,
            after: ""
        }}
        key={match.params.slug}
    >
        {({ data, loading, loadMore }) => {
            const handleLoadMore = () => {
                loadMore(
                    (prev, next) => ({
                        ...prev,
                        merchantBrand: {
                            ...prev?.merchantBrand,
                            merchants: {
                                ...prev?.merchantBrand?.merchants,
                                edges: [...prev.merchantBrand?.merchants.edges, ...next.merchantBrand?.merchants.edges],
                                pageInfo: next.merchantBrand?.merchants.pageInfo,
                            }
                        }
                    }),
                    { after: data.merchantBrand?.merchants.pageInfo.endCursor }
                );
            }
            const { merchantBrand } = data || {};
            const { slug } = merchantBrand || {};
            const breadcrumbs = [];

            const categories = merchantBrand?.merchantBrandMerchantCategoryAssignment?.edges.map(item => {
                if (item?.node?.merchantCategory?.name) {
                    return item?.node?.merchantCategory?.name
                }
            });

            if (slug) {
  
                // Store url
                breadcrumbs.push({
                  link: `/brand/${slug}`,
                  value: 'Select Branch',
                });
            };
        
            if (merchantBrand) {
                return (
                    <MetaWrapper meta={{}}>
                        <Page 
                            categories={categories}
                            merchantBrand={merchantBrand}
                            totalCount={merchantBrand?.merchants?.totalCount}
                            canLoadMore={merchantBrand?.merchants?.pageInfo?.hasNextPage}
                            loading={loading}
                            onLoadMore={handleLoadMore}
                            breadcrumbs={breadcrumbs}
                        />
                    </MetaWrapper>
                );
            } else {
                return <NotFound />;
            }
    }}
    </TypedMerchantBrandQuery>
);

export default View;