import React from "react";
import { TaxedMoney } from "@temp/@next/components/containers";
import DefaultPlaceHolder from "src/images/merchant-placeholder-avatar.jpg"
import { useLayout } from "@temp/layout/hooks/useLayout";
import { convertMinutesToHoursDaysString } from "@temp/helpers";
import preparationIcon from '../../../../images/preparation-icon.svg';
import ReactSVG from "react-svg";
export interface ProductInterface {
  price?: string;
  productName?: string;
  variant?: string;
  quantity?: number;
  imgSrc?: string;
  leadTime?: number;
  remove?: (variantId: string) => void;
  id?: string;
  productPrice?: {
    gross: {
      amount: number;
      currency: string;
    };
    net: {
      amount: number;
      currency: string;
    };
  };
}

const Product: React.FC<ProductInterface> = ({
  price = "[price]",
  productName = "[productName]",
  variant = "[variant]",
  quantity = "[quantity]",
  leadTime = "[quantity]",
  imgSrc,
  remove,
  id,
  productPrice,
}) => {
  const productLeadTime = convertMinutesToHoursDaysString(leadTime) || "0 mins";
  // const addons = [{id: 'test1', name: 'test1'} , {id: 'test2', name: 'test2'}];
  const { setCheckoutSchedules } = useLayout();
  return (
    <div className="cart__list_product d-flex mb-4">
      <img
        alt="Product image"
        srcSet={imgSrc || DefaultPlaceHolder}
      />
      <div className="product-container">
        <div className="product-wrapper">
          <div>
        <p className="product-name">{productName}</p>
        <p>{variant}</p>
        </div>
        {/* <button
          onClick={() => remove(id)}
          className="btn-transparent text-secondary small product-button"
        >
          Remove
        </button> */}
        </div>
        <div className="text-right product-price">
        <p>
          <TaxedMoney taxedMoney={productPrice} />
        </p>
        <article>Qty: {quantity}</article>
        <div className="product-preparation">
          <ReactSVG path={preparationIcon} />
          <span>{productLeadTime}</span>
        </div>
        <i onClick={() => {remove(id); setCheckoutSchedules([])}} className="py-2 fas fa-trash-alt product-remove"/>
        </div>
      </div>
    </div>
  );
};

export default Product;
