import {
  mediumScreen,
  smallScreen,
} from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import NukaCarousel, { CarouselProps } from "nuka-carousel";
import * as React from "react";
import Media from "react-media";
import ReactSVG from "react-svg";

// import arrowImg from "../../images/JR-Mall-Left-Arrow.svg";
// import arrowImgWhite from "../../images/JR-Mall-Left-Arrow-white.svg"
import arrowImgBlack from "../../images/jrmall-carousel-action.svg"

interface CarouselType extends CarouselProps {
  children: React.ReactNode;
  noControl?: boolean;
  minItemPerSlide?: number;
  maxItemPerSlide?: number;
  isHomepage?: boolean;
}

const Carousel: React.FC<CarouselType> = ({ noControl, children, isHomepage, maxItemPerSlide, minItemPerSlide, ...rest }) => {
  const settings = {
    className: "carousel",
    renderBottomCenterControls: () => null,
    renderCenterLeftControls: ({ previousSlide, currentSlide }) =>
      currentSlide !== 0 ? (
        <div
          onClick={previousSlide}
          className={noControl ? 'd-none': isHomepage ? 'carousel__control carousel__control--leftHome' :'carousel__control carousel__control--left'}
        >
          <ReactSVG path={arrowImgBlack} />
        </div>
      ) : null,
    renderCenterRightControls: ({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToShow,
    }) => {
      const rightControl = (
        <div
          onClick={nextSlide}
          className={noControl ? 'd-none': isHomepage ? 'carousel__control carousel__control--rightHome' : 'carousel__control carousel__control--right'}
        >
          <ReactSVG path={arrowImgBlack} />
        </div>
      );
      
      // if attribute named "maxItemPerSlide" exists we do the logic below, but if not we do the old logic
      if (maxItemPerSlide) {
        if (maxItemPerSlide < slideCount && slideCount - slidesToShow !== currentSlide) {
          return rightControl;
        }
      } else {
        if (slideCount - slidesToShow !== currentSlide) {
          return rightControl;
        }
      }

      return null;
    },
    ...rest,
  };
  const carousel = (slides: number) => (
    <NukaCarousel slidesToShow={slides} slidesToScroll={slides} {...settings}>
      {children}
    </NukaCarousel>
  );

  const defaultMaxItemPerSlide = 4;
  return (
    <Media query={{ maxWidth: smallScreen }}>
      {matches =>
        matches ? (
          carousel(minItemPerSlide || 1)
        ) : (
          <Media query={{ maxWidth: mediumScreen }}>
            {matches => carousel(matches ? (maxItemPerSlide ? maxItemPerSlide - 1 : defaultMaxItemPerSlide - 1) : (maxItemPerSlide || defaultMaxItemPerSlide))}
          </Media>
        )
      }
    </Media>
  );
};

export default Carousel;
