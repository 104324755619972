import * as React from 'react';
import {Link} from 'react-router-dom';

import {Carousel, ProductListItem} from '..';
import {generateProductUrl, maybe} from '../../core/utils';
import {TypedFeaturedProductsQuery} from './queries';
import Media from 'react-responsive';
import {smallScreen} from '@styles/constants';
import './scss/index.scss';

const SMALL_SCREEN = 541;

interface ProductsFeaturedProps {
	title?: string;
}

const ProductsFeatured: React.FC<ProductsFeaturedProps> = ({title}) => {
	return (
		<>
			<div className="products-featured__productsContainer">
				<h4 className="products-featured__text">{title}</h4>
				<Link to="/" className="products-featured__link">
					View All
				</Link>
			</div>
			<hr></hr>
			<TypedFeaturedProductsQuery displayError={false}>
				{({data}) => {
					const products = maybe(
						() => data.shop.homepageCollection.products.edges,
						[],
					);

					if (products.length) {
						return (
							<>
								<div className="home-page__categories__productsContainer">
									<h4 className="home-page__categories__text">{title}</h4>
									<Link
										to="/store/metrosecurity-ph/"
										className="home-page__categories__link"
									>
										View All
									</Link>
								</div>
								<hr></hr>
								<div className="products-featured">
									<Media maxWidth={smallScreen}>
										<Carousel
											slidesToScroll="auto"
											slidesToShow={2.1}
											transitionMode="scroll"
										>
											{products.map(({node: product}) => (
												<Link
													to={generateProductUrl(product.id, product.name)}
													key={product.id}
												>
													<ProductListItem product={product} />
												</Link>
											))}
										</Carousel>
									</Media>
									<Media minWidth={SMALL_SCREEN}>
										<Carousel isHomepage maxItemPerSlide={5} dragging>
											{products.map(({node: product}) => (
												<Link
													to={generateProductUrl(product.id, product.name)}
													key={product.id}
												>
													<ProductListItem product={product} />
												</Link>
											))}
										</Carousel>
									</Media>
								</div>
							</>
						);
					} else {
						return null;
					}
				}}
			</TypedFeaturedProductsQuery>
		</>
	);
};

ProductsFeatured.defaultProps = {
	title: 'Best of the Best',
};

export default ProductsFeatured;
