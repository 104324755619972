import { TaxedMoney } from "@temp/@next/components/containers";
import { ITaxedMoney } from "@temp/@next/types";
import { OrderDetail_deliveryQuotation } from "@temp/vendors/saleor/sdk/lib/fragments/gqlTypes/OrderDetail";
import React from "react";

interface OrderSummaryProps {
  paymentTransactionType: any;
  lines: any;
  total: ITaxedMoney;
  subTotal: ITaxedMoney;
  deliveryQuotation?: OrderDetail_deliveryQuotation | null;
}

const OrderSummary: React.FC<Partial<OrderSummaryProps>> = ({
  paymentTransactionType,
  lines, total, subTotal, deliveryQuotation
}) => {

  const shippingPrice:ITaxedMoney = {
    gross: deliveryQuotation?.totalFeeAmount,
    net: deliveryQuotation?.totalFeeAmount
  };

  const itemCount = lines.map(item=> 
    {
        let count = 0;
        count = count + item.quantity;
        return count;
    });

  const qty = itemCount.reduce((a,b) =>a+b,0);

  return (
    <div className="order-details__orderSummary">
      <div className="order-details__orderSummary__text">
        <h3>Order Summary</h3>
      </div>
    <table>
      <tbody>
      <tr>
        <td>Subtotal</td>
        <td><TaxedMoney taxedMoney={subTotal}/></td>
      </tr>
      <tr>
        <td>Delivery Fee</td>
        <td><TaxedMoney taxedMoney={shippingPrice}/></td>
      </tr>
      <tr style={{borderTop: '1px solid #E3E3E3', borderBottom: '1px solid #E3E3E3'}}>
        <td>Total Payment</td>
        <td><TaxedMoney taxedMoney={total}/></td>
      </tr>
      <tr>
        <td>Qty</td>
        <td>{qty}</td>
      </tr>
      </tbody>
    </table>
    </div>
  );
};

export default OrderSummary;