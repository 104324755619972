// import {useLayout} from '@temp/layout/hooks/useLayout';
import React, {useState} from 'react';
import './index.scss';
import {Redirect} from 'react-router-dom';
import {signUp} from '@temp/routes/paths';
import { useLayout } from '@temp/layout/hooks/useLayout';
import { LoginForm, OverlayContext, OverlayType, OverlayTheme } from '@temp/components';

const SignIn = () => {
	// useLayout({
	// 	showNavbar: false,
	// 	showAddressbar: false,
	// 	showScreenTitle: true,
	// 	showCategorybar: false,
	// 	screenTitle: 'JR Mall Account',
	// });

	const {setShowCategorybar} = useLayout();
	setShowCategorybar(false);

	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	if (redirectToLogin) {
		return <Redirect to={signUp} />;
	}

	return (
		<OverlayContext.Consumer>
		{(overlayContext) => {
			const {show, hide} = overlayContext;
		return (
		<div className="main-container bg-white h-100 sign-in">
			<h3 className="sign-in__title">Login</h3>
			<p className="sign-in__subTitle">
				Don't have an account yet?
				<button
					onClick={() => setRedirectToLogin(true)}
					className="sign-in__link bg-none border-0"
					data-test="registerTab"
				>
					Sign Up
				</button>
			</p>
			<LoginForm onClick={() => show(OverlayType.password, OverlayTheme.right)} hide={hide} />
		</div>
		);}}
		</OverlayContext.Consumer>
	);
};

export default SignIn;
