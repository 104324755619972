import moment from 'moment';
import SelectBranchCard from '@temp/components/SelectBranchCard';

// import {useLayout} from '@temp/layout/mobile/hooks/useLayout';
import { useLayout } from '@temp/layout/hooks/useLayout';
import { Button, Loader } from "@components/atoms";

import React, { FC } from 'react';
import { MerchantBrandData } from "./gqlTypes/MerchantBrand";
import {
	convertMinutesToHoursDaysString,
	getDistanceFromLatLonInKm
} from "src/helpers";
import { formatStoreOperatingDays } from "src/core/checkoutScheduleUtils"
import * as S from "./styles";
import './index.scss';
import { mediumScreen, smallScreen } from '@temp/@next/globalStyles/constants';
import { useMediaQuery } from 'react-responsive';
import merchantLogoPlaceholder from '../../../images/merchant-placeholder-img-background.png';
import { Breadcrumb, Breadcrumbs } from '@temp/components';
import Icon from './Icon';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router';
import NoProduct from '@temp/views/Store/StoreProducts/NoProduct';
interface BranchSelectProps {
	categories?: string[];

	totalCount?: number;
	merchantBrand: MerchantBrandData | null;
	canLoadMore?: boolean;
	loading?: boolean;
	onLoadMore?: () => void;
	breadcrumbs?: Breadcrumb[];
}

const formatTime = (valueTime) => moment(valueTime, "HH:mm:ss").format("h:mma");

const BranchSelect: FC<BranchSelectProps> = ({
	categories = [],
	totalCount = 0,
	merchantBrand,
	canLoadMore,
	loading,
	breadcrumbs,
	onLoadMore,
}) => {
	const {
		setShowCategorybar,
		useUserLocation
	} = useLayout();

	const userLocation = useUserLocation();
	const history = useHistory();

	setShowCategorybar(true);
	const merchants = (merchantBrand?.merchants.edges || []).map(item => {
		const merchant = item.node;
		const openHr = merchant?.storeHourStart ? formatTime(merchant?.storeHourStart) : "--";
		const closeHr = merchant?.storeHourStart ? formatTime(merchant?.storeHourEnd) : "--";
		const operatingDays = merchant?.operatingDays ? formatStoreOperatingDays(merchant?.operatingDays) : "--";
		const distanceKM = getDistanceFromLatLonInKm(
			userLocation?.latitude,
			userLocation?.longitude,
			merchant?.primaryAddress?.latitude,
			merchant?.primaryAddress?.longitude
		);

		return {
			...merchant,
			openHr,
			closeHr,
			operatingDays,
			distanceKM
		};
	})
		.sort((mer1, mer2) => mer1.distanceKM - mer2.distanceKM);

	const isDesktop = useMediaQuery({ minWidth: mediumScreen });
	const isMobile = useMediaQuery({ maxWidth: smallScreen });
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });

	const getCategories = () => {
		const items = [];
		categories?.map((item) => {
			if (item) {
				items.push(item);
			}
		});
		return items?.join(', ');
	}

	const outOfCoverageLength = merchants.filter(item => item.distanceKM > 100)?.length || 0;

	return (
		<div className="branch-select">
			<div
				style={
					{
						backgroundColor: '#fff',
						backgroundImage: `url(${merchantBrand?.banner?.url || merchantLogoPlaceholder})`,
						backgroundPosition: "center center",
						backgroundSize: "cover",
						height: `${isDesktop ? '450px' : '250px'}`,
						position: 'relative',
					}
				}>
				{isTablet && <div onClick={() => history.goBack()} className="branch-select__back">
					<Icon style={{ backgroundColor: '#76aad3' }} IconComponent={ChevronLeft} className="pr-1" />
				</div>}
			</div>
			<div className="main-container py-4">
				{isDesktop && <Breadcrumbs breadcrumbs={breadcrumbs} />}
				<div className="branch-select__container">
					<p className="branch-select__brandName">{merchantBrand?.name || ""}</p>
					<p className="branch-select__categories">{getCategories()}</p>

				</div>
			</div>
			<div className={!isMobile && 'main-container'}>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<p className="branch-select__selectText">Select a branch</p>
					<p className="branch-select__branchCount">{totalCount} branch</p>
				</div>
				{totalCount ?
					<>
						<div className="branch-select__grid">
							{merchants.filter(item => item.distanceKM <= 100).map(merchant => {
								const {
									openHr,
									closeHr,
									operatingDays,
									distanceKM
								} = merchant || {};

								return (
									<>
										<SelectBranchCard
											slug={merchant?.slug}
											storeName={merchant?.name}
											timeToPrepare={convertMinutesToHoursDaysString(merchant?.leadTime)}
											operatingHours={`${openHr} - ${closeHr}`}
											operatingDays={operatingDays}
											distance={distanceKM}
										/>
									</>
								)
							})}
						</div>
						{outOfCoverageLength ?
							<div className="mt-5">
								<p className="branch-select__selectText mb-3">Out of Coverage</p>
								<div className="branch-select__grid">
									{merchants.filter(item => item.distanceKM > 100).map(merchant => {
										const {
											openHr,
											closeHr,
											operatingDays,
											distanceKM
										} = merchant || {};

										return (
											<>
												<SelectBranchCard
													slug={merchant?.slug}
													storeName={merchant?.name}
													timeToPrepare={convertMinutesToHoursDaysString(merchant?.leadTime)}
													operatingHours={`${openHr} - ${closeHr}`}
													operatingDays={operatingDays}
													distance={distanceKM}
												/>
											</>
										)
									})}
								</div>
							</div>
							: null}
					</>
					:
					<NoProduct />
				}
			</div>
			<S.Loader>
				{loading ? (
					<Loader />
				) : (
					canLoadMore && (
						<Button
							testingContext="loadMoreProductsButton"
							color="secondary"
							onClick={onLoadMore}
						>
							<span>More +</span>
						</Button>
					)
				)}
			</S.Loader>
		</div>
	);
};

export default BranchSelect;
