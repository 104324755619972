import * as React from "react";
import { 
    TypedStoreDetailsBySlugQuery, 
} from "./queries";
import { StoreDetailsMerchantBySlug } from "./gqlTypes/StoreDetails";

interface ResultProps {
    merchant: StoreDetailsMerchantBySlug;
    merchantProductLoading: boolean;
    merchantProductLoadMore: () => void;
    merchantProductHasNextPage: boolean;
}

interface Props {
    merchantSlug: string,
    merchantProductPageSize: number,
    merchantProductAfter: string,
    merchantCollectionsPageSize: number,
    merchantCollectionsProductPageSize: number,
    children: (
        result: ResultProps
    ) => React.ReactNode;
    onMerchantCompleted?: (data: any) => void
}

const QueryAdapter: React.FC<Props> = ({
    children,
    merchantSlug,
    merchantProductPageSize,
    merchantProductAfter,
    merchantCollectionsPageSize,
    merchantCollectionsProductPageSize,
    onMerchantCompleted
}) => (
    <TypedStoreDetailsBySlugQuery 
        loaderFull
        errorPolicy="all"
        variables={{
            slug: merchantSlug,
            productPageSize: merchantProductPageSize || 10,
            productAfter: merchantProductAfter || '',
            collectionsPageSize: merchantCollectionsPageSize || 20,
            collectionsProductPageSize: merchantCollectionsProductPageSize || 20
        }}
        onCompleted={data => {
            if (onMerchantCompleted) {
                onMerchantCompleted(data);
            }
        }}
        key={merchantSlug}
    >
        {({ data: merchantData, loading: merchantProductLoading, loadMore: productLoadMore }) => {
                const { merchantBySlug: merchant } = merchantData || {};
                const merchantProductLoadMore = () => {
                    productLoadMore(
                        (prev, next) => ({
                            ...prev,
                            merchantBySlug: {
                                ...prev?.merchantBySlug,
                                products: {
                                    ...prev?.merchantBySlug?.products,
                                    edges: [...prev.merchantBySlug?.products.edges, ...next.merchantBySlug?.products.edges],
                                    pageInfo: next.merchantBySlug?.products.pageInfo,
                                }
                            }
                        }),
                        { productAfter: merchantData.merchantBySlug?.products?.pageInfo?.endCursor }
                    );
                }

                return children({ 
                    merchant, 
                    merchantProductLoading, 
                    merchantProductHasNextPage: merchantData.merchantBySlug?.products?.pageInfo?.hasNextPage,
                    merchantProductLoadMore
                })
        }}
    </TypedStoreDetailsBySlugQuery>
);

export default QueryAdapter;