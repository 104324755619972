import React, {
  forwardRef,
  RefForwardingComponent,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { CheckoutAddress } from "@components/organisms";
import { useCart, useCheckout, useUserDetails } from "@saleor/sdk";
import { ShopContext } from "@temp/components/ShopProvider/context";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IAddress, IFormError } from "@types";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { useLayout } from '@temp/layout/hooks/useLayout';
import { useAlert } from "react-alert";
import { validateEmail, catchInActiveCheckoutError, setCheckoutNotes, getDistanceFromLatLonInKm } from "src/helpers";
import { DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT } from "src/constants";
import { CheckoutAddressModal } from "../CheckoutAddressModal/CheckoutAddressModal";
import { ICheckoutModelLine, ICheckoutModelLineVariant } from "@temp/vendors/saleor/sdk/lib/helpers";
export interface ICheckoutAddressSubpageHandles {
  submitAddress: () => void;
}

interface IProps extends RouteComponentProps<any> {
  changeSubmitProgress: (submitInProgress: boolean) => void;
  setIsDisabled?: any;
}

const CheckoutAddressSubpageWithRef: RefForwardingComponent<
  ICheckoutAddressSubpageHandles,
  IProps
> = ({ changeSubmitProgress, setIsDisabled, ...props }: IProps, ref) => {
  const checkoutAddressFormId = "address-form";
  const checkoutAddressFormRef = useRef<HTMLFormElement>(null);
  const checkoutNewAddressFormId = "new-address-form";
  const alert = useAlert();
  const { useUserLocation } = useLayout();
  const { items } = useCart();
  const history = useHistory();
  const { countries } = useContext(ShopContext);
  const { data: user } = useUserDetails();
  const {
    checkout,
    setShippingAddress,
    selectedShippingAddressId,
  } = useCheckout();

  const [errors, setErrors] = useState<IFormError[]>([]);
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
  const [invalidMerchantData, setInvalidMerchantData]: any = useState([]);


  // @ts-ignore
  const userLocation = useUserLocation();

  useImperativeHandle(ref, () => ({
    submitAddress: () => {
      if (user && selectedShippingAddressId) {
        checkoutAddressFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        // TODO validate form
        checkoutAddressFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    },
  }));

  const showErrorAlert = (title: string, message: string, field: string | null) => {
    if (field) {
      setErrors([{ field, message }]);
    }

    alert.error({
      content: message,
      title,
    }, {
      timeout: 2000, // custom timeout just for this one alert
      // onClose: () => {}, // callback that will be executed after this alert is removed
      // onOpen: () => {}, // callback that will be executed after this alert open
    })
  };

  const navAddressBar = userLocation?.address1 === DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT
    ? null
    : {
      streetAddress1: userLocation?.address1,
      placeId: userLocation?.placeId
    };

  const checkoutShippingAddress = checkout?.shippingAddress
    ? {
      ...checkout?.shippingAddress,
      phone: checkout?.shippingAddress?.phone || undefined,
    }
    : navAddressBar;

  const getMerchantDistances = (latitude: number, longitude: number) => items?.map((item: ICheckoutModelLine) => {
    const variant: ICheckoutModelLineVariant = item?.variant || null;
    const address = variant?.product?.merchant?.warehouses?.edges[0]?.node?.address;
    const distanceRadius = getDistanceFromLatLonInKm(
      address?.latitude || 0,
      address?.longitude || 0,
      latitude || 0,
      longitude || 0,
    ) || 0;
    return { variant, distanceRadius };
  })?.filter(item => item !== undefined);

  const handleSetShippingAddress = async (
    address?: IAddress,
    email?: string,
    userAddressId?: string
  ) => {
    const REQUIRED = "Required!";
    if (!address) {
      showErrorAlert(REQUIRED, "Please provide delivery address.", "");
      return;
    }

    // Shipping email validation
    let shippingEmail = null;
    if (validateEmail(user?.email)) {
      shippingEmail = user?.email;
    } else if (validateEmail(address?.email)) {
      shippingEmail = address?.email;
    } else if (validateEmail(email)) {
      shippingEmail = email;
    }

    if (!shippingEmail) {
      showErrorAlert(REQUIRED, "Please provide email address.", "email");
      return;
    }

    else if (!address?.placeId && (!address?.longitude || !address?.latitude)) {
      if (userLocation?.placeId && userLocation?.placeId !== "") {
        address.placeId = userLocation?.placeId
        address.country = {
          code: "PH",
          country: "Philippines"
        }
      } else {
        showErrorAlert(REQUIRED, "Please select an address. e.g. Landmark, Bldg, Street..", "streetAddress1");
        return;
      }
    }

    else if (!address?.phone || address.phone === "") {
      showErrorAlert(REQUIRED, "Please provide phone number.", "phone");
      return;
    }

    changeSubmitProgress(true);
    const { dataError, data } = await setShippingAddress(
      {
        ...address,
        id: userAddressId,
      },
      shippingEmail
    );
    const merchantDistances = getMerchantDistances(data?.shippingAddress?.latitude || 0, data?.shippingAddress?.longitude || 0);
    const errors = dataError?.error;

    if (merchantDistances?.length && merchantDistances?.some(item => item.distanceRadius > 100)) {
      setInvalidMerchantData(merchantDistances.sort((a, b) => b?.distanceRadius - a?.distanceRadius) || [])
      setErrors([{ field: 'autocompleteError', message: '' }]);
      setShowInvalidAddressModal(true);
      changeSubmitProgress(false);
      setIsDisabled(true);
      return;
    }
    else {
      setIsDisabled(false);
    }

    changeSubmitProgress(false);
    if (errors) {
      setErrors(errors);
      showErrorAlert(REQUIRED, (errors.length && errors.length > 0 && errors[0].message) || "An error occurs upon saving shipping details.", null);

      // Check if current checkout is IN-ACTIVE
      catchInActiveCheckoutError(errors)
    } else {
      setErrors([]);
      setCheckoutNotes([]); // Clear checkout notes
      history.push(CHECKOUT_STEPS[0].nextStepLink);
    }


  };

  const userAdresses = user?.addresses
    ?.filter(filterNotEmptyArrayItems)
    .map(address => ({
      address: {
        ...address,
        isDefaultBillingAddress: address.isDefaultBillingAddress || false,
        isDefaultShippingAddress: address.isDefaultShippingAddress || false,
        phone: address.phone || undefined,
      },
      id: address?.id || "",
      onSelect: () => null,
    }));

  let defaultShippingAddress = user?.addresses ? user?.addresses.find(address => address?.isDefaultShippingAddress) : null;
  if (!defaultShippingAddress) {
    defaultShippingAddress = (user?.addresses || []).find(address => address?.latitude === userLocation?.latitude && address?.longitude === userLocation?.longitude);
  }

  return (
    <>
      <CheckoutAddress
        {...props}
        errors={errors}
        formId={checkoutAddressFormId}
        formRef={checkoutAddressFormRef}
        // @ts-ignore
        checkoutAddress={checkoutShippingAddress}
        email={checkout?.email}
        userAddresses={userAdresses}
        selectedUserAddressId={selectedShippingAddressId || defaultShippingAddress?.id}
        countries={countries}
        userId={user?.id}
        newAddressFormId={checkoutNewAddressFormId}
        setShippingAddress={handleSetShippingAddress}
      />
      {showInvalidAddressModal &&
        <CheckoutAddressModal
          invalidMerchantData={invalidMerchantData}
          setShowInvalidAddressModal={setShowInvalidAddressModal}
        />
      }
    </>
  );
};

const CheckoutAddressSubpage = forwardRef(CheckoutAddressSubpageWithRef);

export { CheckoutAddressSubpage };
