import React from "react";
import { Steps } from 'antd';
import moment from "moment";
import { capitalizeFirstLetter } from "src/helpers";

interface OrderTimelineProps {
  merchantName: string;
  orderReference: string;
  customerStatusHistory: any[];
}

const OrderTimeline: React.FC<Partial<OrderTimelineProps>> = ({
  merchantName,
  orderReference,
  customerStatusHistory = []
}) => {
  let activeStep = 0;
  customerStatusHistory = customerStatusHistory
                        .filter(data => !(data.status === "CANCELLED"))
                        .map((data, ix) => {
                          if (data.date) {
                            activeStep = ix
                          }
                          const status = data.status === "UNPAID" ? "PENDING" : data.status;
                          return {
                            date: data.date,
                            status: capitalizeFirstLetter(status).replace("_", " "),
                          }
                        });

  const { Step } = Steps;

  const getStepDetails = (date) => {
    if (date) {
      const _date = moment(date).format("MMM DD");
      const day = moment(date).format("ddd");
      const time = moment(date).format("LT");
  
      return (
        <>
          <p>{_date} {day}</p>
          <span>{time}</span>
        </>
      );
    }

    return null
  }
  
  return (
    <div className="order-details__timeline">
      <div className="order-details__timeline__text">
      <h3>{merchantName}</h3>
        <p>Order Ref. No. <span>{orderReference}</span></p>
      </div>

      <Steps current={activeStep} size="small">
        {
          customerStatusHistory.map(data => {
            const { status, date } = data;
            return <Step title={status} description={getStepDetails(date)} disabled />
          })
        }
        {/* <Step title="PAID" description={<><span>Jan 3 (Sun)</span><span>13:30</span></>} />
        <Step title="PROCESSING" description={<><span>Jan 3 (Sun)</span><span>13:30</span></>} />
        <Step title="IN TRANSIT" description={<><span>Jan 3 (Sun)</span><span>13:30</span></>} />
        <Step title="DELIVERED" description={<><span>Jan 3 (Sun)</span><span>13:30</span></>} /> */}
      </Steps>
    </div>
  );
};

export default OrderTimeline;