import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {productPricingFragment, priceFragment} from 'src/views/Product/queries';
import {
	ProductDetails,
	ProductDetailsVariables,
} from './gqlTypes/ProductDetails';

export const basicProductFragment = gql`
	fragment BasicProductFields on Product {
		id
		name
		leadTime
		thumbnail {
			url
			alt
		}
		thumbnail2x: thumbnail(size: 510) {
			url
		}
	}
`;
export const productVariantFragment = gql`
	${priceFragment}
	fragment ProductVariantFields on ProductVariant {
		id
		sku
		name
		isAvailable
		images {
			id
			url
			alt
		}
		pricing {
			onSale
			priceUndiscounted {
				...Price
			}
			price {
				...Price
			}
		}
		attributes {
			attribute {
				id
				name
				slug
			}
			values {
				id
				name
				value: name
				sortOrder
			}
		}
	}
`;
export const productDetailsQuery = gql`
	${basicProductFragment}
	${productVariantFragment}
	${productPricingFragment}
	query ProductDetails($id: ID!) {
		product(id: $id) {
			...BasicProductFields
			...ProductPricingField
			descriptionJson
			merchant {
				id
				name
				storeTitle
			}
			category {
				id
				name
			}
			images {
				id
				url
			}
			variants {
				...ProductVariantFields
			}
			seoDescription
			seoTitle
			isAvailable
		}
	}
`;
export const TypedProductDetailsQuery = TypedQuery<
	ProductDetails,
	ProductDetailsVariables
>(productDetailsQuery);
