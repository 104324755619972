import gql from 'graphql-tag';

export const fragementPrice = gql`
	fragment Price on TaxedMoney {
		gross {
			amount
			currency
		}
		net {
			amount
			currency
		}
	}
`;

export const fragementProductVariant = gql`
	${fragementPrice}
	fragment ProductVariant on ProductVariant {
		id
		name
		sku
		quantityAvailable
		isAvailable
		pricing {
			onSale
			priceUndiscounted {
				...Price
			}
			price {
				...Price
			}
		}
		attributes {
			attribute {
				id
				name
			}
			values {
				id
				name
				value: name
			}
		}
		product {
			id
			name
			leadTime
			thumbnail {
				url
				alt
			}
			thumbnail2x: thumbnail(size: 510) {
				url
			}
			productType {
				isShippingRequired
			}
			merchant {
				id
				name
				storeHourStart
				storeHourEnd
				operatingDays
				storeTitle
				storeDescription
				slug
				warehouses(first: 5) {
					edges {
						node {
							name
							companyName
							address {
								streetAddress1
								streetAddress2
								latitude
								longitude
								city
								phone
							}
						}
					}
				}
			}
		}
	}
`;

export const fragementCheckoutLine = gql`
	${fragementPrice}
	${fragementProductVariant}
	fragment CheckoutLine on CheckoutLine {
		id
		quantity
		totalPrice {
			...Price
		}
		variant {
			...ProductVariant
		}
	}
`;

export const fragementCheckout = gql`
	${fragementPrice}
	${fragementCheckoutLine}
	fragment Checkout on Checkout {
		token
		id
		email
		totalPrice {
			...Price
		}
		subtotalPrice {
			...Price
		}
		lines {
			...CheckoutLine
		}
		currentDatetime
	}
`;

export const fragmentAddress = gql`
	fragment Address on Address {
		id
		firstName
		lastName
		companyName
		streetAddress1
		streetAddress2
		city
		postalCode
		country {
			code
			country
		}
		countryArea
		phone
		isDefaultBillingAddress
		isDefaultShippingAddress
		longitude
		latitude
		email
	}
`;

export const fragmentOrderDetail = gql`
	${fragementPrice}
	${fragmentAddress}
	${fragementProductVariant}
	fragment OrderDetail on Order {
		userEmail
		paymentStatus
		paymentStatusDisplay
		status
		statusDisplay
		id
		token
		number
		shippingAddress {
			...Address
		}
		lines {
			productName
			quantity
			variant {
				...ProductVariant
			}
			unitPrice {
				currency
				...Price
			}
		}
		subtotal {
			...Price
		}
		total {
			...Price
		}
		shippingPrice {
			...Price
		}
		orderReference
		customerNote
		merchant {
			id
			name
			storeHourStart
			storeHourEnd
			operatingDays
			storeTitle
			storeDescription
			slug
			logo {
				url
			}
			warehouses(first: 1) {
				edges {
					node {
						email
						id
						name
						address {
							firstName
							lastName
							companyName
							streetAddress1
							streetAddress2
							city
							phone
							longitude
							latitude
							companyName
							postalCode
							countryArea
							email
						}
					}
				}
			}
		}
		deliveryQuotation {
			id
			totalFee
			totalKm
			totalFeeAmount {
				currency
				amount
			}
			deliveryScheduleType
			deliverySchedule
			freeShipping
			freeTotalFee
			serviceType
		}
		deliveryOrder {
			id
			deliverySchedule
			deliveryType
			driverName 
			mobileNumber 
		}
		payment {
			id
			paymentTransactionType {
				id
				code
				display
			}
		}
		customerStatusHistory {
			status
			date
		}
	}
`;
