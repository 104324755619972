import {styled} from '@styles';
import {css} from 'styled-components';

interface WrapperProps {
	readonly tileType?: 'hover' | 'addNew' | 'account';
}

export const Wrapper = styled.div<WrapperProps>`
	background-color: ${(props) => props.theme.colors.cardBg};
	border: 1px transparent solid;
	overflow: auto;
	height: 100%;
	padding: 0;
	transition: all 0.3s, color 0s, fill 0s;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: left;
	${(props) => {
		if (props.tileType === 'hover') {
			return css`
				:hover {
					cursor: pointer;
					border-color: ${props.theme.tile.hoverBorder};
				}
			`;
		}
		if (props.tileType === 'addNew') {
			return css`
				color: ${props.theme.colors.white};
				background-color: ${props.theme.colors.secondary};
				p {
					font-family: ${props.theme.typography.baseFontFamily} !important ;
				}
				align-items: center;
				justify-content: center;
				svg path {
					fill: ${props.theme.colors.white};
				}
				:hover {
					cursor: pointer;
					color: ${props.theme.colors.white};
					background-color: ${props.theme.colors.secondary};
					svg path {
						fill: ${props.theme.colors.white};
					}
				}
			`;
		}
		if (props.tileType === 'account') {
			return css`
				background-color: #fff;
				border: 1px solid ${props.theme.colors.secondary};
				> div:first-child {
					padding: 0;
				}
			`;
		}
	}};
`;

Wrapper.displayName = 'Tile';

export const Header = styled.div`
	border-bottom: 2px solid ${(props) => props.theme.tile.divisionLine};
`;

export const Content = styled.div<WrapperProps>`
	padding: 1rem 1.25rem;
`;

export const Footer = styled.div`
	margin-top: auto;
	padding: 0 1rem;
	margin-bottom: 1rem;
`;
