import React, {FC} from 'react';

interface ScreenTitleProps {
	onBack?: () => void;
}

const ScreenTitle: FC<ScreenTitleProps> = ({children, onBack}) => {
	const handleOnBack = () => {
		if (onBack) {
			return onBack();
		}

		window.history.back();
	};

	return (
		<div className="p-3 d-flex border-bottom align-items-center bg-white">
			<button
				onClick={handleOnBack}
				style={{width: '30px', height: '30x'}}
				className="flex-shrink-0 p-0"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
						clipRule="evenodd"
					/>
				</svg>
			</button>
			<div className="flex-grow-1 text-center font-weight-bold ml-n3">
				{children}
			</div>
		</div>
	);
};

export default ScreenTitle;
