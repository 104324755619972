import React from "react";

import { TaxedMoney, SubDetails } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { AddToCartButton } from "@components/atoms/AddToCartButton";
import * as S from "./styles";
import { IProps } from "./types";
import { Link } from "react-router-dom";
import { generateProductUrl, generateStoreProductUrl } from "@temp/core/utils";


export const BrandProductbyTabTile: React.FC<IProps> = ({ product, hideStoreTitle, isMerchantProduct }: IProps) => {
  const { pricing
    // , merchant
  } = product || {};

  const price =
    pricing &&
    pricing.priceRange &&
    pricing.priceRange.start
      ? pricing.priceRange.start
      : undefined;
  const generateUrl = location.pathname && location.pathname.startsWith("/store/") 
  ? generateStoreProductUrl
  : generateProductUrl;
  // const address = merchant 
  //   && merchant?.warehouses
  //   && merchant?.warehouses.edges.length
  //   && merchant?.warehouses.edges.length > 0
  //   ? merchant?.warehouses.edges[0].node.address
  //   : null;
    
  return (
    <>
    <S.Wrapper>
      <S.Image data-test="brandProductThumbnail">
      <Link style={{textDecoration: 'none'}}
            to={generateUrl(product.id, product.name)}
            key={product.id}
            >
        <Thumbnail source={product} />
      </Link>
        <AddToCartButton product={product}/>
      </S.Image>
      <S.DetailsWrapper>
      <Link style={{textDecoration: 'none'}}
            to={generateUrl(product.id, product.name)}
            key={product.id}
          >
      {/* <S.Merchant>{hideStoreTitle ? merchant?.storeTitle : merchant?.storeTitle}</S.Merchant> */}
      <S.Title data-test="brandProductTile">{product.name}</S.Title>
      {/* <S.Category>{hideStoreTitle ? '' : product.category?.name}</S.Category> */}

      {/* <S.Price data-test="productPrice">
        <TaxedMoney taxedMoney={price} />
      </S.Price> */}
      <S.PriceWrapper>
      <SubDetails flex={window.innerWidth <= 540 ? true : false} items={[  
        { component: <S.Price data-test="brandProductPrice">
                      <TaxedMoney taxedMoney={price} />
                    </S.Price> 
        },
        // { iconName: "far fa-clock", text: product?.leadTime ? convertMinutesToHoursDaysString(product.leadTime) : null, alignRight: true, toolTip: "Item Preparation Time", fontSize: 12, iconSize: 12, mobileView: true},
      ]} />
      {/* {isMerchantProduct ? '' :
      <S.LocationWrapper>
      <SubDetails
          items={[
            {
              fontSize: 13,
              iconName: 'fas fa-map-marker-alt',
              iconSize: 13,
              text: address?.city,
              toolTip: "Store Location",
            },
          ]}
        />
        </S.LocationWrapper>
      } */}
     </S.PriceWrapper>
     </Link>
     </S.DetailsWrapper>
    </S.Wrapper>
    </>
  );
};
