import React from "react";
import Item, { ItemProps } from "./Item";
import { ImapItemsByMerchant } from "@temp/core/utils";
export interface ProductListProps {
  data?: ImapItemsByMerchant[];
  remove?: (variantId: string) => void;
}

const dataFormatter = (data: ImapItemsByMerchant[]): ItemProps[] => {
  const formattedData: ItemProps[] = data.map((item: ImapItemsByMerchant) => {
    const merchantCity = item.merchant.warehouses.edges[0].node.address.city;
    const merchantPhone = item.merchant.warehouses.edges[0].node.address.phone;

    // get total amount
    let currency: string;
    const totalAmount = item.products.reduce((acc: number, curr) => {
      if (!currency) {
        currency = curr.price.gross.currency;
      }
      return acc + curr.price.gross.amount;
    }, 0);

    const totalPrice = {
      amount: totalAmount,
      currency,
    };

    if (!item.deliveryQuotations) {
      // @ts-ignore
      item.deliveryQuotations = {};
    }

    return {
      items: item.products.map((product) => ({
        id: product.id,
        imgSrc: product.thumbnail.url2x,
        price: `${product.price.gross.currency}${product.price.gross.amount}`,
        productName: product.productName,
        productPrice: product.price,
        quantity: product.quantity,
        variant: product.name,
        leadTime: product.leadTime,
      })),
      merchantInfo: {
        city: merchantCity,
        merchantName: item.merchant.name,
        phoneNumber: merchantPhone,
        url: item.merchant.slug
      },
      orderDetails: {
        deliveryFee: item.deliveryQuotations.totalFeeAmount,
        totalPrice,
      },
    };
  });

  return formattedData;
};

const ProductList: React.FC<ProductListProps> = ({ data, remove }) => {
  const itemProps = dataFormatter(data || []);
  return (
    <div className="mb-4">
      {itemProps.map((data) => (
        <Item remove={remove} {...data} />
      ))}
    </div>
  );
};

export default ProductList;
