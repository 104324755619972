import {
  // mediumScreen,
  smallScreen,
} from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import NukaCarousel, { CarouselProps } from "nuka-carousel";
import * as React from "react";
import Media from "react-media";
import ReactSVG from "react-svg";

import arrowImg from "../../images/JR-Mall-Left-Arrow.svg";

interface CarouselType extends CarouselProps {
  children: React.ReactNode;
  isOtherProduct?: boolean;
}

const BannerCarousel: React.FC<CarouselType> = ({ isOtherProduct, children, ...rest }) => {
  const settings = {
    className: "bannerCarousel",
    renderBottomCenterControls: ({currentSlide, slideCount, goToSlide}) =>  
    <div className="bannerCarousel__bannerControl--bannerDots">
      <ul>
        {[...Array(slideCount)].map((sc, i) => (
          <li key={i + 1}>
            <div
              onClick={()=> goToSlide(i)}
              style={{
                background: currentSlide === i ? '#76aad3' : '#d6d6d6',
                borderRadius: '50%',
                cursor: 'pointer',
                display: isOtherProduct === true ? 'none' : '',
                height: '12px',
                margin: '0 4px',
                width: '12px',
              }}
            ></div>
          </li>
        ))}
      </ul>
    </div>,
    renderCenterLeftControls: ({ previousSlide, currentSlide }) =>
      currentSlide !== 0 ? (
        <div
          onClick={previousSlide}
          className="bannerCarousel__bannerControl bannerCarousel__bannerControl--bannerLeft"
        >
          <ReactSVG path={arrowImg} />
        </div>
      ) : null,
    renderCenterRightControls: ({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToShow,
    }) =>
      slideCount - slidesToShow !== currentSlide ? (
        <div
          onClick={nextSlide}
          className="bannerCarousel__bannerControl bannerCarousel__bannerControl--bannerRight"
        >
          <ReactSVG path={arrowImg} />
        </div>
      ) : null,
    ...rest,
  };
  const carousel = (slides: number) => (
    <NukaCarousel slidesToShow={slides} slidesToScroll={slides} {...settings}>
      {children}
    </NukaCarousel>
  );

  return (
    <Media query={{ maxWidth: smallScreen }}>
      {/* {matches =>
        matches ? (
          carousel(1)
        ) : (
          <Media query={{ maxWidth: mediumScreen }}>
            {matches => carousel(matches ? 1 : 1)}
          </Media>
        )
      } */}
      {() => carousel(1)}
    </Media>
  );
};

export default BannerCarousel;
