import gql from 'graphql-tag';
import {
	QueryResultPaymentTransactionType,
	QueryResultPaymentTransactionTypeVariables,
	GetJRPayUserBalance,
	GetJRPayUserBalanceVariables,
} from './types';
import {TypedQuery} from 'src/core/queries';

const queryPaymentTransactionTypes = gql`
	query getPaymentTypes($first: Int) {
		paymentTransactionTypes(first: $first) {
			edges {
				node {
					id
					code
					display
				}
			}
		}
	}
`;
export const TypedGetPaymentTransactionTypes = TypedQuery<
	QueryResultPaymentTransactionType,
	QueryResultPaymentTransactionTypeVariables
>(queryPaymentTransactionTypes);

const queryGetJRPayUserBalance = gql`
	query GetJRPayUserBalance($userId: String!) {
		jrpayBalance: getAccountUserJrpayBalance(userId: $userId) {
			availableBalance {
				currency
				amount
			}
			holdedAmount {
				currency
				amount
			}
			actualBalance {
				currency
				amount
			}
			accountReceivable {
				currency
				amount
			}
			accountNumber
		}
	}
`;
export const TypedGetJRPayUserBalance = TypedQuery<
	GetJRPayUserBalance,
	GetJRPayUserBalanceVariables
>(queryGetJRPayUserBalance);
