import BranchCard from "@temp/screens/mobile/BranchCard/BranchCard";
import * as React from "react";
import { useHistory } from 'react-router-dom';
import { useUserDetails } from "@saleor/sdk";
import { Button, Loader } from "@components/atoms";
import * as S from "./styles";
import { useLayout } from '@temp/layout/hooks/useLayout';
import { searchAddress } from '@temp/routes/paths';

import "./scss/index.scss";

interface IProps{
  isSubCategoryPage?: boolean;
}

const NearbyNoAddress = ({ onClick }) => (
  <div className="nearby-brands__noAddress">
    <h3 className="nearby-brands__noAddress__text">Enter your address to see stores nearby!</h3>
    <a className="nearby-brands__noAddress__link" onClick={onClick}>Click here</a>
  </div>
)


const NearbyBrands: React.FC<IProps> = ({
  isSubCategoryPage
}) => {
  const history = useHistory();
  const { data: user } = useUserDetails();
  const { useNearbyStores, useUserLocation, setAddressOverlay } = useLayout();
  const { latitude, longitude } = useUserLocation() || {};
  const { nearByStores, loading, hasNextPage, loadMore } = useNearbyStores();
  const hasAddress = !!(latitude && longitude);

  if (hasAddress && nearByStores?.length === 0) {
    return null;
  }

  return (
  <section className="nearby">
    <div className='main-container'>
      <header className="font-weight-bold">
        <h4>Nearby</h4>
      </header>
      {hasAddress ? 
      <div className="nearby-grid">
        {nearByStores.map(store => 
          <BranchCard 
            name={store?.storeTitle}
            slug={store?.slug}
            distance={store?.distance}
            backgroundUrl={store?.banner?.url}
            preparationTime={store?.preparationTime}
            categories={store?.categories}
          />
        )}
      </div>
      :   
        <NearbyNoAddress 
          onClick={() => {
            if (user) {
              history.push(searchAddress);
            } else {
              // Go to top page
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;

              setAddressOverlay(true);
            }
          }}
        />
      }

      <S.Loader>
				{loading ? (
					<Loader />
				) : (
					hasNextPage && (
					<Button
						testingContext="loadMoreProductsButton"
						color="secondary"
						onClick={loadMore}
					>
						<span>More +</span>
					</Button>
					)
				)}
			</S.Loader>
    </div>
  </section>
  );
}
export default NearbyBrands;
