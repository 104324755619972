import React from "react";
import "../globalStyles/scss/index.scss";
import Layout from '../layout';
import Routes from '../routes';
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss';
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';

const App = () => {
  return (
    <FlagsmithProvider
      options={{
        environmentID: process.env.REACT_APP_FLAGSMITH,
      }}
      flagsmith={flagsmith}>
        <Layout>
          <Routes />
        </Layout>
    </FlagsmithProvider>

    
  )
};

export default App
