import { useCart, useCheckout, useUserDetails } from "@saleor/sdk";
import { History } from "history";
import React from "react";
import { useHistory, Link } from "react-router-dom";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";
import { Button, CartFooter, CartHeader } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartRow } from "@components/organisms";
import { Cart, CartEmpty } from "@components/templates";
import { IItems } from "@saleor/sdk/lib/api/Cart/types";
import { IDeliveryQuotations } from "@saleor/sdk/lib/api/Checkout/types";
import { UserDetails_me } from "@saleor/sdk/lib/queries/gqlTypes/UserDetails";
import { BASE_URL } from "@temp/core/config";
import { ITaxedMoney } from "@types";
import { useLayout } from '@temp/layout/hooks/useLayout';

import { IProps } from "./types";
import * as S from "./styles";

import {
  ICheckoutModelLine,
  ICheckoutByMerchant,
} from "@temp/vendors/saleor/sdk/lib/helpers";
import { accumulateAllTaxMoney, generateBranchUrl } from "src/core/utils";
import bannerSrc from "images/shoppingCart_light.jpg";
import bannerSrcMobile from "images/shoppingCart_light_mobile.png";
import { setCheckoutSchedulesCache } from "@temp/helpers";

const SMALL_SCREEN = 541;

const title = <>
<Media maxWidth={smallScreen}>
  <div className="article-page__cartPage" data-test="cartPageTitle">My <br/> Shopping Cart</div>
</Media>
<Media minWidth={SMALL_SCREEN}>
  <div className="container">
  <div className="article-page__cartPage" data-test="cartPageTitle">My Shopping Cart</div>
  </div>
</Media>
</>
const banner = 
<div className="article-page">
      <div
        className="article-page__header"
        style={ bannerSrc && {
                backgroundImage: `url(${bannerSrc})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: "300px",
              }            
        }
      >
        {title}
        {/* {!bannerSrc && (
          <span className="article-page__header__title">
            {!headerImage && <h1>{"No Image Uploaded"}</h1>}
          </span>
        )} */}
      </div>
  </div>
const bannerMobile = 
<div className="article-page">
      <div
        className="article-page__header"
        style={ bannerSrcMobile && {
                backgroundImage: `url(${bannerSrcMobile})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: "300px",
              }            
        }
      >
        {title}
        {/* {!bannerSrc && (
          <span className="article-page__header__title">
            {!headerImage && <h1>{"No Image Uploaded"}</h1>}
          </span>
        )} */}
      </div>
  </div>


const getShoppingButton = (history: History) => (
  <S.Button>
  <Button
    testingContext="cartPageContinueShoppingButton"
    onClick={() => history.push(BASE_URL)}
  >
    Continue Shopping
  </Button>
  </S.Button>
);

const getCheckoutButton = (history: History, user: UserDetails_me | null) => (
  <S.Button>
  <Button
    testingContext="proceedToCheckoutButton"
    onClick={() => { history.push(user ? `/checkout/` : `/login/`); setCheckoutSchedulesCache([])}}
  >
    Proceed to checkout
  </Button>
  </S.Button>
);

const cartHeader = <CartHeader />;

const prepareCartTotalFee = (
  totalPrice?: ITaxedMoney | null,
  shippingTaxedPrice?: ITaxedMoney | null,
  promoTaxedPrice?: ITaxedMoney | null,
  subtotalPrice?: ITaxedMoney | null
) => (
  <CartFooter
    subtotalPrice={
      <TaxedMoney data-test="subtotalPrice" taxedMoney={subtotalPrice} />
    }
    totalPrice={<TaxedMoney data-test="totalPrice" taxedMoney={totalPrice} />}
    shippingPrice={
      shippingTaxedPrice &&
      shippingTaxedPrice.gross.amount !== 0 && (
        <TaxedMoney data-test="shippingPrice" taxedMoney={shippingTaxedPrice} />
      )
    }
    discountPrice={
      promoTaxedPrice &&
      promoTaxedPrice.gross.amount !== 0 && (
        <TaxedMoney data-test="discountPrice" taxedMoney={promoTaxedPrice} />
      )
    }
  />
);

export const CartPage: React.FC<IProps> = ({}: IProps) => {
  const history = useHistory();
  const { data: user } = useUserDetails();
  const { checkout } = useCheckout();
  const {
    loaded,
    updateItem,
    items,
  } = useCart();
  const { removeCartItem, setQueryParams } = useLayout();
  const removeItem = (variantId: string) => {
    if (removeCartItem) {
      removeCartItem(variantId);
    }
  }
  const generateCart = (
    items: IItems,
    deliveryQuotations: IDeliveryQuotations[],
    removeItem: (variantId: string) => any,
    updateItem: (variantId: string, quantity: number) => any
  ) => {
    const initialValue: ICheckoutByMerchant[] = [];
  
    const cacheId: any = {};
  
    // segregate products
    const separateByMerchant = items?.reduce(
      (prev: ICheckoutByMerchant[], current: ICheckoutModelLine) => {
        // get the merchant info
        const id = current?.variant?.product?.merchant?.id || "[id]";
        const name = current?.variant?.product?.merchant?.name || "[name]";
        const slug = current?.variant?.product?.merchant?.slug || "[slug]";
        const storeTitle = current?.variant?.product?.merchant?.storeTitle || "[storeTitle]";
        const address =
          // @ts-ignore
          current?.variant?.product?.merchant?.warehouses?.edges?.[0]?.node
            ?.address?.streetAddress1 || "[streetAddress1]";
        const phone =
          // @ts-ignore
          current?.variant?.product?.merchant?.warehouses?.edges?.[0]?.node
            ?.address?.phone || "[phone]";
  
        let searchResult: number;
  
        // search for the item
        if (!cacheId?.[id] && cacheId?.[id] !== 0) {
          cacheId[id] = prev.length;
          searchResult = prev.length;
        } else {
          searchResult = cacheId[id];
        }
  
        // if merchant id already exist in the previous iteration
        if (prev[searchResult]) {
          prev[searchResult].items.push(current);
          return prev;
        }
  
        // if not create new enry
        return [
          ...prev,
          {
            items: [current],
            merchantInfo: {
              address,
              deliveryQuotation: deliveryQuotations
              ? deliveryQuotations?.find(
                  (quote) => quote.merchant && quote.merchant.id === id
                )
              : [],
              id,
              name,
              phone,
              slug,
              storeTitle,
            },
          },
        ];
      },
      initialValue
    );
  
    return separateByMerchant?.map((merchantItem: ICheckoutByMerchant) => {
      let subTotal: ITaxedMoney = accumulateAllTaxMoney();
      const shippingTotal: ITaxedMoney = merchantItem?.merchantInfo?.deliveryQuotation?.totalFeeAmount ? {
        gross: merchantItem?.merchantInfo?.deliveryQuotation?.totalFeeAmount,
        net: merchantItem?.merchantInfo?.deliveryQuotation?.totalFeeAmount,
      } : accumulateAllTaxMoney();
      let grandTotal: ITaxedMoney = accumulateAllTaxMoney(null, shippingTotal);
      
      return (
        <div>
          {/* place merchant info and header in the parent */}
          <div className="pt-4">
            <S.MerchantName>
              <Link 
                  to={generateBranchUrl(merchantItem?.merchantInfo?.slug)}>
                {merchantItem?.merchantInfo?.storeTitle ? merchantItem?.merchantInfo?.storeTitle : ""}
              </Link>
            </S.MerchantName>
            <p className="small mt-1 text-secondary">
              Address: {merchantItem?.merchantInfo?.address || "[address]"}
            </p>
            {/* <p className="small mt-n1 text-secondary">
              Contact: {merchantItem?.merchantInfo?.phone || "[phone]"}
            </p> */}
            {cartHeader}
            {/* render the products */}
            {merchantItem.items?.map(
              ({ id, variant, quantity, totalPrice }, index) => {
                subTotal = accumulateAllTaxMoney(subTotal, totalPrice);
                grandTotal = accumulateAllTaxMoney(grandTotal, totalPrice);
  
                return (
                  <CartRow
                    onProductClick={() => {
                      // @ts-ignore
                      setQueryParams({ vwProductCartId: variant?.id })
                    }}
                    key={id ? `id-${id}` : `idx-${index}`}
                    index={index}
                    id={variant?.product?.id || ""}
                    name={variant?.product?.name || ""}
                    maxQuantity={variant.quantityAvailable || quantity}
                    quantity={quantity}
                    onRemove={() => removeItem(variant.id)}
                    onQuantityChange={(quantity) =>
                      updateItem(variant.id, quantity)
                    }
                    thumbnail={{
                      ...variant?.product?.thumbnail,
                      alt: variant?.product?.thumbnail?.alt || "",
                    }}
                    totalPrice={<TaxedMoney taxedMoney={totalPrice} />}
                    unitPrice={<TaxedMoney taxedMoney={variant?.pricing?.price} />}
                    sku={variant.sku}
                    attributes={variant.attributes?.map((attribute) => {
                      return {
                        attribute: {
                          id: attribute.attribute.id,
                          name: attribute.attribute.name || "",
                        },
                        values: attribute.values.map((value) => {
                          return {
                            id: value?.id,
                            name: value?.name || "",
                            value: value?.value,
                          };
                        }),
                      };
                    })}
                  />
                );
              }
            )}
            {prepareCartTotalFee(grandTotal, shippingTotal, null, subTotal)}
          </div>
        </div>
      );
    });
  };
  
  const deliveryQuotations = checkout?.deliveryQuotations || [];

  if (loaded && items?.length) {
    return (<div style={{backgroundColor: '#fff'}}>
      <Media maxWidth={smallScreen}>
        <div>{bannerMobile}</div>
      </Media>
      <Media minWidth={541}>
        <div>{banner}</div>
      </Media>
      <Cart
        button={getCheckoutButton(history, user)}
        cartHeader={cartHeader}
        cart={items && generateCart(items, deliveryQuotations, removeItem, updateItem)}
      />
      </div>
    );
  } else {
    return <CartEmpty button={getShoppingButton(history)} />;
  }
};
