import React, {useState} from "react";

import * as S from "./styles";
import { IProps } from "./types";

const CheckoutCard: React.FC<IProps> = ({ title, checkoutDetails }: IProps) => {
  const [showMore, setShowMore] = useState(false);

  return (
  <>
  <S.CustomerDetails onClick={()=> setShowMore(!showMore)}>
    <S.CustomerWrapper>
    <div>
    { checkoutDetails && 
    <>
    <S.Address>Delivery Address</S.Address>
    <S.Customer>{checkoutDetails?.firstName} {' '} {checkoutDetails?.lastName} | {checkoutDetails?.phone}</S.Customer>
    <S.CustomerAddress>{checkoutDetails?.streetAddress1}</S.CustomerAddress>
    </>
    }
    </div>
    {/* <S.Dropdown showMore={showMore} onClick={()=> setShowMore(!showMore)}><i className="fa fa-caret-down"></i></S.Dropdown>
    </S.CustomerWrapper>
    <S.CustomerWrapper showMore={!showMore}>
  <div style={{marginTop: '1rem'}}>Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet </div>*/}
    </S.CustomerWrapper>
   </S.CustomerDetails>
  </>
  );
};

export {CheckoutCard}
