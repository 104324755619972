import { media, styled } from "@styles";

export const Wrapper = styled.div`
  margin: 1rem 0;
`;
export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  ${media.largeScreen`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
  `}

  ${media.smallScreen`
    grid-template-columns: 1fr 1fr;
    grid-gap: 0rem;
  `}
`;

export const Loader = styled.div`
  text-align: center;
  margin: 1rem 0;
  button {
    span {
      font-family: ${(props) => props.theme.typography.secondaryFontFamily};
    }
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-family: ${(props) => props.theme.typography.secondaryFontFamily};
  padding: 5px 10px;
  ${media.smallScreen`
    font-size: 20px;
  `}
`;
