import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {NearbyStores, NearbyStoresVariables} from './gqlTypes/NearbyStores';
const getNearbyStoresQuery = gql`
	query GetNearbyStores(
		$first: Int
		$after: String
		$latitude: Float
		$longitude: Float
		$placeId: String
	) {
		nearbyStores(
			latitude: $latitude
			longitude: $longitude
			placeId: $placeId
		) {
			latitude
			region
			longitude
			streetAddress1
			storesNearby(after: $after, first: $first) {
				edges {
					node {
						id
						name
						storeTitle
						slug
						leadTime
						banner(size: 350) {
							alt
							url
						}
						logo {
							alt
							url
						}
						primaryAddress {
							id
							latitude
							longitude
						}
						merchantBrandCategoryAssignment(first: 3) {
							edges {
								node {
									id
									merchantCategory {
										id
										name
									}
								}
							}
						}
					}
				}
				totalCount
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
		}
	}
`;
export const TypedGetNearbyStoresQuery = TypedQuery<
	NearbyStores,
	NearbyStoresVariables
>(getNearbyStoresQuery);
