import * as React from "react";
import { useCart } from "@saleor/sdk";
import * as helpers from "src/helpers";
import { TypedProductDetailsQuery } from "./queries";
import BrandProduct from "src/screens/mobile/BrandProduct";

interface Props {
    productId?: string | null;
    productCartId?: string | null;
    setProductOverlay: (status: boolean, productId?: string) => void;
}
const View: React.FC<Props> = ({
    productId,
    productCartId,
    setProductOverlay
}) => {
    const { items } = useCart();

    if (!!productId || !!productCartId) {
        productId = productCartId
            ? (items || []).find(item => item?.variant?.id === productCartId)?.variant?.product?.id
            : productId;

        return (
            <TypedProductDetailsQuery
                variables={{ id: productId || "-xe" }}
            >
                {({ data, loading }) => {
                    let product = data?.product;
                    let productVariant = null;
                    let defaultNotes = null;
                    if (!!productCartId) {
                        productVariant = (items || []).find(item => item?.variant?.id === productCartId && item?.quantity && item?.quantity > 0);
                        const variant = (product?.variants || []).find(variant => variant?.id === productVariant?.variant?.id);
                        product = {
                            ...product,
                            variants: [variant]
                        }
                        const myNote = (helpers.getCartItemNotesCache() || []).find(item => item?.variantId === productVariant?.variant?.id);
                        defaultNotes = myNote?.notes;
                    }

                    return (
                        <div className="branch-page-product">
                            <div className="branch-page-product__contents">
                                <BrandProduct
                                    defaultNotes={defaultNotes}
                                    defaultQty={productVariant?.quantity || 1}
                                    setProductOverlay={setProductOverlay}
                                    loading={loading}
                                    product={product}
                                    merchant={product?.merchant}
                                    isFromCart={!!productCartId}
                                />
                            </div>
                            <div onClick={() => setProductOverlay(false)} className="branch-page-product__overlay"></div>
                        </div>
                    );
                }}
            </TypedProductDetailsQuery>
        );
    }

    return null;
};

export default View;