import "./scss/index.scss";

import * as React from "react";
import { SubDetails } from "@components/containers";
import { FormAddressType } from "./types";

const AddressSummary: React.FC<{
  isStore?: boolean;
  address?: FormAddressType;
  merchantImage?: string;
  merchantName?: string;
  merchantSchedule?: string;
  merchantAddress?: string;
  merchantCity?: string;
  email?: string;
  paragraphRef?: React.RefObject<HTMLParagraphElement>;
}> = ({ address, email, paragraphRef, isStore, merchantName, merchantImage, merchantCity, merchantAddress, merchantSchedule}) => {
  if (address) {
    return (
      <>
      { isStore ? 
      <>
      <div className="merchant-summary">
         <img className="merchant-summary__logo" src={merchantImage}/>
         <div className="merchant-summary__container">
         <p className="merchant-summary__name">{merchantName}</p>
         <div className="merchant-summary__city">
         <SubDetails paddingTop={1}
            items={[
              {
                fontSize: 14,
                iconName: 'fas fa-map-marker-alt',
                iconSize: 14,
                text: merchantCity
              },
            ]}/>
            </div>
         </div>
      </div>
      <div className="merchant-address">
      <SubDetails flex={true}
            items={[
              {
                fontSize: 14,
                iconName: 'fas fa-map-pin',
                iconSize: 16,
                paddingLeft: 3,
                paddingRight: 10,
                text: merchantAddress
              },
              {
                fontSize: 14,
                iconName: 'far fa-clock',
                iconSize: 16,
                text: merchantSchedule
              },
            ]}/>

      </div>
      </>
      :
      <div className="address-summary" ref={paragraphRef}>
        <p className="address-summary__name">{`${address.firstName} ${address.lastName}`}</p>
        {address.companyName && (
        <span>{address.companyName}</span>
        )}
        <>
        <p className="address-summary__title">Pinned Address</p>
        <span>{address.streetAddress1}</span>
        
        <p className="address-summary__title">Address Details</p>
        {address.streetAddress2 && 
         <span>{address.streetAddress2}</span>
        }
        {' '}
        <span>
        {address.city},
        {address.countryArea && <> {address.countryArea},</>}{' '}
        {address.country?.country}
        </span>
        </>
        {address.phone && (<>
          <p className="address-summary__title">Phone Number:</p>
          <span>{address.phone}</span>
          </>
        )}
        {email && (
        <>
        <p className="address-summary__title">Email Address:</p>
        <span>{email}</span>
        </>)}
      </div>
      }
      </>
    );
  } else if (email) {
    return (
      <p className="address-summary" ref={paragraphRef}>
        {email}
      </p>
    );
  }
  return null;
};

export default AddressSummary;
