import React from "react"

import { Collapse } from 'antd';
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import { mediumScreen, smallScreen } from "@temp/@next/globalStyles/constants";

const { Panel } = Collapse;

const FAQContent = [
    {
        title : 'Frequently Asked Questions - GENERAL',
        panel : [
        {
            title: 'What is JR Mall?',
            content: '• JR Mall is the newest online mall for quality food, drinks, groceries, lifestyle and essential items. Customers like you can browse through a wide variety of items available on JR Mall website, select the items you would like to order, settle payment, and have these delivered straight to your doorstep, all in one go!'
        },
        {
            title: 'Where do you deliver? How much is the delivery fee?',
            content: `• JR Mall has partnered with Happy Move and JoyRide for quick and hassle-free deliveries! Our delivery partners can cater to the whole of Metro Manila, Rizal, Cavite, Bulacan, and Laguna. 
• Delivery fees are computed based on distance from the address of your chosen store to your delivery address. Our base fee is Php 60, with an additional Php 8 for every km thereafter. `
        },
        {
            title: 'What are your hours?',
            content: '• JR Mall is available 24 hours a day, 7 days a week! However, each store merchant has their own operating hours. You are still free to browse their offerings and place orders, but note that dispatch of items for delivery will only take place during their declared operating hours. '
        },
        {
            title: 'Can I place an immediate order? What about advanced orders?',
            content: '• Yes, immediate, same-day, and advanced orders are all possible through JR Mall! However, please be mindful of the lead time indicated on the items on your cart. Not all items are available for immediate or same-day orders as some merchants may need a little bit more time to prepare their items and can only cater on a pre-order basis.'
        },
        {
            title: 'Can I still order from a store that is far from my delivery address?',
            content: '• No need to worry about the delivery radius-- JR Mall allows you to order from merchants both near or far! In the event that the branch closest to you does not carry the item you want to purchase, you may check out the store’s other branches and order from there instead. MOreover, since our delivery fees are distance-based, you can rest assured that the delivery riders are fairly compensated in case a long trip is necessary to get you what you need.'
        },
        {
            title:  'What are your payment options?',
            content: '• We accept payments through credit card, debit card, PayMaya, WeChat Pay, and JR Pay.'
        },
        {
            title: 'Can I place an order even if I haven’t registered for an account?',
            content: '• Definitely! All you have to do is to select ‘Continue as Guest’ upon checkout. But we do highly recommend signing up so you can enjoy faster checkouts for your future transactions.'
        },
        {
            title: 'Do you accept cash on delivery payments?',
            content: '• We require orders to be paid prior to delivery in order to protect our partner merchants and riders from bogus transactions. Moreover, safety is one of our primary considerations in light of the COVID-19 pandemic so we are pushing for cashless modes of payment in order to minimize contact between customers and delivery riders. '
        },
        {
            title: 'Why did I receive multiple tracking numbers for my order?',
            content: '• Multiple tracking numbers mean that you ordered from more than one store merchant. Each merchant will fulfill your order separately, so you can expect multiple deliveries to your address depending on the number of merchants you ordered from.'
        },
        {
            title: 'Can I cancel my order?',
            content: `• Order cancellation is subject to the approval of the merchant if the items are not prepared yet. Once items are already prepared or processed, then cancellation requests will no longer be entertained.
• To request for order cancellation, contact JR Mall through our hotline (8864-7777) or email us at help@jrmall.ph. Our representative will be the one to coordinate and facilitate the cancellation in the event that it is approved by the merchant.`
        },
        {
            title: 'Is it possible to edit items in my order?',
            content: '• We can no longer accommodate changes to an order once it has already been placed so as to prevent errors or delays in fulfillment. We highly recommend going over the items on your cart prior to checking out for a hassle-free shopping experience!'
        },
        {
            title: 'I have a business and I want to join JR Mall. How do I do it?',
            content: '• We’d love to have you onboard! Send us an email at merchants@jrmall.ph so our team can help you get started.'
        },
        {
            id:'bulkOrders',
            title: 'I have bulk orders. How can I order?',
            content: `• For bulk orders, please call our hotline at (02) 8864-7777 or send us an email at bulkorders@jrmall.ph`
        },
    ]
    },
    {
        title: 'Frequently Asked Questions - RETURN/REFUND POLICY',
        panel: [
            {
                title: 'Do you accept returns or refunds?',
                content: `• Yes! However, returns and refunds will only be entertained if they meet the following five (5) conditions:
- Return or refund request must be made within the return/refund time frame
- The product being returned must be inadequate or the product being refunded is missing/undelivered
- Product must be eligible for return/refund
- A return/refund request form must be filled up
- All user manuals, guides, invoice, accessories, and freebies that come with the item shall be returned`
            },
            {
                title: 'Within how many days can I request for a return or refund?',
                content: '• Customers will have a period of seven (7) calendar days to request for return/refund should the product be deemed inadequate or is missing/undelivered. The seven (7) calendar days are determined from the date of item receipt. '
            },
            {
                title: 'What products are considered “inadequate”?',
                content: `• Any product which falls under any of the below criteria may be returned: 
a. Wrong Product 
b. Faulty, Defective, or Damaged Product
c. Product is legally non-compliant
d. Product that has been publicly or privately recalled
e. Incomplete Products`
            },
            {
                title: 'Can I return products if I change my mind about the purchase?',
                content: '• No. Returns or refunds based on change of mind or failure to meet customer expectations are not valid reasons for return.'
            },
            {
                title: 'How do I return or refund a product?',
                content: `• Before filing for a return/refund, kindly ensure the following: 
a. Product is unused and in the same condition as received;
b. Product is in the original packaging;
c. Product has a receipt or proof of purchase

STEP 1: Customers must fill out the JR Mall Return/Refund Form, including the details of the product and the defect. For inadequate products, customer should send* the product complete with accessories, if any, together with the form to: 

JoyRide PH Onboarding Facility 
80 Marikina-Infanta Highway, Mayamot, Antipolo City
(02) 8864 - 7777

*Please note that customers will be responsible for shouldering the shipping costs for returning the product. If a customer receives a refund, the cost of return shipping will be deducted by JR Mall from the refund.

STEP 2: Upon receipt of the returned product, JR Mall will determine whether the customer is entitled to refund or replacement as a result of the defect. Customers will be informed of the decision via email. 

For replacements, JR Mall will notify the customer of the defect and send the inadequate product to the seller, who in turn, will replace the product within three (3) days from receipt.
For refunds, JR Mall will immediately notify the customer on the status of the refund after inspecting the item. If approved, JR Mall will initiate a refund to the customer’s account based on the original method of payment. The customer will receive the credit within a certain amount of days, taking into consideration the bank or credit card issuer’s policies.

• The seller may refuse or reject the request in case they disagree with the request for the return/refund. A customer may then file a dispute with JR Mall within seven (7) days from the refusal or rejection by the Seller. JR Mall will review the dispute and will contact the customer once a decision has been made.`
            },
        ],
    }
]

export const FAQ = () => {

    const location = useLocation();
    const isTablet = useMediaQuery({ maxWidth: mediumScreen });
    const isMobile = useMediaQuery({ maxWidth: smallScreen });

    React.useEffect(()=>{
        if(location.hash === '#bulkOrders' && !isTablet){
            window.scrollTo(0,960);
        }
        else if (location.hash === '#bulkOrders' && isMobile){
            window.scrollTo(0,1260);
        }
        else {
            window.scrollTo(0,1100);
        }
    },[location.hash])
    
    return (
    <div className="support-faq main-container py-4">
        {FAQContent.map((content)=> {
        const { title, panel } = content;
        return (
        <>
            <h3 className="my-3">{title}</h3>
            <div className="support-faq-container">
            <div style={{width: '100%'}}>
                {panel.map((item)=> {
                const { title, content, id } = item;
                    return (
                    <div id={id} style={{margin: '1rem 0'}}>
                    <Collapse defaultActiveKey={id ? ['I have bulk orders. How can I order?'] : ['What is JR Mall?']}>
                    <Panel className="support-faq__panel"  header={title} key={title} ><pre style={{whiteSpace: 'pre-wrap'}}>{content}</pre></Panel>
                    </Collapse>
                    </div>
                    );
                })}
            </div>
        </div>
        </>
        )
    })}   
    </div>
    );
}