import React from 'react';

import * as S from './styles';
import {IProps} from './types';

import {Container} from '../Container';
import bannerSrc from 'images/shoppingCart_light.jpg';
import bannerSrcMobile from 'images/shoppingCart_light_mobile.png';
import Media from 'react-responsive';
import {smallScreen} from '@styles/constants';
/**
 * Template for empty cart page.
 */
const CartEmpty: React.FC<IProps> = ({button}: IProps) => {
	const title = (
		<div className="container">
			<div className="article-page__emptyPage" data-test="cartPageTitle">
				Your Cart <br /> looks empty
			</div>
		</div>
	);
	const banner = (
		<div className="article-page">
			<div
				className="article-page__header"
				style={
					bannerSrc && {
						backgroundImage: `url(${bannerSrc})`,
						backgroundPosition: 'center center',
						backgroundSize: 'cover',
						height: '350px',
					}
				}
			>
				{title}
				{/* {!bannerSrc && (
          <span className="article-page__header__title">
            {!headerImage && <h1>{"No Image Uploaded"}</h1>}
          </span>
        )} */}
			</div>
		</div>
	);
	const bannerMobile = (
		<div className="article-page">
			<div
				className="article-page__header"
				style={
					bannerSrcMobile && {
						backgroundImage: `url(${bannerSrcMobile})`,
						backgroundPosition: 'center center',
						backgroundSize: 'cover',
						height: '300px',
					}
				}
			>
				{title}
				{/* {!bannerSrc && (
          <span className="article-page__header__title">
            {!headerImage && <h1>{"No Image Uploaded"}</h1>}
          </span>
        )} */}
			</div>
		</div>
	);
	return (
		<>
			<Media maxWidth={smallScreen}>{bannerMobile}</Media>
			<Media minWidth={541}>{banner}</Media>
			<Container>
				<S.Wrapper>
					{/* <S.TitleFirstLine>Your Cart</S.TitleFirstLine>
        <S.TitleSecondLine>looks empty</S.TitleSecondLine> */}
					{/* <S.HR /> */}
					<S.Subtitle>Looks like there isn't anything here yet. Click the button below to continue browsing.</S.Subtitle>
					<S.ContinueButton>{button}</S.ContinueButton>
				</S.Wrapper>
			</Container>
		</>
	);
};

export {CartEmpty};
