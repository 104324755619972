
import "./scss/index.scss";

import * as React from "react";
import { useSignIn } from "@saleor/sdk";
import { useHistory,Link } from 'react-router-dom';
import { accountConfirmUrl } from "../../../app/routes";

import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { RegisterAccount } from "./gqlTypes/RegisterAccount";
import { TypedAccountRegisterMutation } from "./queries";

import { AlertManager, useAlert } from "react-alert";

import * as S from "../../LoginForm/styles"
import jrlogo from "../../../images/jr.png";
import { TypedValidateFacebookUserMutation } from "src/@next/components/containers/FBLogin/mutations";
import { FBLoginDialog } from "src/@next/components/containers/FBLogin/helper";
import { ValidateFacebookUser } from "src/@next/components/containers/FBLogin/types";

const showSuccessNotification = (
  data: RegisterAccount,
  hide: () => void,
  alert: AlertManager,
  history?: any
) => {
  const successful = maybe(() => !data.accountRegister.errors.length);

  if (successful) {
    if (hide) {
      hide();
    }

    alert.show(
      {
        title: data.accountRegister.requiresConfirmation
          ? "Please check your e-mail for further instructions"
          : "New user has been created",
      },
      { type: "success", timeout: 5000 }
    );

    history.push("/");
  }
};

const RegisterForm: React.FC<{ hide?: () => void, onClick?: () => void, isGuest?: boolean }> = ({ hide, onClick, isGuest}) => {
  const alert = useAlert();
  const history = useHistory();
  const [signIn, { loading: signInLoading }] = useSignIn();

  const showErrorAlert = (title: string, message: string) => {
    alert.error({
      content: message,
      title,
    }, {
      timeout: 2000,
    })
  };



  const handleFacebookLogin = async(event, validateFacebookUser) => {
    try {
      const fbUser = await FBLoginDialog();

      if (fbUser && fbUser.id) {
        const validateUserResponse: ValidateFacebookUser = await validateFacebookUser({ 
          variables: {
            facebookAccessToken: fbUser.accessToken,
            facebookId: fbUser.id,
            facebookName: fbUser?.name
          } 
        });
  
        const { facebookUser, facebookUserKey, errors } = validateUserResponse?.data?.validateFacebookUser || {};

        // Display error
        if (errors && errors.length && errors.length > 0) {
          showErrorAlert('Error', errors[0].message);
          return;
        }

        // Do login using fb credentials
        if (facebookUser?.email && facebookUserKey) {
          const authenticated = await signIn({ email: facebookUser?.email, password: facebookUserKey });
          if (authenticated) {
            if (hide) {
              hide();
            }
            history.push("/");
          }
        } else {
          throw new Error('Facebook user not found.')
        }
      }
    } catch (e) {
      showErrorAlert('Error', 'Error occurs upon log-in to Facebook.')
    }
  };

  return (
    <TypedValidateFacebookUserMutation>
      {(validateFacebookUser, { loading: isFBLoading, data: FBData }) => (
      <TypedAccountRegisterMutation
        onCompleted={data => showSuccessNotification(data, hide, alert, history)}
      >
        {(registerCustomer, { loading, data }) => {

          return (
            <>
              <div className={isGuest? "login-form__button__guest" : "login-form__button"}>
                <S.FacebookLoginBtn
                  isGuest = {isGuest}
                  disabled={isFBLoading || signInLoading}
                  onClick={(e) => handleFacebookLogin(e, validateFacebookUser)}
                >
                  <S.FacebookLoginIcon className="fab fa-facebook-f pr-2"/>
                  {isFBLoading || signInLoading ? "Loading" : "Continue with Facebook"}
                </S.FacebookLoginBtn>
              </div>
              <div className={isGuest? "login-form__button__guest" : "login-form__button"}> 
                <Link
                  className="login-form__button__btn-joyride" 
                  to={{pathname: "https://connect.joyride.com.ph/login/jrmall"}} 
                  target="_blank"> 
                  <img src={jrlogo}/> 
                  {loading ? "Loading" : " Continue with JoyRide"}
                </Link>
              </div>
              {isGuest? '':<hr></hr>}
              <p className={isGuest ? "login-form__guestOptions":"login-form__options"}>Or continue with email</p>

              <Form
                errors={maybe(() => data.accountRegister.errors, [])}
                onSubmit={(event, { firstName, lastName, phone, email, password }) => {
                  event.preventDefault();
                  const redirectUrl = `${window.location.origin}${accountConfirmUrl}`;
                  registerCustomer({ variables: { firstName, lastName, phone, email, password, redirectUrl } });
                }}
              >
                <TextField
                  name="firstName"
                  autoComplete="firstName"
                  label="First Name"
                  type="text"
                  required
                  minLength={2}
                />
                <TextField
                  name="lastName"
                  autoComplete="lastName"
                  label="Last Name"
                  type="text"
                  required
                  minLength={2}
                />
                <TextField
                  name="email"
                  autoComplete="email"
                  label="Email Address"
                  type="email"
                  required
                />
                <div className="login__twoColumnField">
                <TextField 
                  name="phoneArea"
                  label="+63"
                  type="text"
                  disabled
                />
                <TextField 
                  name="phone"
                  label="Mobile Number (9xxxxxxxxx)"
                  type="text"
                  autoComplete="phone"
                  required
                  minLength={10}
                  maxLength={10}
                  pattern="^[0-9]*$"
                />
                </div>
                <TextField
                  name="password"
                  autoComplete="password"
                  label="Password"
                  type="password"
                  required
                />
                
                <div className={isGuest ? "login__content__guest" : "login__content__button"}>
                  <Button
                    testingContext="submitRegisterFormButton"
                    className={isGuest ? 'btn-guest-login':'btn-login'}
                    type="submit"
                    {...(loading && { disabled: true })}
                  >
                    {loading ? "Loading" : "Sign Up"}
                  </Button>
                </div>
                <div style={{fontSize: '14px',color: '#767676',marginTop: '1.5rem'}}>
                By clicking Sign Up or Continue with Facebook, you agree to our 
                <a href="/" style={{color: '#152c59',textDecoration: 'underline', paddingLeft: '0.2rem'}}>Terms and Conditions</a> and 
                <a href="/" style={{color: '#152c59',textDecoration: 'underline', paddingLeft: '0.2rem'}}>Privacy Policy.</a>
                </div>
              </Form>
            </>
          );
        }}
      </TypedAccountRegisterMutation>
    )}
    </TypedValidateFacebookUserMutation>
  );
};

export default RegisterForm;
