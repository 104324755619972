
import React from "react";
import { IProps } from "./types";
// import * as S from "./styles";
import Media from 'react-responsive';
import ourBlog from "../../../images/our-blog.jpg"
import ourBlogMobile from "../../../images/our-blog-mobile.jpg"
import "./scss/index.scss";
import { smallScreen } from "@temp/@next/globalStyles/constants";

export const OurBlog: React.FC<IProps> = ({}: IProps) => {

  return (
    <div className="blog">
      <Media minWidth={541}> 
        <img className="blog-background" src={ourBlog}/>
      </Media>
      <Media maxWidth={smallScreen}>
        <img className="blog-background" src={ourBlogMobile}/>
      </Media>
    </div>
  );
};
