import React from "react";
// import { LoginForm } from "../";
import { OverlayTheme, OverlayType } from "..";
import { OverlayContextInterface } from "../Overlay";
import RegisterForm from "../OverlayManager/Login/RegisterForm";

const SignUpForm: React.FC<{
  onClick: () => void;
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ onClick, overlay, checkoutUrl }) => (
  <>
    <h3 className="checkout__header">Sign up</h3>
    <div className="checkout-login__text">
    <p className="pb-3"> Have an existing account?
    <span
        data-test="showRegisterOverlay"
        className="u-link ml-1"
        onClick={() => overlay.show(OverlayType.login, OverlayTheme.right)}
      >
        Login
      </span>
    </p>
    </div>
    <div className="checkout-button px-3">
      <RegisterForm onClick={onClick} />
    </div>
  </>
);

export default SignUpForm;