import { Skeleton } from '@material-ui/lab';
import React, {FC} from 'react';
import './index.scss';
import JRMallPlaceholder from '../../images/merchant-placeholder-img.jpg';
interface BrandItem {
	brandName: string;
	category: string;
	backgroundUrl: string;
	slug: string;
}

const SkeletonBrandCard: FC<Partial<BrandItem>> = ({
	brandName = '[brandName]',
	category = '[category]',
	backgroundUrl = '[backgroundUrl]',
	slug,
}) => {
	// const [redirect, setRedirect] = useState(false);
	// if (redirect) {
	// 	return <Redirect push to={`${selectBranch}/${id}`} />;
	// }
	return (
		<div className="brand-card-skeleton">
		<img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={JRMallPlaceholder}/>
		{/* <Skeleton animation='wave' style={{borderRadius: 5}} variant="rect" width={'100%'} height={'85%'} /> */}
		<Skeleton animation='wave' width={'50%'} height={'15%'} variant="text" />
		<div style={{margin: '2rem 0'}}/>
		</div>
	);
};

export default SkeletonBrandCard;
