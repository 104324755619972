import React, {FC} from 'react';

interface IconProps {
	IconComponent: React.JSXElementConstructor<any>;
	className?: string;
	style?: {};
	onClick?: any;
}

const Icon: FC<IconProps> = ({IconComponent, className, style, onClick}) => {
	return (
		<button
			className={`${className} border-0 d-flex align-items-center justify-content-center`}
			style={{
				...style,
				width: 40,
				height: 40,
				borderRadius: 1000,
				// background: '#0000007d',
			}}
			onClick={onClick}
		>
			<IconComponent strokeWidth={2} size={24} color="#fff" />
		</button>
	);
};

export default Icon;
