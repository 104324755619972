import { styled } from "@styles";

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  button {
    border-radius: 10px;
    padding: 10px 35px;
    width: 100%;
    span {
      text-transform: capitalize !important;
    }
  }
`;

export const DeliveryButton = styled.div<{ isFinal?: boolean }>`
  > button {
    cursor: unset;
    background-color: ${(props) => `${props?.isFinal ? "#009846" : "#FD7473"}`};
    border-radius: 30px;
    padding: 0.1rem 1.5rem;
    color: #fff;
    font-size: 12px;
    outline: none;
  }
`;

export const Note = styled.div`
  margin: 2rem 0;
  color: ${(props) => props.theme.colors.lightFont};
`;
