import { useLayout } from "@temp/layout/hooks/useLayout";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { 
  capitalizeFirstLetter,
  generateStoreCategoryBreadcrumbs
} from "src/core/utils";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import Page from "./Page";
import { TypedStoreDetailsBySlugQuery } from "./queries";

const View: React.FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {

  const {setShowAddressbar, setShowCategorybar} = useLayout();

  useEffect(()=> {
    setShowAddressbar(false);
    setShowCategorybar(false);
  }, [])
  
  return (
    <TypedStoreDetailsBySlugQuery
      loaderFull
      variables={{
        slug: match.params.slug || "",
      }}
      errorPolicy="all"
      key={match.params.slug}
    >
      {({ data }) => (
        <NetworkStatus>
          {isOnline => {
            const { merchantBySlug } = data || {};
            const { merchantCategoryAssignment } = merchantBySlug || {};

            if (merchantBySlug) {
              let breadcrumbs = [];

              // Add store category hierarchy in breadcrumbs
              if (merchantCategoryAssignment?.edges && merchantCategoryAssignment?.edges.length > 0) {
                const { merchantCategory } = merchantBySlug.merchantCategoryAssignment.edges[0].node || {};
                breadcrumbs = generateStoreCategoryBreadcrumbs(breadcrumbs, merchantCategory)
              }

              // Store url
              breadcrumbs.push({
                link: `/store/${merchantBySlug.slug}`,
                value: capitalizeFirstLetter(merchantBySlug.storeTitle),
              });

              return (
                <MetaWrapper meta={merchantBySlug}>
                  <Page
                    merchant={merchantBySlug}
                    breadcrumbs={breadcrumbs}
                    headerImage={null}
                  />
                </MetaWrapper>
              );
            }

            if (merchantBySlug === null) {
              return <NotFound />;
            }

            if (!isOnline) {
              return <OfflinePlaceholder />;
            }
          }}
        </NetworkStatus>
      )}
    </TypedStoreDetailsBySlugQuery>
  );
};

export default View;
