import React from "react";

import { Loader } from "@components/atoms";

// import { Container } from "../Container";

import * as S from "./styles";
import { IProps } from "./types";
import joyridenote from "images/joyride-note.png";
import happymovenote from "images/happymove-note.png";

/**
 * Template for checkout page.
 */
const Checkout: React.FC<IProps> = ({
  loading,
  navigation,
  showNotes,
  checkout,
  cartSummary,
  button,
}: IProps) => {
  return (
    <div className="main-container" style={{minHeight: '100vh'}}>
      {loading && (
        <S.Loader>
          <Loader fullScreen={true} />
        </S.Loader>
      )}
      <S.Wrapper>
        <S.Navigation>{navigation}</S.Navigation>
        <S.Checkout>{checkout}</S.Checkout>
        <S.CartSummary>{cartSummary}</S.CartSummary>
        <S.Button>{button}</S.Button>
        {showNotes ? 
         <S.NoteWrapper>
          <S.NoteContainer>
          <p>Your requested <span style={{fontFamily: 'RockoBold'}}>Delivery Schedule</span> is subject to change at the discretion of the store.</p>
          <p>The store will do its best to accommodate your selected date and time.</p>
          <p>Should there be any changes with your Deliver Date and Time, kindly expect the store to reach out to you.</p>
          </S.NoteContainer>
          <S.NoteContainer>
            <div className="d-flex justify-content-between">
            <div>
            <p>The delivery of your order/s will be fulfilled by JoyRide x Happy Move.</p>
            <p>You will be notified via SMS and email once your item/s are on the way to your indicated delivery address.</p>
            </div>
            <S.ImageContainer>
            <img src={joyridenote}/>
            <img src={happymovenote}/>
            </S.ImageContainer>
            </div>
          </S.NoteContainer>
          </S.NoteWrapper>
        : ''
        }
        </S.Wrapper>

    </div>
  );
};

export { Checkout };
