import { useOrdersByUser } from "@saleor/sdk/";
import React from "react";

import { Button, Loader } from "@components/atoms";
import { OrderTabel } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";
import "./scss/index.scss";

const ORDERS_PER_APICALL = 100;
const ORDERS_TO_SHOW = 5;
import { Tabs } from 'antd';
import { DeliveryStatus, OrderStatus } from "@temp/helpers";

const { TabPane } = Tabs;


export const OrdersHistory: React.FC<IProps> = ({ history }: IProps) => {
  const [unfulfilledOnGoingCount, setUnfulfilledOnGoingCount] = React.useState(1);
  const [fulfilledOnGoingCount, setFulfilledOnGoingCount] = React.useState(1);
  const [cancelledOnGoingCount, setCancelledOnGoingCount] = React.useState(1);

  const isFromCancelled = history?.location?.state;
  const { data, loading } = useOrdersByUser(
    {
      perPage: ORDERS_PER_APICALL,
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const filteredUnfulfilledData = (data?.edges?.filter((order) => (order?.node?.statusDisplay === OrderStatus.UNFULFILLED || order?.node?.statusDisplay === OrderStatus.FULFILLED) && order?.node?.deliveryStatus !== DeliveryStatus.DELIVERED)) || [];
  const filteredFulfilledData = (data?.edges?.filter((order) => order?.node?.deliveryStatus === DeliveryStatus.DELIVERED)) || [];
  const filteredCancelledData = (data?.edges?.filter((order) => order?.node?.statusDisplay === OrderStatus.CANCELLED)) || [];

  const paginate = (data: any, pageSize: any, counter: any) => {
    return data?.slice(0, pageSize * counter);
  };

  const unfulfilledData = (paginate(filteredUnfulfilledData, ORDERS_TO_SHOW, unfulfilledOnGoingCount));
  const fulfilledData = (paginate(filteredFulfilledData, ORDERS_TO_SHOW, fulfilledOnGoingCount));
  const cancelledData = (paginate(filteredCancelledData, ORDERS_TO_SHOW, cancelledOnGoingCount));

  return loading && !data ? (
    <Loader />
  ) : (
    <>
      <h3 className="order-history__text">Order</h3>
      <Tabs className="order-history" defaultActiveKey={isFromCancelled ? '3' : '1'}>
        <TabPane tab="Ongoing" key="1">
          <S.Container>
            <OrderTabel orders={unfulfilledData || []} history={history} />
            {/* <OrderTabel orders={data?.edges || []} history={history} isOngoing={true}/>
        {data?.pageInfo.hasNextPage && ( */}
            {filteredUnfulfilledData?.length > ORDERS_TO_SHOW * unfulfilledOnGoingCount && (
              <S.Wrapper>
                {
                  loading && data
                    ? <Loader />
                    :
                    <S.ButtonWrapper>
                      <Button
                        testingContext="loadMoreOrdersButton"
                        onClick={() => {
                          setUnfulfilledOnGoingCount(prev => prev + 1);
                          // loadMore({
                          //   after: data!.pageInfo.endCursor,
                          //   perPage: ORDERS_PER_APICALL,
                          // });
                        }}
                      >
                        Load more
                      </Button>
                    </S.ButtonWrapper>
                }
              </S.Wrapper>
            )}
          </S.Container>
          {/* <S.Dispatch>
        Dispatch Schedule is the date and time orders will be dispatched by the Store.
        </S.Dispatch> */}
        </TabPane>
        <TabPane tab="Completed" key="2">
          <S.Container>
            {/* <OrderTabel orders={fulfilledData || []} history={history}/>
        {data?.pageInfo.hasNextPage && ( */}
            <OrderTabel orders={fulfilledData || []} history={history} />
            {filteredFulfilledData?.length > ORDERS_TO_SHOW * fulfilledOnGoingCount && (
              <S.Wrapper>
                {
                  loading && data
                    ? <Loader />
                    :
                    <S.ButtonWrapper>
                      <Button
                        testingContext="loadMoreOrdersButton"
                        onClick={() => {
                          setFulfilledOnGoingCount(prev => prev + 1);
                          // loadMore({
                          //   after: data!.pageInfo.endCursor,
                          //   perPage: ORDERS_PER_APICALL,
                          // });
                        }}
                      >
                        Load more
                      </Button>
                    </S.ButtonWrapper>
                }
              </S.Wrapper>
            )}
          </S.Container>
        </TabPane>
        <TabPane tab="Cancelled" key="3">
          <S.Container>
            {/* <OrderTabel orders={cancelledData || []} history={history}/>
        {data?.pageInfo.hasNextPage && ( */}
            <OrderTabel orders={cancelledData || []} history={history} />
            {filteredCancelledData?.length > ORDERS_TO_SHOW * cancelledOnGoingCount && (
              <S.Wrapper>
                {
                  loading && data
                    ? <Loader />
                    :
                    <S.ButtonWrapper>
                      <Button
                        testingContext="loadMoreOrdersButton"
                        onClick={() => {
                          setCancelledOnGoingCount(prev => prev + 1);
                          // loadMore({
                          //   after: data!.pageInfo.endCursor,
                          //   perPage: ORDERS_PER_APICALL,
                          // });
                        }}
                      >
                        Load more
                      </Button>
                    </S.ButtonWrapper>
                }
              </S.Wrapper>
            )}
          </S.Container>
        </TabPane>

      </Tabs>
    </>
  );
};
