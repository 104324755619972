import * as React from "react";
import { ProductDetails_product_category_products_edges } from "./gqlTypes/ProductDetails";
import { Link } from "react-router-dom";
import { 
    Carousel, 
    // ProductListItem 
} from "src/components";
import { ProductTile } from "@components/molecules";
import { generateRelatedProductUrl, generateMerchantProductUrl } from "src/core/utils";
import { styled, media } from "@styles";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";

const SMALL_SCREEN = 541;

const SideButton = styled.button<{ marginLeft?: number, marginTop?: number, marginBottom?: number}>`
    cursor: unset;
    background-color: #152c59;
    border: 1px solid #152c59;
    border-radius: 10px;
    padding: 0.8rem 3rem;
    width: 50%;
    color: white;
    font-size: 16px;
    font-family: ${(props) => props.theme.typography.baseFontFamily};
    margin-top: ${(props) => `${props?.marginTop ? props?.marginTop : 0}`}px;
    margin-left: ${(props) => `${props?.marginLeft ? props?.marginLeft : 0}`}px;
    margin-bottom: ${(props) => `${props?.marginBottom ? props?.marginBottom : 0}`}px;
    transition: 0.2s ease-out;
    &:hover{
        background-color: #152c59;
        color: white;
        transition: 0.2s ease-out;
    }
    ${media.smallScreen`
    width: 100%;
    border-radius: 30px;
    `};
`;

const ProductContainer = styled.div<{ paddingTop?: number, paddingBottom?: number }>`
    padding-top: ${(props) => `${props?.paddingTop ? props?.paddingTop : 0}`}rem;
    padding-bottom: ${(props) => `${props?.paddingBottom ? props?.paddingBottom : 0}`}rem;
`;

const RelatedProducts: React.FC<{
  title?: string;
  products: ProductDetails_product_category_products_edges[];
  sideButtonTitle?: string;
  sideButtonRedirectUrl?: string;
  isMerchantProduct?: boolean;
  extraPaddingTop?: boolean;
  extraPaddingBottom?: boolean;
}> = ({ 
    title, 
    products, 
    sideButtonTitle, 
    sideButtonRedirectUrl, 
    isMerchantProduct,
    extraPaddingTop,
    extraPaddingBottom
}) => {
    return (
        <ProductContainer 
            paddingBottom={extraPaddingBottom ? 8 : 0}
            paddingTop={extraPaddingTop ? 2 : 0}
        >
            {title && <h4 className="product-page__other-products__related_products_title">{title}</h4>}
                <Media maxWidth={smallScreen}>
                <Carousel slidesToScroll={'auto'} slideWidth={0.45}>
                {products.map(({ node: product }) => (
                    <Link
                        to={
                            isMerchantProduct 
                            ? generateMerchantProductUrl(product.id, product.name) 
                            : generateRelatedProductUrl(product.id, product.name)
                        }
                        key={product.id}
                    >
                        {/* <ProductListItem product={product} /> */}
                        <ProductTile product={product}/>
                    </Link>
                ))}
                    </Carousel>
                </Media>

                <Media minWidth={SMALL_SCREEN}>
                <Carousel minItemPerSlide={2} maxItemPerSlide={5}>
                {products.map(({ node: product }) => (
                    <Link
                        to={
                            isMerchantProduct 
                            ? generateMerchantProductUrl(product.id, product.name) 
                            : generateRelatedProductUrl(product.id, product.name)
                        }
                        key={product.id}
                    >
                        {/* <ProductListItem product={product} /> */}
                        <ProductTile isMerchantProduct={false} product={product}/>
                    </Link>
                ))}
            </Carousel>
            </Media>
            <Media minWidth={SMALL_SCREEN}>
            <>
                <div className="text-center">
                {sideButtonTitle && 
                <Link className="product-page__other-products__related_products_button" to={sideButtonRedirectUrl || "/"}>
                    <SideButton marginTop={15} marginBottom={15}>
                        {sideButtonTitle}
                    </SideButton>
                </Link>}
                </div>
            </>
            </Media>
            <Media maxWidth={smallScreen}>
            <>

            <div className="text-center">
                {sideButtonTitle && 
                <Link className="product-page__other-products__related_products_button" to={sideButtonRedirectUrl || "/"}>
                    <SideButton marginTop={15} marginBottom={15}>
                        {sideButtonTitle}
                    </SideButton>
                </Link>}
                </div>
            </>
            </Media>
        </ProductContainer>
    );
}

export default RelatedProducts;


