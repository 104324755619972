import * as React from "react";
import { Link } from "react-router-dom";
import { MENU_ACCOUNTS_STRING } from "src/constants";
import {
  generateCategoryUrl,
  generateCollectionUrl,
  generatePageUrl,
  generateStoreCategoryUrl
} from "../../core/utils";
import {
  SecondaryMenu_shop_navigation_secondary_items,
  SecondaryMenu_shop_navigation_secondary_items_children,
} from "../Footer/gqlTypes/SecondaryMenu";
import { MainMenu_shop_navigation_main_items } from "../MainMenu/gqlTypes/MainMenu";
import { MainMenuSubItem } from "../MainMenu/gqlTypes/MainMenuSubItem";

interface NavLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  item:
    | MainMenu_shop_navigation_main_items
    | MainMenuSubItem
    | SecondaryMenu_shop_navigation_secondary_items
    | SecondaryMenu_shop_navigation_secondary_items_children;
  iconName?: string;
}
export const NavLink: React.FC<NavLinkProps> = ({ item, iconName, ...props }) => {
  const { name, url, category, collection, page, merchantCategory } = item;

  const link = (url: string) => (
    <Link to={url} {...props}>
      {iconName && <i className={iconName}></i>}
      {name}
    </Link>
  );
  
  if (url && name === MENU_ACCOUNTS_STRING) {
    return link(url)
  } else if (url) {
    return (
      <a href={url} {...props}>
        {name}
      </a>
    );
  } else if (category) {
    return link(generateCategoryUrl(category.id, category.name));
  } else if (collection) {
    return link(generateCollectionUrl(collection.id, collection.name));
  } else if (page) {
    return link(generatePageUrl(page.slug));
  } else if (merchantCategory) {
    return link(generateStoreCategoryUrl(merchantCategory.id, merchantCategory.name));
  }

  return <span {...props}>{name}</span>;
};
