import React from "react";
import { Info } from "react-feather";

// import * as S from "./styles";

interface OrderDeliveryDetailsProps {
  deliveryReference: string;
  requestedDeliverySchedule: string;
  finalDeliverySchedule: string;
  vehicleType: string;
  driverContactNo?: string;
  driverName?: string;
}

const OrderDeliveryDetails: React.FC<Partial<OrderDeliveryDetailsProps>> = ({
  deliveryReference,
  requestedDeliverySchedule,
  finalDeliverySchedule,
  vehicleType,
  driverName,
  driverContactNo
}) => {
  
  return (
    <div className="order-details__deliveryDetails">
      <div className="order-details__deliveryDetails__text">
      <h3>Delivery Details <Info size={16} color={'#fff'}/></h3>
      <p>Delivery Order No. <span>{deliveryReference}</span></p>
      </div>
      <div className="order-details__deliveryDetails__info">
      <table>
        <tr>
          <td>Requested Delivery Timeslot</td>
          <td>{requestedDeliverySchedule}</td>
        </tr>
        <tr>
          <td>Courier</td>
          <td>JoyRide x Happy Move</td>
        </tr>
        <tr>
          <td>Dispatch Schedule</td>
          <td>
            {finalDeliverySchedule}
          {/* <S.DeliveryButton isFinal={isFinal}>
            <button>{orderStatus}</button>
          </S.DeliveryButton> */}
          </td>
        </tr>
        <tr>
          <td>Vehicle Type</td>
          <td>{vehicleType}</td>
        </tr>
        {!!driverName &&
        <tr>
          <td>Driver Name</td>
          <td>{driverName}</td>
        </tr>}
        {!!driverContactNo &&
        <tr>
          <td>Driver Contact Number</td>
          <td>{driverContactNo}</td>
        </tr>}
      </table>
      </div>
    </div>
  );
};

export default OrderDeliveryDetails;