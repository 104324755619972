import {media, styled} from '@styles';

export const CategoryWrapper = styled.div`
	padding: 1rem 0 1rem 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	> h3 {
		font-size: 1.5rem;
	}
	grid-column-gap: 1.5rem;
	grid-row-gap: 2.5rem;

	${media.largeScreen`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
  `}
  ${media.mediumScreen`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}
  
	${media.smallScreen`
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 2rem;
  `}
  
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }
`;

export const Wrapper = styled.div`
	a {
		color: inherit;
		text-decoration: none;
	}
`;
export const Title = styled.div`
	margin-top: 1rem;
	> h3 {
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		font-size: 18px;
	}
`;

export const Place = styled.div`
	* {
		padding-top: 0px;
		color: ${(props) => props.theme.colors.lightFont};
		font-size: 14px;
	}
`;

export const ImageWrapper = styled.div`
	width: auto;
	height: auto;
	max-width: 100%;
	text-align: center;
	display: flex;
	> img {
		width: 255px;
		height: 255px;
		max-width: 100%;
		align-self: center;
	}
	${media.smallScreen`
  > img {
    width: 220px;
    height: 220px;
    max-width: 100%;
    align-self: center;
  }
`}
`;

export const List = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 2rem;

	${media.largeScreen`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
  `}

	${media.smallScreen`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  `}
`;

export const Loader = styled.div`
	text-align: center;
	margin: 2.5rem 0;
	button {
		span {
			text-transform: capitalize !important;
			font-family: ${(props) => props.theme.typography.secondaryFontFamily};
		}
	}
`;

export const Image = styled.div`
	width: auto;
	height: auto;
	max-width: 100%;

	> img {
		border-radius: 5px;
		width: 100%;
		height: 150px;
		max-width: 100%;
	}
	${media.smallScreen`
  > img {
    border-radius: 5px;
    width: 200px;
    height: 120px;
    max-width: 100%;
  }
  `}
`;
