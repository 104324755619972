import gql from 'graphql-tag';

import {TypedMutation} from 'src/core/mutations';
import {
	CheckoutPaymentUpdate,
	CheckoutPaymentUpdateVariables,
	UserJRPayLinking,
	UserJRPayLinkingVariables,
	UserJRPayUnLink,
} from './types';

const checkoutPaymentUpdateMutation = gql`
	mutation checkoutUpdatePayment(
		$checkoutId: ID!
		$paymentTransactionTypeId: ID!
	) {
		checkoutUpdatePaymentTransactionType(
			checkoutId: $checkoutId
			paymentTransactionTypeId: $paymentTransactionTypeId
		) {
			checkout {
				token
				id
				paymentTransactionType {
					id
					code
					display
				}
			}
		}
	}
`;

export const TypedCheckoutPaymentUpdateMutation = TypedMutation<
	CheckoutPaymentUpdate,
	CheckoutPaymentUpdateVariables
>(checkoutPaymentUpdateMutation);

const jrpayUserLinkingMutation = gql`
	mutation AccountUserLinkJRPay($userId: String!) {
		accountUserGenerateJrpayLinkId(userId: $userId) {
			user {
				id
				email
			}
			jrpayLinks {
				linkId
				redirectUrl
			}
			errors {
				message
				field
			}
		}
	}
`;
export const TypedUserJRPayLinkingMutation = TypedMutation<
	UserJRPayLinking,
	UserJRPayLinkingVariables
>(jrpayUserLinkingMutation);

const jrpayUserUnLinkMutation = gql`
	mutation AccountUserUnlinkJRPay($userId: String!) {
		accountUserUnlinkJrpay(userId: $userId) {
			user {
				id
				email
			}
			errors {
				message
				field
			}
		}
	}
`;
export const TypedUserJRPayUnLinkMutation = TypedMutation<
	UserJRPayUnLink,
	UserJRPayLinkingVariables
>(jrpayUserUnLinkMutation);
