import React from "react";
import { useHistory, useLocation } from "react-router-dom";
// import { generateGuestOrderDetailsUrl } from "@utils/core";
import { Loader } from "@components/atoms";
import { IProps } from "./types";
import { CancelledPage } from "./CancelledPage";
// import {TypedOrderCreateCheckout } from '../../../userAccount/views/OrderDetails/graphql/mutations';
// import { setCheckoutToLocalStorage } from "@temp/helpers";

const Page: React.FC<IProps> = ({}: IProps) => {
  const location = useLocation();
  const history = useHistory();
  // const { token, orderNumber } = location.state;
  // const ORDERS_KEY = 'jrmall-order-finalized-key-201';

  const { paymentRedirectUrl } = location?.state || { orders: null, paymentRedirectUrl: null };

  // let tempOrders = null;
  // if (!orders) {
  //   tempOrders = JSON.parse(localStorage.getItem(ORDERS_KEY) || "[]");
  // }

  // const orderId = tempOrders[0]?.id;
  return !paymentRedirectUrl ? (
          // <TypedOrderCreateCheckout>
          //   {(orderCreateCheckout, {loading, data}) => (
          //       <CancelledPage 
          //         orderCreateCheckout={async () => {
          //           const data = await orderCreateCheckout({
          //             variables: {
          //               orderId
          //             },
          //           });

          //           // @ts-ignore
          //           const {checkout} = data?.data?.orderCreateCheckout || {};
          //           if (checkout) {
          //             setCheckoutToLocalStorage(checkout);
          //             window.location.pathname = '/checkout/address';
          //             // localStorage.removeItem(ORDERS_KEY);
          //             console.log(checkout);
          //           }
          //         }}
          //       />
          // )}
          // </TypedOrderCreateCheckout>
          <CancelledPage orderCreateCheckout={()=> history.push({pathname: '/order-history', state: true})} />
  ) : <Loader fullScreen={true} />;
};

export { Page as OrderCancelledPage };
