import React, { useEffect, useState } from "react";

import { TextField } from "@components/molecules";
import { Icon } from "@components/atoms";

interface QuantityTextFieldProps {
  quantity: number;
  maxQuantity: number;
  disabled: boolean;
  onQuantityChange: (value: number) => void;
  hideErrors: boolean;
  index?: number;
}

const QuantityButtonAdd = (
  add: () => void,
  index?: number
) => (
  <div className="product-description__quantity" data-test="quantityControl">
    <div onClick={add} data-test="increaseButton">
      <Icon size={16} name="plus" />
    </div>
  </div>
);
const QuantityButtonSubtract = (
  subtract: () => void,
  index?: number

) => (
  <div className="product-description__quantity" data-test="quantityControls">
    <div onClick={subtract} data-test="subtractButton">
      <Icon size={16} name="horizontal_line" />
    </div>
  </div>
);

export const QuantityTextField: React.FC<QuantityTextFieldProps> = ({
  disabled,
  quantity,
  index,
  maxQuantity,
  onQuantityChange,
  hideErrors,
}: QuantityTextFieldProps) => {
  const [tempQuantity, setTempQuantity] = useState<string>(quantity.toString());
  const [isTooMuch, setIsTooMuch] = useState(false);

  const handleBlurQuantityInput = () => {
    let newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    const newTempQuantity = newQuantity.toString();
    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);

  };

  useEffect(() => {
    setTempQuantity(quantity.toString());
  }, [quantity]);

  const add = 
    () => ( quantity < maxQuantity && onQuantityChange(quantity + 1));

  const subtract =
    () => (quantity > 1 && onQuantityChange(quantity - 1));

  const handleQuantityChange = (evt: React.ChangeEvent<any>) => {
    const newQuantity = parseInt(evt.target.value, 10);

    setTempQuantity(evt.target.value);

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  const quantityErrors = isTooMuch
    ? [
        {
          message: `Maximum quantity is ${maxQuantity}`,
        },
      ]
    : undefined;

  const windowSize =  window.onresize = () => {
    if(window.innerWidth <= 540 )
    {return true}
    else{return false}
  }

  return (
    <TextField
      name="quantity"
      label="Quantity"
      value={tempQuantity}
      onBlur={handleBlurQuantityInput}
      onChange={handleQuantityChange}
      contentLeft={(QuantityButtonSubtract(subtract, index))}
      contentRight={(QuantityButtonAdd(add, index))}
      isProductPage={true}
      errors={quantityErrors}
      borderRadius={windowSize() ? 30 : 5 }
    />
  );
};
