import {media, styled} from '@styles';

export const Wrapper = styled.div`
	background: #fff;
	margin: 0.5rem;
	text-align: center;
	max-height: 29rem;
	min-height: 21rem;
	padding-bottom: 1rem;
	transition: 0.3s;
	border-radius: 10px;
	outline: none;
	${media.smallScreen`
  min-height: 18rem;

  `}
	&:hover {
		color: ${(props) => props.theme.colors.primary};
		img {
			box-shadow: 5px 5px 15px #b3b3b3;
		}
	}
`;

export const DetailsWrapper = styled.div`
	padding: 0 0.5rem;
`;

export const Title = styled.h4`
	color: ${(props) => props.theme.colors.primary} !important;
	font-family: ${(props) => props.theme.typography.baseFontFamily} !important;
	text-transform: capitalize;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	margin: 0 0 0.5rem 0;
	text-align: left;
	min-height: 2.5rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	word-break: break-word;
	overflow-wrap: anywhere;
`;

export const Merchant = styled.p`
	color: ${(props) => props.theme.colors.primary};
	font-family: ${(props) =>
		props.theme.typography.secondaryFontFamily} !important;
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 0.5rem;
	text-align: left;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
`;

export const Category = styled.p`
	color: #a4a4a4;
	font-size: 12px;
	text-transform: uppercase;
	text-align: left;
	margin: -0.5rem 0 0 0;
`;

export const Price = styled.p`
	color: ${(props) => props.theme.colors.primary};
	> span {
		font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	}
	text-align: left;
	font-size: ${(props) => props.theme.typography.smallFontSize};
`;

export const PriceWrapper = styled.div`
	* {
		color: ${(props) => props.theme.colors.primary};
	}
`;

export const Image = styled.div`
	width: 100%;
	height: auto;
	max-width: 100%;

	> img {
		width: 100%;
		height: auto;
		max-width: 100%;
		border-radius: 10px;
		object-fit: cover;
	}
`;

export const LocationWrapper = styled.div`
	* {
		color: ${(props) => props.theme.colors.primary} !important;
	}
`;
