
import React, { useState }  from "react";
import Media from "react-responsive";
import { smallScreen } from "@styles/constants";

// import ReactSVG from "react-svg";

import moment from "moment";
import { SubDetails } from "@components/containers";

import { Breadcrumb, Breadcrumbs } from "../../components";

import { StoreDetailsMerchantBySlug } from "./gqlTypes/StoreDetails";
import * as S from "./styles";
import { formatStoreOperatingDays } from "src/core/checkoutScheduleUtils";
import { getDBIdFromGraphqlIdString } from "../../core/utils";

import StoreProductsView from "./StoreProducts/View";
// import cartImg from "../../images/shopping-cart.svg";
import merchantImgPlaceholder from '../../images/merchant-placeholder-img-background.png';
import merchantLogoPlaceholder from '../../images/merchant-placeholder-avatar.png';
interface PageProps {
  merchant: StoreDetailsMerchantBySlug | null;
  breadcrumbs: Breadcrumb[];
  headerImage: string | null;
}
export const Page: React.FC<PageProps> = ({
  breadcrumbs,
  headerImage,
  merchant,
}) => {

  // const isMobile = useMediaQuery({ query: `(max-width: ${smallScreen}px)` });
  
  const [isOpen, setisOpen] = useState(false);

  const merchantId = getDBIdFromGraphqlIdString(merchant.id, "Merchant");
  const { storeHourStart, storeHourEnd, operatingDays, warehouses } = merchant;
  const storeOpen =
    storeHourStart && moment(storeHourStart, "HH:mm:ss").format("h:mma");
  const storeClose =
    storeHourEnd && moment(storeHourEnd, "HH:mm:ss").format("h:mma");
  const address =
    warehouses &&
    warehouses.edges &&
    warehouses.edges.length > 0 &&
    warehouses.edges[0].node &&
    warehouses.edges[0].node.address
      ? warehouses.edges[0].node.address
      : null;

  const bannerSrc = merchant?.banner?.url;
  const logoSrc = merchant?.logo?.url;

  const bannerStyleMobile = {
    backgroundImage: bannerSrc ? `url(${bannerSrc})` : `url(${merchantImgPlaceholder})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: bannerSrc ? '100px' : '200px',
  }

  const bannerStyle = {
    backgroundImage: bannerSrc ? `url(${bannerSrc})` : `url(${merchantImgPlaceholder})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: bannerSrc ? '460px' : '400px',
  }
  
  const SMALL_SCREEN = 541;

  return (
    <>
    <div className="article-page">
      {/* BANNER */}
      <Media maxWidth={smallScreen}>
      <div className="article-page__header" style={bannerStyleMobile}></div>
      </Media>
      <Media minWidth={SMALL_SCREEN}>
      <div className="article-page__header" style={bannerStyle}></div>
      </Media>

      {/* CONTENT */}
      <div className="container">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <S.Content>
          <div className="d-block d-sm-flex mr-md-5">
            <S.LogoContainer>
              <S.Logo>
              <img
                  src={logoSrc ? logoSrc : merchantLogoPlaceholder}
                  alt="trademark"
                />
              </S.Logo>
            </S.LogoContainer>
            <div className="flex-grow-1 mt-1" style={{order: 1}}>
              <S.Title>{`${
                merchant?.storeTitle ? merchant?.storeTitle : merchant?.name
              }`}</S.Title>
              <S.Description>
              <p>Subcategories</p>
              <S.Details>
              <SubDetails
                  items={[
                    {
                      iconName: "fas fa-map-marker-alt",
                      text: address?.city,
                      toolTip: "Store Location",
                    },
                  ]}
                />
              </S.Details>
              </S.Description>
              <S.Description>{merchant.storeDescription}
              <S.ReadMore isOpen={isOpen} onClick={()=> {
                  setisOpen(true);
                }}>
                  See more
                </S.ReadMore>
                <S.Close isOpen={isOpen} onClick={()=> {
                  setisOpen(false);
                }}>
                  Close
              </S.Close>
              </S.Description>
              <S.AboutContainer isOpen={isOpen}>
              {/* <S.About>About</S.About> */}
              <S.SubdetailsContainer>
                <SubDetails
                  items={[
                    {
                      iconName: 'fas fa-map-pin',
                      text: address?.streetAddress1,
                      toolTip: "Store Location",
                    },
                  ]}
                />
                <SubDetails
                  items={[
                    {
                      iconName: "far fa-clock",
                      text:
                        storeHourStart && storeHourEnd && operatingDays
                          ? `${
                            operatingDays &&
                            `${formatStoreOperatingDays(operatingDays)} |`
                          } ${
                              storeHourStart &&
                              storeHourEnd &&
                              `${storeOpen} - ${storeClose}`
                            }`
                          : null,
                      toolTip: "Operating hours - days",
                    },
                  ]}
                />
                <SubDetails
                  items={[
                    {
                      iconName: "fa fa-phone",
                      text: address?.phone,
                      toolTip: "Store Contact",
                    },
                  ]}
                />
              </S.SubdetailsContainer>
              
              </S.AboutContainer>
            </div>
            
          </div>
        </S.Content>

        <S.ProductsContainer>
          <S.Products>Products</S.Products>
          <S.Divider />
          <StoreProductsView merchantId={merchantId} />
        </S.ProductsContainer>
      </div>
    </div>
    {/* <Media maxWidth={smallScreen}>
    <S.OverlayWrapper>
    <S.Flex>
    <S.Items>6 Items</S.Items>
    <S.Stores>Delivery from 2 stores</S.Stores>
    </S.Flex>
    <S.Price>PHP 1,500.00</S.Price>
    <S.CartIcon>
    <ReactSVG path={cartImg} />
    </S.CartIcon>
   </S.OverlayWrapper>
    </Media> */}
    </>
  );
};
export default Page;
