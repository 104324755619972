import {media, styled} from '@styles';

export const Loader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	z-index: 10;
`;

export const Wrapper = styled.div`
	margin: 45px 0;
	display: grid;

	grid-template-columns: 8fr 4fr;
	grid-template-rows: 85px auto auto;
	grid-column-gap: 30px;
	grid-template-areas:
		'navigation cartSummary'
		'checkout cartSummary'
		'button cartSummary'
		'notes cartSummary';

	${media.mediumScreen`
    grid-template-columns: 1fr;
    grid-template-areas:
      "navigation"
      "checkout"
      "button"
      "notes";
  `}
`;

export const Navigation = styled.div`
	grid-area: navigation;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightFont};
	padding-bottom: 43px;
	height: 85px;
`;
export const Checkout = styled.div`
	grid-area: checkout;
	padding: 2rem 0;
`;
export const CartSummary = styled.div`
	grid-area: cartSummary;

	${media.mediumScreen`
    position: fixed;
    bottom: 0;
    z-index: 10;
  `}
`;
export const Button = styled.div`
	grid-area: button;
`;
export const NoteWrapper = styled.div`
	grid-area: notes;
	margin: 1rem 0;
`;
export const NoteContainer = styled.div`
	background-color: ${(props) => props.theme.colors.cardBg};
	padding: 20px 20px;
	margin: 1rem 0;
	border-radius: 10px;
	p {
		font-family: ${(props) => props.theme.typography.baseFontFamily};
		font-size: ${(props) => props.theme.typography.smallFontSize};
		line-height: 1.5rem;
		color: ${(props) => props.theme.colors.baseFont};
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	> img:first-child {
		width: 60px;
		height: 65px;
		margin: 0.5rem 0.5rem;
		align-self: center;
		justify-self: center;
	}
	> img:nth-child(2) {
		width: 80px;
		height: 60px;
		margin: 0.5rem 0.5rem;
		align-self: center;
		justify-self: center;
	}
	${media.largeScreen`
  flex-wrap: wrap;
  `};
`;
