import React from "react";

import { RichTextContent } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

import { Link } from "react-router-dom";

import { formatStoreOperatingDays, DAYS_LIST_ENUMS } from "src/core/checkoutScheduleUtils";

import { generateStoreUrl } from "src/core/utils";

enum TABS {
  DESCRIPTION,
  ATTRIBUTES,
  STORE_INFO
}

export const ProductDescription: React.FC<IProps> = ({
  description = "",
  descriptionJson = "",
  attributes,
  merchant,
}: IProps) => {
  const [activeTab, setActiveTab] = React.useState<TABS>(TABS.DESCRIPTION);
  const address = merchant 
                  && merchant?.warehouses
                  && merchant?.warehouses.edges.length
                  && merchant?.warehouses.edges.length > 0
                  ? merchant?.warehouses.edges[0].node.address
                  : null;

  return (
    <S.Wrapper>
      <S.Tabs>
        <S.TabTitle
          active={activeTab === TABS.DESCRIPTION}
          onMouseEnter={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.DESCRIPTION);
          }}
          onClick={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.DESCRIPTION);
          }}
        >
          Description
        </S.TabTitle>
        {/* <S.TabTitle
          active={activeTab === TABS.ATTRIBUTES}
          onMouseEnter={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.ATTRIBUTES);
          }}
          onClick={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.ATTRIBUTES);
          }}
        >
          Attributes
        </S.TabTitle> */}
        <S.TabTitle
          active={activeTab === TABS.STORE_INFO}
          onMouseEnter={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.STORE_INFO);
          }}
          onClick={evt => {
            evt.stopPropagation();
            setActiveTab(TABS.STORE_INFO);
          }}
        >
          Store Info
        </S.TabTitle>
      </S.Tabs>
      {activeTab === TABS.DESCRIPTION &&
        (descriptionJson ? (
          <S.Description>
          <RichTextContent descriptionJson={descriptionJson} />
          </S.Description>
        ) : (
          <S.Description>
          <p>{description}</p>
          </S.Description>
        ))}
      {activeTab === TABS.ATTRIBUTES && (
        <S.AttributeList>
          {attributes &&
            attributes.map((attribute, index) => (
              <li key={index}>
                <S.AttributeName>{attribute.attribute.name}: </S.AttributeName>{" "}
                {attribute.values.map(value => value.name).join(", ")}
              </li>
            ))}
        </S.AttributeList>
      )}
      {activeTab === TABS.STORE_INFO && !!merchant && (
        <>
          <S.StoreInfoTitle>
            <Link to={generateStoreUrl(merchant?.slug || "")}>{merchant?.storeTitle ? merchant?.storeTitle : ""}</Link>
          </S.StoreInfoTitle>
          <br />
          <S.StoreDescription>
          <p>{merchant?.storeDescription ? merchant?.storeDescription : ""}</p>
          </S.StoreDescription>
          <br />
          <S.StoreInfoList>
            <li>
              <S.AttributeName>Address: </S.AttributeName>{" "}
              {address && address.streetAddress1}
            </li>
            <li>
              <S.AttributeName>Contact No.: </S.AttributeName>{" "}
              {address && address.phone}
            </li>
            <li>
              <S.AttributeName>Operating Day(s): </S.AttributeName>{" "}
              {merchant?.operatingDays && formatStoreOperatingDays(merchant?.operatingDays, DAYS_LIST_ENUMS.TRIPPLE_CHAR)}
            </li>
          </S.StoreInfoList>
        </>
      )}
    </S.Wrapper>
  );
};
