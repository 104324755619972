import "./scss/index.scss";

import * as React from "react";

import { useSignIn } from "@saleor/sdk";
import { demoMode } from "@temp/constants";
import { maybe } from "@utils/misc";

import { Button, Form, TextField } from "..";

import { TypedValidateFacebookUserMutation } from "../../@next/components/containers/FBLogin/mutations"
import { FBLoginDialog } from "../../@next/components/containers/FBLogin/helper";
import { ValidateFacebookUser } from "../../@next/components/containers/FBLogin/types"
import * as S from "./styles";
import { useAlert } from "react-alert";
import ForgottenPassword from "../OverlayManager/Login/ForgottenPassword";
import { useHistory, Link } from 'react-router-dom';
import * as helper from "src/helpers";
import jrlogo from "../../images/jr.png";

interface ILoginForm {
  hide?: () => void;
  onClick?: () => void;
}

const LoginForm: React.FC<ILoginForm> = ({ hide, onClick }) => {
  const [signIn, { loading, error }] = useSignIn();
  const alert = useAlert();
  const history = useHistory();
  const showErrorAlert = (title: string, message: string) => {
    alert.error({
      content: message,
      title,
    }, {
      timeout: 2000, // custom timeout just for this one alert
      // onClose: () => {}, // callback that will be executed after this alert is removed
      // onOpen: () => {}, // callback that will be executed after this alert open
    })
  };
  const handleFacebookLogin = async(event, validateFacebookUser) => {
    try {
      const fbUser = await FBLoginDialog();

      if (fbUser && fbUser.id) {
        const validateUserResponse: ValidateFacebookUser = await validateFacebookUser({ 
          variables: {
            facebookAccessToken: fbUser.accessToken,
            facebookId: fbUser.id,
            facebookName: fbUser?.name
          } 
        });
  
        const { facebookUser, facebookUserKey, errors } = validateUserResponse?.data?.validateFacebookUser || {};

        // Display error
        if (errors && errors.length && errors.length > 0) {
          showErrorAlert('Error', errors[0].message);
          return;
        }

        // Do login using fb credentials
        if (facebookUser?.email && facebookUserKey) {
          handleOnSubmit(event, { email: facebookUser?.email, password: facebookUserKey })
        } else {
          throw new Error('Facebook user not found.')
        }
      }
    } catch (e) {
      showErrorAlert('Error', 'Error occurs upon log-in to Facebook.')
    }
  };

  const handleOnSubmit = async (evt, { email, password }) => {
    evt.preventDefault();
    const authenticated = await signIn({ email, password });
    // if (authenticated && hide) {
    //   hide();
    // }
    if (authenticated) {
      alert.success({
        // content: "Login completed",
        title: "You are now logged in.",
      }, {
        timeout: 2000
      });

      // Clear checkout if exists and retain only the items in cart
      const checkoutData = helper.getCheckoutToLocalStorage();
      helper.setCheckoutToLocalStorage({
        lines: checkoutData?.lines || []
      });

      setTimeout(() => {
        history.push("/");
      }, 1000)
    }
  };

  const formData = demoMode
    ? {
        email: "admin@example.com",
        password: "admin",
      }
    : {};

  return (
    <TypedValidateFacebookUserMutation>
      {(validateFacebookUser, { loading: isFBLoading, data }) => (
      <div className="login-form">
        <div className="login-form__button">
          <S.FacebookLoginBtn
            disabled={loading || isFBLoading}
            onClick={(e) => handleFacebookLogin(e, validateFacebookUser)}
          >
            <S.FacebookLoginIcon className="fab fa-facebook-f pr-2"/>
            {loading || isFBLoading ? "Loading" : "Continue with Facebook"}
          </S.FacebookLoginBtn>
        </div>
        <div className="login-form__button"> 
          <Link
            className="login-form__button__btn-joyride" 
            to={{pathname: "https://connect.joyride.com.ph/login/jrmall"}} 
            target="_blank"> 
            <img src={jrlogo}/> 
            {loading ? "Loading" : " Continue with JoyRide"}
          </Link>
        </div>
        <hr></hr>
        <p className="login-form__options">Or continue with email</p>
        <Form
          className="login-form__form"
          data={formData}
          errors={maybe(() => error.extraInfo.userInputErrors, [])}
          onSubmit={handleOnSubmit}
        >
          <TextField
            name="email"
            autoComplete="email"
            label="Email Address"
            type="email"
            required
          />
          <TextField
            name="password"
            autoComplete="password"
            label="Password (at least 8 characters)"
            type="password"
            required
          />
          <ForgottenPassword onClick={onClick} />
          <div className="login-form__button">
            <Button
              className="btn-login"
              testingContext="submit"
              type="submit"
              {...(loading && { disabled: true })}
            >
              {loading ? "Loading" : "Login"}
            </Button>
          </div>
        </Form>
        <div style={{fontSize: '14px',color: '#767676',marginTop: '1.5rem'}}>
          By clicking Sign Up or Continue with Facebook, you agree to our 
          <a href="/support/terms-and-conditions" style={{color: '#152c59',textDecoration: 'underline', paddingLeft: '0.2rem'}}>Terms and Conditions</a> and 
          <a href="/support/privacy-policy" style={{color: '#152c59',textDecoration: 'underline', paddingLeft: '0.2rem'}}>Privacy Policy.</a>
        </div>
      </div>
    )}
    </TypedValidateFacebookUserMutation>
  );
};

export default LoginForm;
