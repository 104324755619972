import React, {FC} from 'react';
import ProductCard, {ProductCardProps} from '@temp/components/ProductCard';
import EmptyScreen from './EmptyScreen';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import './index.scss';
import { Element } from 'react-scroll'

SwiperCore.use([Navigation]);

interface FeaturedProps {
	name?: string;
	index?: number;
	products?: ProductCardProps[];
	setProductOverlay?: (state: boolean, id?: string)=> void;
}

const Featured: FC<FeaturedProps> = ({name = '[name]', products = [], setProductOverlay, index}) => {
	const productList = products.map((product) => (
		<SwiperSlide>
			<ProductCard setProductOverlay={setProductOverlay} key={product?.id} {...product} categoryName={product?.categoryName} />
		</SwiperSlide>		
	));

	const prevRef = React.useRef<HTMLDivElement>(null)
	const nextRef = React.useRef<HTMLDivElement>(null)

	const params = {
		observeParents : true,
		observer: true,
		slidesPerView: 1.5,
		freeMode : true,
		spaceBetween: 10,
		breakpoints:{
			1265:{
				slidesPerView: 6,
			},
			992:{
				slidesPerView: 5,
			},
			768: {
				slidesPerView: 4,
			},
			540: {
				slidesPerView: 3.2,
			},
			360: {
				slidesPerView: 2.1,
			}
		},
		navigation: {
			nextEl: nextRef.current,
			prevEl: prevRef.current,
		},
	}

	return (
		<Element className="featured position-relative" name={`collection-${index+1}`}>
			<h4 className="featured-title">{name}</h4>
			<div className="mt-2 mb-4">
			<Swiper {...params} className="py-2">
				{productList}
			</Swiper>
			

			<div ref={prevRef} style={{position: 'absolute', top: '40%', left: '-2rem'}} className="swiper-button-prev"><i style={{fontSize: 25}} className="fas fa-chevron-left"></i></div>
			<div ref={nextRef} style={{position: 'absolute', top: '40%', right: '-2rem'}} className="swiper-button-next"><i style={{fontSize: 25}} className="fas fa-chevron-right"></i></div>
				{/* <ScrollMenu data={productList} alignCenter={false} /> */}
				{!productList.length && <EmptyScreen />}
			</div>
		</Element>
	);
};

export default Featured;
