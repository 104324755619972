import React from "react";
// import { Link } from "react-router-dom";

import { Button, Loader } from "@components/atoms";
// import { ProductTile } from "@components/molecules";

// import { generateProductUrl, generateStoreProductUrl } from "../../../../core/utils";

import * as S from "./styles";
import { IProps } from "./types";
import { BrandProductbyTabTile } from "../../molecules/BrandProductbyTabTile";
import ScrollMenu from 'react-horizontal-scrolling-menu';


export const BrandProductbyTab: React.FC<IProps> = ({
  products,
  canLoadMore = false,
  loading = false,
  testingContextId,
  isMerchantProduct,
  onLoadMore = () => null,
  isStorePage = false,
  title = ''
}: IProps) => {

  const menu =  products.map(product => (
      <BrandProductbyTabTile product={product} hideStoreTitle={isStorePage} isMerchantProduct={isMerchantProduct} />
  ))
  // const generateUrl = location.pathname && location.pathname.startsWith("/store/") 
  //                   ? generateStoreProductUrl
  //                   : generateProductUrl;
  return (
    <S.Wrapper>
    {title && <S.Title>{title}</S.Title>}
      <ScrollMenu 
        wrapperClass="pb-1"
        data={menu}
        alignCenter={false}
      />
      <S.Loader>
        {loading ? (
          <Loader />
        ) : (
          canLoadMore && (
            <Button
              testingContext="loadMoreProductsButton"
              color="secondary"
              onClick={onLoadMore}
            >
              <span>More +</span>
            </Button>
          )
        )}
      </S.Loader>
    </S.Wrapper>
  );
};
