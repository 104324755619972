import React from 'react';
import { ProductDetails_product } from "src/screens/mobile/BranchPage/gqlTypes/ProductDetails";
import BrandProductImage from './BrandProductImage';
import BrandProductDescription from './BrandProductDescription';
import BrandVariantPicker from './BrandVariantPicker';
import ProductAddToCart from './ProductAddToCart';
import BrandProductNotes from './BrandProductNotes';
import './index.scss'
// import { mediumScreen } from '@temp/@next/globalStyles/constants';
// import { useMediaQuery } from 'react-responsive';
import { Loader } from "@components/atoms";
// import { X } from 'react-feather';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { X } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
import { useLayout } from '@temp/layout/hooks/useLayout';
import { convertMinutesToHoursDaysString } from '@temp/helpers';

SwiperCore.use([Pagination]);
interface BrandProductProps {
	setProductOverlay: (status: boolean, id?: string) => void;
	defaultQty?: number;
	product?: ProductDetails_product | null;
	loading?: boolean;
	merchant?: any | null;
	isFromCart?: boolean;
	defaultNotes?: string | null;
}

const BrandProduct: React.FC<BrandProductProps> = ({
	setProductOverlay,
	defaultQty,
	product,
	loading,
	merchant,
	isFromCart = false,
	defaultNotes
}) => {
	const {
		name,
		category,
		descriptionJson,
		images,
		leadTime
	} = product || {};
	const { addCartItem, updateCartItem } = useLayout();
	const [notes, setNotes] = React.useState("");
	const [mainImageUrls, setMainImageUrls] = React.useState(null);
	const [selectedVariant, setSelectedVariant] = React.useState(null);

	// We sort the variant attribute based on what is the sort order set by admin personnel
	const variants = (product?.variants || []).sort((variant1, variant2) => {
		const sortOrder1 = (variant1?.attributes && variant1?.attributes?.length > 0 && variant1?.attributes[0]?.values && variant1?.attributes[0]?.values.length > 0)
						? variant1?.attributes[0]?.values[0].sortOrder
						: 0;
		const sortOrder2 = (variant2?.attributes && variant2?.attributes?.length > 0 && variant2?.attributes[0]?.values && variant2?.attributes[0]?.values.length > 0)
						? variant2?.attributes[0]?.values[0].sortOrder
						: 0;
		return sortOrder1 - sortOrder2;
	});

	const defaultVariant = !!variants?.length && variants?.length > 0 ? variants[0] : null;

	const hasVariant = !!defaultVariant && !!defaultVariant.attributes?.length && defaultVariant.attributes?.length > 0;
	const variantTitle = hasVariant 
					? variants[0]?.attributes[0]?.attribute?.name || "Please Select"
					: "Please Select";

	const productName = hasVariant 
					? name
					: defaultVariant?.isAvailable ? name : `${name} (Out of stock)`;

	const productVariant = selectedVariant || defaultVariant;

	const params = {
		observeParents : true,
		observer: true,
		pagination: {
			el: '.swiper-image-pagination',
			clickable: true,
			slidesPerView: 1,
		}
	}

	const [isHovered, setisHovered] = React.useState(false);
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const productImages = mainImageUrls || images || [];
	const preparationTime = convertMinutesToHoursDaysString(leadTime);

	const renderImageCarousel = () => {
		return (
			<Swiper style={{flex: 1, position: 'relative', maxWidth: `${isTablet ? '100%': ''}`}} {...params}>
				{productImages?.length !== 0 && productImages.map((img)=> (
					<SwiperSlide>
						<BrandProductImage 
							key={img?.id} 
							setProductOverlay={setProductOverlay} 
							source={img?.url} 
							category={category?.name}
						/>
					</SwiperSlide>
				))}
				{productImages?.length === 0 && (
					<SwiperSlide>
						<BrandProductImage  
							setProductOverlay={setProductOverlay} 
							source={null} 
							category={category?.name}
						/>
					</SwiperSlide>
				)}
				{(mainImageUrls || images || []).length > 1 && <div className="swiper-image-pagination"></div>}
			</Swiper>
		)
	};

	const renderCloseButton = () => {
		return (
			<div 
				onMouseEnter={()=>setisHovered(true)} 
				onMouseLeave={()=>setisHovered(false)} 
				onClick={()=>setProductOverlay(false)} 
				className="brand-product-image__close" 
				style={{backgroundColor: `${isHovered ? '#fff':'rgb(82, 82, 82)'}`}}
				>
					<X color={isHovered ? 'rgb(82, 82, 82)' : '#fff'}/>
			</div>
		)
	}

	return (	
		<div className="brand-product">
			{loading && !isTablet ? <Loader /> : 
			<div className="brand-product-container">
				{/* <div style={{borderRadius: '50%', padding: '10px', backgroundColor: 'rgb(82,82,82)', position: 'absolute', top: '15px', left: '15px', zIndex: 600}}><X color="#fff"/></div> */}
				{renderImageCarousel()}
				{renderCloseButton()}
				<div className="brand-product-wrapper">
					<div className="brand-product-wrapper__overflow">
						<BrandProductDescription preparationTime={preparationTime} name={productName} descriptionJson={descriptionJson}/>
						{hasVariant &&
						<BrandVariantPicker 
							title={variantTitle}
							variants={variants} 
							onClick={(data) => {
								if (data?.images?.length && data?.images?.length > 0) {
									setMainImageUrls(data?.images)
								}
								setSelectedVariant(data);
							}}
						/>}
						<BrandProductNotes 
							disabled={(window?.location?.pathname || "").indexOf("checkout/") >= 0}
							defaultText={defaultNotes} 
							onChange={(data) => setNotes(data?.target?.value)} 
						/>
						<div style={{padding: '1rem'}}>
							<p style={{color: '#152c59',fontSize: '12.5px', lineHeight: '1rem'}}>For <strong>bulk orders</strong>, please call our hotline at (02) 8864-7777 or send us an email at bulkorders@jrmall.ph</p>
						</div>
					</div>
					{!isTablet && <ProductAddToCart 
						setProductOverlay={setProductOverlay} 
						defaultQty={defaultQty}
						addToCartText={isFromCart ? "Update Cart" : "Add To Cart"}
						onAddedText={isFromCart ? 'Cart Updated' : 'Added to Cart'}
						price={productVariant?.pricing?.price} 
						disable={!productVariant?.isAvailable}
						onAdd={(qty, total) => {
							if (isFromCart) {
								updateCartItem(productVariant?.id, qty, notes)
							} else {
								addCartItem(productVariant?.id, qty, merchant?.id, productName, notes);
							}
						}}
					/>}
				</div>
			</div>}
			{isTablet && <ProductAddToCart 
						setProductOverlay={setProductOverlay} 
						defaultQty={defaultQty}
						addToCartText={isFromCart ? "Update Cart" : "Add To Cart"}
						onAddedText={isFromCart ? 'Cart Updated' : 'Added to Cart'}
						price={productVariant?.pricing?.price} 
						disable={!productVariant?.isAvailable}
						onAdd={(qty, total) => {
							if (isFromCart) {
								updateCartItem(productVariant?.id, qty, notes)
							} else {
								addCartItem(productVariant?.id, qty, merchant?.id, productName, notes);
							}
				}}
			/>}
		</div>
	);

};

export default BrandProduct;
