import {media, styled} from '@styles';

export const Content = styled.div`
	margin: 35px 0;
`;
export const LogoContainer = styled.div`
	order: 2;
	flex-shrink: 0;
	margin-left: auto;
	margin-bottom: 1.5rem;
	height: 150px;
	width: 150px;
	margin-top: -10rem;
	${media.smallScreen`
    height: 100px;
    width: 100px;
    margin-top: -9rem;
  `};
`;
export const Logo = styled.div`
	img {
		border-radius: 50%;
		box-shadow: 1px 1px 7px 1px #7d7d7d;
		object-fit: contain;
		background-color: #fff;
		height: 100%;
		width: 100%;
	}
`;

export const Title = styled.h2`
	font-size: 2rem;
	margin-top: -2rem;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	margin-bottom: 0.5rem;
	line-height: 2rem;
	${media.smallScreen`
    margin-top: -3rem;
    width: 75%;
    font-size: 1.5rem;
  `};
`;

export const Products = styled.h2`
	font-size: 1.3rem;
	margin-bottom: 1.5rem;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
`;

export const Description = styled.p`
	color: ${(props) => props.theme.colors.lightFont} !important;
	* {
		line-height: 1.5rem;
		color: ${(props) => props.theme.colors.lightFont} !important;
	}
	margin-bottom: 1rem;
`;

export const Details = styled.div`
	* {
		display: inline-block;
	}
`;
export const SubdetailsContainer = styled.div`
	margin-top: 10px;
	* {
		color: ${(props) => props.theme.colors.lightFont};
	}
`;
export const ProductsContainer = styled.div`
	margin-top: 50px;
`;
export const Divider = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.bannerEdge};
	margin: 5px 0px 20px 0px;
`;

export const About = styled.div`
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
`;

export const AboutContainer = styled.div<{
	isOpen: boolean;
}>`
	display: ${(props) => `${props?.isOpen ? 'unset' : 'none'}`};
`;

export const ReadMore = styled.a<{
	isOpen: boolean;
}>`
  margin-left: 10px;
  // padding: 3px 20px;
  border-radius: 15px;
  // background-color: ${(props) => props.theme.colors.primary};
  color: #76aad3 !important;
  font-family: ${(props) => props.theme.typography.secondaryFontFamily};
  display: ${(props) => `${props?.isOpen ? 'none' : 'inline-block'}`};
  outline: none !important;
  &:hover{
    text-decoration: underline !important;
  }
  ${media.smallScreen`
    margin-left: 10px;
  `};
`;

export const Close = styled.a<{
	isOpen: boolean;
}>`
  margin-left: 10px;
  // padding: 3px 20px;
  border-radius: 15px;
  // background-color: ${(props) => props.theme.colors.primary};
  color: #76aad3 !important;
  font-family: ${(props) => props.theme.typography.secondaryFontFamily};
  display: ${(props) => `${props?.isOpen ? 'unset' : 'none'}`};
  outline: none !important;
  &:hover{
    text-decoration: underline !important;
  }
`;

export const OverlayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 85%;
  left: 5%;
  right: 0;
  background-color: ${(props) => props.theme.colors.secondary};
  min-height: 60px;
  max-height: 80px
  width: 90%;
  border-radius: 50px;
  padding: 10px 20px;
  
  `;
export const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	flex: 50%;
`;
export const Items = styled.div`
	display: block;
	padding: 0;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
`;

export const Stores = styled.div`
	margin-top: -0.3rem;
	display: block;
	padding: 0;
	font-size: ${(props) => props.theme.typography.smallFontSize};
	font-family: ${(props) => props.theme.typography.baseFontFamily};
`;

export const Price = styled.div`
	margin-right: 0.5rem;
	font-size: ${(props) => props.theme.typography.baseFontSize};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
`;

export const CartIcon = styled.div`
	margin-top: -0.2rem;
	* {
		width: 20px;
		height: 20px;
		fill: #191919;
	}
`;

export const Container = styled.div`
	width: 100%;
	min-height: 300px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border: 1.5px dashed ${(props) => props.theme.colors.secondary};
	border-radius: 20px;
	margin-bottom: 3rem;
	background-color: #f9f9f9;
	> h5 {
		color: ${(props) => props.theme.colors.secondary};
		margin: 2rem 0 1rem 0;
		font-size: 40px;
	}
	> p {
		color: ${(props) => props.theme.colors.baseFontColorSemiTransparent};
	}
	${media.smallScreen`
  min-height: 200px;
  > h5 {
    margin: 1rem 0 0.5rem 0;
    font-size: 25px;
  }
  > p{
    font-size: 12px;
  }
  `};
`;
