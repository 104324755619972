import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import {CheckoutLogin, NotFound} from '../components';
import UserAccount, * as accountPaths from '../userAccount/routes';
import {OrderDetails} from '../userAccount/views';
import {Account, AccountConfirm} from '../views/Account';
import {ArticlePage} from '../views/Article';
import {CategoryPage} from '../views/Category';
import {CollectionPage} from '../views/Collection';
// import { HomePage } from "../views/Home";

import {ProductPage} from '../views/Product';
import {SearchPage} from '../views/Search';
import {StoreDetailsPage} from '../views/Store';
import {StoreCategoryPage} from '../views/StoreCategory';
// import {HomePage} from '../views/Home';
import HomePage from '@screens/mobile/Home';
import BrandRoutes, * as brandPaths from '@screens/mobile/Home/routes';
import {CartPage, CheckoutPage, PasswordReset, ThankYouPage} from '@pages';
import SupportPage from "../@next/pages/SupportPage/routes";
import BrandCollectionsPage from "@screens/mobile/BrandCollectionsPage";
import * as paths from './paths';
import SearchAddress from '@temp/screens/mobile/SearchAddress';
import BrandPage from "@screens/mobile/BrandPage";
import SignUp from '@temp/screens/mobile/SignUp';
import SignIn from '@temp/screens/mobile/SignIn';
import BranchPage from '@temp/screens/mobile/BranchPage';
import CheckoutGuest from '@temp/screens/mobile/CheckoutGuest';
import { OrderCancelledPage } from '@temp/@next/pages/CancelledPage';

export const DesktopRoutes: React.FC = () => (
	<Switch>
		<Route exact path={paths.baseUrl} component={HomePage} />
		<Route exact path={paths.baseUrlwithAuth} component={HomePage} />
		<Route path={brandPaths.baseUrl} component={BrandRoutes} />
		<Route path={paths.branch} component={BranchPage} />
		<Route exact path={paths.signIn} component={SignIn} />
		<Route exact path={paths.signUp} component={SignUp} />
		<Route exact path={paths.searchAddress} component={SearchAddress} />
		<Route exact path="/checkout-guest" component={CheckoutGuest} />
		<Route path={paths.searchUrl} component={SearchPage} />
		<Route path={paths.categoryUrl} component={CategoryPage} />
		<Route path={paths.collectionUrl} component={CollectionPage} />
		<Route path={paths.productUrl} component={ProductPage} />
		<Route path={paths.cartUrl} component={CartPage} />
		<Route path={paths.checkoutLoginUrl} component={CheckoutLogin} />
		<Route path={paths.pageUrl} component={ArticlePage} />
		<Route path={accountPaths.baseUrl} component={UserAccount} />
		<Route path={accountPaths.userOrderDetailsUrl} component={OrderDetails} />
		<Route path={paths.guestOrderDetailsUrl} component={OrderDetails} />
		<Route path={paths.accountUrl} component={Account} />
		<Route path={paths.accountConfirmUrl} component={AccountConfirm} />
		<Route path={paths.orderHistoryUrl} component={Account} />
		<Route path={paths.addressBookUrl} component={Account} />
		<Route path={paths.passwordResetUrl} component={PasswordReset} />
		<Route path={paths.checkoutUrl} component={CheckoutPage} />
		<Route path={paths.orderFinalizedUrl} component={ThankYouPage} />
		<Route path={paths.orderCancelledUrl} component={OrderCancelledPage} />
		<Route path={paths.storeUrl} component={StoreDetailsPage} />
		<Route path={paths.storeProductUrl} component={ProductPage} />
		<Route path={paths.storeCategoryUrl} component={StoreCategoryPage} />
		<Route path={paths.supportUrl} component={SupportPage} />
		<Route path={paths.brandCollectionsUrl} component={BrandCollectionsPage} />
		<Route path={paths.brandUrl} component={BrandPage} />
		<Route component={NotFound} />
	</Switch>
);

export default DesktopRoutes;
