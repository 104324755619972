import "./scss/index.scss";

import * as React from "react";

import { Button, Form, TextField, OverlayContext } from "..";
import { maybe } from "../../core/utils";
import { TypedPasswordResetMutation } from "./queries";

import { passwordResetUrl } from "../../app/routes";
import { useAlert } from "react-alert";

interface PasswordResetForm {
  onResolve?: (status: boolean) => void
}

const PasswordResetForm: React.FC<PasswordResetForm> = ({
  onResolve = () => {
    //
  }
}) => {
  const alert = useAlert();
  const overlay = React.useContext(OverlayContext);
  
  return (
    <div className="password-reset-form">
      <p>
        Please provide us your email address so we can share you a link to reset
        your password
      </p>
      <TypedPasswordResetMutation>
        {(passwordReset, { loading, data }) => {
          return (
            <Form
              errors={maybe(() => data.requestPasswordReset.errors, [])}
              onSubmit={(event, { email }) => {
                event.preventDefault();
                passwordReset({
                  variables: {
                    email,
                    redirectUrl: `${window.location.origin}${passwordResetUrl}`,
                  },
                })
                  .then((res) => {
                    if (res) {
                      const { errors } = res?.data?.requestPasswordReset || {};

                      if (errors.length) {
                        throw errors;
                      }
                      else {
                        alert.show({ title: "Successfully sent to your email." }, { type: "success" });
                        onResolve(true);
                        overlay.hide();  
                      }
                    }
                  })
                  .catch((e) => {
                    alert.show({ title: "Something went wrong." }, { type: "error" });
                    onResolve(false);
                    overlay.hide();
                  })
              }}
            >
              <TextField
                name="email"
                autoComplete="email"
                label="Email Address"
                type="email"
                required
              />
              <div className="password-reset-form__button">
                <Button
                  testingContext="submit"
                  type="submit"
                  {...(loading && { disabled: true })}
                >
                  {loading ? "Loading" : "Reset password"}
                </Button>
              </div>
            </Form>
          );
        }}
      </TypedPasswordResetMutation>
    </div>
  );
}

export default PasswordResetForm;
