import {styled} from '@styles';

export const Title = styled.h3`
	font-weight: ${(props) => props.theme.typography.boldFontWeight};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	font-size: 28px;
	color: ${(props) => props.theme.colors.primary};
`;
export const SubTitle = styled.h3`
	padding: 0 0 1.6rem 0;
	font-size: 1rem;
	color: ${(props) => props.theme.colors.primary};
	font-family: ${(props) => props.theme.typography.baseFontFamily};
`;
