import React, { useState } from 'react';
import './index.scss';
// import ProductPlaceholder from '../../../../images/merchant-placeholder-avatar.jpg';
import ReactImageMagnify from 'react-image-magnify';
import { smallScreen } from '@temp/@next/globalStyles/constants';
import { useMediaQuery } from 'react-responsive';
import * as helpers from "src/helpers";

interface IProps{
	category?: string;
	source?: string;
	setProductOverlay?: (state: boolean, id?: string)=> void;
}

const BrandProductImage:React.FC<IProps> = ({source, setProductOverlay, category = ""})=> {
	const [loaded, setLoaded] = useState(false);
	const imgSource = !source || !loaded ? helpers.getProductPlaceholder(category) : source;

	// const [isHovered, setisHovered] = useState(false);
	// const [backgroundImage] = useState(`url(${source})`);
	// const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');

	// const handleMouseMove = (e:any) => {
	// 	console.log(e.target.getBoundingClientRect(), ref.current);
		
	// 	const { left, top, width, height } = e.target.getBoundingClientRect();
	// 	console.log(e.pageX, e.pageY,  top, width, height);
	// 	const x = (e.pageX - left) / width * 100;
	// 	const y = (e.pageY - top) / height * 100;

	// 	console.log(x,y);
		
	// 	setStyle((prevState) => ({...prevState, backgroundPosition :`${x}% ${y}%`}));
	// }

	const isMobile = useMediaQuery({ maxWidth: smallScreen });

	return (	
		<div className="brand-product-image">
			{/* <img 
				src={imgSource} 
				onLoad={() => setLoaded(true)}
			/> */}
			{/* <img src={source ? source : ProductPlaceholder}/> */}
			{/* <div 
				onMouseEnter={()=>setisHovered(true)} 
				onMouseLeave={()=>setisHovered(false)} 
				onClick={()=>setProductOverlay(false)} 
				className="brand-product-image__close" 
				style={{backgroundColor: `${isHovered ? '#fff':'rgb(82, 82, 82)'}`}}
				>
					<X color={isHovered ? 'rgb(82, 82, 82)' : '#fff'}/>
				</div> */}
				<ReactImageMagnify {
					... {
					smallImage: {
						isFluidWidth: true,
						src: imgSource,
						onLoad: ()=> setLoaded(true),
					},
					largeImage: {
						src: imgSource,
						width: isMobile ? 600 : 1200,
						height: isMobile ? 600 : 1200,
					},
					enlargedImagePosition: 'over',
					isHintEnabled: false,
					imageStyle:{backgroundColor: '#fff'},
					enlargedImageStyle:{backgroundColor: '#fff'}
				}} />
		</div>
	);
};

export default BrandProductImage;
