import React, {
  forwardRef,
  RefForwardingComponent,
  useImperativeHandle,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useAlert } from "react-alert";
import { CheckoutReview } from "@components/organisms";
import { statuses as dummyStatuses } from "@components/organisms/DummyPaymentGateway";
import { useCheckout, useCart } from "@saleor/sdk";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IFormError, ISelectedPaymentTransactionType } from "@types";
import { removeCheckoutToLocalStorage, getCheckoutNotes, setCheckoutNotes, setCartItemNotesCache, setCheckoutSchedulesCache } from "src/helpers";

export interface ICheckoutReviewSubpageHandles {
  complete: () => void;
}
interface IProps extends RouteComponentProps<any> {
  selectedPaymentGatewayToken?: string;
  changeSubmitProgress: (submitInProgress: boolean) => void;
  paymentTransactionType?: ISelectedPaymentTransactionType | null;
}

const CheckoutReviewSubpageWithRef: RefForwardingComponent<
  ICheckoutReviewSubpageHandles,
  IProps
> = (
  { selectedPaymentGatewayToken, changeSubmitProgress, paymentTransactionType, ...props }: IProps,
  ref
) => {
  const history = useHistory();
  const { checkout, payment, completeCheckout } = useCheckout();
  const alert = useAlert();
  const { items } = useCart()
  const [errors, setErrors] = useState<IFormError[]>([]);

  const checkoutShippingAddress = checkout?.shippingAddress
    ? {
        ...checkout?.shippingAddress,
        phone: checkout?.shippingAddress?.phone || undefined,
      }
    : undefined;

  const checkoutBillingAddress = checkout?.billingAddress
    ? {
        ...checkout?.billingAddress,
        phone: checkout?.billingAddress?.phone || undefined,
      }
    : undefined;

  const getPaymentMethodDescription = () => {
    if (payment?.gateway === "mirumee.payments.dummy") {
      return `Dummy: ${
        dummyStatuses.find(
          status => status.token === selectedPaymentGatewayToken
        )?.label
      }`;
    } else if (payment?.creditCard) {
      return `Ending in ${payment?.creditCard.lastDigits}`;
    }
    return ``;
  };

  useImperativeHandle(ref, () => ({
    complete: async () => {
      changeSubmitProgress(true);

      // Additional validation for order lines
      let hasError = false;
      let errMessage;
      if (items) {
        items.forEach(item => {
          const { variant } = item;
          const { product } = variant;
          const itemMerchant = variant 
                              && product
                              ? product.merchant
                              : null;
          if (itemMerchant) {
            const delivery = checkout?.deliveryQuotations 
                            && checkout?.deliveryQuotations.length
                            && checkout?.deliveryQuotations.length > 0
                            ? checkout?.deliveryQuotations.find(quote => quote.merchant && quote.merchant.id === itemMerchant.id)
                            : null;

            if (!(!!delivery)) {
              hasError = true;
              errMessage = `${product && variant ? `${product.name}(${variant.name})` : "An item"} has no shipping details. Please go back to Shipping section then select shipping type.`;   
            }
          } else {
            hasError = true;
            errMessage = `${product && variant ? `${product.name}(${variant.name})` : "An item"} has no merchant.`;
          }
        })
      }

      if (hasError) {
        changeSubmitProgress(false);
        setErrors([{
          // @ts-ignore
          field: "shippingMethod",
          message: errMessage || "",
        }]);
        return;
      }

      const checkoutNotes = getCheckoutNotes();

      const { data, dataError, paymentRedirectUrl } = await completeCheckout(checkoutNotes);
      const errors = dataError?.error;
      changeSubmitProgress(false);

      

      if (errors) {
        setErrors(errors);
        // Show error when the JR Pay Balance is low
        if(errors[0]?.code === 'INVALID'){
          alert.error({
            content: errors[0]?.message,
            title: 'Insufficient JR Pay Balance'
          },{
            timeout: 3000,
          })
        }
        else {
          alert.error({
            content: "An error occur upon creating payment transaction.",
            title: "Error",
          }, {
            timeout: 2000,
          })
        }
      } else {
        removeCheckoutToLocalStorage();
        setErrors([]);
        setCheckoutNotes([]);
        setCartItemNotesCache([]);
        setCheckoutSchedulesCache([]);
        history.push({
          pathname: CHECKOUT_STEPS[3].nextStepLink,
          state: {
            // id: data?.id,
            // orderNumber: data?.number,
            // token: data?.token,

            // checkoutComplete mutation returns array of orders seperated by merchant
            orders: data,
            paymentRedirectUrl
          },
        });
      }
    },
  }));

  return (
    <CheckoutReview
      {...props}
      deliverQuotations={checkout?.deliveryQuotations}
      paymentTransactionType={paymentTransactionType}
      shippingAddress={checkoutShippingAddress}
      billingAddress={checkoutBillingAddress}
      shippingMethodName={checkout?.shippingMethod?.name}
      paymentMethodName={getPaymentMethodDescription()}
      email={checkout?.email}
      errors={errors}
      note={checkout?.note}
    />
  );
};

const CheckoutReviewSubpage = forwardRef(CheckoutReviewSubpageWithRef);

export { CheckoutReviewSubpage };
