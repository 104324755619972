import {useContext, useEffect} from 'react';
import {Context, LayoutProps} from '../Context';

export const useLayout = (options?: Partial<LayoutProps>) => {
	const useLayoutContext = useContext(Context);

	useEffect(() => {
		return () => {
			useLayoutContext.cleanUp();
		};
	}, []);

	if (options) {
		Object.keys(options).forEach((key) => {
			if (key === 'showNavbar') {
				useLayoutContext.setShowNavbar(options[key]);
			}

			if (key === 'showAddressbar') {
				useLayoutContext.setShowAddressbar(options[key]);
			}

			if (key === 'showCategorybar') {
				useLayoutContext.setShowCategorybar(options[key]);
			}

			if (key === 'showScreenTitle') {
				useLayoutContext.setShowScreenTitle(options[key]);
			}

			if (key === 'screenTitle') {
				useLayoutContext.setScreenTitle(options[key]);
			}
		});
	}

	return useLayoutContext;
};
