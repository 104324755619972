import React from 'react';
import { maybe } from '@temp/@next/utils/misc';
import { TypedMainMenuQuery } from '@temp/components/MainMenu/queries';
import { generateStoreCategoryUrl } from '@temp/core/utils';
import { NavLink, useHistory } from 'react-router-dom';
import './index.scss';
import { useMediaQuery } from 'react-responsive';
import {OverlayContext} from '@temp/components';
import { mediumScreen, smallScreen } from '@temp/@next/globalStyles/constants';
import { setMenuItems } from "src/helpers"
import ScrollMenu from 'react-horizontal-scrolling-menu';
import BrandSearchAutocomplete from "src/components/BrandSearchAutocomplete";
import { generateBrandUrl } from "src/core/utils";

interface CategoryBarProps{
	setSearchOverlay?: (status: boolean) => void;

}

const CategoryBar: React.FC<CategoryBarProps> = ({
	setSearchOverlay,
	
}) => {
  const isTablet = useMediaQuery({ maxWidth: mediumScreen });
  const isMobile = useMediaQuery({ maxWidth: smallScreen });
  const history = useHistory();
  const renderSearchBar = () => {
    return (
      <BrandSearchAutocomplete
        setSearchOverlay={setSearchOverlay}
        onChange={(brand) => {
          history.push(generateBrandUrl(brand?.slug))
        }}
      />
    );
  }

const homeMenu = {
  id: "jrmall-storefront-home",
  merchantCategory:
  {
  id: "jrmall-storefront-home==",
  name: "home",
  },
  name: "Home",
  __typename: "MenuItem"
}

  return (
    <OverlayContext.Consumer>
			{(overlayContext) => (
          <div className="category-bar">
          <TypedMainMenuQuery renderOnError displayLoader={false}>
          {({ data }) => {
            const items = maybe(() => data.shop.navigation.main.items, []);
            setMenuItems(items); // Set menu items
            
            const newItems = [homeMenu, ...items];

            const menu: any[] = newItems.map(item => {

              let url = ''
              if(item?.name !== 'Home'){
              url = item?.merchantCategory?.id
                        ? generateStoreCategoryUrl(item?.merchantCategory?.id || "", item?.merchantCategory?.name || "")
                        : "";
              }
              return (
                <NavLink draggable={false} activeClassName={'category-bar--active'} exact to={url} className='category-bar--inactive' key={item?.id}>
                  {item?.name}
                </NavLink>
              );
            });

            return (
              <div className={!isTablet && 'main-container'}>
                <div className="category-bar-main">
                <ScrollMenu
                  // arrowClass="category-bar-main__arrows"
                  // hideSingleArrow={!isTablet && true}
                  // arrowLeft={!isTablet && <i className="fas fa-chevron-left"></i>}
                  // arrowRight={!isTablet && <i className="fas fa-chevron-right"></i>}
                  wrapperClass="category-bar-main__carousel flex-shrink-1"
                  data={menu}
                  alignCenter={isTablet && !isMobile && true}
                />
                  {!isTablet && renderSearchBar()}
                </div>
              </div>
            )
          }}
          </TypedMainMenuQuery>
          </div>
      )}
      </OverlayContext.Consumer>
    
  )
}

export default CategoryBar;