import gql from 'graphql-tag';
import {
	ListGeolocations,
	ListGeolocationsVariables,
	ListReverseGeolocation,
	ListReverseGeolocationsVariables,
} from './types/ListGeolocations';
import {TypedQuery} from '../../core/queries';

const searchGeolocations = gql`
	query searchGeolocations($input: String!) {
		searchGeolocation(input: $input) {
			placeId
			description
			city
			lat
			lon
		}
	}
`;
export const TypedSearchLocations = TypedQuery<
	ListGeolocations,
	ListGeolocationsVariables
>(searchGeolocations);

const reverseGeolocations = gql`
	query reverseGeolocations($lat: Float!, $lng: Float!) {
		reverseGeolocation(latitude: $lat, longitude: $lng) {
			streetAddress1
			streetAddress2
			placeId
			longitude
			latitude
			region
		}
	}
`;
export const TypedReverseGeolocation = TypedQuery<
	ListReverseGeolocation,
	ListReverseGeolocationsVariables
>(reverseGeolocations);
