import { useUserDetails } from "@saleor/sdk";
import * as React from "react";

import { NavLink } from "..";
// import { Link } from "react-router-dom";

// import * as appPaths from "../../app/routes";
// import mailIcon from "../../images/email-icon.svg"
// import phoneIcon from "../../images/phone-icon.svg"
// import facebookIcon from "../../images/facebook-icon-white.svg"
// import instagramIcon from "../../images/instagram-icon-white.svg"
// import jrmallLogo from "../../images/JRMall-Logo-White.png"

// import { TypedSecondaryMenuQuery } from "./queries";
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';
import wechat from '../../images/wechat.jpg';
import jcb from '../../images/jcb.png';
import jrpayIcon from '../../images/jrpay-icon.png';
import joyrideIcon from '../../images/joyride-note-white.png';
import happymoveIcon from '../../images/happymove-note.png';
import paymaya from '../../images/paymaya.png';
import googlePlay from '../../images/google-play.png';
import appStore from '../../images/app-store.png';
import joyrideLogo from '../../images/joyride-logo.png';

import { Link } from 'react-router-dom';
import "./scss/index.scss";
import { useMediaQuery } from "react-responsive";
import { mediumScreen } from "@temp/@next/globalStyles/constants";

const NavItem = (name, url) => {
  return (
    // @ts-ignore
    <NavLink item={{ name, url }} />
  );
}

const DeliveryServices = () => {
  return (
    <>
      <div className="footer-nav__section-header">Delivery Services</div>
      <div className="d-flex">
        <img style={{height: 60, marginRight: 10}} src={joyrideIcon} />
        <img style={{height: 58}} src={happymoveIcon} />
      </div>
    </>
  );
}

const Nav: React.FC<{}> = () => {

  const isTablet = useMediaQuery({ maxWidth: mediumScreen });
  const user = useUserDetails();
    return (
      <footer className="footer-nav">
        <div className="main-container">
          {/* <TypedSecondaryMenuQuery>
            {({ data }) => {
              return data.shop.navigation.secondary.items.map(item => (
                <div className="footer-nav__section" key={item.id}>
                  <h4 className="footer-nav__section-header">
                    <NavLink item={item} />
                  </h4>
                  <div className="footer-nav__section-content">
                    {item.children.map(subItem => (
                      <p key={subItem.id}>
                        <NavLink item={subItem} />
                      </p>
                    ))}
                  </div>
                </div>
              ));
            }}
          </TypedSecondaryMenuQuery> */}
          {/* <div className="footer-nav__section"> */}
          {/* <div className="footer-nav__section-header">
            <Link to={appPaths.baseUrl}>
              <img src={jrmallLogo} className="footer-nav__section-header__logo"/>
            
            </Link>
          </div> */}
            {/* <div className="footer-nav__section-content"> */}
              {/* <span>
              JR Mall is an on-demand delivery platform for food, drinks, groceries, and non-food.
              </span>
              <p>
              We can deliver to cities in Metro Manila and selected areas in Rizal, Cavite, Bulacan, and Laguna.                
              </p>
              <p>
              <img src={mailIcon} className="footer-nav__section-content__icons"/>
              help@jrmall.ph
              </p>
              <p>
              <img src={phoneIcon} className="footer-nav__section-content__icons"/>
                (02) 8864 7777
                </p>
              <p>
                Follow our social media pages
              </p> */}
              {/* <div className="footer-nav__section-content__social">
              <img src={facebookIcon} />
              <img src={instagramIcon}/>
              </div> */}
            {/* </div> */}
          {/* </div> */}
          {/* <div className="footer-nav__section">
            <div className="footer-nav__section-header">
             JR Mall
            </div>
            <div className="footer-nav__section-content">
              <p>
                {NavItem("About", "/page/about/")}
              </p>
              <p>
                {NavItem("Blog", "/")}
              </p>
              <p>
                {NavItem("Careers", "/")}
              </p>
              <p>
                {NavItem("Become a Merchant", "/")}
              </p>
            </div>
          </div> */}
          <div className="footer-nav__section">
            {/* <div>
            <div className="footer-nav__section-header">
             Get to Know Us
            </div>
            <div className="footer-nav__section-content">
            <p>
                {NavItem("About JR Mall", "/")}
              </p>
              <p>
                {NavItem("Our Blog", "/blog")}
              </p>
              </div>
            </div> */}
            <div>
            <div className="footer-nav__section-header">
              Quick Links
            </div>
            <div className="footer-nav__section-content">
            <p>
                {NavItem("Home", "/")}
              </p>
              <p>
                {NavItem("My Account", "/account")}
              </p>
              {/* <p>
                {NavItem("Search Products", "/search/?q=%20")}
              </p> */}
              <p>
                {!user ? NavItem("Checkout", "/checkout/") : NavItem("Checkout", "/cart/") }
              </p>
            </div>
            </div>
            <div>
            <div className="footer-nav__section-header">
              Support
            </div>
            <div className="footer-nav__section-content">
              <p>
                {NavItem("Help Center", "/support/FAQ")}
              </p>
              <p>
                {NavItem("Become a Merchant", "https://become-merchant.jrmall.ph/")}
              </p>
              <p>
                <Link to={'/support/FAQ#bulkOrders'}>Bulk Orders</Link>
                {/* {NavItem("Bulk Orders", "/support/FAQ/bulkOrders")} */}
              </p>
              {/* <p>
                {NavItem("Contact Us", "/support/contact-us")}
              </p> */}
            </div>
            </div>
            <div>
            <div className="footer-nav__section-header">
                Follow Us
            </div>
            <div className="footer-nav__section-content">
              <p>
                {NavItem("Facebook", "https://www.facebook.com/JRMallPH/")}
              </p>
              <p>
                {NavItem("Instagram", "https://www.instagram.com/joyridephilippines/")}
              </p>
          </div>
          {isTablet && DeliveryServices()}
          </div>
          <div>
            <div className="footer-nav__section-header">Payment Methods</div>
            <div className="footer-nav__section-content">
              <img src={jrpayIcon} />
              <img src={visa} />
              <img src={mastercard} />
              <img src={jcb} />
              <img src={paymaya} />
              <img src={wechat} />
            </div>
            {!isTablet && DeliveryServices()}
          </div>
          <div style={{gridRowEnd: 'auto'}}>
          <div className="footer-nav__section-header">Access JR Mall in the JoyRide App!</div>
            <div className="footer-nav__section-download">
              <a className="footer-nav__section-download__image" href="https://joyride.com.ph"><img src={joyrideLogo} /></a>
              <div className="footer-nav__section-download__wrapper">
              <a href="https://play.google.com/store/apps/details?id=com.joyride.rider&hl=en"><img className="footer-nav__section-download__wrapper-child" src={googlePlay} /></a>
              <a href="https://apps.apple.com/ph/app/joyride-delivery-and-pabili/id1482083123"><img className="footer-nav__section-download__wrapper-child" src={appStore} /></a>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="footer-nav__section-terms">
          <div className="main-container">
            <ul >
              {/* <li>
                <Link to={appPaths.baseUrl}>
                  <img src={jrmallLogo} className="footer-nav__section-terms__logo"/>
                </Link>
              </li> */}
              <li><span>© 2021 JR Mall</span></li>
              <li>{NavItem("Terms and Conditions", "/support/terms-and-conditions")}</li>
              <li>{NavItem("Privacy Policy", "/support/privacy-policy")}</li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }

export default Nav;
