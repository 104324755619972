import {media, styled} from '@styles';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
`;

export const TileWrapper = styled.div`
	height: auto;
	margin-bottom: 1.5rem;
`;

export const Header = styled.div`
	width: 100%;
	padding: 1rem;
	background-color: ${(props) => props.theme.colors.secondary};
	color: ${(props) => props.theme.colors.white};
	font-size: 20px;
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const HeaderSmall = styled.div`
	width: 100%;
	border-bottom: none;
	padding-bottom: 1rem;
	font-size: ${(props) => props.theme.typography.h4FontSize};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Content = styled.div`
	padding: 1rem;
	width: 100%;
`;

export const ContentOneLine = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 90%;
	${media.smallScreen`
    flex-direction: column;
    width: 100%;
  `}
`;
export const ContentEdit = styled.div`
	width: 50%;
	${media.smallScreen`
     width: 100%;
	 
  `}
`;

export const ContentEditOneLine = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	> div {
		width: 48%;
		${media.smallScreen`
      width: 100%;
    `}
	}

	${media.smallScreen`
     flex-direction: column;
  `}
`;

export const ContentExtendInput = styled.div`
	width: 60%;
	background-color: ${(props) => props.theme.colors.white};
`;

export const Form = styled.form`
	background-color: ${(props) => props.theme.colors.white};
`;

export const FormButtons = styled.div`
	height: 5rem;
	padding-top: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	button {
		border-radius: 30px;
		margin-left: 2rem;
	}
`;
export const Reset = styled.div`
	font-size: ${(props) => props.theme.typography.baseFontSize};
	font-family: ${(props) => props.theme.typography.secondaryFontFamily};
	text-decoration: underline;
	* {
		color: ${(props) => props.theme.colors.primary} !important;
	}
	padding: 1rem;
`;

export const LinkButton = styled.div`
	width: 100%;
	button {
		border-radius: 30px;
		${media.smallScreen`
    width: 100% !important;
  `}
		span {
			font-weight: 0;
			font-family: ${(props) => props.theme.typography.secondaryFontFamily};
			text-transform: unset;
		}
	}
`;
