import React from "react";
import { AutoComplete } from "antd";
import {Search} from 'react-feather';
import { TypedMerchantBrandQuery } from "./queries";
import { MerchantBrandsEdgeNodeData } from "./types/Brand";
import { useMediaQuery } from "react-responsive";
import { mediumScreen } from "@temp/@next/globalStyles/constants";

export interface LocationAutocompleteProps {
    disabled?: boolean;
    errors?: any;
    name?: string;
    defaultValue?: string | null;
    onChange?: (value: MerchantBrandsEdgeNodeData) => void;
    setSearchOverlay?: (status: boolean) => void;
}

let delaySearch;
const BrandSearchAutocomplete: React.FC<LocationAutocompleteProps> = ({
    disabled = false,
    errors,
    name,
    defaultValue,
    onChange,
    setSearchOverlay,
}) => {
    const [inputValue, setInputValue] = React.useState(defaultValue || "");
    const [loading, setLoading] = React.useState(false);
    const isBlank = !!inputValue && inputValue.trim() === "";
    const isTablet = useMediaQuery({ maxWidth: mediumScreen });

    return (
        <TypedMerchantBrandQuery displayLoader={false} variables={{ search: inputValue }}>
            {({ data, error, loading: dataLoading }) => {
                const isDataLoading = loading || dataLoading;
                const brandResults = (data?.merchantBrands?.edges || []).map(item => item?.node);
                const brandOptions = brandResults?.map(item => ({ value: item?.name, label: item?.name }));

                return (
                    <div id="nav-search" className="category-bar-main__search flex-shrink-1">
                        <Search style={{color: '#152c59' ,zIndex: 10}} className="position-absolute ml-2" />
                        <AutoComplete 
                            dropdownClassName="category-bar-main__search--dropdown"
                            size={'large'}
                            defaultValue={inputValue}
                            placeholder={'Search a brand'} 
                            notFoundContent={isBlank ? "Please enter keyword to search a brand" : isDataLoading ? "Loading..." : "Brand not found" }
                            onChange={(value: any) => {
                                setLoading(true);
                                clearTimeout(delaySearch);
                                delaySearch = setTimeout(() => {
                                    setInputValue(value);
                                    setLoading(false)
                                }, 1000);
                            }}
                            onSelect={(value: string)=>{
                                
                                if (onChange) {
                                    onChange(brandResults?.find(item => item?.name === value));
                                }
                                if (isTablet) { setSearchOverlay(false) }
                                
                            }}
                            options={isDataLoading ? [] : brandOptions} 
                            className="flex-grow-1"
                        />
                    </div>
                );
            }}
        </TypedMerchantBrandQuery>
    );
}

export default BrandSearchAutocomplete;