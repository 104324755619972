import {CheckoutShippingScheduleProps} from '@temp/core/checkoutScheduleUtils';
import {createContext} from 'react';
import {NearbyStoresMerchant} from './graphQL/gqlTypes/NearbyStores';

interface NearbyStoresProps {
	nearByStores: NearbyStoresMerchant[] | null;
	loadMore: () => void;
	loading: boolean;
	hasNextPage: boolean;
}

interface CartItemNotes {
	merchantId: string;
	variantId: string;
	name: string;
	notes: string;
}

export interface UserLocationProps {
	address1: string | null;
	address2: string | null;
	latitude: number | null;
	longitude: number | null;
	placeId?: string | null;
	region?: string | null;
}

export interface QueryParamProps {
	vwProductId?: string | null;
	vwProductCartId?: string | null;
}

export interface LayoutProps {
	cleanUp: () => void;
	screenTitle: string;
	setScreenTitle: (status: string) => void;
	setShowNavbar: (status: boolean) => void;
	setShowAddressbar: (status: boolean) => void;
	setShowCategorybar: (status: boolean) => void;
	setShowScreenTitle: (status: boolean) => void;
	removeUserLocation: () => void;
	setUserLocation: (userLocation: UserLocationProps) => void;
	useUserLocation: () => UserLocationProps;
	useNearbyStores: () => NearbyStoresProps;
	setAddressOverlay: (status: boolean) => void;
	addCartItem: (
		variantId: string,
		qty: number,
		merchantId: string,
		name?: string,
		notes?: string,
	) => void;
	updateCartItem: (variantId: string, qty: number, notes: string) => void;
	removeCartItem: (variantId: string) => any;
	getCartItemNotes: () => CartItemNotes[];
	setCartItemNotes: (data: any) => void;
	getCheckoutSchedules: () => CheckoutShippingScheduleProps[];
	setCheckoutSchedules: (data: any) => void;
	setQueryParams: (data: QueryParamProps) => void;
	showNavbar: boolean;
	showAddressbar: boolean;
	showCategorybar: boolean;
	showScreenTitle: boolean;
}

// default value
const layoutProps: Partial<LayoutProps> = {
	cleanUp: () => {
		//
	},
	screenTitle: '',
	setScreenTitle: (status: string) => {
		//
	},
	setShowNavbar: (status: boolean) => {
		//
	},
	setShowAddressbar: (status: boolean) => {
		//
	},
	setShowCategorybar: (status: boolean) => {
		//
	},
	setShowScreenTitle: (status: boolean) => {
		//
	},
	setUserLocation: (userLocation: UserLocationProps) => {
		//
	},
	removeUserLocation: () => {
		//
	},
	useUserLocation: () => null,
	useNearbyStores: () => null,
	setAddressOverlay: (status: boolean) => {
		//
	},
	addCartItem: (
		variantId: string,
		qty: number,
		merchantId: string,
		name?: string,
		notes?: string,
	) => {
		//
	},
	removeCartItem: (variantId: string) => {
		//
	},
	getCartItemNotes: () => [],
	setCartItemNotes: (data: any) => {
		//
	},
	getCheckoutSchedules: () => [],
	setCheckoutSchedules: (data: any) => {
		//
	},
	setQueryParams: (data: QueryParamProps) => {
		//
	},
	updateCartItem: (variantId: string, qty: number, notes: string) => {
		//
	},
	showNavbar: false,
	showAddressbar: false,
	showCategorybar: false,
	showScreenTitle: false,
};

export const Context = createContext(layoutProps);
