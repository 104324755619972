import { Formik } from 'formik';
import React from 'react';
// import DateTimePicker from "react-datetime-picker";
import { Link } from 'react-router-dom';
import { ErrorMessage } from '@components/atoms';
import { SubDetails } from '@components/containers';
import { useLayout } from '@temp/layout/hooks/useLayout';
// import { Radio } from "@components/atoms";
import * as S from './styles';
import { IProps } from './types';

import placeholderImg from 'images/merchant-placeholder-avatar.jpg';

import { useCart, useCheckout } from '@saleor/sdk';

import '@styles/datetimePicker.css';
import './scss/index.scss';

import moment from 'moment';
import { setCheckoutNotes, getCheckoutNotes } from 'src/helpers';
import {
	generateDefaultShippingSchedule,
	formatStoreOperatingDays,
	getOrderScheduleErrorText,
	CheckoutShippingScheduleProps,
} from 'src/core/checkoutScheduleUtils';

import { generateBranchUrl } from 'src/core/utils';
import { CheckoutCard } from '../../atoms/CheckoutCard/CheckoutCard';
import DateOptions, { DATE_FORMAT } from './DateOptions';
import TimeOptions, { TIME_FORMAT } from './TimeOptions';

const getDefaultNotes = (cartNotes: any[] = [], merchantId: string = '') => {
	// Get the assigned checkout notes from previously saved checkout notes
	// @ts-ignore
	const myNote = getCheckoutNotes().find(
		(item) => item?.merchantId === merchantId,
	);
	if (myNote) {
		return myNote?.notes || '';
	}

	// Generate default notes from product remarks notes
	return (cartNotes || [])
		.filter(
			(item) =>
				item?.merchantId === merchantId &&
				item?.notes &&
				item?.notes.trim().length > 0,
		)
		.map((item) => `${item?.name}: ${item?.notes}`)
		.join(', \n');
};

/**
 * Shipping method selector used in checkout.
 */
const CheckoutShipping: React.FC<IProps> = ({
	shippingMethods,
	selectedShippingMethodId,
	selectShippingMethod,
	errors,
	formId,
	formRef,
}: IProps) => {
	const { items } = useCart();
	const { checkout } = useCheckout();
	const {
		setCheckoutSchedules,
		getCheckoutSchedules,
	}: Partial<any> = useLayout();
	const cachedDataSchedule = getCheckoutSchedules();
	const schedulesSettings = generateDefaultShippingSchedule(
		items,
		checkout?.currentDatetime || null,
	);
	const [deliverySchedules, setDeliverySchedules] = React.useState(
		schedulesSettings,
	);
	const [onDateChange, setOnDateChange] = React.useState(false);
	const [deliveryRemarks, setDeliveryRemarks] = React.useState<any>({});

	const { getCartItemNotes } = useLayout() || {};

	const checkoutShippingAddress = checkout?.shippingAddress
		? {
			...checkout?.shippingAddress,
			phone: checkout?.shippingAddress?.phone || undefined,
		}
		: undefined;

	const onScheduleChange = (
		dateStr: string,
		timeStr: string,
		index: number,
	) => {
		const schedules = deliverySchedules;
		const date =
			!dateStr || !timeStr
				? schedules[index].minSchedule
				: moment(`${dateStr} ${timeStr}`).toDate();

		schedules[index].pickupDate = date;
		schedules[index].errorMessage = getOrderScheduleErrorText(
			date,
			schedules[index],
		);

		setDeliverySchedules(schedules);
		setOnDateChange(!onDateChange);
	};

	const addPlaceholderImage = (event: any) => {
		event.target.src = placeholderImg;
	};

	React.useEffect(() => {
		setDeliverySchedules(
			cachedDataSchedule?.length ? cachedDataSchedule : schedulesSettings,
		);
	}, [items, cachedDataSchedule?.length]);

	return (
		<section>
			<Formik
				initialValues={{
					shippingMethod: selectedShippingMethodId,
				}}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting }) => {
					// Check the whole collections if there's an error or problem on selected date
					let hasScheduleError = false;
					const schedulesWithError = (deliverySchedules || []).map(
						(schedule: any) => {
							const errorSchedule = getOrderScheduleErrorText(
								schedule?.pickupDate,
								schedule,
							);
							if (errorSchedule) {
								schedule.errorMessage = errorSchedule;
								hasScheduleError = true;
							}
							return schedule;
						},
					);

					if (hasScheduleError) {
						setDeliverySchedules(schedulesWithError);
						return;
					}

					const newNotes: any[] = [];
					let checkoutNotes: string = '';
					// @ts-ignore
					const cartNotes = getCartItemNotes() || [];
					cartNotes.map((data) => {
						const find = newNotes.find(
							(item) => item?.merchantId === data?.merchantId,
						);
						if (find) {
							return null;
						}

						const defaultNotes = getDefaultNotes(cartNotes, data?.merchantId);
						const remarks: any = deliveryRemarks || {};
						const generatedNotes =
							remarks[data?.merchantId || ''] || defaultNotes;
						newNotes.push({
							notes: generatedNotes,
							merchantId: data?.merchantId,
						});
						checkoutNotes += generatedNotes + ' \n';
					});

					// Save checkout notes in cache
					setCheckoutNotes(newNotes);
					setCheckoutSchedules(deliverySchedules);

					if (selectShippingMethod) {
						selectShippingMethod(
							// @ts-ignore
							values?.shippingMethod,
							deliverySchedules,
							checkoutNotes,
						);
					}
					setSubmitting(false);
				}}
			>
				{({
					handleChange,
					handleSubmit,
					handleBlur,
					values,
					setFieldValue,
					setFieldTouched,
				}) => {
					return (
						<>
							<S.Title data-test="checkoutPageSubtitle">
								Delivery Schedule
							</S.Title>
							<S.ItemSubTitleContent>
								When do you want your items delivered?
							</S.ItemSubTitleContent>
							<CheckoutCard checkoutDetails={checkoutShippingAddress} />
							<S.ShippingMethodForm
								id={formId}
								ref={formRef}
								onSubmit={handleSubmit}
							>
								{deliverySchedules &&
									deliverySchedules.map(
										(
											{
												merchant,
												minSchedule,
												pickupDate,
												maxLeadTime,
												options,
												closingHours,
												errorMessage,
											}: CheckoutShippingScheduleProps,
											schedIndex: any,
										) => {
											// @ts-ignore
											const defaultNotes = getDefaultNotes(
												getCartItemNotes(),
												merchant?.id,
											);

											const {
												storeHourStart,
												storeHourEnd,
												operatingDays,
												warehouses,
											} = merchant;
											const storeOpen =
												storeHourStart &&
												moment(storeHourStart, 'HH:mm:ss').format('h:mma');
											const storeClose =
												storeHourEnd &&
												moment(storeHourEnd, 'HH:mm:ss').format('h:mma');
											const address =
												warehouses &&
													warehouses.edges &&
													warehouses.edges.length > 0 &&
													warehouses.edges[0].node &&
													warehouses.edges[0].node.address
													? warehouses.edges[0].node.address
													: null;


											const selectedDateString = moment(
												pickupDate || new Date(),
											).format(DATE_FORMAT);
											const selectedTimeString = moment(
												pickupDate || new Date(),
											).format(TIME_FORMAT);

											return (
												<S.ItemContainer
													key={schedIndex}
													error={!!errorMessage}
												>
													<S.Flex>
														<S.Photo>
															<img
																onError={(e) => addPlaceholderImage(e)}
																src={
																	merchant?.logo
																		? merchant?.logo?.url
																		: placeholderImg
																}
															/>
														</S.Photo>
														<S.MerchantDetailsWrapper>
															<S.ItemTitle>
																<Link
																	to={generateBranchUrl(merchant?.slug || '')}
																>
																	{merchant?.storeTitle
																		? merchant?.storeTitle
																		: merchant?.name}
																</Link>
															</S.ItemTitle>
															<S.SubDetailsContainer>
																<SubDetails
																	flex={true}
																	items={[
																		{
																			iconName: 'fas fa-map-pin',
																			paddingLeft: 4,
																			paddingRight: 11,
																			text: address?.city,
																			toolTip: 'Store Address',
																		},
																		{
																			iconName: 'far fa-clock',
																			text:
																				storeHourStart &&
																					storeHourEnd &&
																					operatingDays
																					? `${storeHourStart &&
																					storeHourEnd &&
																					`${storeOpen} - ${storeClose}`
																					} ${operatingDays &&
																					`(${formatStoreOperatingDays(
																						operatingDays,
																					)})`
																					}`
																					: null,
																			toolTip: 'Operating hours - days',
																		},
																	]}
																/>
															</S.SubDetailsContainer>
														</S.MerchantDetailsWrapper>
													</S.Flex>
													<S.DeliveryDateContainer>
														{/* NOTE: Below is another component imported from ant-design  */}
														<span>Select Delivery Timeslot</span>
														<div className="checkout-shipping">
															<DateOptions
																startDateTime={minSchedule}
																operatingDays={operatingDays}
																value={selectedDateString}
																onChange={(selectedTime) =>
																	onScheduleChange(
																		selectedTime,
																		selectedTimeString,
																		schedIndex,
																	)
																}
																options={options}
																exceptionDates={merchant?.exceptionDates}
															/>

															<TimeOptions
																deliveryTimeSlots={
																	merchant?.deliveryTimeSlots || []
																}
																minDate={minSchedule}
																pickDate={pickupDate}
																startTime={storeHourStart}
																endTime={storeHourEnd}
																value={selectedTimeString}
																onChange={(selectedTime) =>
																	onScheduleChange(
																		selectedDateString,
																		selectedTime,
																		schedIndex,
																	)
																}
															/>
														</div>
														{/* End of component */}

														<S.NotesTitle>Notes (Optional)</S.NotesTitle>
														<S.InputRemarks
															rows={5}
															onChange={(e) => {
																const newRemarks: any = deliveryRemarks || {};
																newRemarks[merchant?.id || ''] = e.target.value;
																setDeliveryRemarks(newRemarks);
															}}
															maxLength={250}
															defaultValue={defaultNotes || ''}
															value={deliveryRemarks[merchant?.id || '']}
														/>
													</S.DeliveryDateContainer>
													{errorMessage && (
														<S.ItemErrorText>{errorMessage}</S.ItemErrorText>
													)}
												</S.ItemContainer>
											);
										},
									)}
							</S.ShippingMethodForm>

							{/* <S.Divider /> */}
							{errors && <ErrorMessage errors={errors} />}
						</>
					);
				}}
			</Formik>
		</section>
	);
};

export { CheckoutShipping };
