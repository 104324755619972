import React, {useState} from 'react';
import RegisterForm from '@temp/components/OverlayManager/Login/RegisterForm';
// import {useLayout} from '@temp/layout/hooks/useLayout';
import {Redirect} from 'react-router-dom';
import {signIn} from '@temp/routes/paths';
import './index.scss';
import { useLayout } from '@temp/layout/hooks/useLayout';

const SignUp = () => {
	// useLayout({
	// 	showNavbar: false,
	// 	showAddressbar: false,
	// 	showScreenTitle: true,
	// 	showCategorybar: false,
	// 	screenTitle: 'JR Mall Account',
	// });
	const {setShowCategorybar} = useLayout();
	setShowCategorybar(false);

	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	if (redirectToLogin) {
		return <Redirect to={signIn} />;
	}

	return (
		<div className="main-container bg-white h-100 sign-up">
			<h3 className="sign-up__title">Sign Up</h3>
			<p className="sign-up__subTitle">
				Have an existing account?
				<button
					onClick={() => setRedirectToLogin(true)}
					className="bg-none border-0 sign-in__link"
					data-test="registerTab"
				>
					Login
				</button>
			</p>
			<RegisterForm />
		</div>
	);
};

export default SignUp;
