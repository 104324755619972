import React, {FC} from 'react';
import './index.scss';
import BranchAvatarPlaceholder from '../../../../images/merchant-placeholder-avatar.png';
import { Breadcrumb } from '@temp/components';
import { useMediaQuery } from 'react-responsive';
import { mediumScreen, smallScreen } from '@temp/@next/globalStyles/constants';
import ReactSVG from 'react-svg';
import preparationIcon from '../../../../images/preparation-icon.svg';
import { formatStoreOperatingDays } from '@temp/core/checkoutScheduleUtils';
import { getBrowserType } from '@temp/helpers';
import { X } from 'react-feather';
interface BranchInfoProps {
	name?: string;
	bannerUrl?: string;
	avatarUrl?: string;
	categories?: string[];
	distance?: number | null;
	time?: string;
	breadcrumbs?: Breadcrumb[];
	storeHourStart?: string;
	storeHourEnd?:string;
	operatingDays?: string;
	storeOpen?: string;
	storeClose?: string;
	streetAddress1?: string;
	setShowBranchInfo?: (value: boolean) => void;
	description?: string;
}

const BranchModal: FC<BranchInfoProps> = ({
	name = '[Unknown store name]',
	avatarUrl = '',
	bannerUrl= '',
	categories = [],
	distance,
	time = '',
	breadcrumbs,
	storeHourStart,
	storeHourEnd,
	operatingDays,
	storeOpen,
	storeClose,
	streetAddress1,
	setShowBranchInfo,
	description,
}) => {
	const isMobile = useMediaQuery({ maxWidth: smallScreen });
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const isDesktop = useMediaQuery({ minWidth: mediumScreen });

	return (
		<div className="branch-modal">
			<div className="branch-modal-container">
				<div onClick={()=> setShowBranchInfo(false)} className="branch-modal__closeButton">
					<X size={25} color={'#fff'}/>
				</div>
				<div
					style={{
						height: `${isDesktop ? '300px': '250px'}`,
						width: '100%',
						backgroundImage: `url(${bannerUrl})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
					}}
				/>
				<div
					className="flex-shrink-0 branch-info"
					style={{
						position: 'absolute',
						marginTop: isTablet ? '-5.5rem':'-6.5rem',
						width: isTablet ? '120px' : '150px',
						height: isTablet ? '120px' : '150px',
						transformOrigin: 'bottom left',
						borderRadius: 1000,
						left:0,
						right:0,
						top: isTablet ? '11rem' : '12rem',
						margin: '0 auto',
						background: `url(${avatarUrl ? avatarUrl : BranchAvatarPlaceholder}) #fff`,
						backgroundSize: 'cover',
						backgroundPositionY: 'center',
					}}
				/>
				<div style={{marginTop: `${getBrowserType() === 'Firefox' ? '4.5rem':'3.5rem'}`, paddingBottom: '2rem'}} className="flex-grow-1">
					{/* {!isTablet && <Breadcrumbs propStyle={{display: 'flex', justifyContent: 'center'}} breadcrumbs={breadcrumbs}></Breadcrumbs>} */}
					<h4 className="branch-modal__branchName">{name}</h4>
					<p className="branch-modal__description mt-2">{description}</p>
					<p className="branch-modal__categories mt-2">{categories.join(', ')}</p>
					<div className="mt-3">
					<div className="mt-2 d-flex align-items-center">
						{!!distance && 
						<div className="branch-modal__distance">
							<i className="fa fa-map-marker-alt mr-1"></i> 
							<p>{distance ? `${distance}km` : ''}</p>
						</div>
						}
						<div className={`branch-modal__prepTime ${distance ? 'ml-3': `${isMobile ? 'pl-3':'pl-5'}`}`} >
							<ReactSVG className="mr-1 mt-n1" path={preparationIcon}/> 
							<p>{time || '0 mins'}</p>
						</div>
					</div>
					{storeHourStart && storeHourEnd && operatingDays ?
					<div className="branch-modal__details">
						<p><i style={{fontSize: '13px', marginLeft: '-1.5px'}} className="far fa-clock mr-1"></i>{storeHourStart && storeHourEnd && `${storeOpen} - ${storeClose}`} {operatingDays && `(${formatStoreOperatingDays(operatingDays)})`}</p>
					</div>
					: null}
					<div className="branch-modal__details">
						<p><i className="fas fa-map-pin mr-1"></i>{streetAddress1}</p>
					</div>
					</div>
					{/* <div className="branch-info__details">
						<p><i style={{fontSize: '13.5px', marginLeft: '-1px'}} className="fas fa-map-marker-alt mr-1"></i>{address?.streetAddress2}</p>
					</div> */}
				</div>
			</div>
		<div onClick={()=> setShowBranchInfo(false)} className="branch-modal-overlay"></div>
		</div>
	);
};

export default BranchModal;
