import React, {useState }from "react";

interface MobileQuantityTextFieldProps {
  props?: any;
}
export const MobileQuantityTextField: React.FC<MobileQuantityTextFieldProps> = ({
}: MobileQuantityTextFieldProps) => {

  const [qty, setQty] = useState(1);

  const add = () => {
    setQty(qty+ 1);
  }
  
  const subtract = () => {
    if(qty <= 0) {
      setQty(0);
    }
    else{
      setQty(qty-1);
    }
  }
  return (
      <div className="brand-description__quantityTextField"> 
      <div className="brand-description__quantityTextField__wrapper">
      <button onClick={subtract}><i className="fas fa-minus"></i></button>
      <div>{qty}</div>
      <button onClick={add}><i className="fas fa-plus"></i></button>
      </div>
    </div>
  );
};
