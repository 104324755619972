import './scss/index.scss';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Loader} from '@components/atoms';
import {
	// useOrderDetails,
	useUserDetails,
} from '@saleor/sdk';
import Page from './Page';
import {TypedOrderCreateCheckout} from './graphql/mutations';
import {TypedOrderByTokenQuery} from './graphql/queries';
import {setCheckoutToLocalStorage} from 'src/helpers';

const View: React.FC<RouteComponentProps<{token?: string}>> = ({
	match: {
		params: {token},
	},
}) => {
	// const { data: order, loading } = useOrderDetails({ token });
	const {data: user} = useUserDetails();
	const guest = !user;

	return (
		<TypedOrderByTokenQuery variables={{token}}>
			{({data, error, loading}) => {
				const order = data?.orderByToken;

				if (loading) {
					return <Loader />;
				}

				return (
					<div className="order">
						<div className="order-details main-container">
							<TypedOrderCreateCheckout>
								{(orderCreateCheckout, {loading, data}) => (
									<Page
										loading={loading}
										guest={guest}
										order={order}
										orderCreateCheckout={async () => {
											const data = await orderCreateCheckout({
												variables: {
													orderId: order?.id,
												},
											});

											// @ts-ignore
											const {checkout} = data?.data?.orderCreateCheckout || {};
											if (checkout) {
												setCheckoutToLocalStorage(checkout);
												window.location.pathname = '/checkout/address';
											}
										}}
									/>
								)}
							</TypedOrderCreateCheckout>
						</div>
					</div>
				);
			}}
		</TypedOrderByTokenQuery>
	);
};

export default View;
