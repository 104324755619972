import React, { FC } from 'react';
import { ChevronDown } from 'react-feather';
import './index.scss';
import { useMediaQuery } from 'react-responsive';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
import { useUserDetails } from "@saleor/sdk";
import { useHistory } from 'react-router-dom';
import { searchAddress } from '@temp/routes/paths';
import { useLayout } from '@temp/layout/hooks/useLayout';
interface Addressbar {
	completeAddress?: string;
	setAddressOverlay?: (bool: boolean) => void;
	isLoading?: boolean;
}

const AddressBar: FC<Addressbar> = ({ completeAddress = 'Enter Address', setAddressOverlay, isLoading }) => {
	const { data: user } = useUserDetails();
	const { useUserLocation } = useLayout();
	const { address1 } = useUserLocation() || {};
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const history = useHistory();

	return (
		<div className="address-bar">
			<button
				onClick={() => {
					if (user) {
						history.push(searchAddress);
					} else {
						setAddressOverlay(true);
					}
				}}
				className={`py-3 border-0 d-block ${isTablet && 'w-100'}`}
			>
				<div className="address-bar__container">
					<div className='address-bar__wrapper'>
						<h4 className={`${!isTablet && 'text-white'} address-bar__address`}>Deliver to{' '}
							{isLoading && !address1 ? 'Loading...' : <strong>{address1 || "Enter Address"}</strong>}
						</h4>
					</div>
					<ChevronDown color={`${isTablet ? '#152c59' : '#fff'}`} className="" />
				</div>
			</button>
		</div>
	);
};

export default AddressBar;
