import React from 'react';
import * as S from "./../styles";
import {Package} from 'react-feather';
const NoProduct: React.FC<{}> = ({}) => {

  return (
    <S.Container>
    <Package size={60} color='#76aad3' strokeWidth='1.5px'/>
    <h5>Catch us soon!</h5>
    <p>Stay tuned for more updates</p>
    </S.Container>
  );
}


export default NoProduct;