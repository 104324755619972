import { styled } from "@styles";

export const Loader = styled.div`
  text-align: center;
  margin: 1rem 0;
  button {
    span {
      font-family: ${(props) => props.theme.typography.secondaryFontFamily};
    }
  }
`;