import React from "react";

import { TaxedMoney, SubDetails } from "@components/containers";
import { Thumbnail } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";

import { convertMinutesToHoursDaysString } from "src/helpers";

export const ProductTile: React.FC<IProps> = ({ product, hideStoreTitle, isMerchantProduct }: IProps) => {
  const { pricing, merchant } = product || {};
  
  const price =
    pricing &&
    pricing.priceRange &&
    pricing.priceRange.start
      ? pricing.priceRange.start
      : undefined;

  const address = merchant 
    && merchant?.warehouses
    && merchant?.warehouses.edges.length
    && merchant?.warehouses.edges.length > 0
    ? merchant?.warehouses.edges[0].node.address
    : null;

  const windowSize =  window.onresize = () => {
    if(window.innerWidth <= 540 )
    {return true}
    else{return false}
  }
    
  return (
    <S.Wrapper>
       <S.Image data-test="productThumbnail">
        <Thumbnail source={product} />
      </S.Image>
      <S.DetailsWrapper>
      <S.Merchant>{hideStoreTitle ? merchant?.storeTitle : merchant?.storeTitle}</S.Merchant>
      <S.Title data-test="productTile">{product.name}</S.Title>
      <S.Category>{hideStoreTitle ? '' : product.category?.name}</S.Category>

      {/* <S.Price data-test="productPrice">
        <TaxedMoney taxedMoney={price} />
      </S.Price> */}
      <S.PriceWrapper>
      <SubDetails flex={windowSize()} items={[  
        { component: <S.Price data-test="productPrice">
                      <TaxedMoney taxedMoney={price} />
                    </S.Price> 
        },
        { iconName: "far fa-clock", text: product?.leadTime ? convertMinutesToHoursDaysString(product.leadTime) : null, alignRight: true, toolTip: "Item Preparation Time", fontSize: 12, iconSize: 12, mobileView: true},
      ]} />
      {isMerchantProduct ? '' :
      <S.LocationWrapper>
      <SubDetails
          items={[
            {
              fontSize: 13,
              // iconName: 'fas fa-map-marker-alt',
              // iconSize: 13,
              text: address?.city,
              toolTip: "Store Location",
            },
          ]}
        />
        </S.LocationWrapper>
      }
     </S.PriceWrapper>
     </S.DetailsWrapper>
    </S.Wrapper>
  );
};
