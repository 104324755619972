
interface FBMeResponse {
    id: string | null;
    accessToken: string | null;
    name: string | null;
}

export const FBLoginDialog = () => {
    return new Promise<FBMeResponse>((resolve, reject) => {
        // @ts-ignore
        const facebookLib = FB;
        if (!facebookLib) {
            resolve(null);
        } 

        try {
            facebookLib.login((loginResponse) => {
                const { accessToken } = loginResponse?.authResponse || {};
                if (loginResponse && loginResponse.status === "connected") {
                    facebookLib.api('/me', (responseMe) => {
                        const { id, name } = responseMe || {}
                        resolve({ id, name, accessToken })
                    });
                }    
            }, {scope: 'email'});
        } catch (e) {
            resolve(null);
        }
    });
}