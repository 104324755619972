import * as React from 'react';
import { useUserDetails, useSignOut } from "@saleor/sdk";
import { useHistory } from 'react-router-dom';
import {INavItem, MobileNavList, Overlay, OverlayContextInterface} from '..';

const MobileNav: React.FC<{overlay: OverlayContextInterface}> = ({overlay}) => {
	const items: INavItem[] = overlay?.context?.data || [];
	const { data: user } = useUserDetails();
	const [signOut] = useSignOut();
	const history = useHistory();

	return (
		<Overlay testingContext="mobileNavigationOverlay" context={overlay}>
			<div className="side-nav" onClick={(evt) => evt.stopPropagation()}>
				<MobileNavList 
					user={user} 
					items={items} 
					hideOverlay={overlay.hide} 
					signOut={() => {
						signOut();
						setTimeout(() => history.push("/"), 500);
						overlay.hide();
					}}
				/>
			</div>
		</Overlay>
	);
};

export default MobileNav;
