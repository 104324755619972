
import "./scss/index.scss";
import * as React from "react";

// import { 
//   // Button, Loader, 
//   // // MainMenu, 
//   ProductsFeatured } from "../../components";
// import { generateCategoryUrl } from "../../core/utils";

import StoreFeaturedProducts from "src/components/StoreFeaturedProducts"

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./gqlTypes/ProductsList";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
// import ReactSVG from "react-svg";
// import  JRHorizontalImg  from "../../images/JR_Horizontal_Icon.png";
// import  FeaturedProductImage  from "../../images/featured_product_image.png";
// import  BackgroundImageUrl  from "../../images/hero_wallpaper.png";

import Slides from "./Slides";
// import MerchantsFeatured from "@temp/components/MerchantsFeatured";
// import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      
      <div
        className="home-page__hero"
        // style={
        //   backgroundImage
        //     ? { backgroundImage: `url(${BackgroundImageUrl})` }
        //     : null
        // }
      >
        {/* <header>
        <MainMenu />
      </header> */}
        <div className="container">
        {/* <div className="home-page__hero-text">
          <div className="home-page__hero__wrapper">
            <div className="home-page__hero__wrapper__title">
              <h1>The right helmet for your backrides</h1>
              <div className="home-page__hero__wrapper__title__textwrapper"><h3>Motorcycle helmets available for delivery</h3>
              <h4>in collaboration with <img src={JRHorizontalImg}/>
              </h4>
              </div>
              <div className="home-page__hero-action">
          {loading && !categories ? (
            <Loader />
          ) : (
            categoriesExist() && (
              <Link
                to={generateCategoryUrl(
                  categories.edges[0].node.id,
                  categories.edges[0].node.name
                )}
              >
                <Button className="home-page__hero-action__button" testingContext="homepageHeroActionButton">
                  <span>Shop Now</span>
                </Button>
              </Link>
            )
          )}
        </div>
            </div>
            <span className="home-page__hero__wrapper__product">
              <img src={FeaturedProductImage}/>
            </span>
            
          </div>*/}
          {/* <div>
            <span className="home-page__hero__title">
              <h1>Up to 70% off sale</h1>
            </span>
          </div> */}
        {/* </div>  */}
        
        </div>
      </div>
      <Slides/>

      {categoriesExist() && (
        <div className="home-page__categories">
       
          <div className="container">
            
            {/* <h3>Collections</h3>
            <hr></hr> */}
            {/* <div className="home-page__categories__feature mb-3"></div> */}
            
            {/* <div className="home-page__categories__wrapper">
              <ProductsFeatured />
            </div> */}

            <div className="home-page__categories__wrapper">
              <StoreFeaturedProducts />
            </div>

            <hr style={{paddingBottom: '2rem'}}></hr>          

            {/* <h4 className="home-page__categories__text">All the Best</h4>
            <p className="home-page__categories__subText">Specially curated items just for you</p>
            <hr></hr> */}
            {/* <MerchantsFeatured /> */}
            {/* <h4 className="home-page__categories__text" style={{marginTop: '1rem'}}>JoyRide Originals</h4>
            <p className="home-page__categories__subText">Helmets, bags, caps, masks, raincoats, and more!</p> */}
            {/* <div className="home-page__promo"></div> */}
            {/* <div className="home-page__categories__list">
              {categories.edges.map(({ node: category }) => (
                <div key={category.id}>
                  <Link
                    to={generateCategoryUrl(category.id, category.name)}
                    key={category.id}
                  >
                    <div
                      className={classNames(
                        "home-page__categories__list__image",
                        {
                          "home-page__categories__list__image--no-photo": !category.backgroundImage,
                        }
                      )}
                      style={{
                        backgroundImage: `url(${
                          category.backgroundImage
                            ? category.backgroundImage.url
                            : noPhotoImg
                        })`,
                      }}
                    />
                    <h3>{category.name}</h3>
                  </Link>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      )}
    
    </>
  );
};

export default Page;
