import 'antd/dist/antd.css';

// colors
export const autofillColor = 'rgb(250, 255, 189)';
export const autofillColorSelected = 'rgb(232, 233, 254)';
export const baseFontColor = '#191919';
export const baseFontColorSemiTransparent = 'rgba(50,50,50,0.6)';
export const baseFontColorTransparent = 'rgba(50,50,50,0.1)';
export const baseLightBlue = 'rgba(39,33,215,0.05)';
export const primary = '#152c59';
export const blueOverlay = `rgba(33,18,94,0.1)`;
export const blueOverlayDark = `rgba(33,18,94,0.2)`;
export const darkBlue = '#528FB5';
export const cardBg = '#bddef4';
export const disabledButton = '#536C91';
export const gray = '#767676';
export const grayMedium = '#555555';
export const grayDark = '#323232';
export const green = '#3ed256';
// export const grayLight = "#f1f5f5";
export const grayLight = '#f0f0f0';
export const inputBorder = '#CCCCCC';
export const secondary = '#76aad3';
export const secondaryLight = '#B3D6EC';
export const overlayColor = 'rgba(21, 44, 89, 0.6)';
export const pink = '#e04fd2';
export const rose = '#c22d74';
// export const turquoiseLight = "rgba(6, 132, 123, 0.25)";
// export const turquoiseTransparent = "rgba(6, 132, 123, 0.1)";
export const white = '#fff';
export const secondaryGrey = '#EBF1F6';
export const secondaryGreyDark = '#9D9FB1';
export const secondaryBlue = '#4d98ff';
export const yellow = '#FFBD05';
// theme colors
export const theme = {
	autofill: autofillColor,
	autofillSelected: autofillColorSelected,
	bannerBackground: secondaryGrey,
	bannerEdge: secondaryGreyDark,
	bannerLight: baseLightBlue,
	bannerLink: secondaryBlue,
	baseFont: baseFontColor,
	baseFontColorSemiTransparent,
	baseFontColorTransparent,
	cardBg,
	darkBlue,
	disabled: gray,
	disabledButton,
	divider: grayLight,
	dividerDark: grayMedium,
	error: rose,
	hoverLightBackground: grayLight,
	inputBorder,
	light: grayLight,
	lightFont: gray,
	listAttributeName: baseFontColorSemiTransparent,
	overlay: overlayColor,
	pink,
	primary,
	secondary,
	secondaryBlue,
	secondaryLight,
	secondaryOverlay: blueOverlay,
	secondaryOverlayDark: blueOverlayDark,
	success: green,
	tabsBorder: baseFontColorTransparent,
	white,
};

// typography
export const baseFontFamily = "'RockoRegular', sans-serif";
export const secondaryFontFamily = "'RockoBold'";
export const baseFontSize = '1rem'; // 16px
export const baseLineHeight = '1.25rem'; // 20px
export const boldFontWeight = 500;
export const extraBoldFontWeight = 800;
export const h1FontSize = '4rem'; // 64px
export const h2FontSize = '3rem'; // 48px
export const h1LineHeight = 1;
export const h3FontFamily = "'RockoBold'"; // TTNormsExtraBold
export const h3FontSize = '1.5rem'; // 24px
export const h4FontSize = '1.125rem'; // 18px
export const inputFont = 'sans-serif';
export const labelFontSize = '0.75rem'; // 12px
export const normalFontFamily = "'TTNormsProNormal'";
export const regularFontFamily = "'TTNormsProRegular'";
export const smallFontSize = '0.875rem'; // 14px
export const ultraBigFont = '6rem'; // 96px

// spacing
export const spacer = 1; // rem
export const fieldSpacer = '1.875rem';

// breakpoints
export const xxxLargeScreen = 1920;
export const xxLargeScreen = 1600;
export const xLargeScreen = 1265;
export const largeScreen = 992;
export const mediumScreen = 768;
export const smallScreen = 540;
export const xSmallScreen = 360;
