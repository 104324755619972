import React, {useState} from 'react';
import RegisterForm from '@temp/components/OverlayManager/Login/RegisterForm';
import {useLayout} from '@temp/layout/hooks/useLayout';
import {Redirect} from 'react-router-dom';
import {signIn} from '@temp/routes/paths';
import './index.scss';

const CheckoutGuest = () => {
	useLayout({
		showNavbar: true,
	});

	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	if (redirectToLogin) {
		return <Redirect to={signIn} />;
	}

	return (
		<div className="py-5 bg-white h-100">
			<div className="guest-checkout main-container">
			<div className="guest-checkout-wrapper">
			<h3 className="sign-in__title">Guest Checkout</h3>
			<p className="sign-in__subTitle">
				No account yet? You can create an account later. <br />
				<br />
				We recommend signing up for faster checkout, order tracking, and access
				to exclusive deals and promotions!
			</p>
			<div className="px-4 mb-5">
				<button className="btn-login text-white py-3">Continue as Guest</button>
			</div>
			</div>
			<div className="guest-checkout-wrapper">
			<h3 className="sign-in__title mt-3">Sign Up</h3>
			<p className="sign-in__subTitle">
				Have an existing account?
				<button
					onClick={() => setRedirectToLogin(true)}
					className="bg-none border-0 sign-in__link"
					data-test="registerTab"
				>
					Login
				</button>
			</p>
			<RegisterForm />
			</div>
			</div>
		</div>
	);
};

export default CheckoutGuest;
