
import "./scss/index.scss";

import * as React from "react";
import ReactSVG from "react-svg";

import {
  LoginForm,
  Offline,
  OfflinePlaceholder,
  Online,
  Overlay,
  OverlayContextInterface,
  OverlayTheme,
  OverlayType,
} from "../..";
import RegisterForm from "./RegisterForm";
import logoImg from "../../../images/jrmall-white.svg";
import closeImg from "../../../images/x.svg";
// import ForgottenPassword from "./ForgottenPassword";

class Login extends React.Component<
  { overlay: OverlayContextInterface; active?: "login" | "register" },
  { active: "login" | "register" }
> {
  static defaultProps = {
    active: "login",
  };
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }

  changeActiveTab = (active: "login" | "register") => {
    this.setState({ active });
  };

  render() {
    const { overlay } = this.props;
    const { show, hide } = overlay;

    return (
      <Overlay testingContext="loginOverlay" context={overlay}>
        <div className="login">
          <Online>
            <div className="overlay__header">
              <p className="overlay__header-text"><img src={logoImg}></img> <span>Account</span></p>
              <ReactSVG
                path={closeImg}
                onClick={hide}
                className="overlay__header__close-icon"
              />
            </div>
            {/* <div className="login__tabs">
              <span
                data-test="loginTab"
                onClick={() => this.changeActiveTab("login")}
                className={this.state.active === "login" ? "active-tab" : ""}
              >
                Login
              </span>
              <span
                data-test="registerTab"
                onClick={() => this.changeActiveTab("register")}
                className={this.state.active === "register" ? "active-tab" : ""}
              >
                Sign Up
              </span>
            </div> */}
            <div className="login__content">
              {this.state.active === "login" ? (
                <>
                <h3 className="login__content__title">Login</h3>
                <p className="login__content__subTitle">Don't have an account yet? 
                  <span className="login__content__link" data-test="registerTab" onClick={() => this.changeActiveTab("register")}>
                  Sign Up
                  </span>
              </p>
                <LoginForm onClick={() => show(OverlayType.password, OverlayTheme.right)} hide={hide} />
                </>
              ) : (
                <>
                <h3 className="login__content__title">Sign Up</h3>
                <p className="login__content__subTitle">Have an existing account? 
                  <span className="login__content__link" data-test="loginTab" onClick={() => this.changeActiveTab("login")}>
                  Login
                  </span>
              </p>
                <RegisterForm hide={hide} />
                </>
              )}
            </div>
          </Online>
          <Offline>
            <OfflinePlaceholder />
          </Offline>
        </div>
      </Overlay>
    );
  }
}

export default Login;
