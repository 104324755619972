import * as React from "react";
import { TypedStoreFeaturedProducts } from "./queries";
import { maybe } from "../../core/utils";
import ProductsFeatured from "./ProductsFeature"

const StoreFeaturedProducts: React.FC<{}> = () => {
    return (
        <TypedStoreFeaturedProducts displayError={false}>
        {({ data }) => {
            const merchantsData = maybe(
                () => data.featuredMerchants,
                []
            );
            const merchants = merchantsData.map(data => {
                const merchant = data?.merchant;
                const products = maybe(
                    () => merchant?.products?.edges,
                    []
                );
                return {
                    id: merchant?.id,
                    products: products.map(data => ({
                        ...data?.node,
                    })),
                    slug: merchant?.slug,
                    storeTitle: merchant?.storeTitle,
                }
            });

            return merchants.map(merchant => (
                <ProductsFeatured slug={merchant.slug} merchant={merchant} products={merchant.products} title={merchant.storeTitle} />
            ))
        }}
        </TypedStoreFeaturedProducts>
    )
};

export default StoreFeaturedProducts;